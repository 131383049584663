import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {StatisticsService} from '../statistics.service';
import {DatePipe} from '@angular/common';
import {DateHelper} from '../../service/helpers/date.helper';
import {Hub} from '../../service/models';
import {HubsService} from '../../service/hubs.service';
import {AppComponent} from '../../app.component';
import {DailyMeetingClientsComponent} from './daily-meeting-clients/daily-meeting-clients.component';
import {DailyMeetingOrdersComponent} from './daily-meeting-orders/daily-meeting-orders.component';

@Component({
    selector: 'app-daily-meeting',
    templateUrl: './daily-meeting.component.html',
    styleUrls: ['./daily-meeting.component.scss']
})
export class DailyMeetingComponent implements OnInit {

    public selectedHub: Hub;

    public dailyForm: FormGroup;
    public dailyDataIsLoading: boolean = false;
    public dailyStat;
    public dailyReportDays: string[];

    public todayStat;
    public todayDataIsLoading: boolean = false;

    public maxDailyReportDay: Date;
    public minDailyReportDay: Date;

    constructor(
        private api: StatisticsService,
        protected app: AppComponent,
        protected dataPipe: DatePipe,
        private dateHelper: DateHelper,
        private hubsService: HubsService
    ) {
        this.minDailyReportDay = new Date('2020-11-17');

        this.maxDailyReportDay = new Date();
        this.maxDailyReportDay.setDate((new Date()).getDate() - 1);
    }

    isHoliday(date: string) {
        const dayOfWeek = new Date(date).getDay();
        return dayOfWeek === 6 || dayOfWeek === 0;
    }

    ngOnInit() {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        const weekAgo = new Date();
        weekAgo.setDate(today.getDate() - 7);

        this.dailyForm = new FormGroup({
            date_start: new FormControl(weekAgo),
            date_end: new FormControl(yesterday)
        });

        this.hubsService.find(1).then(hub => {
            this.selectedHub = hub;

            this.loadTodayStat();
        });
    }

    /** Статистика за сегодня */
    public loadTodayStat() {
        this.todayDataIsLoading = true;
        this.todayStat = null;

        this.api.getMeetingTodayStat(this.selectedHub.id)
            .subscribe(data => {
                this.todayStat = data;

                this.todayDataIsLoading = false;
            }, error => {
                this.todayDataIsLoading = false;
            })
    }

    public getTodayStatData(param: string, key: string = null) {
        if (!this.todayStat || !this.todayStat.snapshots) {
            return null;
        }

        let value = null;
        if (key) {
            if (!this.todayStat[key]) {
                return null;
            }

            value = this.todayStat[key][param];
        } else {
            if (!this.todayStat.snapshots[1]) {
                return null;
            }

            value = this.todayStat.snapshots[1][param];
        }

        return value;
    }

    onChangeHub(hub: Hub) {
        this.selectedHub = hub;
        this.loadTodayStat();
        this.loadDailyData();
    }

    public getDailyStatData(report: string, param: string, reportDate: string, defaultValue: any = null) {
        if (!this.dailyStat) {
            return defaultValue;
        }

        if (!this.dailyStat[report]) {
            return defaultValue;
        }

        if (!this.dailyStat[report][reportDate]) {
            return defaultValue;
        }

        return this.dailyStat[report][reportDate][param];
    }

    /** Статистика за период */
    public loadDailyData() {
        this.dailyStat = null;

        const query = this.dailyForm.value;
        query.date_start = this.dataPipe.transform(query.date_start, 'yyyy-MM-dd');
        query.date_end = this.dataPipe.transform(query.date_end, 'yyyy-MM-dd');
        query.hub_id = this.selectedHub.id;

        this.dailyReportDays = this.dateHelper.returnIntervalDays(new Date(query.date_start), new Date(query.date_end));

        this.dailyDataIsLoading = true;
        this.api.getMeetingDailyStat(query)
            .subscribe(data => {
                this.dailyStat = data;

                this.dailyDataIsLoading = false;
            }, error => {
                this.dailyDataIsLoading = false;
            })
    }

    /**
     * Доставляемость заказов (доля выполненных от выданных)
     * @param reportDate
     */
    public getDeliveryRate(reportDate: string) {
        const ordersTotal = this.getDailyStatData('ordersStat', 'orders_on_storage', reportDate, 0);
        if (ordersTotal === 0) {
            return 0;
        }

        return Math.round(10000 * this.getDailyStatData('courierSalary', 'orders_count', reportDate)
            / ordersTotal) / 100;
    }

    /**
     * Доставляемость курьерская (доля доставленных от готовых к доставке)
     * @param reportDate
     */
    public getDeliveryCourierRate(reportDate: string) {
        const ordersTotal = this.getDailyStatData('ordersStat', 'orders_on_delivery', reportDate, 0);
        if (ordersTotal === 0) {
            return 0;
        }

        return Math.round(10000 * this.getDailyStatData('courierSalary', 'orders_count', reportDate)
            / ordersTotal) / 100;
    }

    /**
     * Расчёт косячности - кол-во косяков от готовых к доставке заказов
     * @param reportDate
     */
    public getFailuresRate(type: string, reportDate: string) {
        const totalOrders = this.getDailyStatData('ordersStat', 'orders_on_storage', reportDate, 0);
        if (totalOrders === 0) {
            return 0;
        }

        return Math.round(10000 * this.getDailyStatData('failuresStat', type, reportDate)
            / totalOrders) / 100;
    }

    /**
     * Старая косячность
     * @param reportDate
     */
    public getOldFailuresRate(reportDate: string) {
        const ordersZordersTotal = (this.getDailyStatData('ordersStat', 'orders_on_storage', reportDate, 0)
            + this.getDailyStatData('zordersStat', 'zorders_distributed', reportDate, 0)
        );

        if (ordersZordersTotal === 0) {
            return 0;
        }

        return Math.round(10000 * this.getDailyStatData('failuresStat', 'old_stat_failures', reportDate)
            / ordersZordersTotal)
            / 100;
    }

    public onClientsStatClick(type: string) {
        this.app.createDialog(DailyMeetingClientsComponent, {
            selectedHub: this.selectedHub,
            type: type
        }).onDestroy(data => {

        })
    }

    /**
     * Список заказов
     * @param type
     * @param reportDate
     */
    public showOrdersList(type: string, reportDate: string) {
        let date = new Date(reportDate);
        if (reportDate === 'today') {
            date = new Date();
        }

        this.app.createDialog(DailyMeetingOrdersComponent, {
            selectedHub: this.selectedHub,
            reportDate: date,
            reportType: type
        }).onDestroy(data => {

        })
    }

    /**
     * Возвращает имена незакрытых курьеров
     * @param reportDay
     */
    public getNotClosedCourierNames(reportDay) {
        const couriers = this.getDailyStatData('courierSalary', 'not_closed_couriers', reportDay);
        let courierNames = [];
        for (let i in couriers) {
            courierNames.push(couriers[i].full_name);
        }

        return courierNames.join(', ');
    }

    /** Кол-во заказов в работу */
    public getTodayOrdersToWork() {
        return this.getTodayStatData('orders_on_storage')
            - this.getTodayStatData('orders_searching', 'commonStat')
            - this.getTodayStatData('orders_shifting', 'commonStat')
            - this.getTodayStatData('orders_problem', 'commonStat')
            - this.getTodayStatData('orders_zaval', 'commonStat');
    }
}
