import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SkladService} from 'app/service/sklad.service';
import {HelpersService} from '../../../service/helpers.service';
import {ErrorDialogService} from '../../../components/error-dialog/errordialog.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-return-orders',
    templateUrl: './return-orders.component.html',
    styleUrls: ['./return-orders.component.scss']
})

export class ReturnOrdersComponent implements OnInit {
    public formBarCode: FormGroup;
    public order: any;

    public printOrder;

    hideButton = false;
    loading = false;

    barCodeResult: any;
    public classAlert: string;

    /* устанавливаем фокус на input штрихкода */
    @ViewChild('barCodeInput') barCodeInput: ElementRef;

    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        public error: ErrorDialogService
    ) {
        this.formBarCode = new FormGroup({
            barCode: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {
    }

    returnOrderToWarehouse(event) {
        if (event.keyCode !== 13) {
            return;
        }

        this.loading = true;
        this.barCodeResult = null;
        this.order = null;

        this.api.returnSomethingOnStock({
            userId: null,
            barCode: this.formBarCode.value
        }).subscribe(barcode => {
            this.loading = false;
            this.barCodeResult = barcode;
            this.order = this.barCodeResult.order;
            this.formBarCode.reset();

            if (this.barCodeResult.order.status == 6 || this.barCodeResult.order.status == 7 || this.barCodeResult.order.status == 8) {
                this.classAlert = 'return';
                return;
            } else {
                const diff = new Date(this.barCodeResult.order.delivery_date).getDate() - new Date().getDate();

                if (diff == 0) {
                    this.classAlert = 'today';
                } else {
                    this.classAlert = 'nextday';
                }

                if (this.barCodeResult.order.courier.id == environment.not_founded_courier_id) {
                    this.classAlert = 'notfounded';
                }
                if (this.barCodeResult.order.courier.id == environment.problem_courier_id) {
                    this.classAlert = 'problem';
                }
            }
        });
    }
}
