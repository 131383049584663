import {Injectable} from '@angular/core';
import {LaraService} from './lara.service';

@Injectable({
    providedIn: 'root'
})
export class FinanceService extends LaraService {

    /**
     * Статистика для расчёта курьера
     * @param date
     * @param courier_id
     */
    public getCourierMoneyCheck(date, courier_id) {
        return this.get('/buh/getMoneyCheck', {date: date, courier_id: courier_id});
    }

    /**
     * Получить список штрафов по курьеру
     * @param date
     * @param courier_id
     */
    public getCourierPenalties(date, courier_id) {
        return this.get('/buh/getPenalties', {date: date, courier_id: courier_id});
    }

    /**
     * Получить ЗП курьера
     * @param date
     * @param courier_id
     * @param salary
     */
    public setCourierSalary(date, courier_id, salary) {
        return this.post('/buh/saveCourierSalary', {date: date, courier_id: courier_id, salary: salary});
    }

    /**
     * Логи по изменению ЗП курьера
     * @param date
     * @param courier_id
     */
    public getCourierSalaryLog(date, courier_id) {
        return this.get('/buh/getCourierSalaryLog', {date: date, courier_id: courier_id});
    }

    /**
     * Записать логи по ЗП курьера
     * @param date
     * @param courier_id
     * @param cash_amount
     * @param given_amount
     * @param salary
     */
    public saveCourierSalaryLog(date, courier_id, cash_amount, given_amount, salary) {
        return this.post('/buh/saveCourierSalaryLog', {
            date: date, courier_id: courier_id,
            cash_amount: cash_amount, given_amount: given_amount, salary: salary
        });
    }

    /**
     * Список ХУ заказов
     * @param start_date
     * @param end_date
     */
    public getTroubleOrders(start_date, end_date) {
        return this.get('/buh/getProblemOrders', {start_date: start_date, end_date: end_date});
    }

    /**
     * Лист закрытия курьеров
     * @param start_date
     * @param end_date
     */
    public getCourierCloseList(start_date, end_date, hub_id) {
        return this.get('/buh/getCloseListForCashier', {start_date: start_date, end_date: end_date, hub_id: hub_id});
    }

    /**
     * Лист закрытия курьеров для логистов
     * @param start_date
     * @param end_date
     */
    public getLogistCloseList(start_date, end_date, hub_id) {
        return this.get('/buh/getCloseListForLogist', {start_date: start_date, end_date: end_date, hub_id: hub_id});
    }

    /**
     * Список сдвигов курьеров
     * @param cr_id
     * @param hour
     * @param startdate
     * @param enddate
     */
    public getSdvg(cr_id, hour, startdate, enddate) {
        return this.get('/buh/getSdvg', {
            end_date: enddate, start_date: startdate,
            courier_id: cr_id, hour: hour
        });
    }

    /**
     * Список сдвинутых интервалов курьера
     * @param cr_id
     * @param startdate
     * @param enddate
     */
    public getNotInInterval(cr_id, startdate, enddate) {
        return this.get('/buh/getNotInInterval', {
            end_date: enddate, start_date: startdate,
            courier_id: cr_id
        });
    }


    /**
     * Список косячных заказов курьеров
     * @param cr_id
     * @param startdate
     * @param enddate
     */
    public getKos(cr_id, startdate, enddate) {
        return this.get('/buh/getKos', {end_date: enddate, start_date: startdate, courier_id: cr_id});
    }

    /**
     * Получить внесения денег в электронного кассира
     * @param params
     */
    public getEkassirDeposits(params) {
        return this.post('/e-kassir/stat', params);
    }

    /**
     * Получить данные о последней инкассации
     */
    public getEncashmentInfo(params) {
        return this.post('/e-kassir/stat/getEncashmentInfo', params);
    }

    /**
     * Провести инкассацию
     * @param params
     */
    public processEncashment(params) {
        return this.post('/e-kassir/processEncashment', params);
    }

    /**
     * Отчёт по кассе
     * @param params
     */
    public getKassaReport(params) {
        return this.post('/buh/kassaReport', params);
    }

    /** Чеки налички Орандж */
    public getOrangeCashReceipts(params) {
        return this.post('/buh/kassaReport/orangeCashReceipts', params);
    }

    /** Чеки безнал Орандж */
    public getOrangeCardReceipts(params) {
        return this.post('/buh/kassaReport/orangeCardReceipts', params);
    }

    /** Чеки Apiship */
    public getApishipCashReceipts(params) {
        return this.post('/buh/kassaReport/apishipCashReceipts', params);
    }

    /** Платежи Ibox */
    public getIboxPayments(params) {
        return this.post('/buh/kassaReport/iboxPayments', params);
    }

    /** Список проблем в отчёте по кассе */
    public getKassaReportProblems(params) {
        return this.post('/buh/kassaReport/problems', params);
    }

    /** Пересчёт заказов и заборов */
    public recalcOrders(params) {
        return this.post('/buh/recalcOrders', params);
    }

    public recalcZorders(params) {
        return this.post('/buh/recalcZorders', params);
    }

    /** Переданные заказы */
    getTransferOrders(params) {
        return this.post('/buh/transferOrders', params);
    }
}
