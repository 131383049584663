import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {SkladService} from '../../../service/sklad.service';
import {AppComponent} from '../../../app.component';
import {HelpersService} from "../../../service/helpers.service";

@Component({
    selector: 'app-print-return-act',
    templateUrl: './print-return-act.component.html',
    styleUrls: ['./print-return-act.component.scss']
})
export class PrintReturnActComponent implements OnInit {

    vaktid: string;
    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;
    public loading = false;

    public summaActs;
    public aboutVakt;
    public yaSumm: any = {os: '', places: ''};

    constructor(
        private route: ActivatedRoute,
        private api: SkladService,
        private resolver: ComponentFactoryResolver,
        private app: AppComponent,
        public helpers: HelpersService
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.route.params.subscribe(params => {
            this.vaktid = params['old_vact_id'];
            //this.loading = true;
        });
        //console.log('getReturnAct');

        this.api.getReturnAct(this.vaktid).subscribe((data) => {
            this.aboutVakt = data;
            var total = 0;
            for (var val of data.orders) {
                for (var value of val.returned_goods) {
                    total += (value.count * value.price);
                }
            }
            if (this.helpers.isYandex(this.aboutVakt.client.id)) {
                let summos = 0;
                let summplaces = 0;
                for (const order of this.aboutVakt.orders) {
                    summos += order.bill.os;
                    summplaces += order.hash_bar_codes_count;
                }
                this.yaSumm.os = summos;
                this.yaSumm.places = summplaces;
            }
            this.summaActs = total;
            //console.log('done');
            this.loading = false;

        });


    }

    // кол-во товаров в акте
    getReturnedGoodsAmount() {
        let total = 0;
        for (const order of this.aboutVakt.orders) {
            for (const good of order.returned_goods) {
                total += good.count;
            }
        }

        return total;
    }

    decimal(number) {
        return parseFloat(number).toFixed(2);
    }

    sendMail(vakt) {
        this.api.sendMailReturn(vakt).subscribe(data => {
            console.log(data);
            alert('Письмо отправлено!');
        })
    }

    checkStatus(status: number) {
        switch (status) {
            case 1: {
                return 'Новая';
            }
            case 2: {
                return 'Подтверждена';
            }
            case 3: {
                return 'На доставке';
            }
            case 4: {
                return 'Отгружена';
            }
        }
    }

    printPage() {
        window.print();
    }
}
