import {ButtonsModule, CheckboxModule, WavesModule} from 'angular-bootstrap-md';
import {ClientsRoutingModule} from './clients-routing.module';
import {CommonModule} from '@angular/common';
import {ComponentsModule} from '../components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {GridModule, RowFilterModule} from '@progress/kendo-angular-grid';
import {HttpClientModule} from '@angular/common/http';
import {MatButtonModule, MatFormFieldModule, MatInputModule, MatTabsModule} from '@angular/material';
import {NgModule} from '@angular/core';
import {NgxDaDataModule} from '@kolkov/ngx-dadata';
import {PagesModule} from './pages/pages.module';

import {CalculatorComponent} from '../calculators/calculator/calculator.component';
import {CalculatorsTabComponent} from './../calculators/calculators-tab/calculators-tab.component';
import {EditComponent} from './edit/edit.component';
import {FooterComponent} from './footer/footer.component';
import {HeaderComponent} from './header/header.component';
import {IndexComponent} from './index/index.component';
import {KgtComponent} from '../matrixes/kgt/kgt.component';
import {MatrixComponent} from '../matrixes/matrix/matrix.component';
import {MatrixesTabComponent} from './../matrixes/matrixes-tab/matrixes-tab.component';
import {NavbarComponent} from './navbar/navbar.component';
import {OrdersComponent} from './orders/orders.component';
import {PageContentComponent} from './page-content/page-content.component';
import {PaginationComponent} from './pagination/pagination.component';
import {PersonalOfficeComponent} from './personal-office/personal-office.component';
import {TableMainComponent} from './orders/table-main/table-main.component';
import {TaCalculatorComponent} from './../calculators/ta-calculator/ta-calculator.component';
import {TakeAwayComponent} from '../matrixes/take-away/take-away.component';
import {ToolbarComponent} from './orders/toolbar/toolbar.component';
import {TakeAwayCfoComponent} from '../matrixes/take-away-cfo/take-away-cfo.component';
import {MatrixHubsComponent} from '../matrixes/matrix-hubs/matrix-hubs.component';
import {MatrixTopdeliveryComponent} from '../matrixes/matrix-topdelivery/matrix-topdelivery.component';
import {MatIconModule} from "@angular/material/icon";
import {MatDatepickerModule} from '@angular/material/datepicker';

@NgModule({
    declarations: [
        CalculatorComponent,
        CalculatorsTabComponent,
        EditComponent,
        FooterComponent,
        HeaderComponent,
        IndexComponent,
        KgtComponent,
        MatrixComponent,
        MatrixesTabComponent,
        MatrixTopdeliveryComponent,
        MatrixHubsComponent,
        NavbarComponent,
        OrdersComponent,
        PageContentComponent,
        PaginationComponent,
        PersonalOfficeComponent,
        TableMainComponent,
        TaCalculatorComponent,
        TakeAwayComponent,
        ToolbarComponent,
        TakeAwayCfoComponent
    ],
    imports: [
        ButtonsModule,
        ClientsRoutingModule,
        CheckboxModule,
        CommonModule,
        ComponentsModule,
        FormsModule,
        GridModule,
        HttpClientModule,
        MatButtonModule,
        MatTabsModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatInputModule,
        NgxDaDataModule,
        PagesModule,
        ReactiveFormsModule,
        RowFilterModule,
        WavesModule,
        MatIconModule,
    ],
    exports: [
        FooterComponent,
        HeaderComponent,
        NavbarComponent,
        OrdersComponent,
        PersonalOfficeComponent,
        PageContentComponent,
        PaginationComponent,
        TableMainComponent,
        ToolbarComponent
    ],
    entryComponents: [
        CalculatorComponent,
        TakeAwayCfoComponent
    ]
})

export class ClientsModule {
}
