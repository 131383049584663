import {ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit} from '@angular/core';
import {Observable} from 'rxjs';
import {Title} from '@angular/platform-browser';
import {DaDataConfig, DaDataType} from '@kolkov/ngx-dadata';
import {environment} from '../../../environments/environment';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientInfo, MatrixCategoryResponse, MatrixRegion} from '../../service/models';
import {MatrixService} from '../../service/matrix.service';
import {tap} from 'rxjs/operators';

@Component({
    selector: 'app-calculator',
    templateUrl: './calculator.component.html',
    styleUrls: ['./calculator.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class CalculatorComponent implements OnInit {
    public formCalculator: FormGroup;

    public regionsList: Observable<MatrixRegion[]>;
    public categoryList: MatrixCategoryResponse = new MatrixCategoryResponse();

    public selectedClient: ClientInfo = new ClientInfo({
        id: 0,
        full_name: 'Не выбранно',
        matrix: 'A',
        client_uid: 'N/A'
    });

    public optionsKey = [
        'buyback', 'call', 'equipment', 'fitting', 'opening', 'packaging', 'returned_doc', 'skid_kgt', 'sms'
    ];

    public optionsTitle = [
        'Возврат част. отказ(%)', 'Прозвон клиента', 'Комплектация',
        'Примерка/проверка', 'Вскрытие заказа',
        'Упаковка', 'Возврат накладной', 'Занос КГТ', 'СМС'
    ];

    public optionDesc = {
        option_buyback: 'Возврат част. выкупа',
        option_return: 'Возврат',
        option_call: 'Прозвон клиента',
        option_card: 'Наложенный платеж (безнал)',
        option_cash: 'Наложенный платеж (нал)',
        option_equipment: 'Комплектация',
        option_fitting: 'Примерка/проверка',
        option_opening: 'Вскрытие заказа',
        option_os: 'Объявленная ценность',
        option_packaging: 'Упаковка',
        option_returned_doc: 'Возврат накладной',
        option_sms: 'СМС',
        option_skid_kgt: 'Стоимость подъема КГТ',
        base: 'Базовый тариф (сумма)',
    };

    public result = null;
    public resultKeys: string[] = null;

    public configDaData: DaDataConfig = {
        apiKey: environment.dadata_api_key,
        type: DaDataType.address
    };

    constructor(
        private title: Title,
        private api: MatrixService,
        public cd: ChangeDetectorRef
    ) {
        this.title.setTitle('Калькулятор');

        const options = new FormGroup({});
        for (const key of this.optionsKey) {
            options.addControl(key, new FormControl());
        }

        this.formCalculator = new FormGroup({
            from: new FormControl(77, Validators.required),
            address: new FormControl('', Validators.required),
            is_nds: new FormControl(0),
            cod_cash: new FormControl(''),
            cod_card: new FormControl(''),
            os: new FormControl(''),
            weight: new FormControl('1', Validators.required),
            client_id: new FormControl(),
            category: new FormControl({value: 'A', disabled: false}, Validators.required),
            floor: new FormControl(1),
            cargo_lift: new FormControl(0),
            dimension_side1: new FormControl(1),
            dimension_side2: new FormControl(1),
            dimension_side3: new FormControl(1),
            sms_counter: new FormControl(1),
            options: options
        });
    }

    ngOnInit() {
        /* грузим список регионов */
        this.regionsList = this.api.getMatrixRegionsList().pipe(tap(() => this.cd.detectChanges()));
        /* грузим список категорий */
        this.api.getMatrixCategoryList().subscribe(data => {
            this.categoryList = data;
        });
    }

    public getOptionName(name) {
        if (this.optionDesc[name]) {
            return this.optionDesc[name];
        }
        return name;
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        if (client.full_name) {
            this.formCalculator.get('category').disable();
        } else {
            this.formCalculator.get('category').enable();
        }
        this.formCalculator.controls['client_id'].setValue(client.client_uid);
        this.formCalculator.controls['category'].setValue(client.matrix);
    }

    onSubmit() {
        this.result = null;
        this.resultKeys = null;
        this.api.calculateTariff(this.formCalculator.value).subscribe((data: any) => {
            this.result = data;
            this.resultKeys = Object.keys(data.detail);
            $('body').click();
        });
    }

}
