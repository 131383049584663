import {CommonModule, DatePipe} from '@angular/common';
import {ComponentsModule} from '../components/components.module';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {
    MAT_DATE_LOCALE,
    MAT_TABS_CONFIG,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule,
    MatTabsModule
} from '@angular/material';
import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {BodyModule, GridModule, SharedModule} from '@progress/kendo-angular-grid';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {ActlistComponent} from './actlist/actlist.component';
import {ActCloseComponent} from './act-close/act-close.component';
import {BoardOnlineComponent} from './board-online/board-online.component';
import {CounterComponent} from './board-online/counter.component';
import {DeliveryCouriersComponent} from './transfer/delivery-couriers/delivery-couriers.component';
import {ErrorDialogComponent} from './../components/error-dialog/errordialog.component';
import {ErrorDialogService} from './../components/error-dialog/errordialog.service';
import {IndexComponent} from './transfer/index/index.component';
import {MactlistComponent} from './mactlist/mactlist.component';

import {ProcessingReturnsComponent} from './processing-returns/processing-returns.component';
import {ReceptionCouriersComponent} from './transfer/reception-couriers/reception-couriers.component';
import {ReceptionOrdersComponent} from './reception-orders/reception-orders.component';
import {SberbankComponent} from '../sberbank/sberbank.component';
import {TransferTabComponent} from 'app/storage/transfer/transfer-tab/transfer-tab.component';
import {TurnWorkComponent} from './../turn-work/turn-work.component';
import {ClaimLetterComponent} from './printer/claim-letter/claim-letter.component';
import {ReturnactsComponent} from './returnacts/returnacts.component';
import {ReturnActComponent} from './return-act/return-act.component';
import {ReturnactModalComponent} from './return-act/returnact-modal/returnact-modal.component';
import {PrintCourierMactComponent} from './printer/print-courier-mact/print-courier-mact.component';
import {ActComponent} from './act/act.component';
import {ActModalComponent} from './act/act-modal/act-modal.component';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {DialogService} from '../components/dialog/dialog.service';
import {DialogComponent} from '../components/dialog/dialog.component';
import {CourierInterfaceComponent} from '../couriers/courier-interface/courier-interface.component';
import {CreateTeComponent} from './create-te/create-te.component';
import {TeListComponent} from './te-list/te-list.component';
import {NgxBarcodeModule} from 'ngx-barcode';
import {TransitListComponent} from './transit-list/transit-list.component';
import {NgxPrintModule} from 'ngx-print';
import {PalletCardComponent} from './pallet-card/pallet-card.component';
import {PalletModalComponent} from './pallet-card/pallet-modal/pallet-modal.component';
import {TransitCardComponent} from './transit-card/transit-card.component';
import {TransitModalComponent} from './transit-card/transit-modal/transit-modal.component';
import {TransitPrintComponent} from './printer/transit-print/transit-print.component';
import {ActCreateComponent} from './act-create/act-create.component';
import {PowerOfAttorneyComponent} from './printer/power-of-attorney/power-of-attorney.component';
import { PreAssemblyComponent } from './pre-assembly/pre-assembly.component';
import {ReturnOrdersComponent} from './transfer/return-orders/return-orders.component';
import {OrdersPlacementCheckComponent} from './orders-placement-check/orders-placement-check.component';
import {OrdersPlacementReportComponent} from './orders-placement-report/orders-placement-report.component';
import {PalletListComponent} from './pallet/pallet-list/pallet-list.component';
import {PalletItemComponent} from './pallet/pallet-item/pallet-item.component';
import {PalletEditModalComponent} from './pallet/pallet-edit-modal/pallet-edit-modal.component';
import {TransitsListComponent} from './transits/transits-list/transits-list.component';
import {TransitsItemComponent} from './transits/transits-item/transits-item.component';
import {TransitDriverModalComponent} from './transits/transit-driver-modal/transit-driver-modal.component';
import {DropDownsModule} from '@progress/kendo-angular-dropdowns';
import { TransitReceiveComponent } from './transits/transit-receive/transit-receive.component';
import {TransitVehicleModalComponent} from './transits/transit-vehicle-modal/transit-vehicle-modal.component';
import {SpbControlComponent} from '../logistics/spb-control/spb-control.component';
import {ZoneGroupAddComponent} from '../logistics/zone-redactor/zone-group-add/zone-group-add.component';
import {EKassirComponent} from '../finance/e-kassir/e-kassir.component';
import {MatSelectModule} from '@angular/material/select';
import {KassaReportComponent} from '../finance/kassa-report/kassa-report.component';
import {KassaReportOrdersComponent} from '../finance/kassa-report/kassa-report-orders/kassa-report-orders.component';
import {KassaReportProblemsComponent} from '../finance/kassa-report/kassa-report-problems/kassa-report-problems.component';
import {RecalculateOrdersComponent} from '../finance/recalculate-orders/recalculate-orders.component';
import {TransferOrdersComponent} from '../finance/transfer-orders/transfer-orders.component';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatRadioModule} from '@angular/material/radio';
import { TransitCarrierModalComponent } from './transits/transit-carrier-modal/transit-carrier-modal.component';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {MatIconModule} from '@angular/material/icon';
import {SecondShiftAssemblingComponent} from './second-shift-assembling/second-shift-assembling.component';
import {SecondShiftControlAssemblingComponent} from './second-shift-control-assembling/second-shift-control-assembling.component';
import {SecondShiftAssemblingOrdersComponent} from './second-shift-assembling-orders/second-shift-assembling-orders.component';


@NgModule({
    declarations: [
        ActlistComponent,
        ActCloseComponent,
        BoardOnlineComponent,
        CounterComponent,
        DeliveryCouriersComponent,
        ErrorDialogComponent,
        DialogComponent,
        IndexComponent,
        MactlistComponent,

        ProcessingReturnsComponent,
        ReceptionOrdersComponent,
        ReceptionCouriersComponent,
        ReturnOrdersComponent,
        OrdersPlacementCheckComponent,
        OrdersPlacementReportComponent,
        ReceptionOrdersComponent,
        SecondShiftAssemblingComponent,
        SberbankComponent,
        CourierInterfaceComponent,
        TransferTabComponent,
        TurnWorkComponent,
        ClaimLetterComponent,
        ReturnactsComponent,
        ReturnActComponent,
        ReturnactModalComponent,
        PrintCourierMactComponent,
        ActComponent,
        ActModalComponent,
        CreateTeComponent,
        TeListComponent,
        TransitListComponent,
        PalletListComponent,
        PalletItemComponent,
        PalletCardComponent,
        PalletModalComponent,
        PalletEditModalComponent,

        TransitsListComponent,
        TransitsItemComponent,
        TransitDriverModalComponent,
        TransitVehicleModalComponent,

        TransitCardComponent,
        TransitModalComponent,
        TransitPrintComponent,

        PowerOfAttorneyComponent,
        ActCreateComponent,
        PreAssemblyComponent,
        TransitReceiveComponent,
        SpbControlComponent,
        EKassirComponent,
        KassaReportComponent,
        KassaReportOrdersComponent,
        KassaReportProblemsComponent,
        ZoneGroupAddComponent,
        RecalculateOrdersComponent,
        TransitCarrierModalComponent,
        SecondShiftControlAssemblingComponent,
        SecondShiftAssemblingOrdersComponent,
        TransferOrdersComponent,

    ],
    imports: [

        CommonModule,
        ComponentsModule,
        FormsModule,
        MatDialogModule,
        GridModule,
        MatTabsModule,
        MatButtonModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        WindowModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        NgxBarcodeModule,
        NgxPrintModule,
        DropDownsModule,
        MatSelectModule,
        BodyModule,
        SharedModule,
        MatCheckboxModule,
        MatRadioModule,
        NgxMatFileInputModule,
        MatIconModule
    ],
    exports: [
        ActlistComponent,
        ActCloseComponent,
        BoardOnlineComponent,
        IndexComponent,
        MactlistComponent,

        ProcessingReturnsComponent,
        ReceptionCouriersComponent,
        ReceptionOrdersComponent,
        TurnWorkComponent,
        ReturnActComponent

    ],
    providers: [
        DatePipe,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        DialogService,
        ErrorDialogService,
        /*{
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: HttpConfigInterceptor,
        },*/
        {provide: MAT_TABS_CONFIG, useValue: {animationDuration: '0ms'}},
        [{provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}]
    ],
    entryComponents: [
        DialogComponent,
        DeliveryCouriersComponent,
        DialogComponent,
        ErrorDialogComponent,
        ReceptionCouriersComponent,
        ReturnOrdersComponent,
        ReturnactModalComponent,
        ActModalComponent,
        PalletModalComponent,
        PalletEditModalComponent,
        TransitModalComponent,
        TransitDriverModalComponent,
        TransitVehicleModalComponent,
        TransitCarrierModalComponent,
        ZoneGroupAddComponent,
        KassaReportOrdersComponent,
        KassaReportProblemsComponent,
        SecondShiftAssemblingOrdersComponent
    ]
})

export class StorageModule {
}
