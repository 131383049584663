/**
 * Описание ответов апи
 */
import {
    ApiKey,
    ClientInfo,
    ClientSetting,
    ClientSettingKey,
    MatrixData,
    MatrixKgt,
    MatrixRegion,
    MatrixTakeAway,
    User
} from './models';


export class ApiResult {
    public static: number;
    public response: any;
}

export class ApiClientInfo {
    public client: ClientInfo;
    public users: User[];
    public api_keys: ApiKey[];
    public api_key_active?: ApiKey;
    public matrix: {
        base: MatrixData[],
        kgt: MatrixKgt,
        regions: MatrixRegion[],
        take_away?: MatrixTakeAway[],
        take_away_default: MatrixTakeAway[],
    };
    public settings: ClientSetting[];
    public setting_keys: ClientSettingKey[];
}
