import {Component, OnInit} from '@angular/core';
import {ApiClientInfo} from '../../../service/api_result';
import {AppComponent} from '../../../app.component';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {CurrentUserService} from '../../../service/current-user.service';
import {User} from '../../../service/models';
import {HelpersService} from '../../../service/helpers.service';

@Component({
    selector: 'app-client-dialog',
    templateUrl: './client-dialog.component.html',
    styleUrls: ['./client-dialog.component.scss'],
    providers: [ColumnResizingService]
})
export class ClientDialogComponent implements OnInit {

    public clientInfo: ApiClientInfo;

    public user: User;

    public width;

    public index = 1;

    public optionsKey = [
        'buyback', 'call', 'equipment', 'fitting', 'opening', 'packaging', 'returned_doc', 'sms',
        'cod_cash', 'cod_card', 'os'
    ];

    public optionsTitle = [
        'Возврат част. отказ(%)', 'Прозвон клиента (за день до доставки)', 'Комплектация',
        'Примерка/проверка электроники', 'Вскрытие заказа',
        'Упаковка', 'Возврат накладной', 'Стоимость СМС',
        'Наложенный платеж (нал) %', 'Наложенный платеж (безнал) %', 'Объявленная ценность %'
    ];

    constructor(
        protected app: AppComponent,
        protected currentUser: CurrentUserService,
        public helper: HelpersService,
    ) {
        this.width = window.innerWidth * 0.6;
        this.currentUser.get().subscribe((user: User) => {
            this.user = user
        });
    }

    ngOnInit() {
    }

    getKgtValue(mass) {
        return this.clientInfo.matrix.kgt.matrix[mass];
    }

    getTakeAway() {
        return this.clientInfo.matrix.take_away.length ? this.clientInfo.matrix.take_away : this.clientInfo.matrix.take_away_default;
    }

    onClose() {
        this.app.closeDialog(this);
    }

    openTabs(index) {
        this.index = index;
    }

    parkingType(type) {
        switch (type) {
            case 1 : {
                return 'Нет';
            }
            case 2 : {
                return 'Оплата всех парковок';
            }
            case 3 : {
                return 'Оплата парковок в БЦ';
            }
            default : {
                return 'Информация отсутствует'
            }
        }
    }
}
