import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";

interface CourierType {
    name: string,
    value: number
}

@Component({
    selector: 'app-courier-type-select',
    templateUrl: './courier-type-select.component.html',
    styleUrls: ['./courier-type-select.component.scss']
})
export class CourierTypeSelectComponent implements OnInit {
    @Input() protected multi = false;
    @Input() placeHolder = 'Тип курьера';
    @Output() onChange = new EventEmitter<number | number[]>();

    dropdownList: CourierType[] = [];
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    constructor(
        private api: LaraService,
    ) {
    }

    ngOnInit(): void {
        this.api.getCourierTypes().subscribe(data => {
            this.dropdownList = data;
        })
        this.selectedItems = [];
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: 3,
            allowSearchFilter: true
        };
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.value));
    }
}
