import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClientInfo} from '../../service/models';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';

@Component({
    selector: 'app-actlist',
    templateUrl: './actlist.component.html',
    styleUrls: ['./actlist.component.scss']
})
export class ActlistComponent implements OnInit {
    public selectedClient: ClientInfo;
    public loadData = false;
    public date = null;
    public gridData: GridDataResult;
    public formActs: FormGroup;
    private pageTitle = 'Акты приема-передачи';
    public pageSize = 10;
    public skip = 0;
    @ViewChild('input_date') inputDate: ElementRef;

    constructor(private title: Title,
                private api: SkladService,
                public helpers: HelpersService
    ) {
        this.title.setTitle(this.pageTitle);
        this.formActs = new FormGroup({
            inputDate: new FormControl('', Validators.required)
        });
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
    }

    ngOnInit() {
    }

    getActLink(data) {
        return [
            '/print-mactcr',
            {
                'actId': data
            }
        ]
    }

    onDateChange() {
        this.date = this.inputDate.nativeElement.value;
    }

    onFormSubmit() {
        this.skip = 0;
        this.loadActs();
    }

    public loadActs() {
        this.loadData = true;
        if (!this.date) {
            alert('Не указана дата');
            return;
        }
        const clientId = (this.selectedClient) ? this.selectedClient.id : 0;
        this.api.getClientActs(this.pageSize, this.skip, this.helpers.formatDateForSQL(this.date), clientId).subscribe(data => {
            this.gridData = {data: data.trans_acts, total: data.total};
            this.loadData = false;
        }, error => {
            this.loadData = false;
        });
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadActs();
    }
}
