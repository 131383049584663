import {Component, OnDestroy, OnInit} from '@angular/core';
import {StatisticsService} from '../statistics.service';

@Component({
    templateUrl: './logsis-pulse.component.html',
    styleUrls: ['./logsis-pulse.component.scss'],
    selector: 'app-logsis-pulse'
})

export class LogsisPulseComponent implements OnInit, OnDestroy {
    public data;

    public loading: boolean = false;

    public timer: number;
    // интервал обновления в секундах
    private updateIntervalSeconds: number = 10;
    private updateInterval;
    private timerInterval;

    constructor(private statisticsService: StatisticsService) {
    }

    ngOnInit() {
        this.loadData();

        this.setIntervals();
    }

    private setIntervals() {
        this.timer = this.updateIntervalSeconds;
        this.updateInterval = setInterval(() => {
            this.loadData();
            this.timer = this.updateIntervalSeconds;
        }, this.updateIntervalSeconds * 1000);

        this.timerInterval = setInterval(() => {
            if (this.timer > 0) {
                this.timer -= 1;
            }
        }, 1000);
    }

    private clearIntervals() {
        clearInterval(this.updateInterval);
        clearInterval(this.timerInterval);
    }

    ngOnDestroy() {
        this.clearIntervals();
    }

    public loadData() {
        this.loading = true;
        this.statisticsService.getLogsisPulse()
            .subscribe(data => {
                this.data = data;
                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    public loadDataManually() {
        this.loadData();

        this.clearIntervals();
        this.setIntervals();
    }
}
