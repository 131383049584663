import {Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges} from '@angular/core';
import {Order, OrdersRequest, User} from '../../service/models';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../dialog/dialog.service';
import {Subscription} from 'rxjs';
import {LaraService} from '../../service/lara.service';
import {CurrentUserService} from "../../service/current-user.service";
import {CoordsHelper} from "../../service/helpers/coords.helper";

@Component({
    selector: 'app-order-map',
    templateUrl: './order-map.component.html',
    styleUrls: ['./order-map.component.scss']
})
/**
 * @deprecated Use LocationMapComponent
 */
export class OrderMapComponent implements OnInit, OnDestroy, OnChanges {
    protected ymaps: any;
    protected instance: any;
    public isOpen = false;
    public point: any = null;
    public addressPoint: any = null;
    public btnHide = false;
    public headerShow = true;
    public enableRed = false;
    public updatedAddr: Array<string> = Array(1);

    private applyCoordsButton = null;
    private searchResults = [];
    public searchResultCoords = null;

    protected loader: Subscription;
    public user;

    @Output() addrUpdate = new EventEmitter<any>();
    @Output() mapOpenedStateChange = new EventEmitter<boolean>();
    @Output() pointCoordsUpdated = new EventEmitter<any>();
    @Output() progress = new EventEmitter<any>();
    @Input('query') public query: OrdersRequest;
    @Input('zquery') public zquery;
    @Input('lon') public lon = null;
    @Input('lat') public lat = null;
    @Input('selected') public selected: string[] = [];
    @Input('mark') public mark = false;
    @Input('opened') public opened: boolean;
    @Input('header') public header: boolean;
    public orderPointVisible: boolean = true;
    @Input('addressPointLat') private addressPointLat = null;
    @Input('addressPointLon') private addressPointLon = null;
    @Input('addressPointLabel') private addressPointLabel;
    @Input('addressPointVisible') public addressPointVisible;

    constructor(
        protected api: LaraService,
        protected currentUser: CurrentUserService,
        protected helper: HelpersService,
        protected dialog: DialogService
    ) {
        if (!this.lon || !this.lat) {
            return;
        }
        this.currentUser.get().subscribe(user => {
            this.user = user;
        })
    }

    ngOnInit() {
        if (this.opened) {
            this.isOpen = true;
        }
        if (!this.header) {
            this.headerShow = false;
            this.btnHide = true;
        }

        this.addressPointVisible = (null !== this.addressPointLat && null !== this.addressPointLon && !CoordsHelper.IsCoordsEquals([
            this.lat, this.lon
        ], [
            this.addressPointLat, this.addressPointLon
        ]));
    }

    ngOnDestroy(): void {
        if (this.loader) {
            this.loader.unsubscribe();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        console.warn('ch', changes);

        if (null !== this.point && ('undefined' !== typeof changes['lat'] || 'undefined' !== typeof changes['lon'])) {
            this.point.geometry.setCoordinates([this.lat, this.lon]);
        }

        if (null !== this.addressPoint && ('undefined' !== typeof changes['addressPointLat'] || 'undefined' !== typeof changes['addressPointLon'])) {
            this.addressPoint.geometry.setCoordinates([this.addressPointLat, this.addressPointLon]);
        }

        this.setAddressPointVisibility(!CoordsHelper.IsCoordsEquals([
            this.lat, this.lon
        ], [
            this.addressPointLat, this.addressPointLon
        ]));

    }

    onOpen() {
        this.isOpen = true;
        this.btnHide = true;
        this.mapOpenedStateChange.emit(true);
    }

    onClose() {
        this.isOpen = false;
        this.ngOnDestroy();
        this.btnHide = false;
        this.mapOpenedStateChange.emit(false);
    }

    loadMap(event) {
        this.ymaps = event.ymaps;
        this.instance = event.instance;
        this.loadOrders();
    }

    applyCoords(coords) {
        const myRevengeGeocoder = new this.ymaps.geocode(coords);
        myRevengeGeocoder.then(res => {
            const mdata = res.geoObjects.get(0).properties.get('metaDataProperty');
            console.log(mdata.GeocoderMetaData.text);
            this.updatedAddr[0] = mdata.GeocoderMetaData.text;
        });
        if (this.zquery) {
            this.progress.emit(true);
            this.api.setCoordinatesZorder(coords, this.zquery).subscribe(data => {
                this.updatedAddr[1] = coords.join(', ');
                this.addrUpdate.emit(this.updatedAddr);
                this.pointCoordsUpdated.emit(coords);
                this.progress.emit(false);
            });
        } else if (this.query) {
            this.progress.emit(true);
            this.api.setCoordinatesOrder(coords, this.query).subscribe(data => {
                console.log(data);
                this.pointCoordsUpdated.emit(coords);
                this.progress.emit(false);
            });
        }
    }

    bindEventMap() {
        if (this.helper.checkPermissions('orders:change')) {
            if (this.query && this.enableRed || this.zquery) {
                this.applyCoordsButton = new this.ymaps.control.Button({
                    data: {
                        content: '<b>Применить найденное</b>'
                    },
                    options: {
                        maxWidth: [175],
                        visible: false,
                    },
                    state: {
                        selected: true,
                    }
                });

                const toggleEditButton = new this.ymaps.control.Button({
                    data: {
                        content: '<b>Редактировать</b>'
                    },
                    options: {
                        maxWidth: [150]
                    }
                });

                this.instance.controls.add(this.applyCoordsButton);
                this.instance.controls.add(toggleEditButton);

                const searchControl = this.instance.controls.get('searchControl');
                searchControl.events
                    .add('load', () => {
                        this.searchResults = searchControl.getResultsArray();

                        if (1 === this.searchResults.length) {
                            this.searchResultCoordsSet(this.searchResults[0].geometry.getCoordinates());
                        } else {
                            this.searchResultCoordsSet(null);
                        }
                    }, this)
                    .add('resultshow', e => {
                        let result = null;
                        if ('undefined' !== typeof this.searchResults[e.originalEvent.index]) {
                            result = this.searchResults[e.originalEvent.index];
                        }

                        this.searchResultCoordsSet(result ? result.geometry.getCoordinates() : null);
                    }, this)
                ;

                this.applyCoordsButton.events
                    .add('click', () => {
                        if (this.searchResultCoords) {
                            searchControl.clear();
                            this.point.geometry.setCoordinates(this.searchResultCoords);
                            this.applyCoords(this.searchResultCoords);
                            this.searchResultCoordsSet(null);
                        }
                    });

                toggleEditButton.events
                    .add('select', e => {
                        this.point.options.set({draggable: true});
                    })
                    .add('deselect', e => {
                        this.point.options.set({draggable: false});
                        this.applyCoords(this.point.geometry.getCoordinates());
                    });
            }
        }
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    loadOrders() {
        if (this.query) {
            this.api.getOrder(this.query).subscribe((data: Order) => {
                this.enableRed = ((data.status < 5) || (data.status == 9));
                this.renderPoints(data);
                this.bindEventMap();
            });
        } else if (this.zquery) {
            this.api.getZorder(this.zquery).subscribe((data) => {
                console.log(data);
                this.renderPoints(data);
                this.bindEventMap();
            });
        }
    }

    renderPoints(order) {
        let num;
        let lat;
        let lon;
        console.log(order);
        if (order.uid) {
            num = order.uid;
            lat = order.target.lat;
            lon = order.target.lon;
        } else {
            num = order.zorder.id;
            lat = order.zorder.warehouse.lat;
            lon = order.zorder.warehouse.lon;
        }

        this.point = new this.ymaps.GeoObject({
                type: 'Feature',
                objectID: num,
                id: num,
                properties: {
                    iconContent: num,
                    objectID: num,
                    pointtype: 'order'
                    /*hintContent: this.helper.getDeliveryTimePeriod(order.delivery_time),
                    objectweight: order.option.weight*/
                },
                geometry: {
                    type: 'Point',
                    coordinates: [lat, lon]
                }
            },
            {
                preset: 'islands#blackStretchyIcon',
                draggable: false,
                visible: this.orderPointVisible,
            }
        );

        this.addressPoint = new this.ymaps.GeoObject({
                type: 'Feature',
                properties: {
                    iconContent: this.addressPointLabel,
                    pointtype: 'order'
                },
                geometry: {
                    type: 'Point',
                    coordinates: [this.addressPointLat, this.addressPointLon]
                }
            },
            {
                preset: 'islands#greenStretchyIcon',
                draggable: false,
                visible: this.addressPointVisible,
            }
        );

        // console.warn([lat, lon], [this.addressPointLat, this.addressPointLon]);

        this.setOrderPointVisibility(this.orderPointVisible);
        this.setAddressPointVisibility(this.addressPointVisible);

        this.autoScale();
    }

    private searchResultCoordsSet(coords) {
        this.searchResultCoords = coords;

        if (null === this.applyCoordsButton) {
            return;
        }

        this.applyCoordsButton.options.set({visible: !!coords});
    }

    onMapBaloonEvent(event) {
        if ('balloonopen' === event.event.originalEvent.type) {
            const balloonData = event.event.originalEvent.currentTarget.balloon.getData();
            this.searchResultCoordsSet(balloonData.geometry.getCoordinates());
        }

        if ('balloonclose' === event.event.originalEvent.type) {
            this.searchResultCoordsSet(null);
        }
    }

    onToggleAddressPointVisibility() {
        this.setAddressPointVisibility(!this.addressPointVisible);
    }

    setAddressPointVisibility(visible) {
        this.addressPointVisible = visible;
        if (null === this.addressPoint) {
            return;
        }

        this.addressPoint.options.set({
            visible: this.addressPointVisible,
        });

        if (this.addressPointVisible) {
            this.instance.geoObjects.add(this.addressPoint);
        } else {
            this.instance.geoObjects.remove(this.addressPoint);
        }
    }

    onToggleOrderPointVisibility() {
        this.setOrderPointVisibility(!this.orderPointVisible);
        this.point.options.set({
            visible: this.orderPointVisible,
        });
    }

    setOrderPointVisibility(visible) {
        this.orderPointVisible = visible;
        if (null === this.point) {
            return;
        }

        this.point.options.set({
            visible: this.orderPointVisible,
        });

        if (this.orderPointVisible) {
            this.instance.geoObjects.add(this.point);
        } else {
            this.instance.geoObjects.remove(this.point);
        }
    }

    isToggleAddressPointVisible() {
        return (null !== this.addressPointLat && null !== this.addressPointLon);
    }

    isToggleOrderPointVisible() {
        return (null !== this.lat && null !== this.lon);
    }

    autoScale() {
        let geoObjectsSize = 0;
        this.instance.geoObjects.each(() => {
            geoObjectsSize++;
        });

        if (2 > geoObjectsSize) {
            return;
        }

        this.instance.setBounds(this.instance.geoObjects.getBounds(), {
            checkZoomRange: true,
            zoomMargin: 50,
        });
    }

    onAutoScale() {
        this.autoScale();
    }
}
