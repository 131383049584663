import {Component, OnInit, ViewChild} from '@angular/core';
import {LaraService} from '../../../service/lara.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';

@Component({
    selector: 'app-roles-control',
    templateUrl: './roles-control.component.html',
    styleUrls: ['./roles-control.component.scss']
})
export class RolesControlComponent implements OnInit {

    public rolesList;
    public permissions;
    public modal_role;
    public loading = false;
    public formRole: FormGroup;
    public formPermissions: FormGroup;
    @ViewChild('closeModal') private closeModal;
    constructor(
      private api: LaraService
    ) {
        this.formRole = new FormGroup({
            name: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required)
        });
        this.formPermissions = new FormGroup({
            name: new FormControl('', Validators.required),
            description: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {
        this.getRoles();
        this.getPermissions();
    }
    getRoles() {
        this.api.getRoles().subscribe(data => {
            this.rolesList = data;
        })
    }
    getPermissions() {
        this.api.getMembersPermissions().subscribe(data => {
            this.permissions = data;
        })
    }
    create() {
        this.loading = true;
        if (this.modal_role) {
            let val = this.permissions.filter(item => {
                return item.checked;
            });
            val = val.map(x => x.id);
            const send = this.formRole.value;
            send.permissions = val;
            this.api.createRole(send).subscribe(data => {
                this.getRoles();
                this.formRole.reset();
                this.resetPermissions();
                this.hideModal();
                this.loading = false;
            })
        } else {
            this.api.createPermission(this.formPermissions.value).subscribe(data => {
                this.formPermissions.reset();
                this.hideModal();
                this.getPermissions();
                this.loading = false;
            })
        }
    }
    validate() {
        if (this.modal_role) {
            if (this.formRole.value.name && this.formRole.value.description) {
                return false;
            }
            return true;
        } else {
            if (this.formPermissions.value.name && this.formPermissions.value.description) {
                return false;
            }
            return true;
        }
    }
    setPermission(e, id) {
        for (const permission of this.permissions) {
            if (permission.id === id) {
                permission.checked = e.target.checked
            }
        }
    }
    resetPermissions() {
        for (const permission of this.permissions) {
            permission.checked = false;
        }
    }
    public hideModal() {
        this.closeModal.nativeElement.click();
    }
}
