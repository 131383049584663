import {Component, OnInit} from '@angular/core';
import {ClientInfo, MatrixData, MatrixRegion} from '../../service/models';
import {Title} from '@angular/platform-browser';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {MatrixService} from '../../service/matrix.service';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {ApiClientInfo} from '../../service/api_result';


@Component({
    selector: 'app-matrix',
    templateUrl: './matrix.component.html',
    styleUrls: ['./matrix.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class MatrixComponent implements OnInit {
    public regionsList: MatrixRegion[];
    public categoryList: string[];
    public returnTypes;

    public selectedRegionCode = 77;
    public selectedCategory = 'A';
    public isSelectedCategory = true;
    public selectedClient: ClientInfo;

    public pageTitle = 'Тарифная матрица';

    public formMatrixOptions: FormGroup;
    public formMatrixList: FormArray;
    public formMatrixDist: FormArray;
    public formMatrixMass: FormArray;

    public isEditHash = [];

    public dataHash: MatrixData;

    public optionsKey = [
        'return_tar_type', 'buyback', 'call', 'equipment', 'fitting', 'opening', 'packaging', 'returned_doc', 'sms',
        'cod_cash', 'cod_card', 'os', 'minimal_os', 'return_price'
    ];

    public optionsTitle = [
        'Тарификация част. отказ', 'Возврат част. отказ(%)', 'Прозвон клиента (за день до доставки)', 'Комплектация',
        'Примерка/проверка электроники', 'Вскрытие заказа',
        'Упаковка', 'Возврат накладной', 'Стоимость СМС',
        'Наложенный платеж (нал) %', 'Наложенный платеж (безнал) %', 'Объявленная ценность %',
        'Мин. объявл. ценность (руб)', 'Стоимость возврата'
    ];

    public tarTypesTitle = [
        'Фиксированная цена', 'Процент от тарифа'

    ];

    constructor(
        private api: MatrixService,
        private title: Title,
        private route: ActivatedRoute,
    ) {
    }

    ngOnInit() {
        /* грузим список регионов */
        this.api.getMatrixRegionsList().subscribe((data: MatrixRegion[]) => {
            this.regionsList = data;
        });
        /* грузим список категорий */
        this.api.getMatrixCategoryList().subscribe(data => {
            this.categoryList = data.moscow;
        });

        this.api.getReturnTypes().subscribe((data) => {
            this.returnTypes = data;
        });

        this.loadMatrix();

        this.route.paramMap.subscribe((params: ParamMap) => {
            const clientId = params.get('id');
            if (clientId) {
                this.api.getClientInfo(clientId).subscribe((client: ApiClientInfo) => {
                    this.onChangeClient(client.client);
                });
            }
        });
    }

    public onChangeRegion(regionCode: string) {
        this.selectedRegionCode = parseInt(regionCode, 10);
        this.loadMatrix();
    }

    public onChangeCategory(categoryCode: string) {
        this.selectedCategory = categoryCode;
        this.loadMatrix();
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        if (client) {
            this.selectedCategory = client.matrix;
            this.isSelectedCategory = false;
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name + ' (' + client.client_uid_old + ')');
        } else {
            this.isSelectedCategory = true;
            this.title.setTitle(this.pageTitle);
        }

        this.loadMatrix();
    }

    public onAddMass() {
        if (!this.formMatrixDist.controls.length) {
            this.onAddDist();
        }

        this.formMatrixMass.push(new FormControl('', Validators.required));
        const newRow: FormArray = new FormArray([]);
        for (let i = 0; i < this.formMatrixDist.controls.length; i++) {
            newRow.push(
                new FormArray([
                    new FormControl('', Validators.required),
                    new FormControl('', Validators.required)
                ])
            );
        }
        this.formMatrixList.push(newRow);
    }

    public onAddDist() {
        this.formMatrixDist.push(new FormControl('', Validators.required));
        for (let i = 0; i < this.formMatrixMass.controls.length; i++) {
            const row: FormArray = this.formMatrixList.controls[i] as FormArray;
            row.push(new FormArray([
                new FormControl('', Validators.required),
                new FormControl('', Validators.required)
            ]));
        }
    }

    public onDeleteMass(index) {
        this.formMatrixMass.removeAt(index);
        this.formMatrixList.removeAt(index);

        delete this.isEditHash[index];
        this.isEditHash = this.isEditHash.filter(val => val !== undefined);
    }

    public onDeleteDist(index) {
        this.formMatrixDist.removeAt(index);
        for (let i = 0; i < this.formMatrixMass.controls.length; i++) {
            const row: FormArray = this.formMatrixList.controls[i] as FormArray;
            row.removeAt(index);

            let hash = this.isEditHash[i];
            delete hash[index];
            hash = hash.filter(val => val !== undefined);
            this.isEditHash[i] = hash;
        }
    }

    public onSubmitForm() {
        const matrix = {};
        for (let i = 0; i < this.formMatrixMass.length; i++) {
            for (let j = 0; j < this.formMatrixDist.length; j++) {
                if (this.isEditMatrix(i, j)) {
                    matrix[this.formMatrixMass.controls[i].value + ':' + this.formMatrixDist.controls[j].value] = [
                        this.getMatrixControl(i, j, 0).value,
                        this.getMatrixControl(i, j, 1).value
                    ];
                }
            }
        }

        const data = {
            matrix: matrix,
            options: this.formMatrixOptions.value,
            category: this.selectedClient ? null : this.selectedCategory
        };

        this.api.updateTariffMatrix(
            data,
            this.selectedCategory,
            this.selectedRegionCode,
            this.selectedClient ? this.selectedClient.id : null
        ).subscribe(() => {
            this.loadMatrix();
        });
    }

    public getMatrixControl(mass_index, dist_index, type) {
        const arr: FormArray = this.formMatrixList.controls[mass_index] as FormArray;
        const ctrl: FormArray = arr.controls[dist_index] as FormArray;
        return ctrl.controls[type];
    }

    /**
     * можно ли редактировать значение
     * логика немного хитрая, если данные пришли с сервера, то у этого значения уже есть права доступа им и доверяем
     * если значения нет, значит поле созданно денамически на клиенте
     *
     * @param mass_index
     * @param dist_index
     */
    public isEditMatrix(mass_index, dist_index) {
        if (mass_index in this.isEditHash && dist_index in this.isEditHash[mass_index]) {
            return this.isEditHash[mass_index][dist_index];
        }
        return true;
    }

    public isEditMass(mass_index) {
        if (mass_index in this.isEditHash) {
            for (let i = 0; i < this.isEditHash[mass_index].length; i++) {
                if (this.isEditHash[mass_index][i] === false) {
                    return false;
                }
            }
        }
        return true;
    }

    public isEditDist(dist_index) {
        for (let i = 0; i < this.isEditHash.length; i++) {
            if (this.isEditHash[i][dist_index] === false) {
                return false;
            }
        }
        return true;
    }

    protected createOptionsForm(values) {
        this.formMatrixOptions = new FormGroup({});
        for (const key of this.optionsKey) {
            if (key !== 'return_tar_type1') {
                this.formMatrixOptions.addControl(key, new FormControl(values[key] === undefined ? '' : values[key]));
                console.log(key);
            } else {
                //role: new FormControl('', Validators.required)
                //this.formMatrixOptions.addControl("returnType", new FormControl(values[key] === undefined ? '' : values[key]));
            }
        }
    }

    protected loadMatrix() {
        this.createOptionsForm({});

        this.formMatrixList = new FormArray([]);
        this.formMatrixDist = new FormArray([]);
        this.formMatrixMass = new FormArray([]);
        this.isEditHash = [];

        this.api.getTariffMatrix(
            this.selectedRegionCode,
            this.selectedCategory,
            this.selectedClient ? this.selectedClient.id : null
        ).subscribe((data: MatrixData) => {
            /* форма опций */
            this.createOptionsForm(this.selectedClient ? data.options : data.options_default);

            /* сама матрица */
            this.dataHash = data;
            const matrix = data.matrix,
                dist = data.dist,
                mass = data.mass;

            for (let i = 0; i < dist.length; i++) {
                this.formMatrixDist.push(new FormControl(dist[i], Validators.required));
            }

            for (let i = 0; i < mass.length; i++) {
                this.formMatrixMass.push(new FormControl(mass[i], Validators.required));

                const hash = [];
                const row: FormArray = new FormArray([]);
                for (let j = 0; j < dist.length; j++) {
                    hash.push(matrix[i][j][2]);
                    row.push(new FormArray([
                        new FormControl(matrix[i][j][0], Validators.required),
                        new FormControl(matrix[i][j][1], Validators.required)
                    ]))
                }
                this.isEditHash.push(hash);
                this.formMatrixList.push(row);
            }
        });
    }
}
