import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatePipe, Location} from '@angular/common';
import {DocReturnActHelper} from "../../service/helpers/docReturnAct.helper";
import {DocReturnService} from "../../service/doc-return.service";
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Title} from "@angular/platform-browser";
import {FormControl, FormGroup} from "@angular/forms";
import {ClientInfo, DocReturnAct, DocReturnActOrderResponseUnit, User} from "../../service/models";
import {ObjectsHelper} from "../../service/helpers/objects.helper";
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";
import {DialogService} from "../../components/dialog/dialog.service";

@Component({
    selector: 'app-doc-return',
    templateUrl: './doc-return.component.html',
    styleUrls: ['./doc-return.component.scss']
})
export class DocReturnComponent implements OnInit {
    @ViewChild('barcode') barcodeInput: ElementRef;

    public today = new Date();

    public loading: boolean = false;
    public loadingActClose = {};
    public loadingAdoptSignedAct = {};
    public loadingAddOrder = {};
    public loadingMass = false;
    public loadingMassActClose = false;
    public loadingMassAdoptSignedAct = false;
    public loadingMassAddOrders = false;
    public massActionPanelVisible = true;

    public formFilter: FormGroup;

    public activeView: string = 'add-order';
    public activeViewTitle: string;
    public availableViews = {
        'add-order': 'Добавление заказа в акт',
        'list-acts': 'Список актов',
        'list-orders': 'Список заказов',
    }

    public selectedClients;
    public selectedUsers;

    public addOrderBarcode: string | null = null;
    public addOrderResultClass: string | null = null;
    public addOrderResultAdded: boolean | null = null;
    public addOrderResultMessage: string | null = null;
    public addOrderResultOrders: DocReturnActOrderResponseUnit[] | null = null;
    public addOrderResultAct: DocReturnAct | null = null;

    public listOfActsGridData: GridDataResult;
    public listOfActs: DocReturnAct[] | null = null;
    public listOfActsPageSize: number = 10;
    public listOfActsSkip: number = 0;

    public listOfOrdersDebtGridData: GridDataResult;
    public listOfOrdersDebt: DocReturnActOrderResponseUnit[] | null = null;
    public listOfOrdersDebtPageSize: number = 10;
    public listOfOrdersDebtSkip: number = 0;

    public selectedActStatuses = [];
    public actStatusesList = [];
    public actStatusesSelectSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'label',
        allowSearchFilter: false,
        singleSelection: false,
        enableCheckAll: false,
        itemsShowLimit: 1,
        closeDropDownOnSelection: false,
        defaultOpen: false,
    }

    public selectedOrdersDebtStatuses = [];
    public ordersDebtStatusesList = [
        {
            id: 'debt',
            label: 'Не актирован',
        },
        {
            id: 'with_act',
            label: 'Актирован',
        },
        {
            id: 'deleted',
            label: 'Удалён из акта',
        },
        {
            id: 'any',
            label: 'Не важно',
        },
    ];

    public ordersDebtStatusesSelectSettings: IDropdownSettings = {
        idField: 'id',
        textField: 'label',
        allowSearchFilter: false,
        singleSelection: true,
        enableCheckAll: false,
        itemsShowLimit: 1,
        closeDropDownOnSelection: false,
        defaultOpen: false,
    }

    public selectedActs: number[] = [];
    public selectedActsByStatuses: {} = {};

    public selectedOrders: number[] = [];

    constructor(
        public docReturnActHelper: DocReturnActHelper,
        protected api: DocReturnService,
        protected dataPipe: DatePipe,
        protected dialog: DialogService,
        protected location: Location,
        protected objectsHelper: ObjectsHelper,
        protected route: ActivatedRoute,
        protected router: Router,
        protected title: Title,
    ) {
    }

    ngOnInit() {
        this.formFilter = new FormGroup({
            date_from: new FormControl(this.dataPipe.transform(this.today, 'yyyy-MM-dd')),
            date_to: new FormControl(null),

            client_id: new FormControl(null),
            courier_id: new FormControl(null),

            barcode: new FormControl(''),

            act_status: new FormControl(null),

            orders_debt_status: new FormControl(this.ordersDebtStatusesList[0].id),
        });

        // проверка адреса страницы
        this.route.paramMap.subscribe((params: ParamMap) => {
            let view = params['params'].view;
            if ('undefined' === typeof this.availableViews[view]) {
                // недопустимый фрагмент адреса страницы - переадресация на первый допустимый
                view = Object.keys(this.availableViews)[0];
            }

            this.activateView(view);
        });

        this.actStatusesList = [];
        DocReturnAct.STATUS_DESCRIPTION.forEach((value, index) => {
            if (value.length) {
                this.actStatusesList.push({
                    id: index,
                    label: value,
                });
            }
        });

        this.selectedOrdersDebtStatuses = [
            this.ordersDebtStatusesList[0],
        ];
    }

    /**
     * Активация вкладки интерфейса
     * @param view
     */
    activateView(view: string) {
        this.route.paramMap.subscribe((params: ParamMap) => {
            let routeView = params['params'].view;
            if (view !== routeView) {
                let url = this.router.createUrlTree(['admin', 'client-service', 'doc-return', view]).toString();
                this.location.go(url);
            }

            this.activeView = view;
            this.activeViewTitle = this.availableViews[this.activeView];
            this.title.setTitle(this.activeViewTitle);

            this.barcodeInput.nativeElement.focus();
        });
    }

    /**
     * Обработка выбора клиента из списка
     * @param clients
     */
    onSelectClient(clients: ClientInfo[]) {
        this.selectedClients = clients;
        this.formFilter.controls.client_id.setValue(clients.length ? clients[0].id : null);
    }

    /**
     * Обработка выбора курьера из списка
     * @param users
     */
    onSelectCourier(users: User[]) {
        this.selectedUsers = users;
        this.formFilter.controls.courier_id.setValue(users.length ? users[0].id : null);
    }

    /**
     * Обработка добавления статуса акта возврата документов в выпадающем меню
     * @param event
     */
    public onSelectActStatusItem(event) {
        this.selectedActStatuses = this.selectedActStatuses.filter(item => {
            return item.id != event.id;
        });
        this.selectedActStatuses.push(event);
        this.patchFormFilterActStatus();
    }

    /**
     * Обработка удаления статуса акта возврата документов в выпадающем меню
     * @param event
     */
    public onDeSelectActStatusItem(event) {
        this.selectedActStatuses = this.selectedActStatuses.filter(item => {
            return item.id != event.id;
        });
        this.patchFormFilterActStatus();
    }

    /**
     * Обработка выбора значения статуса актирования заказа в выпадающем меню
     * @param event
     */
    public onSelectOrderDebtStatusItem(event) {
        this.selectedOrdersDebtStatuses = [event,];
        this.patchFormFilterOrderDebtStatus();
    }

    /**
     * Обработка попытки удаления статуса актирования заказа в выпадающем меню
     * @param event
     */
    public onDeSelectOrderDebtStatusItem(event) {
        this.selectedOrdersDebtStatuses = [event,];
        this.patchFormFilterOrderDebtStatus();
    }

    /**
     * Возвращает данные для запроса
     * @param pager
     */
    getQuery(pager = {pageSize: 20, skip: 0,}) {
        let filter = this.formFilter.value;
        filter.date_from = this.dataPipe.transform(filter.date_from, 'yyyy-MM-dd');
        filter.date_to = this.dataPipe.transform(filter.date_to, 'yyyy-MM-dd');

        if (null !== filter.barcode) {
            filter.barcode = filter.barcode.trim();
            if (!filter.barcode.length) {
                filter.barcode = null;
            }
        }

        if ('list-orders' !== this.activeView) {
            filter.orders_debt_status = null;
        }

        filter.page = 1 + Math.floor(pager.skip / pager.pageSize);
        filter.per_page = pager.pageSize;

        return this.objectsHelper.excludeNullValues(filter);
    }

    /**
     * Добавление заказа в акт возврата документов
     * @param order_id
     * @param barcode
     */
    addOrderToAct(order_id = null, barcode = null) {
        this.addOrderResultClass = null;

        let query = this.getQuery();
        if (null !== order_id) {
            delete query['barcode'];
            query['order_id'] = order_id;
            this.loadingAddOrder[order_id] = true;
        } else if (null !== barcode) {
            delete query['order_id'];
            query['barcode'] = barcode;
            query['ignore_missing_order'] = true;
        }

        this.addOrderBarcode = ('undefined' !== typeof query['barcode'] ? query['barcode'] : null);

        this.resetAddOrderResult();
        this.loading = true;
        this.api.addOrder(query).subscribe(responsePayload => {
            this.addOrderResultAdded = responsePayload.added;
            this.addOrderResultMessage = responsePayload.message;
            this.addOrderResultOrders = responsePayload.orders.map((order) => {
                return new DocReturnActOrderResponseUnit(order);
            });
            if (responsePayload.act) {
                this.addOrderResultAct = new DocReturnAct(responsePayload.act);
                if ('list-orders' == this.activeView && 1 === this.addOrderResultOrders.length) {
                    this.listOfOrdersDebtGridData.data = this.listOfOrdersDebtGridData.data.map((item: DocReturnActOrderResponseUnit) => {
                        return item.order_id === order_id ? this.addOrderResultOrders[0] : item;
                    });
                }
            }

            this.addOrderResultClass = this.defineAddOrderResultClass();

            this.loading = false;
            if (order_id) {
                delete this.loadingAddOrder[order_id];
            }
        }, error => {
            this.loading = false;
            if (order_id) {
                delete this.loadingAddOrder[order_id];
            }
        });
    }

    /**
     * Добаление штрихкода без заказа в акт возврата документов
     * @param barcode
     */
    addBarcodeToAct(barcode) {
        this.addOrderToAct(null, barcode);
    }

    /**
     * Сброс ответа на попытку добавления заказа в акт
     */
    resetAddOrderResult() {
        this.addOrderResultAdded = null;
        this.addOrderResultMessage = null;
        this.addOrderResultOrders = null;
        this.addOrderResultAct = null;
    }

    /**
     * Определяет класс сообщения результата добавления заказа в акт
     */
    defineAddOrderResultClass() {
        if (true === this.addOrderResultAdded) {
            return 'add-order-result-added';
        }

        if (1 === this.addOrderResultOrders.length) {
            return 'add-order-result-already-added';
        }

        if (0 === this.addOrderResultOrders.length && (!this.addOrderBarcode || !this.addOrderResultAct)) {
            return 'add-order-result-not-found';
        }

        if (0 === this.addOrderResultOrders.length && this.addOrderBarcode) {
            return 'add-order-result-already-added';
        }

        return 'add-order-result-found-multiple-orders';
    }

    /**
     * Загрузка списка актов
     */
    loadActs() {
        this.listOfActs = null;
        this.selectedActs = [];
        this.selectedActsByStatuses = {};
        this.loading = true;
        this.api.listActs(this.getQuery({
            pageSize: this.listOfActsPageSize,
            skip: this.listOfActsSkip,
        })).subscribe(responsePayload => {
            responsePayload.data = responsePayload.data.map((actData: DocReturnAct) => {
                return new DocReturnAct(actData);
            });
            this.listOfActsGridData = responsePayload;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    /**
     * Проверка активного запроса на формирование акта
     * @param act_id
     */
    isLoadingActClose(act_id) {
        return ('undefined' !== typeof this.loadingActClose[act_id] && this.loadingActClose[act_id]);
    }

    /**
     * Формирование акта
     * @param act_id
     * @param printOnSuccess
     */
    closeAct(act_id, printOnSuccess = false) {
        this.loadingActClose[act_id] = true;
        this.api.closeAct(act_id).subscribe((act: DocReturnAct) => {
            delete this.loadingActClose[act_id];
            this.listOfActsGridData.data = this.listOfActsGridData.data.map((item: DocReturnAct) => {
                return item.id === act.id ? act : item;
            });

            if (print) {
                this.printActOpenWindow(act.id);
            }
        }, error => {
            delete this.loadingActClose[act_id];
        });
    }

    /**
     * Обработчик клика по кнопке формирования акта
     * @param act_id
     */
    onCloseAct(act_id) {
        this.closeAct(act_id);
    }

    /**
     * Проверка активного запроса на приём подписанного акта
     * @param act_id
     */
    isLoadingAdoptSignedAct(act_id) {
        return ('undefined' !== typeof this.loadingAdoptSignedAct[act_id] && this.loadingAdoptSignedAct[act_id]);
    }

    /**
     * Приём подписанного акта
     * @param act_id
     */
    adoptSignedAct(act_id) {
        this.loadingAdoptSignedAct[act_id] = true;
        this.api.adoptSignedAct(act_id).subscribe((act: DocReturnAct) => {
            delete this.loadingAdoptSignedAct[act_id];
            this.listOfActsGridData.data = this.listOfActsGridData.data.map((item: DocReturnAct) => {
                return item.id === act.id ? act : item;
            });
        }, error => {
            delete this.loadingAdoptSignedAct[act_id];
        });
    }

    /**
     * Обработчик клика по кнопке приёма подписанного акта
     * @param act_id
     */
    onAdoptSignedAct(act_id) {
        this.adoptSignedAct(act_id);
    }

    /**
     * Сброс списка актов
     */
    resetListOfActs() {
        this.listOfActs = null;
        this.listOfActsSkip = 0;
        this.selectedActs = [];
        this.selectedActsByStatuses = {};
    }

    /**
     * Обработка изменения списка отмеченных актов
     * @param event
     */
    listOfActsSelectionChange(event) {
        if (event.selectedRows.length) {
            event.selectedRows.map(unit => {
                let act = unit.dataItem;
                if (DocReturnAct.STATUS_OPENED != unit.dataItem.status && DocReturnAct.STATUS_CLOSED != unit.dataItem.status) {
                    return;
                }

                if ('undefined' === typeof this.selectedActsByStatuses[act.status]) {
                    this.selectedActsByStatuses[act.status] = {};
                }

                this.selectedActsByStatuses[act.status][act.id] = true;
            });
        }

        if (event.deselectedRows.length) {
            event.deselectedRows.map(unit => {
                let act = unit.dataItem;
                if ('undefined' !== typeof this.selectedActsByStatuses[act.status] && 'undefined' !== typeof this.selectedActsByStatuses[act.status][act.id]) {
                    delete this.selectedActsByStatuses[act.status][act.id];
                }
            });
        }
    }

    /**
     * Проверка необходимости спрятать чекбокс выбора акта в списке актов
     * @param act
     */
    isDisabledActCheckbox(act) {
        switch (act.status) {
            case DocReturnAct.STATUS_OPENED:
                return (act.act_orders_with_info_count == 0);

            case DocReturnAct.STATUS_CLOSED:
                return false;

            default:
                return true;
        }
    }

    /**
     * Массовая смена статуса актов
     * @param act_ids
     * @param new_status
     */
    massActsStatusChange(act_ids: number | string[], new_status: number) {
        this.loadingMass = true;

        switch (new_status) {
            case DocReturnAct.STATUS_CLOSED:
                this.loadingMassActClose = true;
                break;

            case DocReturnAct.STATUS_SIGNED:
                this.loadingMassAdoptSignedAct = true;
                break;
        }

        this.api.massChangeActsStatus(act_ids, new_status).subscribe(responsePayload => {
            let dialogMessage = '';

            for (let act_id_string in responsePayload.errors) {
                let act_id = Number.parseInt(act_id_string);
                let error = responsePayload.errors[act_id];
                let newActRecord = responsePayload.acts[act_id];
                let oldActStatus = null;
                this.listOfActsGridData.data = this.listOfActsGridData.data.map((item: DocReturnAct) => {
                    if (item.id == act_id) {
                        oldActStatus = item.status;
                    }
                    return item.id == act_id ? newActRecord : item;
                });

                this.selectedActs = this.selectedActs.filter((selected_act_id: number) => {
                    return selected_act_id != act_id;
                });

                if (oldActStatus && 'undefined' !== typeof this.selectedActsByStatuses[oldActStatus] && 'undefined' !== typeof this.selectedActsByStatuses[oldActStatus][act_id]) {
                    delete this.selectedActsByStatuses[oldActStatus][act_id];
                }

                if (error) {
                    dialogMessage += '<div class="text-danger">Акт №' + act_id + ': ' + error + '</div>';
                } else {
                    dialogMessage += '<div>Акт №' + act_id + ' успешно обновлён</div>';
                }
            }

            this.massActionPanelVisible = false;
            this.dialog.alert('<div class="text-left">' + dialogMessage + '</div>', 500, 400, true)
                .subscribe(() => {
                    this.massActionPanelVisible = true;
                });

            this.loadingMass = false;
            this.loadingMassActClose = false;
            this.loadingMassAdoptSignedAct = false;
        }, error => {
            this.loadingMass = false;
            this.loadingMassActClose = false;
            this.loadingMassAdoptSignedAct = false;
        });
    }

    /**
     * Клик по кнопке массовой смены статуса актов
     * @param current_status
     */
    onMassActsStatusChange(current_status) {
        let acts = this.getSelectedActsByStatus(current_status);
        let new_status = null;
        switch (current_status) {
            case DocReturnAct.STATUS_OPENED:
                new_status = DocReturnAct.STATUS_CLOSED;
                break;

            case DocReturnAct.STATUS_CLOSED:
                new_status = DocReturnAct.STATUS_SIGNED;
                break;
        }

        if (acts.length) {
            this.massActsStatusChange(acts, new_status);
        }
    }

    /**
     * Возвращает выбранные акты по статусу
     * @param status
     * @protected
     */
    protected getSelectedActsByStatus(status) {
        if ('undefined' === typeof this.selectedActsByStatuses[status]) {
            return [];
        }

        return Object.keys(this.selectedActsByStatuses[status])
    }

    /**
     * Возвращает число выбранных актов по статусу
     * @param status
     */
    getSelectedActsByStatusCount(status) {
        return this.getSelectedActsByStatus(status).length;
    }

    /**
     * Показывать ли панель массового обновления статуса актов
     */
    isMassActStatusUpdatePanelVisible() {
        for (let status in this.selectedActsByStatuses) {
            if (this.getSelectedActsByStatusCount(status)) {
                return true;
            }
        }

        return false;
    }

    /**
     * Загрузка списка заказов
     */
    loadOrdersDebt() {
        this.listOfOrdersDebt = null;
        this.loading = true;
        this.api.listOrdersDebt(this.getQuery({
            pageSize: this.listOfOrdersDebtPageSize,
            skip: this.listOfOrdersDebtSkip,
        })).subscribe((responsePayload) => {
            responsePayload.data = responsePayload.data.map((orderData => {
                return new DocReturnActOrderResponseUnit(orderData);
            }));
            this.listOfOrdersDebtGridData = responsePayload;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    /**
     * Сброс списка заказов
     */
    resetListOfOrdersDebt() {
        this.listOfOrdersDebt = null;
        this.listOfOrdersDebtSkip = 0;
        this.selectedOrders = [];
    }

    /**
     * Проверка необходимости спрятать чекбокс выбора заказа в списке заказов
     * @param act
     */
    isDisabledAddOrdersCheckbox(order: DocReturnActOrderResponseUnit) {
        return (null !== order.doc_return_act_id && 1 === order.doc_return_act_order_status);
    }

    /**
     * Массовое добавление заказов в акты
     */
    massAddOrders() {
        if (!this.selectedOrders.length) {
            return;
        }

        this.loadingMass = true;
        this.loadingMassAddOrders = true;

        this.api.massAddOrders(this.selectedOrders).subscribe(responsePayload => {
            let dialogMessage = '';

            for (let order_id_string in responsePayload.errors) {
                let order_id = Number.parseInt(order_id_string);
                let error = responsePayload.errors[order_id_string];
                let success = responsePayload.successes[order_id_string];
                let newActOrderRecord = responsePayload.orders[order_id_string];

                this.listOfOrdersDebtGridData.data = this.listOfOrdersDebtGridData.data.map((item: DocReturnActOrderResponseUnit) => {
                    return item.order_id == order_id ? newActOrderRecord : item;
                });

                this.selectedOrders = this.selectedOrders.filter((selected_order_id: number) => {
                    return selected_order_id != order_id;
                });

                if (error) {
                    if (newActOrderRecord) {
                        dialogMessage += '<div class="text-danger">' + error + '</div>';
                    } else {
                        dialogMessage += '<div class="text-danger">Запись №' + order_id + ': ' + error + '</div>';
                    }
                } else {
                    dialogMessage += '<div>' + success + '</div>';
                }
            }

            this.massActionPanelVisible = false;
            this.dialog.alert('<div class="text-left">' + dialogMessage + '</div>', 500, 400, true)
                .subscribe(() => {
                    this.massActionPanelVisible = true;
                });

            this.loadingMass = false;
            this.loadingMassAddOrders = false;
        }, error => {
            this.loadingMass = false;
            this.loadingMassAddOrders = false;
        });
    }

    /**
     * Клик по кнопке массового добавления заказов в акты
     */
    onMassAddOrders() {
        this.massAddOrders();
    }

    /**
     * Показывать ли панель массового добавления заказов в акты
     */
    isMassAddOrdersPanelVisible() {
        return !!this.selectedOrders.length;
    }

    /**
     * Проверка активного запроса на добавление заказа в акт
     * @param order_id
     */
    isLoadingAddOrder(order_id) {
        return ('undefined' !== typeof this.loadingAddOrder[order_id] && this.loadingAddOrder[order_id]);
    }

    /**
     * Клик по кнопке добавления заказа в акт из списка заказов
     * @param order_id
     */
    onAddOrder(order_id) {
        this.addOrderToAct(order_id);
    }

    /**
     * Удаление записи заказа/штрихкода из акта
     * @param id
     * @param act_id
     */
    removeRecord(id, act_id) {
        this.api.removeRecordFromAct(id, act_id).subscribe(responsePayload => {

        });
    }

    /**
     * Клик по кнопке удаления записи заказа/штрихкода из акта
     * @param id
     * @param act_id
     */
    onRemoveRecord(id, act_id) {
        console.warn('onDeleteRecord', id, act_id);
    }

    /**
     * Выполнение действия по кнопке или по окончанию ввода в поле штрих-кода
     */
    execAction() {
        switch (this.activeView) {
            case 'add-order':
                this.resetListOfActs();
                this.resetListOfOrdersDebt();
                return this.addOrderToAct();

            case 'list-acts':
                this.resetListOfOrdersDebt();
                return this.loadActs();

            case 'list-orders':
                this.resetListOfActs();
                return this.loadOrdersDebt();
        }
    }

    /**
     * Обработка перехода по страницам списка
     * @param skip
     * @param take
     */
    public onPageChange({skip, take}: PageChangeEvent): void {
        console.warn(skip, take);
        switch (this.activeView) {
            case 'list-acts':
                this.listOfActsSkip = skip;
                this.listOfActsPageSize = take;
                return this.loadActs();

            case 'list-orders':
                this.listOfOrdersDebtSkip = skip;
                this.listOfOrdersDebtPageSize = take;
                return this.loadOrdersDebt();
        }
    }

    /**
     * Установка подходящего фильтра по статусу акта возврата документов
     */
    public patchFormFilterActStatus() {
        let value = null;
        if (this.selectedActStatuses.length) {
            value = this.selectedActStatuses.map(item => {
                return item.id;
            }).join(',');
        }

        this.formFilter.controls.act_status.setValue(value);
    }

    /**
     * Установка подходящего фильтра статусу актирования заказа
     */
    public patchFormFilterOrderDebtStatus() {
        let value = null;
        if (this.selectedOrdersDebtStatuses[0].id !== this.ordersDebtStatusesList[0].id) {
            value = this.selectedOrdersDebtStatuses[0].id
        }

        this.selectedOrdersDebtStatuses[0].id;
        this.formFilter.controls.orders_debt_status.setValue(value);
    }

    /**
     * routeLink на печать акта возврата документов
     * @param act_id
     */
    public getPrintDocReturnActRouteLink(act_id) {
        return ['/client-service/doc-return/print-act/' + act_id];
    }

    /**
     * Открывает страницу печати акта возврата документов
     * @param act_id
     */
    public printActOpenWindow(act_id) {
        window.open(this.router.createUrlTree(this.getPrintDocReturnActRouteLink(act_id)).toString(), '_blank');
    }

    /**
     * Клик по кнопке печати акта возврата документов
     * @param act
     */
    public onPrintAct(act) {
        if (act.status !== DocReturnAct.STATUS_OPENED) {
            return this.printActOpenWindow(act.id);
        }

        const dialog = this.dialog.confirm('Акт в статусе "Формируется". Закрыть перед печатью?').subscribe(result => {
            dialog.unsubscribe();

            if (!result) {
                return this.printActOpenWindow(act.id);
            }

            this.closeAct(act.id, true);
        });
    }
}
