import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {UserHub} from '../../service/models';

@Component({
    selector: 'app-delivery-warehouse',
    encapsulation: ViewEncapsulation.Emulated,
    templateUrl: 'delivery-warehouse.component.html',
    styleUrls: ['delivery-warehouse.component.scss']
})

export class DeliveryWarehouseComponent implements OnInit {
    public gridData: GridDataResult;
    public date = new Date();
    public dates;
    public formList: FormGroup;
    isLoading = false;
    public selectedHubs: UserHub[];
    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
    ) {
        this.formList = new FormGroup({
            date: new FormControl(this.date, Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        this.isLoading = true;
        const query = {
            'date': this.datePipe.transform(this.formList.value.date, 'yyyy-MM-dd'),
            'hub_ids[]': this.selectedHubs.map(x => x.id)
        };
        this.statisticsService.getReceivedOrdersInStockByDay(query).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data, total: data.length};
        }, error => {
            this.isLoading = false;
        });
    }

    /**
     * Получение предыдушего дня
     * @param day
     */
   getPreviousDay(day) {
        const d = new Date(day);
        return d.setDate(d.getDate() - 1);
   }
}
