import {Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ClientInfo, Hub, User} from '../../service/models';
import {HubsService} from "../../service/hubs.service";
import {AutoCompleteComponent} from '@progress/kendo-angular-dropdowns';
import {IDropdownSettings} from "ng-multiselect-dropdown/multiselect.model";
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {CurrentUserService} from "../../service/current-user.service";

@Component({
    selector: 'app-hub-select',
    templateUrl: './hub-select.component.html',
    styleUrls: ['./hub-select.component.scss']
})
/* дока: https://github.com/nileshpatel17/ng-multiselect-dropdown#readme */
export class HubSelectComponent implements OnInit {
    @ViewChild('autocomplete') public autocomplete: AutoCompleteComponent;
    @Input() public customHubsList: Hub[] = null;
    @Input() public selectedHubs: Hub[] = [];
    @Input() public selectedHub: Hub = null;
    @Input() public placeholder: string;
    @Input() public addTransit: boolean = false;

    @Input() public isShowUserHubs = false;
    @Input() public isOnlyNativeHubs = true;

    @Output() onChange = new EventEmitter<Hub | Hub[]>();
    @Input() protected multi: boolean = false;
    @Input() protected disabled: boolean = false;
    @Input() protected preventLastItem: boolean = false;


    public settings: IDropdownSettings = {};

    public hubsList: Hub[] = [];

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private hubsService: HubsService,
        private currentUserService: CurrentUserService
    ) {
    }

    findModel(id) {
        const list = this.hubsList.filter((item: Hub) => {
            return item.id === id;
        });
        if (!list.length) {
            return;
        }
        return list[0];
    }

    findSelectedModel(id) {
        const list = this.selectedHubs.filter((item: Hub) => {
            return item.id === id;
        });
        if (!list.length) {
            return;
        }
        return list[0];
    }

    ngOnInit() {
        if (null === this.customHubsList) {
            // грузим список хабов из хранилища
            if (this.isShowUserHubs) {
                this.getUserHubsList();
            } else {
                this.getHubsList();
            }
            if (this.addTransit) {
                this.hubsList.push(new Hub({id : '0', name : 'В пути'}));
            }
        } else {
            // используем какой-то кастомный список хабов
            this.hubsList = this.customHubsList;
        }

        this.settings = {
            idField: 'id',
            textField: 'full_name_id',
            allowSearchFilter: true,
            singleSelection: !this.multi,
            enableCheckAll: !this.multi,
            itemsShowLimit: 1,
            closeDropDownOnSelection: !this.multi,
            searchPlaceholderText: 'искать',
            noDataAvailablePlaceholderText: 'ничего не найдено',
            defaultOpen: false
        };
    }

    /**
     * Восстанавливает из хаба дропдауна (а там только значение и лейбл) хаб из оригинального списка
     * @param hubFromDropdown
     * @private
     */
    private restoreHubObject(hubFromDropdown) {
        if (null === hubFromDropdown) {
            return null;
        }

        let hub = this.hubsList.find(hubFind => {
            return hubFind.id === hubFromDropdown.id;
        })

        return hub ? hub : hubFromDropdown;
    }

    onChangeHub() {
        if (this.selectedHubs.length === 0) {
            this.selectedHub = null;
        } else {
            this.selectedHubs = this.selectedHubs.map(hub => {
                return this.restoreHubObject(hub);
            });

            this.selectedHub = this.selectedHubs[0];
        }

        this.onChange.emit(this.multi ? this.selectedHubs : this.selectedHub);

    }

    onSelectItem(event) {
        this.onChangeHub();
    }

    onSelectAll() {
        this.onChangeHub();
    }

    onDeSelect(event) {
        if (this.preventLastItem && !this.multi) {
            let recentHub = this.restoreHubObject(event);
            this.selectedHubs = [recentHub,];
            this.selectedHub = recentHub;

            return;
        }

        if (this.multi) {
            this.selectedHubs = this.selectedHubs.filter((item: Hub) => {
                return item.id !== event.id;
            });
        } else {
            this.selectedHubs = [];
            this.selectedHub = null;
        }
        this.onChangeHub();
    }

    onDeSelectAll() {

        if (this.preventLastItem && !this.multi) {
            if (this.hubsList.length) {
                this.selectedHubs = [this.hubsList[0],];
                this.selectedHub = this.selectedHubs[0];
            }

            return;
        }

        this.selectedHubs = [];
        this.selectedHub = null;
    }

    onSyncClick() {
        if (this.isShowUserHubs) {
            this.getUserHubsList();
        } else {
            this.getHubsList(true);
        }
    }

    getHubsList(fresh: boolean = false) {
        this.hubsService.get(fresh).subscribe((data: Hub[]) => {
            if (this.isOnlyNativeHubs) {
                data = data.filter((hub: Hub) => {
                    return hub.type === Hub.TYPE_LOGSIS;
                });
            }
            this.hubsList = data;
        });
    }

    getUserHubsList() {
        this.currentUserService.get().subscribe(async (user: User) => {
            this.hubsList = user.hubs;
            console.log(this.hubsList);
            const hub = this.currentUserService.getCurrentHub();
            this.selectedHubs = [hub,];
            this.selectedHub = hub;

        })
    }
}
