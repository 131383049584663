import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {FileDownloadService} from '../../http/file-download.service';
import {Hub} from '../../service/models';
import {HubsService} from '../../service/hubs.service';
@Component({
    selector: 'app-clients-stat-with-filter',
    encapsulation: ViewEncapsulation.Emulated,
    styles: [
        `kendo-grid {
        height: 610px;
      }
      kendo-grid .k-grid-content {
        height: 600px;
      }`
    ],
    templateUrl: './clients-stat-with-filter.component.html',
    styleUrls: ['./clients-stat-with-filter.component.scss']
})

export class ClientsStatWithFilterComponent implements OnInit {
    public gridData: GridDataResult;
    public formList: FormGroup;
    public isLoading = false;
    public selectedHubs;
    public excelIsLoading = false;

    public hubInfo = [];
    public selectedStatuses: number[] = [];

    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        private fileDownloadService: FileDownloadService,
        public helpers: HelpersService,
        protected hubsService: HubsService,
    ) {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubInfo = hubs;
        });

        this.formList = new FormGroup({
            dateStart: new FormControl('', Validators.required),
            dateEnd: new FormControl('', Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }

    /**
     * Загрузка отчета
     */
    loadReport() {
        if (!this.checkErrors()) {
            return;
        }
        const query = this.prepareQuery();
        this.isLoading = true;
        this.statisticsService.getClientsStatWithFilter(query).subscribe(data => {
            this.isLoading = false;
            console.warn(data);
            this.gridData = {data: data, total: data.length};
        }, error => {
            this.isLoading = false;
        });
    }

    onExcelClick() {
        if (!this.checkErrors()) {
            return;
        }
        const query = this.prepareQuery();
        const fileName = 'Клиентская статистика -' + query.date_start + ' - ' + query.date_end;
        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/statistics/getClientsStatWithFilterExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    /**
     * Подготовка запроса
     * @private
     */
    private prepareQuery() {
        return {
            'date_start': this.datePipe.transform(this.formList.value.dateStart, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.dateEnd, 'yyyy-MM-dd'),
            'hub_ids': this.selectedHubs.map(x => x.id),
            'order_statuses': this.selectedStatuses,
        };
    }

    onSelectStatus(event) {
        this.selectedStatuses = event;
    }

    /**
     * Проверка ошибок
     * @private
     */
    private checkErrors() {
        if (this.formList.controls.dateStart.value > this.formList.controls.dateEnd.value) {
            this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
            return false;
        }

        if (this.selectedStatuses.length === 0) {
            this.notifier.openNotifier('Выберите статус(ы)', null, {class: 'danger', duration: 5000});
            return false;
        }
        return true;
    }
}
