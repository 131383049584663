import {Component, OnInit} from '@angular/core';
import {StatisticsService} from '../statistics.service';
import {AppComponent} from '../../app.component';
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup} from '@angular/forms';
import {CourierSalaryListComponent} from './courier-salary-list/courier-salary-list.component';

@Component({
    selector: 'app-courier-salary',
    templateUrl: './courier-salary.component.html',
    styleUrls: ['./courier-salary.component.scss']
})
export class CourierSalaryComponent implements OnInit {

    public dateForm: FormGroup;
    public dataIsLoading: boolean = false;
    public stat;

    public maxDailyReportDay: Date;

    constructor(
        private api: StatisticsService,
        protected app: AppComponent,
        protected dataPipe: DatePipe
    ) {
        this.maxDailyReportDay = new Date();
        this.maxDailyReportDay.setDate((new Date()).getDate() - 1);
    }

    ngOnInit() {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        const weekAgo = new Date();
        weekAgo.setDate(today.getDate() - 7);

        this.dateForm = new FormGroup({
            date_start: new FormControl(weekAgo),
            date_end: new FormControl(yesterday)
        });
    }

    loadStatistics() {
        this.stat = null;

        const query = this.dateForm.value;
        query.date_start = this.dataPipe.transform(query.date_start, 'yyyy-MM-dd');
        query.date_end = this.dataPipe.transform(query.date_end, 'yyyy-MM-dd');

        this.dataIsLoading = true;
        this.api.getCourierSalaryStat(query)
            .subscribe(data => {
                this.stat = data;

                this.dataIsLoading = false;
            }, error => {
                this.dataIsLoading = false;
            })
    }

    /**
     * Получаем значения полей
     * @param key
     * @param hubKey
     * @param param
     */
    getValue(key: string, hubKey: string, param: string) {
        if (!this.stat || !this.stat[key] || !this.stat[key][hubKey]) {
            return null;
        }

        return this.stat[key][hubKey][param];
    }

    /**
     * Значения Итого
     * @param key
     * @param param
     */
    getTotalValue(key: string, param: string) {
        if (!this.stat || !this.stat[key]) {
            return null;
        }

        let total = 0;
        Object.keys(this.stat[key]).forEach(hubKey => {
            total += +this.stat[key][hubKey][param];
        });

        return total;
    }

    /**
     * Список курьеров
     */
    public showCouriersList() {
        this.app.createDialog(CourierSalaryListComponent).onDestroy(data => {

        })
    }
}
