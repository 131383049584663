import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {JobProgress} from '../../service/models';

@Component({
    selector: 'app-jobs-progress',
    templateUrl: './jobs-progress.component.html',
    styleUrls: ['./jobs-progress.component.scss']
})
export class JobsProgressComponent implements OnInit, OnDestroy {

    @Input('jobsList') jobsList: string[];

    public jobs: JobProgress[];
    private activeJobIds: number[] = [];

    public loading: boolean = false;
    private interval;

    constructor(protected api: LaraService) {
    }

    ngOnInit() {
        this.reloadList();

        this.interval = setInterval(() => {
            this.reloadChanges()
        }, 10000);
    }

    ngOnDestroy() {
        if (this.interval) {
            clearInterval(this.interval);
            this.interval = null;
        }
    }

    reloadChanges() {
        if (!this.activeJobIds.length) {
            return;
        }

        this.loading = true;
        this.api.getJobsProgress({
                jobsList: this.jobsList,
                ids: this.activeJobIds
            })
            .subscribe(data => {
                this.updateJobsPartially(data);
                this.collectActiveJobIds();

                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    reloadList() {
        this.loading = true;
        this.api.getJobsProgress({
                jobsList: this.jobsList
            })
            .subscribe(data => {
                this.jobs = data;
                this.collectActiveJobIds();

                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    private collectActiveJobIds() {
        this.activeJobIds = [];

        for (let i in this.jobs) {
            if (this.jobs[i].status !== JobProgress.STATUS_PROCESSED) {
                this.activeJobIds.push(this.jobs[i].id);
            }
        }
    }

    private updateJobsPartially(jobs) {
        for (let k in jobs) {
            for (let i in this.jobs) {
                if (jobs[k].id === this.jobs[i].id) {
                    this.jobs[i] = jobs[k];
                    break;
                }
            }
        }
    }
}
