import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClientInfo} from '../../service/models';
import {Title} from '@angular/platform-browser';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {environment} from '../../../environments/environment';


@Component({
    selector: 'app-processing-returns',
    templateUrl: './processing-returns.component.html',
    styleUrls: ['./processing-returns.component.scss']
})

export class ProcessingReturnsComponent implements OnInit {
    public display: any[] = [];
    public allReturnsList: any[] = [];
    public warehouses: any[] = [];
    public message: string = '';
    public  error: string='';
    private pageTitle = 'Обработка возвратов';
    public selectedClient: ClientInfo;
    public formScan: FormGroup;
    public showOpened: boolean = false;
    public showWarehouses: boolean = false;
    public loadingWarehouses: boolean = false;
    public actId: string = '';
    private barcodes = [];

    @ViewChild('input_barcode') barcode: ElementRef;
    @ViewChild('input_warehouse') warehouse: ElementRef;
    @ViewChild('input_is_opened') isOpened: ElementRef;

    constructor(private title: Title, private api: SkladService, public helpers: HelpersService) {
        this.title.setTitle(this.pageTitle);

        this.formScan = new FormGroup({
            barcode: new FormControl('', Validators.required),
            isOpened: new FormControl(''),
            warehouse: new FormControl('')
        });


    }

    ngOnInit() {
    }

    public loadWarehouses(client_id) {
        this.api.getWarehouses(client_id).subscribe(data => {
            this.warehouses = data;
            this.loadingWarehouses = false;
        });
    }

    /*public onChangeWarehouse() {
        this.loadReturnsByWarehouse(this.warehouse.nativeElement.value);
    }*/

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        this.loadingWarehouses = false;
        this.showWarehouses = false;
        this.showOpened = false;
        if (client.id) {
            this.title.setTitle(this.pageTitle + ' для ' + client.client_uid + ': ' + client.full_name + ' (' + client.client_uid_old + ')');
            if (this.isYandex()) {
                this.showOpened = true;
                this.showWarehouses = true;

                this.loadingWarehouses = true;
                this.message = '';
                this.loadWarehouses(client.id);
                this.barcode.nativeElement.disabled = true;
            } else {
                this.barcode.nativeElement.disabled = false;
                this.onLoadReturns();
            }

        } else {
            this.title.setTitle(this.pageTitle);
            this.clearReturns();
            this.barcode.nativeElement.disabled = true;
        }
        this.clearDisplay();


    }

    isYandex() {
        if (!this.selectedClient) {
            return false;
        }
        return this.helpers.isYandex(this.selectedClient.id);
    }

    private enableScan() {
        if (!this.selectedClient) {
            this.message = 'Выберите клиента';
            this.barcode.nativeElement.disabled = true;
            return false;
        }
        if (!this.isYandex()) {
            this.barcode.nativeElement.disabled = false;
            return false;
        }
        if (this.warehouse.nativeElement.value === '') {
            this.message = 'Выберите склад';
            this.barcode.nativeElement.disabled = true;
            return false;
        }
        if (this.isOpened.nativeElement.value === '') {
            this.message = 'Выберите вскрытые/не вскрытые';
            this.barcode.nativeElement.disabled = true;
            return false;
        }
        this.message = '';
        this.barcode.nativeElement.disabled = false;
        return true;
    }


    onBarCodeKeyDown(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.onScan();
    }

    private clearDisplay() {
        this.display = [];
        this.barcode.nativeElement.value = '';
    }

    private clearReturns() {
        this.allReturnsList = [];
    }


    private loadReturnsByWarehouse() {
        if (!this.enableScan()) {
            return;
        }
        const warehouseId = this.warehouse.nativeElement.value;
        this.loadingWarehouses = true;

        this.api.getGoodsForReturnByWarehouse(warehouseId).subscribe(data => {

            this.allReturnsList = data;
            this.clearDisplay();
            this.message = 'Начинайте сканирование';
            this.error='';
            this.loadingWarehouses = false;
        });
    }

    private onLoadReturns() {
        if (!this.selectedClient) {
            this.message = 'Выберите клиента';
            return;
        }

        this.api.getGoodsForReturnClient(this.selectedClient.id).subscribe(data => {

            if (data != null) {
                this.allReturnsList = data;
                this.display = [];
                this.message = 'Начинайте сканирование';
                this.error='';
            }
        });
    }

    onScan() {
        if (!this.barcode.nativeElement.value || !this.selectedClient) {

            this.message = 'не введены баркод или клиент';
            return;
        }

        this.message = 'ищем товары клиента по баркоду';
        for (let i = 0; i < this.display.length; i++) {
            if (this.barcode.nativeElement.value.trim() === this.display[i].inner_n || this.barcode.nativeElement.value.trim() == this.display[i].uid) {
                this.message = 'Заказ уже отсканирован';
                return;
            }
        }
        this.api.getGoodsForReturn(this.selectedClient.id, this.barcode.nativeElement.value).subscribe(data => {
            if (data.order) {
                this.delChecked('order');
                this.display.unshift(data.order);
                this.barcode.nativeElement.value = '';
                this.message = '';
            } else if (data.bar_code) {
                const order = data.bar_code.order;
                order.refuse_goods = data.bar_code.refuse_goods;
                order.bar_scanned = 1;
                const bar = data.bar_code.id;
                for (const ord of this.display) {
                    if (ord.bar_codes) {
                        for (const barcode of ord.bar_codes) {
                            if (barcode === data.bar_code.id) {
                                this.message = 'Место уже отсканировано';
                                return;
                            }
                        }
                    }
                }
                this.barcodes.push(data.bar_code.id);
                let done = 0;
                for (let i  = 0; i < this.display.length; i++) {
                    if (order.uid === this.display[i].uid) {
                        for (const good of order.refuse_goods) {
                            this.display[i].refuse_goods.push(good);
                        }
                        if (this.display[i].bar_codes.length) {
                            this.display[i].bar_codes.push(bar);
                        }
                        done = 1;
                    }
                }
                if (!done) {
                    order.bar_codes = [bar];
                    this.display.unshift(order);
                    console.log(order);
                }
                this.delChecked('barcode', order)
                this.barcode.nativeElement.value = '';
            }
            console.log(data);
            if (data.bar_code == null && data.order) {
                this.message = 'Отказных товаров не найдено';
            }
        });
    }
    delChecked(setting, order = null) {
        if (setting === 'order') {
            for (let i = 0; i < this.allReturnsList.length; i++) {
                if (this.barcode.nativeElement.value.trim() == this.allReturnsList[i].inner_n ||
                    this.barcode.nativeElement.value.trim() == this.allReturnsList[i].uid) {
                    this.allReturnsList.splice(i, 1);
                    break;
                }
            }
        } else if (setting === 'barcode') {
            for (const goodr of order.refuse_goods) {
                for (let i  = 0; i < this.allReturnsList.length; i++) {
                    if (this.allReturnsList[i].uid === order.uid) {
                        for (let ig  = 0; ig < this.allReturnsList[i].refuse_goods.length; ig++) {
                            if (this.allReturnsList[i].refuse_goods[ig].id === goodr.id) {
                                this.allReturnsList[i].refuse_goods[ig].checked = true;
                            }
                        }
                        const checked = this.allReturnsList[i].refuse_goods.filter(item => item.checked);
                        if (checked.length === this.allReturnsList[i].refuse_goods.length) {
                            this.allReturnsList.splice(i, 1);
                        }
                        break;
                    } else {
                        continue;
                    }
                }
            }
        }
    }
    onCreateAct() {
        if (!confirm('Создать акт возврата?')) {
            return;
        }
        const orders = this.display.filter(item => !item.bar_scanned);
        const barcodes = this.display.filter(item => item.bar_scanned);
        const obars = barcodes.map(item => item.bar_codes);
        const checkedbars = [];
        for (const bars of obars) {
            for (const bar of bars) {
                checkedbars.push(bar);
            }
        }
        const uids = orders.map(item => item.uid);
        console.log({order_uid: uids, bar_codes: checkedbars});
        this.api.createReturnAct({
            order_uid: uids,
            barcode_ids: checkedbars,
            warehouse_id: (this.warehouse ? this.warehouse.nativeElement.value : null),
            is_opened: (this.isOpened ? this.isOpened.nativeElement.value : null)
        }).subscribe(data => {
            this.clearDisplay();
            this.message = data.message;
            this.error = data.errors;
            if (data.returnAct) {
                this.actId = data.returnAct.old_vact_id;
            }
            this.barcodes = [];
            /*   if (!this.isYandex()) {
                   this.onLoadReturns();
               } else {
                   this.loadReturnsByWarehouse();
               } */
        });
    }

    onDelete(uid) {
        for (let i = 0; i < this.display.length; i++) {
            if (this.display[i].uid == uid) {
                let done = false;
                if (this.display[i].bar_scanned) {
                    for (const order of this.allReturnsList) {
                        if (order.uid === uid) {
                            for (const good of this.display[i].refuse_goods) {
                                order.refuse_goods.push(good);
                            }
                            done = true;
                        }
                    }
                }
                if (!done) {
                    this.allReturnsList.push(this.display[i]);
                }
                this.display.splice(i, 1);
                break;
            }
        }
    }
}
