import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-couriers-claim-letter',
    templateUrl: './couriers-claim-letter.component.html',
    styleUrls: ['couriers-claim-letter.component.scss']
})
export class CouriersClaimLetterComponent implements OnInit {
    ngOnInit(): void {

    }
}
