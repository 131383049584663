import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import 'rxjs/add/operator/filter';
import * as $ from 'jquery';

@Component({
    selector: 'app-admin-layout',
    templateUrl: './admin-layout.component.html',
    styleUrls: ['./admin-layout.component.scss']
})

export class AdminLayoutComponent implements OnInit {

    constructor(
        public title: Title
    ) {
    }

    ngOnInit() {
        $.getScript('assets/js/jquery.selectBoxIt.js');
        $.getScript('assets/js/tabs.js');
        $.getScript('assets/js/dropzone.min.js');
        $.getScript('assets/js/main.js');
    }
}
