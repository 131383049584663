import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {HelpersService} from '../../service/helpers.service';
import {split} from "ts-node";
import {StatusSelectEventsService} from './status-select-events.service';
import {Observable, Subscription} from 'rxjs';

@Component({
    selector: 'app-status-select',
    templateUrl: './status-select.component.html',
    styleUrls: ['./status-select.component.scss']
})

export class StatusSelectComponent implements OnInit, OnDestroy {
    @Input() protected multi: boolean = false;
    @Input() protected disabled: boolean = false;
    @Input() protected showLimit;

    @Input() public statuses: number[] = [];

    @Input() public selectedStatuses: number[] = [];
    @Input() public selectedStatus: number = null;


    @Input() public customStatusesNames = null;


    /* события и подписки - чтоб слушаться родителей */
    private eventsSubscription: Subscription;
    @Input() events: Observable<void>;

    public statusesList: StatusItem[] = [];

    @Output() onChange = new EventEmitter<number | number[]>();

    public selectedItems: any[] = [];
    public settings: IDropdownSettings = {};

    private statusChangeSubscription: Subscription;


    constructor(
        protected helper: HelpersService,
        private statusSelectEventsService: StatusSelectEventsService
    ) {
    }

    ngOnInit() {
        if (this.events) {
            this.eventsSubscription = this.events.subscribe(data => this.onEvents(data));
        }
        this.settings = {
            idField: 'status',
            textField: 'title',
            singleSelection: !this.multi,
            closeDropDownOnSelection: !this.multi,
            itemsShowLimit: this.showLimit ? this.showLimit : 2,
            defaultOpen: false
        };

        /* если не указан список статусов, то берем все */
        if (!this.statuses.length) {

            this.statuses = [1, 2, 3, 4, 5, 6, 7, 8, 9];

        }


        let name = '';
        let i = 0;

        for (const st of this.statuses) {
            name = this.getStatusName(st, i);
            i++;

            this.statusesList.push({
                status: st,
                title: name
            });
        }

        this.setSelectedStatuses(this.selectedStatuses);

        // реагируем на изменения выбранных статусов извне
        this.statusChangeSubscription = this.statusSelectEventsService.notifyObservable$.subscribe((data: number[]) => {
            this.setSelectedStatuses(data);
        })
    }

    getStatusName(status: number, index: number) {
        if (this.customStatusesNames && (this.customStatusesNames.length > index)) {
            return this.customStatusesNames[index];
        }

        return this.helper.getNameOrderStatus(status);
    }

    setSelectedStatuses(statuses: number[]) {
        this.selectedStatuses = statuses;

        this.selectedItems = [];
        let name = '';
        let i = 0;

        for (const st of this.statuses) {
            name = this.getStatusName(st, i);

            if (this.selectedStatuses.indexOf(st) > -1) {
                this.selectedItems.push({
                    status: st,
                    title: name
                });
            }

            i++;
        }
    }

    ngOnDestroy() {
        this.statusChangeSubscription.unsubscribe();
        if (this.eventsSubscription) {
            this.eventsSubscription.unsubscribe();
            this.eventsSubscription = null;
        }
    }

    public onSelectStatus(event: StatusItem) {
        //TODO убрать это все и использовать this.selectedItems для получения значений
        if (this.multi) {
            this.selectedStatuses.push(event.status);
        } else {
            this.selectedStatus = event.status;
        }
        this.onChangeStatus();

    }

    public onSelectAllStatus() {
        this.selectedStatuses = this.statusesList.map(st => st.status);
        this.selectedStatus = null;
        this.onChangeStatus();
    }

    public onDeSelectStatus(event: StatusItem) {
        if (this.multi) {
            this.selectedStatuses = this.selectedStatuses.filter(st => {
                return st !== event.status;
            });
        } else {
            this.selectedStatus = null;
        }

        this.onChangeStatus();
    }

    public onDeSelectAllStatus() {
        this.selectedStatuses = [];
        this.selectedStatus = null;
        this.onChangeStatus();
    }

    onChangeStatus() {
        this.onChange.emit(this.multi ? this.selectedStatuses : this.selectedStatus);
    }

    onEvents(data) {
        switch (data.type) {

            case 'statusListChanged':

                this.statusesList = [];
                let name = '';
                let i = 0;
                this.customStatusesNames = data.customStatusesNames;
                this.statuses = data.statuses;
                for (const st of this.statuses) {
                    name = this.getStatusName(st, i);
                    i++;

                    this.statusesList.push({
                        status: st,
                        title: name
                    });
                }


        }
    }
}

class StatusItem {
    status: number;
    title: string
}


