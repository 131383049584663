import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class SkladService extends LaraService {

    /**
     * @param data
     */
    // Проверить статус курьера
    public checkCourierStatus(data) {
        return this.post('/storage/checkCourierStatus', data);
    }

    // Проверить статус курьера
    public getNewCourierId(data) {
        return this.post('/storage/getNewCourierId', data);
    }

    // Загрузить заказы для выдачи
    public loadCourierQuene(data) {
        return this.post('/storage/loadCourierQueue', data);
    }

    // Проверить штрихкод
    public checkBarcode({userId, barCode}) {
        return this.post('/storage/checkBarcode', {userId, barCode})
    }

    // Завершить выдачи
    public finishCourierShipping({userId, order_id}) {
        return this.post('/storage/finishCourierShipping', {userId, order_id});
    }

    // Получить список документов
    public returnCourierDocs(userId) {
        return this.post('/storage/returnCourierDocs', userId);
    }

    // Получить список для приема
    public courierDebt({userId, date}) {
        return this.get('/storage/courierDebt/' + userId + '/' + date);
    }

    // Проверить штрихкод
    public returnSomethingOnStock({userId, barCode}) {
        return this.post('/storage/returnSomethingOnStock', {userId, barCode})
    }

    // Принять заказ на склад
    public putZorderOnStock(zorderId) {
        return this.post('/storage/putZorderOnStock', zorderId);
    }

    // Закрыть курьера
    public closeCourierOnStock({userId, date, returnDocuments}) {
        return this.post('/storage/closeCourierOnStock', {userId, date, returnDocuments});
    }

    // Сбербанк
    public getSberbank(hub_id: number, improved: boolean = false) {
        return this.get('/storage/getSberbank/' + hub_id + (improved ? '?useImprovedProcedure=1' : ''));
    }

    // Сбербанк оптимизированный
    public getSberbankOptimized(hub_id: number) {
        return this.get('/logistic/getSberbankOptimized/' + hub_id);
    }

    // Установка приоритета
    public startAssemble(id) {
        return this.post('/logistic/startAssemble', {id: id});
    }

    // Обновление приоритетов
    public saveAssemblePriorities(data) {
        return this.post('/logistic/saveAssemblePriorities', data);
    }

    // Табло
    public getTodayCouriers(hubId: number) {
        return this.get('/storage/getTodayCouriers/' + hubId);
    }

    /**
     * приход курьеров
     * @param hubId
     */
    public getTodayCouriersStatus(hubId: number) {
        return this.get('/storage/getTodayCouriersStatus/' + hubId);
    }

    /** список актированных, не актированных заказов и не распознанных штрихкодов */
    public receptionDisplay() {
        return this.get('/storage/weights/display');
    }

    /** Перевес **/
    public pereves(data) {
        return this.post('/storage/weights/weight', data);
    }

    /** удаление заказа из акта ПП */
    public removeOrderOfAct(uid) {
        return this.delete('/storage/weights/order/' + uid);
    }

    /** удаление нераспознаного штрихкода */
    public removeNotFoundBarCode(id) {
        return this.delete('/storage/weights/barcode/' + id);
    }

    /** прием/весоприем на склад */
    public scanBarCode(data) {
        return this.post('/storage/weights/scan', data);
    }

    /** создание акта ПП для клиента */
    public createClientAct(clientId) {
        return this.post('/storage/weights/act/' + clientId, {});
    }

    /** поиск отказных товаров по клиенту и баркоду заказа */
    public getGoodsForReturn(clientId, barCode = null) {
        return this.post('/storage/getGoodsForReturn', {client_id: clientId, barcode: barCode});
    }

    /** Все отказные товары по клиенту */
    public getGoodsForReturnClient(clientId) {
        return this.get('/storage/getGoodsForReturn/' + clientId);
    }

    /** поиск отказных товаров по клиенту и баркоду заказа */
    public getGoodsForReturnByWarehouse(warehouseId, barCode = null) {
        return this.get('/storage/getGoodsForReturnByWarehouse/' + warehouseId + (barCode ? '/' + barCode : ''));
    }

    /**
     * получает список складов клиента
     * @param clientId
     */
    public getWarehouses(clientId) {
        return this.get('/storage/getWarehouses/' + clientId);
    }


    /** создать возвратный акт */
    public createReturnAct(data) {
        return this.post('/storage/createReturnAct', data);
    }

    /** получить список возвратных актов по клиенту и датам начала и конца периода */
    public getReturnActs(clientId, dateStart, dateEnd) {
        return this.get('/storage/getReturnActs/' + dateStart + '/' + dateEnd + (clientId ? '/' + clientId : ''));

    }

    /** получить возвратный акт по old_vact_id **/
    public getReturnAct(oldVactId) {
        return this.get('/storage/getReturnAct/' + oldVactId);
    }

    /** отправить возвратный акт на почту по id атка **/
    public sendMailReturn(id) {
        return this.get('/storage/mailReturnAct/' + id);
    }

    /** получить акт матотв по id*/
    public getMact(mactId) {
        return this.get('/storage/acts/getCourierActById/' + mactId);
    }


    /** получить список актов матотв по курьеру и дате */
    public getCourierActs(hubId, pagesize, skip, date, courierId) {
        return this.get('/storage/acts/getCourierActs/hub/' + hubId + '/' + pagesize + '/' + skip + '/' + date + (courierId ? '/' + courierId : ''));
    }

    /** получить акт курьера пп по id */
    public getCourierActById(actId) {
        return this.get('/storage/acts/getCourierActById/' + actId);
    }

    /** получить список актов ПП по кдиенту и дате */
    public getClientActs(pagesize, skip, date, clientId) {
        return this.get('/storage/acts/getTransActs/' + pagesize + '/' + skip + (date ? '/' + date + (clientId ? '/' + clientId : '') : ''));
    }

    /** получить акт ПП по id*/
    public getClientActById(actId) {
        return this.get('/storage/acts/getTransActByActId/' + actId);
    }

    /** получить Доверенность */
    public getAttorneys(date, crId) {
        return this.get('/storage/acts/getCourierDocs/' + date + '/' + crId);
    }

    public getCouriersWithDocs(date) {
        return this.get('/storage/acts/getCouriersWithDocs/' + date);
    }


    public createPack(data) {
        return this.post('/storage/transits/createPack', data);
    }

    public deletePack(data) {
        return this.post('/storage/transits/deletePack', data);
    }

    public createPackInHub(data) {
        return this.post('/storage/transits/createPackInHub', data);
    }

    public updatePackInHub(data) {
        return this.post('/storage/transits/updatePackInHub', data);
    }

    public findForPacking(data) {
        return this.get('/storage/transits/findForPacking/' + data);
    }

    public findPlaceForPacking(data) {
        return this.post('/storage/transits/findPlaceForPacking', data);
    }

    /** получить список палетт по статусу*/
    public getPackList(pagesize, skip, status) {
        return this.get('/storage/transits/packList/' + pagesize + '/' + skip + '/' + status);
    }

    /** получить список палет по фильтрам */
    public getPackFilteredList(filters, pagesize, skip) {
        return this.post('/storage/transits/packFilteredList/' + pagesize + '/' + skip, filters);
    }

    /** создание перемещения */
    public createTransit(data) {
        return this.post('/storage/transits/createTransit', data);
    }

    /** создание перемещения между хабами */
    public createHubTransit(data) {
        return this.post('/storage/transits/createHubTransit', data);
    }

    /** получить данные о перемещения */
    public getTransit(data) {
        return this.get('/storage/transits/pack/' + data);
    }

    /** получить данные о паллете */
    public getPack(packId: number) {
        return this.get('/storage/transits/pack/' + packId);
    }

    /** получить данные о перемещении */
    public getTransitInfo(id) {
        return this.get('/storage/transits/transit/' + id);
    }

    /** получить данные о перемещении */
    public getTransitForReceive(id) {
        return this.get('/storage/transits/getTransitForReceive/' + id);
    }

    /** прием места на склад из перемещения */
    public receivePlaceFromTransit(data) {
        return this.post('/storage/transits/receivePlaceFromTransit', data);
    }

    /** set label for order as printed */
    public setLabelPrinted(uid) {
        return this.get('/storage/transits/setLabelPrinted/' + uid);
    }


    /** добавить палету к перемещению */
    public setPalletToTransit(transit_id, pack_ids) {
        return this.post('/storage/transits/addToTransit', {transit_id: transit_id, pack_ids: pack_ids});
    }

    /** добавить палету к перемещению в другой хаб */
    public setPalletToHubTransit(transit_id, pack_ids) {
        return this.post('/storage/transits/addToHubTransit', {transit_id: transit_id, pack_ids: pack_ids});
    }

    /** получить данные о перемещении */
    public removePalletFromTransit(transit_id, pack_ids) {
        return this.post('/storage/transits/removeFromTransit', {transit_id: transit_id, pack_ids: pack_ids});
    }

    /** получить список перемещений */
    public getTransitList(data) {
        return this.post('/storage/transits/list', data);
    }

    /** получить список перемещений между хабами */
    public getHubTransitList(data) {
        return this.post('/storage/transits/hubTransitList', data);
    }


    /** массовое назначение курьера на перемещения*/
    public setCourierForTransits(tids, courierId) {
        return this.post('/storage/transits/setCourier', {tids: tids, courier_id: courierId});
    }

    /** массовое изменения статуса перемещения*/
    public setTransitStatus(tids, status) {
        return this.post('/storage/transits/setStatus', {tids: tids, status: status});
    }

    /** изменения статуса перемещения между хабами */
    public setHubTransitStatus(transitId, status) {
        return this.post('/storage/transits/setTransitStatus', {transitId: transitId, status: status});
    }

    /**
     * Получение данных о перемещении для печати
     * @param $transitId
     */
    public getTransitForPrint(transitId) {
        return this.get('/storage/transits/getTransitInfoForAct/' + transitId);
    }

    /*  Получение данных о транзите */
    public getTransitdata(tids) {
        return this.get('/storage/transits/transit/' + tids);
    }


    /** Добавление места в паллету*/
    public addPlacePallet(pallet_id, barcode) {
        return this.post('/storage/transits/addToPack', {pack_id: pallet_id, barcode_ids: barcode});
    }

    /** Добавление мест в паллету*/
    public addPlacesToPallet(pallet_id, barcode) {
        return this.post('/storage/transits/addPlacesToPack', {pack_id: pallet_id, barcode_ids: barcode});
    }

    /** Список мест для добавления в паллету */
    public getPlacesForPallet(pallet_id, orderUids = []) {
        return this.post('/storage/transits/getPlacesForPallet', {pack_id: pallet_id, order_uids: orderUids});
    }

    /** Удаление места с паллеты*/
    public deletePlacePallet(pallet_id, barcode) {
        return this.post('/storage/transits/removeFromPack', {pack_id: pallet_id, barcode_ids: barcode});
    }

    public deletePlaceFromPallet(pallet_id, barcode) {
        return this.post('/storage/transits/removePlaceFromPack', {pack_id: pallet_id, barcode_ids: barcode});
    }


    /** Отчёт по возвратам*/
    public getOrderReturns(data) {
        return this.post('/statistics/getOrderReturns', data);
    }

    /** Начало предсборки*/
    public preAssembleStart(max) {
        return this.get('/storage/predsbor/start/' + max);
    }

    /** Скан предсборка*/
    public preAssembleScan(bar_code) {
        return this.post('/storage/predsbor/scan', {bar_code: bar_code});
    }

    /** Конец предсборки*/
    public preAssembleFinish() {
        return this.get('/storage/predsbor/finish');
    }

    /** Ячейка собрана*/
    public markCellAsCompleted(cell_num) {
        return this.post('/storage/predsbor/markCellAsCompleted', {cell_num: cell_num});
    }

    /** Проверка размещения: сканирование штрихкода */
    public orderPlacementScanBarCode(data) {
        return this.post('/storage/placement/scanBarCode', data);
    }

    /** Проверка размещения: подтверждение размещения на полке отличной от назначенной */
    public orderPlacementInvalidShelfAccept(data) {
        return this.post('/storage/placement/acceptInvalidShelf', data);
    }

    /** Проверка размещения: подтверждение перемещения на полку назначения */
    public orderPlacementInvalidShelfFix(data) {
        return this.post('/storage/placement/fixInvalidShelf', data);
    }

    /** Проверка размещения: отчет о размещении */
    public orderPlacementReportGet(data) {
        return this.post('/storage/placement/getReport', data);
    }

    /** Список водителей */
    public getTransitDrivers() {
        return this.get('/storage/transits/drivers');
    }

    public addTransitDriver(driver) {
        return this.post('/storage/transits/addTransitDriver', driver);
    }

    public saveTransitDriver(driver) {
        return this.post('/storage/transits/saveTransitDriver', driver);
    }

    public setDriverInTransit(transitId, driverId) {
        return this.post('/storage/transits/setDriverInTransit', {transitId: transitId, driverId: driverId});
    }

    /** Список компаний-перевозчиков */
    public getTransitCarriers() {
        return this.get('/storage/transits/carriers');
    }

    public addTransitCarrier(carrier) {
        return this.post('/storage/transits/addTransitCarrier', carrier);
    }

    public saveTransitCarrier(carrier) {
        return this.post('/storage/transits/saveTransitCarrier', carrier);
    }

    public setCarrierInTransit(transitId, carrierId) {
        return this.post('/storage/transits/setCarrierInTransit', {transitId: transitId, carrierId: carrierId});
    }

    /** Список машин */
    public getTransitVehicles() {
        return this.get('/storage/transits/vehicles');
    }

    public addTransitVehicle(vehicle) {
        return this.post('/storage/transits/addTransitVehicle', vehicle);
    }

    public saveTransitVehicle(vehicle) {
        return this.post('/storage/transits/saveTransitVehicle', vehicle);
    }

    public setVehicleInTransit(transitId, vehicleId) {
        return this.post('/storage/transits/setVehicleInTransit', {transitId: transitId, vehicleId: vehicleId});
    }

    public assemblingSecondShift(barcode) {
        return this.get('/storage/assemblingSecondShift/' + barcode);
    }
}
