import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-table-main',
    templateUrl: './table-main.component.html',
    styleUrls: ['./table-main.component.scss']
})
export class TableMainComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
