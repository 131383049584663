import { Component, OnInit } from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {CurrentUserService} from '../../service/current-user.service';
import {User} from '../../service/models';
import {DialogService} from '../../components/dialog/dialog.service';

@Component({
    selector: 'app-number-settings',
    templateUrl: './number-settings.component.html',
    styleUrls: ['./number-settings.component.scss']
})
export class NumberSettingsComponent implements OnInit {
    public extensions;
    public extension;
    public user;
    public loading = false;
    constructor(
        protected api: LaraService,
        public dialog: DialogService
    ) { }

    ngOnInit() {
        this.getExtensions();
        this.getMember();
    }
    getMember() {
        this.api.currentUser().subscribe((user: User) => {
            this.user = new User(user);
        });
    }
    extensionChange(e) {
        this.extension = e.target.value;
    }
    setExtension() {
        this.loading = true;
        this.api.setExtension(this.extension).subscribe(data => {
            this.loading = false;
            this.getMember();
            this.dialog.alert('Номер установлен');
        })
    }
    getExtensions() {
        this.api.getExtensions().subscribe(data => {
            this.extensions = data;
            console.log(this.extensions);
        })
    }
}
