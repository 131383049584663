import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientInfo, Hub, UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {toFixedPrecision} from '@progress/kendo-angular-inputs/dist/es2015/common/math';
import * as moment from 'moment';

@Component({
    selector: 'app-indicators-im',
    templateUrl: './Indicators-im.component.html',
    styleUrls: ['./Indicators-im.component.scss']
})
export class IndicatorsImComponent implements OnInit {
    public gridData: GridDataResult;
    public date = new Date();
    public dates;
    public formList: FormGroup;
    public isLoading = false;
    public data = null;
    public selectedHubs: UserHub [] = [];
    public selectedClients: ClientInfo[] = [];
    private selectedCompany = 0;
    public statusPie: any[] = [];
    public zonePie: any[] = [];

    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
    ) {
        this.formList = new FormGroup({
            date_start: new FormControl(this.date, Validators.required),
            date_end: new FormControl(this.date, Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(),];
    }

    /**
     * Надпись внутри секции
     * @param e
     */
    public labelPieIn(e: any): string {
        return e.value;
    }

    /**
     * Изменение хаба
     * @param hub
     */
    onSelectHub(hubs) {
        this.selectedHubs = hubs;
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {
                class: 'danger',
                duration: 5000
            });
        }

        this.isLoading = true;
        const query = this.formList.value;
        query.date_start = this.datePipe.transform(this.formList.value.date_start, 'yyyy-MM-dd');
        query.date_end = this.datePipe.transform(this.formList.value.date_end, 'yyyy-MM-dd');
        query.company_id = this.selectedCompany;
        query.clients_id = this.selectedClients.map((client) => {
            return client.id
        });
        query.hub_ids = this.selectedHubs.map((hub) => {
            return hub.id
        });
        console.warn(query);
        this.statisticsService.getIndicatorIM(query).subscribe(data => {
            this.isLoading = false;
            this.data = data;
            const dataIm = this.prepareData();
            this.gridData = {data: dataIm, total: dataIm.length};
            this.loadChars();
        }, error => {
            this.isLoading = false;
        });
    }

    /**
     * Событие при выбор клиента
     * @param client
     */
    public onChangeClient(client: ClientInfo []) {
        this.selectedClients = client;
    }

    /**
     * Событие при смене компании
     * @param company
     * @constructor
     */
    OnChangeCompany(company) {
        if (company) {
            this.selectedCompany = company.id;
        } else {
            this.selectedCompany = 0;
        }
    }

    /**
     * Получение имени строки
     * @param index
     */
    getRowName(index: number) {
        switch (index) {
            case 1:
                return 'Заказы';
            case 2:
                return 'Тариф';
            case 3:
                return 'Кассовое обслуживание';
            case 4:
                return 'Экваринг';
            case 5:
                return 'Возврат';
            case 6:
                return 'Частичный отказ';
            case 7:
                return 'Оценочная стоимость';
            case 8:
                return 'СМС';
            case 9:
                return 'Сумма за доставку';
        }
    }

    /**
     * Получение названия переменной строки
     * @param index
     */
    getRowNameColumn(index: number) {
        switch (index) {
            case 1:
                return 'count';
            case 2:
                return 'tariff_base';
            case 3:
                return 'tariff_cash';
            case 4:
                return 'tariff_card';
            case 5:
                return 'tariff_return';
            case 6:
                return 'tariff_buyback';
            case 7:
                return 'tariff_os';
            case 8:
                return 'tariff_sms';
            case 9:
                return 'tariff_total';

        }
    }

    /**
     * Получение средних значений строки
     * @param index
     */
    getAvgRow(index: number) {
        if (index == 1) {

            return this.data.orders_sum.count / (this.getCountDaysBetweenDays() + 1);
        } else {
            return this.data.orders_sum[this.getRowNameColumn(index)] ? toFixedPrecision(this.data.orders_sum[this.getRowNameColumn(index)] / this.data.orders_sum.count, 2) : 0;
        }
    }

    /**
     * Подготовка данных для отображения в таблице
     * @private
     */
    private prepareData() {
        const dataIm = [];
        for (let i = 1; i <= 9; i++) {
            dataIm.push(
                {
                    name: this.getRowName(i),
                    sum: this.data.orders_sum[this.getRowNameColumn(i)] ? this.data.orders_sum[this.getRowNameColumn(i)] : 0,
                    avg: this.getAvgRow(i)
                }
            )
        }
        return dataIm;
    }

    /**
     * Функция для рассчета кол-во дней между датами
     * @private
     */
    private getCountDaysBetweenDays() {
        const firstDate = moment(this.formList.value.date_start);
        const secondDate = moment(this.formList.value.date_end);
        return Math.abs(firstDate.diff(secondDate, 'days'));
    }

    /**
     * Загрузка графиков
     * @private
     */
    private loadChars() {
        let five = 0, six = 0, seven = 0, eight = 0;
        this.data.orders_by_status.forEach(status => {
            switch (status.status) {
                case 5:
                    five = status.count;
                    break;
                case 6:
                    six = status.count;
                    break;
                case 7:
                    seven = status.count;
                    break;
                case 8:
                    eight = status.count;
                    break;
            }
        });
        this.statusPie = [
            {category: 'Доставлено (' + five + ') ' + this.getPercent(five, [five, six, seven, eight]), value: five, type: 5},
            {category: 'Част отказ (' + six + ') ' + this.getPercent(six, [five, six, seven, eight]), value: six, type: 6},
            {category: 'Полн отказ (' + seven + ') ' + this.getPercent(seven, [five, six, seven, eight]), value: seven, type: 7},
            {category: 'Отмена (' + eight + ') ' + this.getPercent(eight, [five, six, seven, eight]), value: eight, type: 8},
        ];

        this.zonePie = [
            {
                category: 'Город (' + this.data.orders_in_city_and_region.in_city + ') ' +
                    this.getPercent(
                        this.data.orders_in_city_and_region.in_region,
                        [
                            this.data.orders_in_city_and_region.in_city,
                            this.data.orders_in_city_and_region.in_region
                        ]
                    ),
                value: this.data.orders_in_city_and_region.in_city,
                type: 100
            },
            {
                category: 'Область (' + this.data.orders_in_city_and_region.in_region + ') ' +
                    this.getPercent(
                        this.data.orders_in_city_and_region.in_region,
                        [
                            this.data.orders_in_city_and_region.in_city,
                            this.data.orders_in_city_and_region.in_region
                        ]
                    ),
                value: this.data.orders_in_city_and_region.in_region,
                type: 101
            },
        ];
    }

    /**
     * Получение процента
     * @param value
     * @param array
     */
    public getPercent(value, array) {
        let summ = 0;
        array.forEach(function (arr) {
            summ += arr;
        });
        const percent = toFixedPrecision(value / (summ / 100), 2);
        return (!percent ? 0 : percent) + ' %';
    }

    /**
     * Цвет секции
     * @param point
     */
    public pointColor(point): string {
        switch (point.dataItem.type) {
            case 5:
                return 'LimeGreen';
            case 6:
                return 'DarkGreen'
            case 7:
                return 'purple'
            case 8:
                return 'red'
            case 100:
                return 'purple'
            case 101:
                return 'LimeGreen'
        }
    }
}
