import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HelpersService} from "../../service/helpers.service";
import {FinanceService} from "../../service/finance.service";
import {EventerService} from "../../service/eventer.service";
import {CurrentUserService} from "../../service/current-user.service";
import {UserHub} from "../../service/models";
import {FileDownloadService} from "../../http/file-download.service";

@Component({
    selector: 'app-close-list-logist',
    templateUrl: './close-list-logist.component.html',
    styleUrls: ['./close-list-logist.component.scss']
})
export class CloseListLogistComponent implements OnInit {
    @ViewChild('input_datestart') public input_datestart: ElementRef;
    @ViewChild('input_dateend') public input_dateend: ElementRef;
    @ViewChild('modalOpen') public modalOpen: ElementRef;
    public datestart;
    public dateend;
    public loading = false;
    public loadingExcel = false;
    public loadingModal = false;
    public modal = null;
    public data = null;
    public globalSdvig;
    public globalKos;
    public globalNotInInterval;
    private reqDates: { startdate: string, enddate: string };
    public factSalaryFilter: string = 'all';
    public shiftNumberFilter: number[] = [];

    public CRTYPE_CARGO: number = 3;

    public totals: any;
    public couriers;
    public couriersSource;

    public showTotals: boolean = false;
    public errors: any;

    public selectedHub: UserHub = null;

    constructor(
        protected api: FinanceService,
        public helper: HelpersService,
        private eventerService: EventerService,
        private currentUserService: CurrentUserService,
        private fileDownloadService: FileDownloadService,
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.selectedHub = hub;
        });
    }

    ngOnInit() {
        this.selectedHub = this.currentUserService.getCurrentHub();
    }

    onDateChange(date) {
        date === 1 ?
            this.datestart = this.input_datestart.nativeElement.value :
            this.dateend = this.input_dateend.nativeElement.value;
    }

    courierControlActive() {
        return this.datestart === this.dateend;
    }

    onSalaryFilterChanged(filter) {
        this.couriers = this.couriersSource.filter(a => {
            if (filter === 'all') {
                return true;
            }

            if (filter === '1') {
                if (a.fact_salary !== null) {
                    return true;
                } else {
                    return false;
                }
            }

            if (filter === '0') {
                if (a.fact_salary !== null) {
                    return false;
                } else {
                    return true;
                }
            }
        });

        this.calcTotals();
    }

    onSumbit() {

        if (this.loading) {
            return;
        }

        this.loading = true;
        this.showTotals = false;
        this.errors = [];
        this.factSalaryFilter = 'all';
        this.api.getLogistCloseList(
            this.helper.formatDateForSQL(this.datestart),
            this.helper.formatDateForSQL(this.dateend),
            (this.selectedHub ? this.selectedHub.id :  this.currentUserService.getCurrentHub().id)
        )
            .subscribe(data => {
                this.couriers = data.couriers;
                this.couriersSource = data.couriers;

                //  this.totals = data.totals;
                this.errors = data.errors;
                this.loading = false;
                this.reqDates = {
                    startdate: this.helper.formatDateForSQL(this.datestart),
                    enddate: this.helper.formatDateForSQL(this.dateend)
                }
                this.calcTotals();
            }, error => {
                this.loading = false;
            });
    }

    calcTotals() {
        this.totals = {
            'count_orders': 0,
            'count_points': 0,
            'count_snos': 0,

            'penalties': 0,
            'fact_salary': 0,
            'calc_salary': 0,
            'calc_salary_points': 0,
            'calc_salary_redelivery': 0,
            'calc_salary_kgt': 0,
            'calc_salary_parking': 0,
            'calc_salary_other': 0,
            'recalculated_salary': 0

        };

        for (const courierId in this.couriers) {
            for (const key in this.totals) {
                if (this.couriers[courierId][key]) {
                    this.totals[key] += +this.couriers[courierId][key];
                }
            }
        }

        this.showTotals = true;
    }

    openModal(cr_id, type) {
        this.modal = type;
        switch (type) {
            case 'jamb' : {
                this.getKosi(cr_id);
                break;
            }
            case 'inter14' : {
                this.getInterv(cr_id, 14);
                break;
            }
            case 'inter18' : {
                this.getInterv(cr_id, 18);
                break;
            }
            case 'not_in_interval': {
                this.getNotInInterval(cr_id);
                break;
            }
        }
    }

    getInterv(cr_id, hours) {
        this.loadingModal = true;
        this.api.getSdvg(cr_id, hours, this.reqDates.startdate, this.reqDates.enddate).subscribe(data => {
            this.globalSdvig = data;
            this.loadingModal = false;
            this.modalOpen.nativeElement.click();
        })
    }

    getNotInInterval(cr_id) {
        this.loadingModal = true;
        this.api.getNotInInterval(cr_id,  this.reqDates.startdate, this.reqDates.enddate).subscribe(data => {
            this.globalNotInInterval = data;
            this.loadingModal = false;
            this.modalOpen.nativeElement.click();
        })
    }

    getKosi(cr_id) {
        this.loadingModal = true;
        this.api.getKos(cr_id, this.reqDates.startdate, this.reqDates.enddate).subscribe(data => {
            this.globalKos = data;
            this.loadingModal = false;
            this.modalOpen.nativeElement.click();
        })
    }

    getStartDate() {
        return this.input_datestart.nativeElement.value;
    }

    onExcelClick() {
        if (this.loadingExcel) {
            return;
        }
        this.loadingExcel = true;
        const params = {
            start_date: this.helper.formatDateForSQL(this.datestart),
            end_date: this.helper.formatDateForSQL(this.dateend),
            hub_id: (this.selectedHub ? this.selectedHub.id : 1),
            fact_salary_filter: this.factSalaryFilter,
            shift_number_filter: this.shiftNumberFilter,
        }
        const fileName = 'Close-List-Logist_' + this.datestart + ' - ' +  this.dateend;
        this.fileDownloadService
            .downloadFile('/buh/getCloseListForLogist/excel', fileName + '.xlsx', 'post', params)
            .subscribe(response => {
                this.loadingExcel = false;
            }, error => {
                this.loadingExcel = false;
            });
    }

    /**
     * Смена фильтра по номеру смены
     * @param selectedShiftNumbers
     */
    onChangeShift(selectedShiftNumbers: number[]) {
        this.shiftNumberFilter = selectedShiftNumbers;

        if (!selectedShiftNumbers.length) {
            this.couriers = this.couriersSource;
        } else {
            this.couriers = this.couriersSource.filter(user => {
                if (user.courier_work_is_first_shift && selectedShiftNumbers.indexOf(1) !== -1) {
                    return true;
                }

                if (user.courier_work_is_second_shift && selectedShiftNumbers.indexOf(2) !== -1) {
                    return true;
                }

                return false;
            });
        }

        this.calcTotals();
    }
}
