import {AdminModule} from './admin/admin.module';
import {AgmCoreModule} from '@agm/core';
import {AppRoutingModule} from './app.routing';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {ClientsModule} from './personalcabinet/clients.module';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {ComponentsModule} from './components/components.module';
import {EffectsModule} from '@ngrx/effects';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {HttpModule} from '@angular/http';
import {LabelModule} from '@progress/kendo-angular-label';
import {MatDialogModule} from '@angular/material';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {NgModule} from '@angular/core';
import {NgxBarcodeModule} from 'ngx-barcode';
import {RouterModule} from '@angular/router';
import {StoreDevtoolsModule} from '@ngrx/store-devtools';
import {StoreModule} from '@ngrx/store';
import {AdminLayoutComponent} from './admin/admin-layout/admin-layout.component';
import {AppComponent} from './app.component';
import {EmptyComponent} from './layouts/empty/empty.component';
import {PrintlabelComponent} from './storage/printlabel/printlabel.component';
import {PrintReturnActComponent} from './storage/printer/print-return-act/print-return-act.component';
import {OrdersListDirective} from './orders-list/orders-list.directive';
import {ZoneRedactorComponent} from './logistics/zone-redactor/zone-redactor.component';
import {DatePipe} from '@angular/common';
import {PrintMactcrComponent} from './storage/printer/print-mactcr/print-mactcr.component';
import {LabelPrintListFirstComponent} from './storage/printer/label-print-list-first/label-print-list-first.component';
import {AngularYandexMapsModule} from 'angular8-yandex-maps';
import {TePrintComponent} from './storage/printer/te-print/te-print.component';
import {NgxPrintModule} from 'ngx-print';
import { ChartsModule } from '@progress/kendo-angular-charts';
import 'hammerjs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';







@NgModule({
    imports: [
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        HttpModule,
        ComponentsModule,
        MatDialogModule,
        RouterModule,
        AgmCoreModule.forRoot({
            apiKey: 'YOUR_GOOGLE_MAPS_API_KEY'
        }),
        StoreModule.forRoot({}),
        StoreDevtoolsModule.instrument(),
        EffectsModule.forRoot([]),
        HttpClientModule,
        MDBBootstrapModule.forRoot(),
        LabelModule,
        AdminModule,
        ClientsModule,
        AppRoutingModule,
        NgxBarcodeModule,
        NgxPrintModule,
        AngularYandexMapsModule,
        NgxBarcodeModule,
        ChartsModule,
        DropDownsModule,
    ],
    declarations: [
        AppComponent,
        AdminLayoutComponent,
        EmptyComponent,
        PrintlabelComponent,
        LabelPrintListFirstComponent,
        PrintReturnActComponent,
        PrintMactcrComponent,
        ZoneRedactorComponent,
        ZoneRedactorComponent,
        PrintMactcrComponent,
        TePrintComponent,





    ],
    providers: [
        ColumnResizingService,
        OrdersListDirective,
        DatePipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
