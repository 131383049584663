import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
    selector: 'app-courier-salary-fact',
    templateUrl: './courier-salary-fact.component.html',
    styleUrls: ['./courier-salary-fact.component.scss']
})
export class CourierSalaryFactComponent implements OnInit {
    public gridData: GridDataResult;
    public date = new Date();
    public formList: FormGroup;
    public isLoading = false;
    public isExcelLoading = false;

    public lastFieldSort = null;
    public lastSortBy = null;

    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        private fileDownloadService: FileDownloadService,
    ) {
        this.formList = new FormGroup({
            date: new FormControl(this.date, Validators.required),
        });
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (!this.checkError()) {
            return;
        }
        const query = this.prepareQuery();
        this.startLoad(query);
    }

    startLoad(query) {
        this.isLoading = true;
        this.statisticsService.getSalariesByDate(query).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data, total: data.length}
        }, error => {
            this.isLoading = false;
        });
    }

    public checkError() {
        if (!this.formList.value.date) {
            this.notifier.openNotifier('Дата выборки не может быть пустой', null, {class: 'danger', duration: 5000});
            return false;
        }
        return true;
    }

    public prepareQuery() {
        return {
            'date': this.datePipe.transform(this.formList.value.date, 'yyyy-MM-dd'),
            'hub_id': this.currentUserService.getCurrentHub().id
        };
    }


    onExcelClick() {
        if (!this.checkError()) {
            return;
        }
        this.isExcelLoading = true;
        const query = this.prepareQuery();
        const fileName = 'Отчёт по фактической дате ЗП - ' + query.date;
        this.fileDownloadService
            .downloadFile('/buh/getSalariesByDateExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.isExcelLoading = false;
            }, error => {
                this.isExcelLoading = false;
            });
        this.isExcelLoading = false;
    }

    onSortDataGrid(event) {
        const sort = event[0];
        if (this.lastFieldSort === sort.field && this.lastSortBy === sort.dir) {
            sort.dir = sort.dir === 'asc' ? 'desc' : 'asc';
        }
        if (this.lastFieldSort !== sort.field) {
            this.lastFieldSort = sort.field;
        }
        if (this.lastSortBy !== sort.dir) {
            this.lastSortBy = sort.dir;
        }
        this.lastFieldSort = this.lastFieldSort !== sort.field ? sort.field : this.lastFieldSort;
        const query = this.prepareQuery();
        query['sort'] = sort;
        this.startLoad(query);
    }
}
