import {EventEmitter, Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class EventerService {
    currentUserData: EventEmitter<any> = new EventEmitter();

    apiError: EventEmitter<any> = new EventEmitter();

    primaryHubChange: EventEmitter<any> = new EventEmitter();

    adminSidebarToggle: EventEmitter<any> = new EventEmitter();

    userLookupById: EventEmitter<any> = new EventEmitter();

    userLookupResponse: EventEmitter<any> = new EventEmitter();

    constructor() {
    }
}
