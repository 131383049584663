import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SkladService} from "../../../service/sklad.service";
import {HelpersService} from '../../../service/helpers.service';

@Component({
    selector: 'app-print-mactcr',
    templateUrl: './print-mactcr.component.html',
    styleUrls: ['./print-mactcr.component.scss']
})
export class PrintMactcrComponent implements OnInit {

    mactPPId: string;
    actPPData: any;

    uid: String;
    innerNumber: String;
    deliveryDate: String;
    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;
    public loading = false;

  totalWe: number;
  totalPl: number;
  totalPlYa: number;
  totalO: number;

  constructor(
    private route: ActivatedRoute,
    private api: SkladService,
    public helpers: HelpersService
  ) { }

    ngOnInit() {
        this.loading = true;
        this.route.params.subscribe(params => {
            this.mactPPId = params['actId'];
            this.black = 'black';
            this.svg = 'svg';
            this.CODE128 = 'CODE128';
            this.bottom = 'bottom';
            this.center = 'center';
            this.monospace = 'monospace';
        });

        this.api.getClientActById(this.mactPPId).subscribe((data) => {
            this.actPPData = data;
            this.loading = false;
        })
    }

  getTotalCount(data) {
    let totalWeight = 0;
    let totalPlace = 0;
    let totalOs = 0;
    let totalPlaceYa = 0;
    for (const val of data) {
      totalWeight += val.option.weight;
      totalPlace += val.bar_codes.length;
      totalOs += val.bill.os;
      totalPlaceYa += val.hash_bar_codes_count;
      console.log(val.option.weight);
    }
    this.totalPlYa = totalPlaceYa;
    this.totalWe = totalWeight;
    this.totalPl = totalPlace;
    this.totalO = totalOs;

    }

    printPage() {
        window.print();
    }

}
