import {Component, OnInit} from '@angular/core';
import * as $ from 'jquery';

@Component({
    selector: 'app-personal-office',
    templateUrl: './personal-office.component.html',
    styleUrls: ['./personal-office.component.scss']
})
export class PersonalOfficeComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        $.getScript('assets/js/jquery.selectBoxIt.js');
        $.getScript('assets/js/tabs.js');
        $.getScript('assets/js/dropzone.min.js');
        $.getScript('assets/js/main.js');
    }

}
