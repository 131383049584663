import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {User, UserHub} from '../../service/models';
import {HelpersService} from '../../service/helpers.service';
import {FinanceService} from '../../service/finance.service';
import {CurrentUserService} from "../../service/current-user.service";
import {Subject} from "rxjs";
import {EventerService} from "../../service/eventer.service";

@Component({
    selector: 'app-courier-calculation',
    templateUrl: './courier-calculation.component.html',
    styleUrls: ['./courier-calculation.component.scss']
})
export class CourierCalculationComponent implements OnInit {

    public courier: User;
    public date;
    public courierData;
    public courierStat;
    public courierPenalties;
    public confirmedPenalties;
    public modal;
    public widthM;
    public details = false;
    public loadDetails = false;
    public loadLogs = false;
    public loadCalc = false;
    public loadSalary = false;
    public selectedHub: UserHub = null;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();

    public rights = {
        calculation: false
    }

    public courSalary: number = 0;
    public calculator;
    public salaryLogs;
    @ViewChild('inputDate') public inputDate: ElementRef;

    @ViewChild('closeModal') public closeModal: ElementRef;
    @ViewChild('calcSalary') public calcSalary: ElementRef;
    @ViewChild('calcGave') public calcGave: ElementRef;

    constructor(
        protected helper: HelpersService,
        protected api: FinanceService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
    ) {

        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.selectedHub = hub;
            this.appUserSelectEventsSubjectEmit();
        });
    }




ngOnInit() {
        this.rights.calculation = this.helper.checkPermissions('buh:crmoney-save');
        this.selectedHub = this.currentUserService.getCurrentHub();
    }

    onSetSelectCourier(user: User) {
        this.courier = user;
        if (this.date && this.courier) {
            this.getCourierData();
        }
    }

    onSetDate() {
        this.date = this.inputDate.nativeElement.value;
        if (this.courier) {
            this.getCourierData();
        }
    }


    getCourierData() {
        this.loadDetails = true;
        const date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value)
        this.api.getCourierMoneyCheck(date, this.courier.id).subscribe(data => {
            this.getCourierPenalties();
            this.calculator = 0;
            this.courierData = data;
            if (this.courierData.salary) {
                console.log(this.courierData);
                this.courSalary = this.courierData.salary;
            } else {
                if (this.courierData.sum) {
                    console.log (this.courierData.tarificator);
                    // если задана минимальная зарплата для курьера на день, то выводим ее
                    this.courSalary = (this.courierData.tarificator.tarSettings.min_salary > this.courierData.sum.sum_salary_without_parking) ? this.courierData.tarificator.tarSettings.min_salary : this.courierData.sum.sum_salary_without_parking;
                }
            }
            let i = 1;
            if (!this.courierData.is_closed) {
                this.loadDetails = false;
                return;
            }
            for (const order of this.courierData.orders) {
                order.num = i++;
                order.color = this.setOrderStatusColor(order);
                order.status_text = this.helper.getNameOrderStatus(order.delivery_status);
            }

            for (const zorder of this.courierData.zorders) {
                zorder.num = i++;
                zorder.color = this.setZorderStatusColor(zorder.status);
                zorder.status_text = this.helper.getNameZOrderStatus((zorder.status));
            }
            this.courierData.tarificator.date_format = this.helper.formatDate(this.courierData.tarificator.date);
            this.courierData.tarificator.courier_type_text = this.helper.getCourierType(this.courierData.tarificator.courier_type);
            this.getCourierStat(data);
            if (this.calcGave) {
                this.calcGave.nativeElement.value = null;
            }


        });
    }

    getSalaryLogs() {
        this.loadLogs = true;
        const date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value);
        this.api.getCourierSalaryLog(date, this.courier.id).subscribe(data => {
            this.loadLogs = false;
            this.salaryLogs = data;
            for (const log of this.salaryLogs) {
                log.change_date = this.helper.formatDateTime(log.change_date);
            }
        })
    }

    getCourierPenalties() {
        const date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value);
        this.api.getCourierPenalties(date, this.courier.id).subscribe(data => {
            let confirmed = 0;
            for (const penalty of data) {
                if (penalty.isConfirmed) {
                    confirmed++;
                }
                penalty.pen_date = this.helper.formatDate(penalty.pen_date);
            }
            this.courierPenalties = data;
            this.confirmedPenalties = confirmed;
        })
    }

    setCourierSalary() {
        this.loadSalary = true;
        const date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value);

        this.api.setCourierSalary(date, this.courier.id, this.courSalary).subscribe(data => {
            this.loadSalary = false;
            this.courierData.salary = this.courSalary;
            this.closeModal.nativeElement.click();
        })
    }

    getCourierStat(data) {
        let count = 0;
        let zone = 0;
        if (data.orders) {
            for (const order of data.orders) {
                count++;
                if (order.zone) {
                    zone += +order.zone.tarif
                }
            }
        }
        if (data.zorders) {
            for (const zorder of data.zorders) {
                count++;
            }
        }
        this.courierStat = {
            count: count,
            zone: zone
        }
        this.loadDetails = false;
    }

    calculate() {
        const date = this.helper.formatDateForSQL(this.inputDate.nativeElement.value);
        const salary = +this.courierData.salary;
        this.calculator =
            (this.calcSalary.nativeElement.value -
                (+this.calcGave.nativeElement.value + salary)).toFixed(2);
        this.loadCalc = true;
        this.api.saveCourierSalaryLog(date, this.courier.id, this.calcSalary.nativeElement.value,
            this.calcGave.nativeElement.value, salary).subscribe(data => {
            this.loadCalc = false;
            this.salaryLogs = data;
            // this.calcGave.nativeElement.value = null;
        })
    }

    setOrderStatusColor(order) {
        if (order.is_success === false) {
            return 'alert-sadly';
        } else if (order.delivery_status === 3) {
            return 'alert-info';
        } else if (order.delivery_status === 5) {
            return 'alert-success';
        } else if (order.delivery_status === 6 || order.delivery_status === 7 || order.delivery_status === 8) {
            return 'alert-danger';
        }
        return;
    }

    setZorderStatusColor(status) {
        if (status === 3) {
            return 'alert-succes';
        } else if (status === 4) {
            return 'alert-warning'
        }
        return;
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.selectedHub]});
    }

    courierIsSelfEmployed() {
        if (!this.courierData) {
            return false;
        }

        if (this.courierData.hasOwnProperty('is_self_employed') && this.courierData.is_self_employed) {
            return true;
        }

        if (!this.courierData.courier) {
            return false;
        }

        if (this.courierData.courier.hasOwnProperty('is_self_employed') && this.courierData.courier.is_self_employed) {
            return true;
        }
    }
}
