import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {UsersComponent} from './users/users.component';
import {GroupsComponent} from './users/groups/groups.component';
import {EditComponent} from './users/edit/edit.component';
import {EditgroupComponent} from './users/groups/editgroup/editgroup.component';
import {SearchComponent} from '../search/search.component';

import {ActlistComponent} from './../storage/actlist/actlist.component';
import {ActCloseComponent} from './../storage/act-close/act-close.component';
import {BoardOnlineComponent} from './../storage/board-online/board-online.component';
import {IndexComponent} from 'app/storage/transfer/index/index.component';

import {MactlistComponent} from './../storage/mactlist/mactlist.component';

import {ProcessingReturnsComponent} from './../storage/processing-returns/processing-returns.component';
import {ReceptionOrdersComponent} from './../storage/reception-orders/reception-orders.component';
import {ReturnOrdersComponent} from './../storage/transfer/return-orders/return-orders.component';

import {TransferTabComponent} from 'app/storage/transfer/transfer-tab/transfer-tab.component';
import {TurnWorkComponent} from './../turn-work/turn-work.component';
import {UserResolver} from './users/userResolver.service';
import {ReturnactsComponent} from './../storage/returnacts/returnacts.component';
import {ActCreateComponent} from './../storage/act-create/act-create.component';
import {OrdersListComponent} from '../orders-list/orders-list.component';
import {OrdersCheckComponent} from '../logistics/orders-check/orders-check.component';
import localeRu from '@angular/common/locales/ru';
import {registerLocaleData} from '@angular/common';
import {CreateTeComponent} from '../storage/create-te/create-te.component';
import {TeListComponent} from '../storage/te-list/te-list.component';
import {TransitListComponent} from '../storage/transit-list/transit-list.component';
import {ZordersListComponent} from '../logistics/zorders-list/zorders-list.component';
import {CourierStatusComponent} from '../logistics/courier-status/courier-status.component';
import {CourierDocsComponent} from '../logistics/courier-docs/courier-docs.component';
import {UnrecognizedAddressesComponent} from '../logistics/unrecognized-addresses/unrecognized-addresses.component';
import {CheckComponent} from '../logistics/check/check.component';
import {CourierTarifficatorComponent} from '../logistics/courier-tarifficator/courier-tarifficator.component';
import {ProblemsComponent} from '../logistics/problems/problems.component';
import {CouriersComponent} from '../staff/couriers/couriers.component';
import {PenaltiesComponent} from '../staff/penalties/penalties.component';
import {PushNotificationsComponent} from '../mailer/push-notifications/push-notifications.component';
import {ReportReturnsComponent} from '../statistics/report-returns/report-returns.component';
import {SberbankControlComponent} from '../logistics/sberbank-control/sberbank-control.component';
import {RolesControlComponent} from './users/roles-control/roles-control.component';
import {CourierCalculationComponent} from '../finance/courier-calculation/courier-calculation.component';
import {PreAssemblyComponent} from '../storage/pre-assembly/pre-assembly.component';
import {CouriersAssemblyComponent} from '../statistics/couriers-assembly/couriers-assembly.component';
import {NumberSettingsComponent} from '../client-service/number-settings/number-settings.component';
import {RightsFailureComponent} from '../components/rights-failure/rights-failure.component';
import {CloseListComponent} from '../finance/close-list/close-list.component';
import {CheckingOrdersComponent} from '../finance/checking-orders/checking-orders.component';
import {RegionalOrdersComponent} from '../logistics/regional-orders/regional-orders.component';
import {PowerOfAttorneyComponent} from '../storage/printer/power-of-attorney/power-of-attorney.component';
import {RefusedOnSpotComponent} from '../logistics/refused-on-spot/refused-on-spot.component';
import {OrdersPlacementCheckComponent} from '../storage/orders-placement-check/orders-placement-check.component';
import {OrdersPlacementReportComponent} from '../storage/orders-placement-report/orders-placement-report.component';
import {CloseListLogistComponent} from '../finance/close-list-logist/close-list-logist.component';
import {PalletListComponent} from '../storage/pallet/pallet-list/pallet-list.component';
import {PalletItemComponent} from '../storage/pallet/pallet-item/pallet-item.component';
import {TransitsListComponent} from '../storage/transits/transits-list/transits-list.component';
import {TransitsItemComponent} from '../storage/transits/transits-item/transits-item.component';
import {TransitReceiveComponent} from '../storage/transits/transit-receive/transit-receive.component';
import {SpbControlComponent} from '../logistics/spb-control/spb-control.component';
import {MainStatComponent} from '../statistics/main-stat/main-stat.component';
import {LogsisPulseComponent} from '../statistics/logsis-pulse/logsis-pulse.component';
import {EKassirComponent} from '../finance/e-kassir/e-kassir.component';
import {CargoWorkdayComponent} from '../logistics/cargo-workday/cargo-workday.component';
import {CourierExtraExpensesComponent} from '../logistics/courier-extra-expenses/courier-extra-expenses.component';
import {BadAdressesComponent} from '../statistics/bad-adresses/bad-adresses.component';
import {NewsComponent} from '../client-service/news/news.component';
import {NewsEditComponent} from '../client-service/news/edit/news-edit.component';
import {SuspiciousAdressesComponent} from '../statistics/suspisious-adresses/suspisious-adresses.component';
import {DocumentsComponent} from '../documents/documents.component';
import {ClientsSendsComponent} from '../client-service/clients-sends/clients-sends.component';
import {KassaReportComponent} from '../finance/kassa-report/kassa-report.component';
import {DeliveryWarehouseComponent} from '../statistics/delivery-warehouse/delivery-warehouse.component';
import {Top20DeliveryWarehouseComponent} from '../statistics/top20-delivery-warehouse/top20-delivery-warehouse.component';
import {DailyMeetingComponent} from '../statistics/daily-meeting/daily-meeting.component';
import {CourierStatComponent} from '../statistics/courier-stat/courier-stat.component';
import {ReasonsPerenosComponent} from '../statistics/reasons-perenos/reasons-perenos.component';
import {IntervalsStatComponent} from '../statistics/intervals-stat/intervals-stat.component';
import {CourierSalaryComponent} from '../statistics/courier-salary/courier-salary.component';
import {ClientsStatComponent} from '../statistics/clients-stat/clients-stat.component';
import {WarehouseStatComponent} from '../statistics/warehouse-stat/warehouse-stat.component';
import {CourierIndicatorsComponent} from '../statistics/courier-indicators/courier-indicators.component';
import {CouriersMapComponent} from '../couriers/couriers-map/couriers-map.component';
import {CouriersRouteListComponent} from '../couriers/couriers-route-list/couriers-route-list.component';
import {DocReturnComponent} from '../client-service/doc-return/doc-return.component';
import {PrintDocReturnActComponent} from '../client-service/doc-return/print-doc-return-act/print-doc-return-act.component';
import {DispatchedNotificationsComponent} from '../mailer/dispatched-notifications/dispatched-notifications.component';
import {IndicatorsImComponent} from '../statistics/Indicators-im/Indicators-im.component';
import {ZordersStatComponent} from '../statistics/zorders-stat/zorders-stat.component';
import {NewStatComponent} from '../statistics/new-stat/new-stat.component';
import {RecalculateOrdersComponent} from '../finance/recalculate-orders/recalculate-orders.component';
import {DetmirRemainsComponent} from '../reports/detmir-remains/detmir-remains.component';
import {CouriersClaimLetterComponent} from '../couriers/couriers-claim-letter/couriers-claim-letter.component';
import {CourierSalaryFactComponent} from '../statistics/courier-salary-fact/courier-salary-fact.component';
import {ClientsStatWithFilterComponent} from '../statistics/clients-stat-with-filter/clients-stat-with-filter.component';
import {SecondShiftControlAssemblingComponent} from '../storage/second-shift-control-assembling/second-shift-control-assembling.component';
import {SecondShiftAssemblingComponent} from '../storage/second-shift-assembling/second-shift-assembling.component';

import {TransferOrdersComponent} from '../finance/transfer-orders/transfer-orders.component';

registerLocaleData(localeRu);

const routes: Routes = [
    {path: 'users/index', component: UsersComponent, resolve: {user: UserResolver}},
    {path: 'users/edit/:id', component: EditComponent},
    {path: 'users/edit', component: EditComponent},
    {path: 'users/new', component: EditComponent},
    {path: 'users/groups/index', component: GroupsComponent},
    {path: 'users/groups/new', component: EditgroupComponent},
    {path: 'users/groups/editgroup/:id', component: EditgroupComponent},
    {path: 'users/roles-control', component: RolesControlComponent},

    {path: 'search', component: SearchComponent},
    {path: 'sklad/index', component: IndexComponent},
    {path: 'sklad/onlinetablo', component: BoardOnlineComponent},
    {path: 'sklad/reception', component: TransferTabComponent},
    {path: 'sklad/actlist', component: ActlistComponent},
    {path: 'sklad/mactlist', component: MactlistComponent},
    {path: 'sklad/turn', component: TurnWorkComponent},

    {path: 'sklad/act_close', component: ActCloseComponent},
    {path: 'sklad/return', component: ProcessingReturnsComponent},
    {path: 'sklad/reception_order', component: ReceptionOrdersComponent},
    {path: 'sklad/return_acts', component: ReturnactsComponent},
    {path: 'sklad/act-create', component: ActCreateComponent},
    {path: 'sklad/second-shift/assembling-control', component: SecondShiftControlAssemblingComponent},
    {path: 'sklad/second-shift/assembling', component: SecondShiftAssemblingComponent},
    {path: 'orders/list', component: OrdersListComponent},
    {path: 'zorders/list', component: ZordersListComponent},
    {path: 'courier-docs', component: CourierDocsComponent},
    {path: 'logistics/courier-status', component: CourierStatusComponent},
    {path: 'logistics/unrecognized-addresses', component: UnrecognizedAddressesComponent},
    {path: 'logistics/check', component: CheckComponent},
    {path: 'logistics/courier-tarifficator', component: CourierTarifficatorComponent},
    {path: 'logistics/problems', component: ProblemsComponent},
    {path: 'logistics/sberbank-control', component: SberbankControlComponent},
    {path: 'logistics/regional-orders', component: RegionalOrdersComponent},
    {path: 'logistics/refused-on-spot', component: RefusedOnSpotComponent},
    {path: 'logistics/spb-control', component: SpbControlComponent},
    {path: 'logistics/cargo-workday', component: CargoWorkdayComponent},
    {path: 'logistics/courier-extra-expenses', component: CourierExtraExpensesComponent},

    {path: 'users/groups/index', component: GroupsComponent},
    {path: 'users/groups/new', component: EditgroupComponent},
    {path: 'users/groups/editgroup/:id', component: EditgroupComponent},
    {path: 'search', component: SearchComponent},
    {path: 'statistics/main', component: MainStatComponent},
    {path: 'statistics/report-returns', component: ReportReturnsComponent},
    {path: 'statistics/logsis-pulse', component: LogsisPulseComponent},
    {path: 'statistics/couriers-assembly', component: CouriersAssemblyComponent},
    {path: 'statistics/bad-addresses', component: BadAdressesComponent},
    {path: 'statistics/suspisious-addresses', component: SuspiciousAdressesComponent},
    {path: 'statistics/delivery-warehouse', component: DeliveryWarehouseComponent},
    {path: 'statistics/top20-delivery-warehouse', component: Top20DeliveryWarehouseComponent},
    {path: 'statistics/daily-meeting', component: DailyMeetingComponent},
    {path: 'statistics/courier-stat', component: CourierStatComponent},
    {path: 'statistics/courier-salary', component: CourierSalaryComponent},
    {path: 'statistics/courier-salary-fact', component: CourierSalaryFactComponent},
    {path: 'statistics/reasons-perenos', component: ReasonsPerenosComponent},
    {path: 'statistics/clients-stat', component: ClientsStatComponent},
    {path: 'statistics/clients-stat-with-filter', component: ClientsStatWithFilterComponent},
    {path: 'statistics/warehouse-stat', component: WarehouseStatComponent},
    {path: 'statistics/zorders-stat', component: ZordersStatComponent},
    {path: 'statistics/indicators-im', component: IndicatorsImComponent},
    {path: 'statistics/courier-indicators', component: CourierIndicatorsComponent},
    {path: 'statistics/intervals-stat', component: IntervalsStatComponent},
    {path: 'statistics/new-stat', component: NewStatComponent},

    {path: 'sklad/index', component: IndexComponent},
    {path: 'sklad/onlinetablo', component: BoardOnlineComponent},
    {path: 'sklad/reception', component: TransferTabComponent},
    {path: 'sklad/actlist', component: ActlistComponent},
    {path: 'sklad/mactlist', component: MactlistComponent},
    {path: 'sklad/turn', component: TurnWorkComponent},

    {path: 'sklad/act_close', component: ActCloseComponent},
    {path: 'sklad/return', component: ProcessingReturnsComponent},
    {path: 'sklad/reception_order', component: ReceptionOrdersComponent},
    {path: 'sklad/return_acts', component: ReturnactsComponent},

    {path: 'orders/list', component: OrdersListComponent},
    {path: 'orders/list/ondelivery', component: OrdersListComponent},
    {path: 'orders/list/check-orders', component: OrdersCheckComponent},
    {path: 'orders/list/onware', component: OrdersListComponent},

    {path: 'sklad/create-te', component: CreateTeComponent},

    {path: 'sklad/pallets', component: PalletListComponent},
    {path: 'sklad/pallets/:id', component: PalletItemComponent},

    {path: 'sklad/transits', component: TransitsListComponent},
    {path: 'sklad/transits/receive', component: TransitReceiveComponent},
    {path: 'sklad/transits/:id', component: TransitsItemComponent},

    {path: 'sklad/te-list', component: TeListComponent},
    {path: 'sklad/transit-list', component: TransitListComponent},
    {path: 'staff/couriers', component: CouriersComponent},
    {path: 'staff/penalties', component: PenaltiesComponent},
    {path: 'sklad/pre-assembly', component: PreAssemblyComponent},
    {path: 'sklad/placement-check', component: OrdersPlacementCheckComponent},
    {path: 'sklad/placement-report', component: OrdersPlacementReportComponent},
    {path: 'sklad/return-orders', component: ReturnOrdersComponent},

    {path: 'mailer/push-notifications', component: PushNotificationsComponent},
    {path: 'finance/courier-calculation', component: CourierCalculationComponent},
    {path: 'finance/close-list', component: CloseListComponent},
    {path: 'finance/close-list-logist', component: CloseListLogistComponent},
    {path: 'finance/e-kassir', component: EKassirComponent},
    {path: 'mailer/push-notifications', component: PushNotificationsComponent},
    {path: 'mailer/dispatched-notifications', component: DispatchedNotificationsComponent},
    {path: 'finance/kassa-report', component: KassaReportComponent},
    {path: 'finance/recalculate-orders', component: RecalculateOrdersComponent},
    {path: 'finance/transfer-orders', component: TransferOrdersComponent},

    {path: 'client-service/number-settings', component: NumberSettingsComponent},
    {path: 'client-service/news', component: NewsComponent},
    {path: 'client-service/news/:id', component: NewsEditComponent},
    {path: 'client-service/clients-sends', component: ClientsSendsComponent},

    {path: 'client-service/doc-return/print-act/:act_id', component: PrintDocReturnActComponent, pathMatch: 'full'},
    {path: 'client-service/doc-return', redirectTo: 'client-service/doc-return/add-order', pathMatch: 'full', },
    {path: 'client-service/doc-return/:view', component: DocReturnComponent, pathMatch: 'full'},

    {path: 'rights-failure', component: RightsFailureComponent},
    {path: 'finance/checking-orders', component: CheckingOrdersComponent},
    {path: 'power-of-attorney', component: PowerOfAttorneyComponent},
    {path: 'documents', component: DocumentsComponent},

    {path: 'couriers/map', component: CouriersMapComponent},
    {path: 'couriers/route-list', component: CouriersRouteListComponent},
    {path: 'couriers/route-list/:courier_id:date', component: CouriersRouteListComponent},
    {path: 'couriers/claim-letter', component: CouriersClaimLetterComponent},

    {path: 'reports/detmir-remains', component: DetmirRemainsComponent},
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
    providers: [UserResolver]
})

export class AdminRoutingModule {
}
