import {Component, OnInit} from '@angular/core';
import {DataStateChangeEvent, GridDataResult} from "@progress/kendo-angular-grid";
import {process, State} from "@progress/kendo-data-query";
import {UserGroup} from "../../../service/models";
import {Title} from "@angular/platform-browser";
import {MemberService} from "../../../service/member.service";

@Component({
    selector: 'app-groups',
    templateUrl: './groups.component.html',
    styleUrls: ['./groups.component.scss']
})
export class GroupsComponent implements OnInit {
    public groups;

    public gridData: GridDataResult;
    public state: State = {
        take: 50,

        filter: {
            logic: 'and',
            filters: []
        }
    };

    constructor(
        protected api: MemberService,
        private title: Title
    ) {
        this.title.setTitle('Группы');
        this.loadGroups();
    }

    ngOnInit() {

    }

    loadGroups() {
        this.api.groupsList().subscribe((data: UserGroup[]) => {
            this.groups = data.map(data => new UserGroup(data));
            this.gridData = process(this.groups, this.state);
        });
    }

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
        this.gridData = process(this.groups, this.state);
    }

    public onRemoveGroup(group_id: number) {
        this.api.removeUserGroup(group_id).subscribe((data) => {
            alert('Группа удалена и это печально');
            this.loadGroups();
        });
    }
}
