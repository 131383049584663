import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Hub, Order} from '../../service/models';
import {DialogService} from '../../components/dialog/dialog.service';
import {environment} from '../../../environments/environment';
import {HubsService} from '../../service/hubs.service';

@Component({
    selector: 'app-reception-orders',
    templateUrl: './reception-orders.component.html',
    styleUrls: ['./reception-orders.component.scss']
})
export class ReceptionOrdersComponent implements OnInit, OnDestroy {
    public display: any;
    public openActId: string = null;
    public date: Date = new Date();
    public resultScan: string[] = [];
    public tab = 1;
    public resultOrder: Order;
    public loading = false;
    public aLoading = false;
    public isSubscribed = null;
    public letPrint = false;
    public printStat = false;
    public lastPrintValue = false;
    public uid;
    public bar;
    public isForeignHubOrder = false;
    public isSuspicious = false; //заказ с подозрительным адресом
    public isRegional = false;
    private hubsList: Hub[];
    private displayActs = false;

    public formScan: FormGroup;
    @ViewChild('input_barcode') barcode: ElementRef;
    @ViewChild('input_weight') weight: ElementRef;

    constructor(
        private api: SkladService,
        private hubsService: HubsService,
        private dialog: DialogService
    ) {
        this.formScan = new FormGroup({
            barcode: new FormControl('', Validators.required),
            weight: new FormControl('')

        });

        this.isSubscribed = this.api.errorEmit().subscribe(error => {
            this.loading = false;
            this.formScan.reset();
            if (this.tab === 1) {
                this.barcode.nativeElement.focus();
            } else {
                this.weight.nativeElement.focus();
            }
        })
    }

    toggleDisplayActs() {
        this.displayActs = !this.displayActs;
    }

    ngOnInit() {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
            this.loadDisplay();
        });
    }

    ngOnDestroy(): void {
        if (this.isSubscribed) {
            this.isSubscribed.unsubscribe();
        }
    }


    loadDisplay() {
        if (this.tab !== 3) {

            this.aLoading = true;
            this.api.receptionDisplay().subscribe(data => {
                this.display = data;
                this.aLoading = false;
            });
            this.weight.nativeElement.focus();
        }
    }

    onOpenActList(id) {
        if (this.openActId === id) {
            this.openActId = null;
        } else {
            this.openActId = id;
        }
    }

    onRemoveOrder(uid) {
        const dialog = this.dialog.confirm('Удалить из акта заказ: ' + uid).subscribe(result => {
            if (result) {
                this.api.removeOrderOfAct(uid).subscribe(() => this.loadDisplay());
            }
            dialog.unsubscribe();
        });
    }

    onRemoveBarCode(id) {
        const dialog = this.dialog.confirm('Удалить штрих код?').subscribe(result => {
            if (result) {
                this.api.removeNotFoundBarCode(id).subscribe(() => this.loadDisplay());
            }
            dialog.unsubscribe();
        });
    }

    onRemoveAllBarCodes() {
        const dialog = this.dialog.confirm('Удалить все нераспознанные штрихкоды?').subscribe(result => {
            if (result) {
                this.api.removeNotFoundBarCode('all').subscribe(() => this.loadDisplay());
            }
            dialog.unsubscribe();
        });
    }

    onScan() {
        if (!this.formScan.valid) {
            this.formScan.reset();
            return;
        }

        if (this.tab !== 1 && !this.formScan.get('weight').value) {
            this.formScan.reset();
            this.weight.nativeElement.focus();
            this.dialog.alert('Товар необходимо взвесить!');
            return;
        }

        this.resultScan = [];
        this.resultOrder = null;
        switch (this.tab) {
            case 1:
                this.reception();
                this.barcode.nativeElement.focus();
                break;
            case 2:
                this.reception();
                this.weight.nativeElement.focus();
                break;
            case 3:
                this.letPrint = false;
                this.reWeight();
                this.weight.nativeElement.focus();
                break;
        }
    }

    reception() {
        this.loading = true;
        this.api.scanBarCode(this.formScan.value).subscribe((data: any) => {
            this.loading = false;
            this.resultScan = data.messages;
            if (data.order) {
                this.openActId = data.order.documents.transceiver_id;
                this.resultOrder = data.order;
                this.isForeignHubOrder = false;
                this.isSuspicious = false;
                if ('undefined' !== typeof data.is_foreign_hub_order && data.is_foreign_hub_order) {
                    this.isForeignHubOrder = true;
                    this.resultOrder.hub_destination = this.hubsList.find((hub: Hub) => {
                        return hub.id === this.resultOrder.hub_destination_id;
                    });
                }
                if (data.order.suspicious_address) {
                    this.isSuspicious = true;
                }
                if (data.order.is_regional) {
                    this.isRegional = true;
                }
                /* Если заказ 2й смены и принимают его не на ТСД, напечатаем этикетку */
                if (data.order.shift_number === 2 && window.outerWidth > 480) {
                    this.lastPrintValue = this.letPrint;
                    this.letPrint = true;
                }
                if (this.letPrint || this.isForeignHubOrder) {
                    this.uid = data.order.uid;
                    if (data.bar_code.length) {
                        this.bar = data.bar_code[0].id;
                    }
                    this.printStat = true;
                }
            }
           // this.loadDisplay();
            this.formScan.reset();
        });
    }

    reWeight() {
        this.loading = true;
        this.api.pereves(this.formScan.value).subscribe(data => {
            this.loading = false;
            this.resultOrder = data;
            this.formScan.reset();
        });
    }

    onWeightKeyDown(event) {
        const weightInput = this.formScan.get('weight');
        weightInput.setErrors(null);

        if (event.keyCode !== 13) {
            return;
        }

        const weight = this.formScan.get('weight').value.replace(',', '.') * 10;
        if (isNaN(weight)) {
            weightInput.setErrors({
                invalidValue: true
            });

            this.weight.nativeElement.focus();
            this.dialog.alert('Недопустимое значение веса!');
            return;
        }

        this.formScan.get('weight').setValue(weight.toFixed(2));
        this.barcode.nativeElement.focus();
    }

    onBarCodeKeyDown(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.onScan();
    }

    onCreateAct(clientId) {
        console.log('что вообще происходит?');
        const dialog = this.dialog.confirm('Создать Акт приемопередачи?').subscribe(result => {
            if (result) {
                this.api.createClientAct(clientId).subscribe(() => this.loadDisplay());
            }
            dialog.unsubscribe();
        });
    }

    dateStyle() {
        if (!this.resultOrder) {
            return;
        }

        if (this.isForeignHubOrder) {
            return 'foreign';
        }
        if (this.isSuspicious) {
            return 'suspicious';
        }
        if (this.isRegional) {
            return 'regional';
        }
        const delta = new Date(this.resultOrder.delivery_date).getDate() - new Date().getDate();
        console.log(new Date().getHours());

        if (delta == 0) {
            if (new Date().getHours() >= 6) {
                return 'prevday';
            }
            return 'today';
        }
        if (delta > 0) {
            if (delta === 1 && new Date().getHours() >= 21) {
                return 'today';
            }
            return 'nextday';
        }
        if (delta < 0) {
            return 'prevday'
        }

        if (this.resultOrder.courier.id == environment.not_founded_courier_id) {
            return 'notfounded';
        }
        if (this.resultOrder.courier.id == environment.problem_courier_id) {
            return 'problem';
        }
        /*console.log(delta);
        switch (delta) {
            case 0: {
                if(new Date().getHours()>=9){
                  return 'green';
                } else {
                  return 'red';
                }
            }
            case 1: {
                return 'green';
            }
            case 2: {
                return 'orange';
            }
            default: {
                return 'grey';
            }
        }*/
    }

    statusStyle() {
        if (!this.resultOrder) {
            return '';
        }

        const delta = new Date(this.resultOrder.delivery_date).getDate() - new Date().getDate();

        if (this.resultOrder.is_regional === 1) {
            return 'statusPurple';
        }

        if (delta) {
            return 'statusorange';
        } else {
            return 'statusgreen';
        }
        if (this.resultOrder.status == 8) {
            return 'statusred';
        }


    }

    letMePrint(e) {
        this.letPrint = e.checked;
    }

    setPrinter(e) {
        this.printStat = !e;
        this.uid = null;
        this.bar = null;
        if (this.lastPrintValue !== true) {
            this.letPrint = false;
        }
    }
}
