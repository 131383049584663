import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import { KeyValue } from '@angular/common';
import {FinanceService} from "../../service/finance.service";
import {HelpersService} from "../../service/helpers.service";

@Component({
    selector: 'app-checking-orders',
    templateUrl: './checking-orders.component.html',
    styleUrls: ['./checking-orders.component.scss']
})
export class CheckingOrdersComponent implements OnInit {
    public tables;
    public datestart;
    public dateend;
    public loading = false;
    @ViewChild('input_datestart') public input_datestart: ElementRef;
    @ViewChild('input_dateend') public input_dateend: ElementRef;
    constructor(
        protected api: FinanceService,
        private helper: HelpersService
    ) { }

    ngOnInit() {
    }
    getTables() {
        this.loading = true;
        this.api.getTroubleOrders
        (this.helper.formatDateForSQL(this.datestart), this.helper.formatDateForSQL(this.dateend))
            .subscribe(data => {
                this.tables = data;
                this.loading = false;
                this.updateTable(this.tables);
        })
    }

    updateTable(data) {
        for (const table of data.data) {
            for (const order of table.data) {
                if (order.status) {
                    order.status = this.helper.getNameOrderStatus(order.status);
                }
            }
            table.keys = Object.keys(table.data[0]);
            table.keys = table.keys.filter(item => {
                if (item !== 'id') {
                    return item;
                }
            })
        }
    }

    onDateChange(date) {
        date === 1 ?
            this.datestart = this.input_datestart.nativeElement.value :
            this.dateend = this.input_dateend.nativeElement.value;
    }
    zero = (a: KeyValue<number, string>, b: KeyValue<number, string>): number => {
        return 0;
    }

    onClosed() {
        this.getTables();
    }
}
