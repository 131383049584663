import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {RoleDialogComponent} from './role-dialog/role-dialog.component';
import {LaraService} from '../../service/lara.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-role-card',
  templateUrl: './role-card.component.html',
  styleUrls: ['./role-card.component.scss']
})
export class RoleCardComponent implements OnInit {
    public loading = false;
    @Input('role_id') public role_id: string;
    constructor(private api: LaraService,
                private resolver: ComponentFactoryResolver,
                private app: AppComponent) { }

    ngOnInit() {
    }

    public onOpen() {

        this.loading = true;
        this.api.getRoleDetail(this.role_id).subscribe((data) => {
            this.app.createDialog(RoleDialogComponent, {
                role: data
            });
            this.bodyClick();
            this.loading = false;
        });


    }

    bodyClick() {
        $('body').click();
    }

}
