import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {User, UserHub} from "../../service/models";
import {CurrentUserService} from "../../service/current-user.service";
import {EventerService} from "../../service/eventer.service";

@Component({
    selector: 'app-courier-status',
    templateUrl: './courier-status.component.html',
    styleUrls: ['./courier-status.component.scss']
})

export class CourierStatusComponent implements OnInit {
    public todayCouriers: any[] = [];
    private pageTitle = 'Приход курьеров';
    public message = '';
    public loading = true;

    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];

    constructor(
        private title: Title,
        private api: SkladService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        public helpers: HelpersService
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            let hub = this.currentUserService.getCurrentHub();

            this.selectedHubs = [hub, ];
            this.selectedHubId = hub.id;

            this.loadCouriers(this.selectedHubId);
        });
    }

    public loadCouriers(hubId: number) {
        this.todayCouriers = [];
        this.api.getTodayCouriersStatus(hubId).subscribe(data => {
            console.log(data);
            if ((data != null) && (data.length)) {
                this.todayCouriers = data;
                this.message = '';
            } else {
                this.message = 'Не назначен ни один курьер на сегодня';
            }
            this.loading = false;

        });

    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id))
        {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub, ];
        this.selectedHubId = hub.id;
        this.loadCouriers(this.selectedHubId);
    }
}
