import {Component, OnInit} from '@angular/core';
import {ClientInfo, MatrixData, MatrixRegion, SubAgent} from '../../../service/models';
import {Title} from '@angular/platform-browser';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ColumnResizingService} from '@progress/kendo-angular-grid';
import {MatrixService} from "../../../service/matrix.service";

@Component({
    selector: 'app-pvz',
    templateUrl: './pvz.component.html',
    styleUrls: ['./pvz.component.scss'],
    providers: [
        ColumnResizingService
    ]
})
export class PvzComponent implements OnInit {
    public regionsList: MatrixRegion[];
    public clientsList: ClientInfo[];
    public agentsList: SubAgent[];

    public selectedRegionCode = 77;

    public nullClient: ClientInfo = new ClientInfo({
        id: 0,
        uid: '--',
        name: 'НЕТ',
        matrix: 'A'
    });
    public selectedClient: ClientInfo = this.nullClient;

    public nullAgent: SubAgent = new SubAgent({
        id: 0,
        name: 'N/A'
    });
    public selectedSubAgent = this.nullAgent;

    public pageTitle = 'ПВЗ: Тарифная матрица';

    public formMatrixOptions: FormGroup;
    public formMatrixList: FormArray;
    public formMatrixDist: FormArray;
    public formMatrixMass: FormArray;

    public isEditHash = [];

    public dataHash: MatrixData;

    public optionsKey = [
        'insurance', 'cod_cash', 'cod_card', 'sms', 'storage'
    ];

    public optionsTitle = [
        'Страхование (%)', 'Наложенный платеж (нал) (%)', 'Наложенный платеж (безнал), Эквайринг (%)', 'Стоимость СМС', 'Срок хранения'
    ];

    constructor(
        private api: MatrixService,
        private title: Title,
    ) {
        this.title.setTitle(this.pageTitle);
    }

    ngOnInit() {
        /* грузим список регионов */
        this.api.getMatrixRegionsList().subscribe((data: MatrixRegion[]) => {
            this.regionsList = data;
        });
        /* грузим список клиентов */
        this.api.getClientList().subscribe((data: ClientInfo[]) => {
            this.clientsList = [this.nullClient].concat(data);
        });
        /* грузим список субагентов */
        this.api.getSubAgentList().subscribe((data: SubAgent[]) => {
            this.agentsList = [this.nullAgent].concat(data);
        });

        this.loadMatrix();
    }

    public onChangeRegion(regionCode: string) {
        this.selectedRegionCode = parseInt(regionCode, 10);
        this.loadMatrix();
    }

    public onChangeClient(client: ClientInfo) {
        console.log('client', client);
        this.selectedSubAgent = this.nullAgent;
        this.selectedClient = client;
        if (client.id) {
            this.title.setTitle(this.pageTitle + ' для КЛИЕНТА: ' + client.client_uid + ': ' + client.full_name);
        } else {
            this.title.setTitle(this.pageTitle);
        }
        this.loadMatrix();
    }

    public onChangeAgent(agent: SubAgent) {
        console.log('agent', agent);
        this.selectedSubAgent = agent;
        this.selectedClient = this.nullClient;
        if (agent.id) {
            this.title.setTitle(this.pageTitle + ' для ПВЗ: ' + agent.name);
        } else {
            this.title.setTitle(this.pageTitle);
        }
        this.loadMatrix();
    }

    public onAddMass() {
        if (!this.formMatrixDist.controls.length) {
            this.onAddDist();
        }

        this.formMatrixMass.push(new FormControl('', Validators.required));
        const newRow: FormArray = new FormArray([]);
        for (let i = 0; i < this.formMatrixDist.controls.length; i++) {
            newRow.push(
                new FormArray([
                    new FormControl('', Validators.required)
                ])
            );
        }
        this.formMatrixList.push(newRow);
    }

    public onAddDist() {
        this.formMatrixDist.push(new FormControl('', Validators.required));
        for (let i = 0; i < this.formMatrixMass.controls.length; i++) {
            const row: FormArray = this.formMatrixList.controls[i] as FormArray;
            row.push(new FormArray([
                new FormControl('', Validators.required)
            ]));
        }
    }

    public onDeleteMass(index) {
        this.formMatrixMass.removeAt(index);
        this.formMatrixList.removeAt(index);

        delete this.isEditHash[index];
        this.isEditHash = this.isEditHash.filter(val => val !== undefined);
    }

    public onDeleteDist(index) {
        this.formMatrixDist.removeAt(index);
        for (let i = 0; i < this.formMatrixMass.controls.length; i++) {
            const row: FormArray = this.formMatrixList.controls[i] as FormArray;
            row.removeAt(index);

            let hash = this.isEditHash[i];
            delete hash[index];
            hash = hash.filter(val => val !== undefined);
            this.isEditHash[i] = hash;
        }
    }

    public onSubmitForm() {
        const matrix = {};
        for (let i = 0; i < this.formMatrixMass.length; i++) {
            for (let j = 0; j < this.formMatrixDist.length; j++) {
                if (this.isEditMatrix(i, j)) {
                    const pref = this.formMatrixMass.controls[i].value + ':' + this.formMatrixDist.controls[j].value;
                    matrix[pref] = this.getMatrixControl(i, j, 0).value;
                }
            }
        }

        const data = {
            matrix: matrix,
            options: this.formMatrixOptions.value,
        };

        console.log(data);

        this.api.updatePvzMatrix(data, this.selectedClient.id, this.selectedSubAgent.id).subscribe(() => {
            this.loadMatrix();
        });
    }

    public getMatrixControl(mass_index, dist_index, type) {
        const arr: FormArray = this.formMatrixList.controls[mass_index] as FormArray;
        const ctrl: FormArray = arr.controls[dist_index] as FormArray;
        return ctrl.controls[type];
    }

    /**
     * можно ли редактировать значение
     * логика немного хитрая, если данные пришли с сервера, то у этого значения уже есть права доступа им и доверяем
     * если значения нет, значит поле созданно денамически на клиенте
     *
     * @param mass_index
     * @param dist_index
     */
    public isEditMatrix(mass_index, dist_index) {
        if (mass_index in this.isEditHash && dist_index in this.isEditHash[mass_index]) {
            return this.isEditHash[mass_index][dist_index];
        }
        return true;
    }

    public isEditMass(mass_index) {
        if (mass_index in this.isEditHash) {
            for (let i = 0; i < this.isEditHash[mass_index].length; i++) {
                if (this.isEditHash[mass_index][i] === false) {
                    return false;
                }
            }
        }
        return true;
    }

    public isEditDist(dist_index) {
        for (let i = 0; i < this.isEditHash.length; i++) {
            if (this.isEditHash[i][dist_index] === false) {
                return false;
            }
        }
        return true;
    }

    protected createOptionsForm(values) {
        this.formMatrixOptions = new FormGroup({});
        for (const key of this.optionsKey) {
            this.formMatrixOptions.addControl(key, new FormControl(values[key] === undefined ? '' : values[key]));
        }
    }

    protected loadMatrix() {
        this.createOptionsForm({});

        this.formMatrixList = new FormArray([]);
        this.formMatrixDist = new FormArray([]);
        this.formMatrixMass = new FormArray([]);

        return;
        this.api.getTariffMatrix(this.selectedRegionCode, 1, this.selectedClient.id).subscribe((data: MatrixData) => {
            /* форма опций */
            this.createOptionsForm(data.options);

            /* сама матрица */
            this.dataHash = data;
            this.isEditHash = [];
            const matrix = data.matrix,
                dist = data.dist,
                mass = data.mass;

            for (let i = 0; i < dist.length; i++) {
                this.formMatrixDist.push(new FormControl(dist[i], Validators.required));
            }

            for (let i = 0; i < mass.length; i++) {
                this.formMatrixMass.push(new FormControl(mass[i], Validators.required));

                const hash = [];
                const row: FormArray = new FormArray([]);
                for (let j = 0; j < dist.length; j++) {
                    hash.push(matrix[i][j][2]);
                    row.push(new FormArray([
                        new FormControl(matrix[i][j][0], Validators.required),
                        new FormControl(matrix[i][j][1], Validators.required)
                    ]))
                }
                this.isEditHash.push(hash);
                this.formMatrixList.push(row);
            }
        });
    }
}
