import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {LaraService} from '../service/lara.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HelpersService} from '../service/helpers.service';
import {DialogService} from '../components/dialog/dialog.service';
import {OrdersService} from '../service/orders.service';
import {Title} from "@angular/platform-browser";
import {HubsService} from "../service/hubs.service";
import {Hub, Order} from "../service/models";
import {Subject} from "rxjs";
import {ActivatedRoute} from '@angular/router';


@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})

export class SearchComponent implements OnInit {
    result = false;
    public orders = [];
    public searchOrders: FormGroup;
    public selectedUsers;
    public substatuses = [];
    public cancelStatus;
    public modal;
    public loading = false;
    public loadingSearch = false;
    public ploading = false;
    public poloading = false;
    public dloading = false;
    public cloading = false;
    public order;
    public name = '';
    public filter: any;
    public printEvent = false;
    private lastQuery = '';

    public selectedHubs = [];
    public checkedOrdersHubsCnt = 0;
    public filterIncludeHubs = [];
    public appUserSelectEventsSubject: Subject<any> = new Subject<any>();
    public onSelectCourierBypass = false;

    public isTSD = false;

    @ViewChild('closeModal') public closeModal: ElementRef;
    @ViewChild('checkAllBtn') public checkAllFlag: ElementRef;
    @ViewChild('modalSetCourier') public modalSetCourier: ElementRef;
    @ViewChild('default') public default: ElementRef;

    @ViewChild('hubOriginFilterEnable') public hubOriginFilterEnable: ElementRef;
    @ViewChild('hubDestinationFilterEnable') public hubDestinationFilterEnable: ElementRef;
    @ViewChild('hubCurrentFilterEnable') public hubCurrentFilterEnable: ElementRef;
    @ViewChild('hubModeAnd') public hubModeAnd: ElementRef;
    @ViewChild('hubModeOr') public hubModeOr: ElementRef;

    constructor(
        private route: ActivatedRoute,
        protected api: LaraService,
        protected apiOrders: OrdersService,
        protected hubsService: HubsService,
        public helper: HelpersService,
        public dialog: DialogService,
        public title: Title
    ) {
        this.searchOrders = new FormGroup({
            search: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {
        const width = window.outerWidth;
        if (width < 480) {
            this.isTSD = true;
        }

        this.title.setTitle('Поиск по заказам');

        this.route.queryParams.subscribe(params => {
            const uid = params['uid'];

            if (uid) {
                this.searchOrders.get('search').setValue(uid);
                this.search();
            }
        })
    }

    /* Тип поиска */
    typeSearch(type: any) {
        if (!type) {
            this.default.nativeElement.checked = true;
        }
        this.filter = {};
        if (null !== type) {
            this.filter.type = type;
        } else if (this.filter.hasOwnProperty('type')) {
            delete this.filter.type;
        }
    }

    /* Обработка запросов по статусам */
    setStatus(val) {
        switch (val) {
            case 6: {
                if (this.numChecked() > 1) {
                    break;
                }
                this.order = null;
                this.poloading = true;
                this.api.getOrder(this.checkedOrdersUids()[0]).subscribe(async data => {
                    this.order = await this.hubsService.defineOrderHubs(data);
                    this.poloading = false;
                })
                break;
            }
            case 7: {
                this.submitDialog('Присвоить статус "Отказ на месте" ' + this.numChecked() + ' заказам?', val);
                break;
            }

            case 8: {
                if (this.substatuses.length === 0) {
                    this.getOrderCancelSubstatuses();
                }
                break;
            }
        }
    }

    submitDialog(message, status) {
        const dialog = this.dialog.confirm(message).subscribe(result => {
            if (result) {
                if (status === 5 || status === 7) {
                    this.apiOrders.setFinishedStatusOrders(this.checkedOrdersUids(), status).subscribe(data => {
                        this.finishQuery();
                        if (data.message) {
                            this.dialog.alert(data.message, 500);
                        }
                    })
                }

            }
            dialog.unsubscribe();
        })
    }
    submitStatus(status) {
        this.dloading = true;
        this.apiOrders.setFinishedStatusOrders(this.checkedOrdersUids(), status).subscribe(data => {
            this.searchOrders.patchValue({
                search: this.lastQuery
            })
            this.finishQuery();
            this.dloading = false;
            if (data.message) {
                this.dialog.alert(data.message, 500);
            }
        })
    }
    /* --- */

    /* Функционал по отмене */
    getOrderCancelSubstatuses() {
        this.api.getOrdersCancelSubstatuses().subscribe((data: any) => {
            this.substatuses = data;
        });
    }

    setCancelStatus(event) {
        this.cancelStatus = event.target.value;
    }

    submitCancel() {
        this.loading = true;
        this.api.setCancelSubstatus(this.checkedOrdersUids(), +this.cancelStatus).subscribe(data => {
            this.searchOrders.patchValue({
                search: this.lastQuery
            })
            this.finishQuery();
            this.loading = false;
            if (data.message) {
                this.dialog.alert(data.message, 500);
            }
        })
    }
    /* --- */

    /* Функционал по частичному отказу */
    submitPartialCancel() {
        if (this.goodNumChecked()) {
            this.ploading = true;
            const goods = this.order.all_goods.slice();
            const goods_f = goods.filter(x => x.checked);
            const good_ids = goods_f.map(x => x.id);
            console.log(good_ids);
            this.api.setPartialCancelOrder(this.order.uid, good_ids).subscribe(data => {
                this.searchOrders.patchValue({
                    search: this.lastQuery
                })
                this.finishQuery();
                this.ploading = !data;
                if (data.message) {
                    this.dialog.alert(data.message, 500);
                }
            })
        }
    }
    checkGood(index, event) {
        this.order.all_goods[index].checked = event.target.checked;
    }
    goodNumChecked() {
        if (!this.order) {
            return false;
        }
        let i = 0;
        for (const good of this.order.all_goods) {
            if (good.checked) {
                i++;
            }
        }
        return i;
    }
    /* --- */

    /* Функционал по назначению курьера */
    onSelectCourier(users) {
        if (this.onSelectCourierBypass) {
            return;
        }
        this.selectedUsers = users;
        console.log(this.selectedUsers);
        if (this.numChecked() > 0 || this.selectedUsers) {
            this.setCourier();
            // this.modalSetCourier.nativeElement.click();
        }
    }
    setCourier() {
        this.cloading = true;
        this.searchOrders.patchValue({
            search: this.lastQuery
        })
        if (this.selectedUsers && this.numChecked() > 0) {
            this.apiOrders.setCourierOrders(this.checkedOrdersUids(), this.selectedUsers.id).subscribe(data => {
                const orders = data.changedOrders.map(x => x.uid);
                if (orders) {
                    this.dialog.alert('Заказы ' + orders.join(', ') +
                        ' назначены на курьера ' + this.selectedUsers.name, 500);
                } if (data.errors) {
                    this.dialog.alert(data.errors, 500);
                }
                this.cloading = false;
                this.finishQuery();
            })
        } else {
            this.dialog.alert('Нехватает данных');
            this.cloading = false;
            this.finishQuery();
        }
    }
    /* --- */

    /* Функционал по поиску */
    keyDownFunction(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.printEvent = true;
        this.search();
    }

    search() {
        const search = this.searchOrders.get('search').value;
        this.lastQuery = search;
        if (!search) {
            this.dialog.alert('Запрос отсутствует');
            return;
        }
        this.loadingSearch = true;
        this.checkAllFlag.nativeElement.checked = false;

        let hSrc = this.hubOriginFilterEnable.nativeElement.checked;
        let hDst = this.hubDestinationFilterEnable.nativeElement.checked;
        let hCur = this.hubCurrentFilterEnable.nativeElement.checked;
        let mAnd = this.hubModeAnd.nativeElement.checked;
        let ids = this.selectedHubs.map((hub: Hub) => {
            return hub.id;
        });

        if (this.filter && this.filter.hub_origin && (!hSrc || !ids.length)) {
            delete this.filter.hub_origin;
        }

        if (this.filter && this.filter.hub_destination && (!hDst || !ids.length)) {
            delete this.filter.hub_destination;
        }

        if (this.filter && this.filter.hub_current && (!hCur || !ids.length)) {
            delete this.filter.hub_current;
        }

        if (this.filter && this.filter.hub_mode_and && (!mAnd || !ids.length)) {
            delete this.filter.hub_mode_and;
        }

        if ((hSrc || hDst || hCur) && ids.length) {
            if (!this.filter) {
                this.filter = {};
            }

            this.filter['hubs[]'] = ids;

            if (hSrc) {
                this.filter.hub_origin = true;
            }

            if (hDst) {
                this.filter.hub_destination = true;
            }

            if (hCur) {
                this.filter.hub_current = true;
            }

            if (mAnd) {
                this.filter.hub_mode_and = true;
            }
        } else {
            if (this.filter && this.filter['hubs[]']) {
                delete this.filter['hubs[]'];
            }

            if (this.filter && this.filter.hub_mode_and) {
                delete this.filter.hub_mode_and;
            }
        }

        if (!this.filter || !this.filter.type) {
            this.api.searchOrders(search, this.filter)
                .subscribe(async (data: any) => {
                    data = await this.commonResponseProcessor(data);
                    this.orders = data;
                    this.loadingSearch = false;
                    this.searchOrders.reset();
                }, error => {
                    this.loadingSearch = false;
                });
        } else {
            this.filter.find = search;
            this.api.searchOrdersByType(search, this.filter)
                .subscribe(async data => {
                    data = await this.commonResponseProcessor(data);
                    this.orders = data;
                    this.loadingSearch = false;
                }, error => {
                    this.loadingSearch = false;
                })
        }
        for (const order of this.orders) {
            order.checked = false;
        }
    }

    private async commonResponseProcessor(data) {
        let deliveryIntervals = await this.hubsService.getDeliveryIntervals();

        /**
         * Подготовка информации о возвратных актах
         * @param order
         */
        function prepareActInfo(order: any) {
            for (const goods of order.all_goods) {
                if (goods.return_act && !order.return_old_vact_id) {
                    order['return_act_id'] = goods.return_act.id;
                    order['return_old_vact_id'] = goods.return_act.old_vact_id;
                }
            }
            return order;
        }

        // TODO: replace with data.map + Promise.all()
        for (let order of data) {
            order = await this.hubsService.defineOrderHubs(order);

            order.x_delivery_time_label = null;
            order.x_delivery_time_invalid = false;
            let deliveryInterval = deliveryIntervals.find((interval) => {
                return interval.id === order.delivery_time;
            });
            if (deliveryInterval) {
                if (deliveryInterval.hub_id === order.hub_destination_id) {
                    order.x_delivery_time_label = deliveryInterval.label;
                }
                else {
                    order.x_delivery_time_label = 'Не передали интервал';
                    order.x_delivery_time_invalid = true;
                }
            }
           order = prepareActInfo(order);
        }

        return data;
    }

    checkOrder(index, event) {
        this.orders[index].checked = event.target.checked;
        this.updateCouriersDropdown();
    }

    checkAll(event) {
        for (const order of this.orders) {
            order.checked = event.target.checked;
            /*
            if (order.status < 5) {
                order.checked = event.target.checked;
            }
            */
        }
        this.updateCouriersDropdown();
    }

    private updateCouriersDropdown() {
        let activeHubs = {};
        let checkedOrders = 0;
        for (let order of this.orders) {
            if (order.checked) {
                checkedOrders++;
                let hubIdInt = 1 * order.hub_destination_id;
                if (!activeHubs.hasOwnProperty(hubIdInt)) {
                    activeHubs[hubIdInt] = true;
                }
            }
        }

        this.filterIncludeHubs = Object.keys(activeHubs);
        this.checkedOrdersHubsCnt = this.filterIncludeHubs.length;

        if (1 === this.checkedOrdersHubsCnt) {
            let hubId = 1 * this.filterIncludeHubs[0];
            this.onSelectCourierBypass = true;
            this.appUserSelectEventsSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [hubId,]});
            this.onSelectCourierBypass = false;
        }
    }

    numChecked() {
        if (!this.orders) {
            return false;
        }
        let i = 0;
        for (const order of this.orders) {
            if (order.checked && order.status < 5) {
                i++;
            }
        }
        return i;
    }

    finishQuery() {
        this.search();
        this.checkAllFlag.nativeElement.checked = false;
        this.closeModal.nativeElement.click();
    }

    checkedOrdersUids() {
        let order = this.orders.filter(x => {
            return x.checked === true;
        })
        order = order.map(x => x.uid);
        return order;
    }
    /* --- */

    statusStyle(status: number) {
        switch (status) {
            case 1: {
                return 'grey';
            }
            case 2: {
                return 'grey';
            }
            case 3: {
                return 'blue';
            }
            case 4: {
                return 'blue';
            }
            case 5: {
                return 'green';
            }
            case 6: {
                return 'green';
            }
            case 7: {
                return 'red';
            }
            case 8: {
                return 'red';
            }
            default: {
                console.log('Invalid choice');
            }
        }
    }

    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }
}
