import {Inject, Injectable} from '@angular/core';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {LaraService} from './lara.service';
import {Observable} from 'rxjs';
import {Hub, User, UserHub} from './models';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class CurrentUserService {
    private CURRENT_USER_KEY = 'current_user';
    private currentUser: User;
    private currentUserHub: UserHub;

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private api: LaraService,
        protected router: Router
    ) {
        const data = this.storage.get(this.CURRENT_USER_KEY);
        if (data) {
            this.setCurrentUser(data);
        }

        setInterval(() => this.update(), 60000 * 5);
    }

    public get() {
        return new Observable((observer) => {
            if (this.currentUser) {
                observer.next(this.currentUser);
                observer.complete();
            } else {
                this.api.currentUser().subscribe((data: any) => {
                    if (data) {
                        data.hubs = data.hubs.map((hub: UserHub) => {
                            hub = new UserHub(hub);
                            if (hub.is_default_hub) {
                                data.default_hub = hub;
                            }

                            return hub;
                        });

                        this.setCurrentUser(data);
                    }
                    observer.next(this.currentUser);
                    observer.complete();
                });
            }
        });
    }

    public isLogin() {
        return this.api.getToken().toString().length > 0;
    }

    protected setCurrentUser(data) {
        this.storage.set(this.CURRENT_USER_KEY, data);
        data = new User(data);
        console.log('setCurrentUser', data);
        this.currentUser = data;
        this.currentUserHub = this.currentUser.default_hub;
    }

    public update() {
        /* для сбера игнорим обновление токена */
        if (this.router.url === '/sberbank') {
            return;
        }
        if (!this.isLogin()) {
            return;
        }
        this.api.updateToken().subscribe(() => {
            console.log('UPDATE TOKEN');
        });
    }

    public logout() {
        this.storage.clear();
        this.currentUser = null;
    }

    /**
     * Проверка наличия хаба в списке хабов пользователя
     * @param hub_id
     */
    public hasHub(hub_id: number): boolean {
        return this.currentUser.hasHub(hub_id);
    }

    /**
     * Получение текущего активного хаба
     */
    public getCurrentHub(): UserHub {
        return this.currentUserHub;
    }

    /**
     * Установка текущего активного хаба
     * @param hub
     */
    public setCurrentHub(hub: UserHub): UserHub {
        if (!this.currentUser.hasHub(hub.id))
        {
            hub = this.currentUser.default_hub;
        }

        this.currentUserHub = hub;
        return hub;
    }

    /**
     * получить хаб из хабов пользователя по ID
     * @param id
     */
    public getHubById(id: number): UserHub {
        let hub;
        hub = this.currentUser.hubs.find((hub: UserHub) => hub.id == id);
        if (!hub) {
            hub = this.currentUser.default_hub;

        }
        return hub;
    }
}
