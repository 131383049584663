import {
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild
} from '@angular/core';

import {OrdersService} from '../../service/orders.service';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {Subscription} from 'rxjs';

import {FormControl, FormGroup} from '@angular/forms';
import {DistrictSelectComponent} from '../../components/district-select/district-select.component';
import {Title} from '@angular/platform-browser';
import {HubsService} from '../../service/hubs.service';
import {Hub, ZoneGroup} from '../../service/models';
import {map} from 'rxjs/operators';
import {ZoneGroupAddComponent} from './zone-group-add/zone-group-add.component';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-zone-redactor',
    templateUrl: './zone-redactor.component.html',
    styleUrls: ['./zone-redactor.component.scss']
})
export class ZoneRedactorComponent implements OnInit, OnDestroy {
    protected ymaps: any;
    protected instance: any;
    public isOpen = true;
    private selectedPoly: any = null;
    @Output() onChange = new EventEmitter<string[]>();
    @Output() useMark = new EventEmitter<this>();

    @Input('selected') public selected: string[] = [];

    @ViewChild('zone_shelf') zone_shelf: ElementRef;
    @ViewChild('zone_district') zone_district: DistrictSelectComponent;
    @ViewChild('zone_tarif') zone_tarif: ElementRef;
    @ViewChild('zone_name') zone_name: ElementRef;
    @ViewChild('zone_group') zone_group: ElementRef;
    @ViewChild('zone_type') zone_type: ElementRef;
    @ViewChild('is_sameday') is_sameday: ElementRef;
    @ViewChild('zone_hub') zone_hub: ElementRef;

    protected filter_tarif: number = null;
    protected filter_type: number|string = 0;
    protected filter_hub: number = 0;
    protected filter_is_sameday: number = null;

    protected loader: Subscription;
    private isEditMode: boolean = false;
    private isClientType: boolean = false;

    public mapCenter: [number, number] = [55.751952, 37.600739];
    public mapZoom: number = 8;

    newZoneButton: any;

    public formFilter: FormGroup;

    public id: number = 0;
    private newZoneId = 9999;
    public filterTariffs: any[] = [];
    public hubs: Hub[];
    public zoneGroups: ZoneGroup[];

    public zoneIsSaving: boolean = false;

    public selectedDistrict = null;
    public zone_id: number;

    private zoneTypes = [
        {id: 1, name: 'Для курьера'},
        {id: 2, name: 'Для клиента'}
    ];

    private samedayOptions = [
        {value: 0, name: 'Нет'},
        {value: 1, name: 'Возможна'},
    ];

    private TYPE_COURIER: number = 1;
    private TYPE_CLIENT: number = 2;

    constructor(
        protected api: OrdersService,
        protected helper: HelpersService,
        protected dialog: DialogService,
        private changeDetectorRef: ChangeDetectorRef,
        protected title: Title,
        private hubsApi: HubsService,
        private app: AppComponent
    ) {
        this.formFilter = new FormGroup(
            {
                zone_shelf: new FormControl(),
                zone_tarif: new FormControl(),
                zone_type: new FormControl(),
                is_sameday: new FormControl(),
                zone_hub: new FormControl(),
                zone_name: new FormControl(),
                zone_group: new FormControl(),
                filter_by_hub: new FormControl(''),
                filter_by_tarif: new FormControl(''),
                filter_by_type: new FormControl(''),
                filter_by_is_sameday: new FormControl('')
            }
        );

        this.hubsApi.get()
            .pipe(map((data: Hub[]) => {
                // оставляем только хабы с типом Логсис
                return data.filter(h => h.type === Hub.TYPE_LOGSIS);
            }))
            .subscribe((data: Hub[]) => {
                this.hubs = data;
            })

        this.loadZonesGroups();
    }

    loadZonesGroups(setLastId = null) {
        this.api.getZoneGroups()
            .subscribe((data: ZoneGroup[]) => {
                this.zoneGroups = data;

                if (setLastId) {
                    let maxGroupId = 0;
                    data.map((group) => {
                        if (group.id > maxGroupId) {
                            maxGroupId = group.id;
                        }
                    });

                    setTimeout(() => {
                        this.zone_group.nativeElement.value = maxGroupId;
                    }, 500)
                }
            })
    }

    ngOnInit() {
        // this.setEnabled(false);
        if (this.useMark) {
            this.useMark.emit(this);
        }

        this.title.setTitle('Редактирование зон');
    }

    onCancelEdit() {
        if (this.selectedPoly && this.id != this.newZoneId) {
            if (this.selectedPoly.editor.state.get('editing')) {
                this.selectedPoly.editor.stopEditing();
            }
        }

        this.setEnabled(false);
        this.loadZones();
        this.clearAllFields();
    }

    ngOnDestroy(): void {
        if (this.loader) {
            this.loader.unsubscribe();
        }
    }

    onOpen() {
        this.isOpen = true;
    }

    tarifChanged(value) {
        this.filter_tarif = value;
        this.loadZones();
    }

    shiftNumberChanged(value) {
        this.filter_is_sameday = value;
        this.loadZones();
    }

    loadMap(event) {
        this.ymaps = event.ymaps;
        this.instance = event.instance;

        let elem = this.instance.container.getElement().parentElement.parentElement;
        elem.classList.remove('container');
        elem.classList.add('map');
        this.instance.container.fitToViewport();

        this.bindEventMap();
        this.initMap();
        this.loadZones();
    }

    onGroupAddClick() {
        this.app.createDialog(ZoneGroupAddComponent, {
            hubId: this.zone_hub.nativeElement.value
        }).onDestroy(data => {
            this.loadZonesGroups(true);
        });
    }

    onChangeDistrict(district) {
        if (district) {
            this.selectedDistrict = district;
        } else {
            this.selectedDistrict = null;
        }
    }

    setEnabled(isEnable) {
        if (isEnable) {
            this.formFilter.get('zone_shelf').enable();
            this.formFilter.get('zone_tarif').enable();
            this.formFilter.get('zone_name').enable();
            this.formFilter.get('zone_group').enable();
            this.formFilter.get('is_sameday').enable();

            if (this.id === this.newZoneId) {
                // для новой зоны даём возможность менять тип
                this.formFilter.get('zone_type').enable();
            } else {
                // не даём редактировать тип существующей зоны
                this.formFilter.get('zone_type').disable();
            }

            this.formFilter.get('zone_hub').enable();
            this.zone_district.disabled = false;

            this.formFilter.get('filter_by_hub').disable();
            this.formFilter.get('filter_by_tarif').disable();
            this.formFilter.get('filter_by_type').disable();
            this.formFilter.get('filter_by_is_sameday').disable();

            this.isEditMode = true;
        } else {
            this.formFilter.get('zone_shelf').disable();
            this.formFilter.get('zone_tarif').disable();
            this.formFilter.get('zone_name').disable();
            this.formFilter.get('zone_group').disable();
            this.formFilter.get('zone_type').disable();
            this.formFilter.get('is_sameday').disable();
            this.formFilter.get('zone_hub').disable();
            this.zone_district.disabled = true;

            this.formFilter.get('filter_by_hub').enable();
            this.formFilter.get('filter_by_tarif').enable();
            this.formFilter.get('filter_by_type').enable();
            this.formFilter.get('filter_by_is_sameday').enable();

            this.isEditMode = false;
        }

        $('body').click();
    }

    onTypeChange() {
        if (+this.zone_type.nativeElement.value === this.TYPE_CLIENT) {
            this.isClientType = true;
        } else {
            this.isClientType = false;
        }
    }

    onTypeFilterChange(type) {
        this.filter_type = type;
        this.loadZones();
    }

    onHubFilterChange(hubId) {
        this.filter_hub = +hubId;
        const selectedHub = this.hubs.find(hub => {
            return hub.id === this.filter_hub;
        });

        if (selectedHub) {
            if (selectedHub.map_zoom > 0
                && selectedHub.map_center_lat !== 0
                && selectedHub.map_center_lon !== 0
            ) {
                this.mapZoom = selectedHub.map_zoom;
                this.mapCenter = [selectedHub.map_center_lat, selectedHub.map_center_lon];

                this.instance.setCenter(this.mapCenter, this.mapZoom);
            }
        }

        this.loadZones();
    }

    bindEventMap() {
        this.instance.geoObjects.events.add('click', e => {
            const target = e.get('target');
            if (target.geometry.getType() === 'Polygon') {
                const objectId = target.options.get('objectID');
                const zone_id = target.options.get('zone_id');
                const zone_shelf = target.options.get('zone_shelf');

                const color = target.options.get('color');
                const zone_tarif = target.options.get('zone_tarif');
                const zone_name = target.options.get('zone_name');
                const zone_group = target.options.get('zone_group');
                const zone_type = target.options.get('zone_type');
                const is_sameday = target.options.get('is_sameday');
                const zone_hub = target.options.get('zone_hub');
                this.zone_id = zone_id;

                this.id = objectId;

                this.selectedPoly = target;

                this.zone_shelf.nativeElement.value = zone_shelf;
                this.zone_tarif.nativeElement.value = zone_tarif;
                this.zone_name.nativeElement.value = zone_name;
                this.zone_group.nativeElement.value = zone_group;
                this.zone_type.nativeElement.value = zone_type;
                this.is_sameday.nativeElement.value = is_sameday;
                this.zone_hub.nativeElement.value = zone_hub;
                this.zone_district.setSelected(target.options.get('district_id'));
                this.isClientType = (+zone_type === this.TYPE_CLIENT ? true : false);

                this.changeDetectorRef.detectChanges();
            } else {
                this.selectedPoly = null;
            }
            if (this.onChange) {
                this.onChange.emit(this.selected);
            }
        });

        this.changeDetectorRef.detectChanges();
    }

    rotateGeometry(geometry) {
        const coordinates = geometry.getCoordinates();
        const coorsTo = coordinates.map(cor => {
            return cor.map(point => [point[1], point[0]]);
        });
        const polygonGeometry = new this.ymaps.geometry.Polygon(coorsTo);

        return polygonGeometry;
    }

    initMap() {
        const myMap = this.instance;

        this.setEnabled(false);
        // Buttons for map
        this.newZoneButton = new this.ymaps.control.Button({
            data: {content: 'Новая зона'},
            options: {maxWidth: [150, 178], selectOnClick: false}
        });

        // add buttons to map
        myMap.controls.add(this.newZoneButton);

        // Новая зона
        this.newZoneButton.events.add('click', e => {
            // завершаем редактирование других зон
            if (this.selectedPoly && this.id != this.newZoneId) {
                if (this.selectedPoly.editor.state.get('editing')) {
                    this.selectedPoly.editor.stopEditing();
                }
            }

            const myPolygon = new this.ymaps.Polygon([], {}, {
                editorDrawingCursor: 'crosshair',
                fillColor: '#00FF00',
                strokeColor: '#0000FF',
                opacity: 0.3,
                strokeWidth: 1,
                objectID: this.newZoneId
            });
            this.instance.geoObjects.add(myPolygon);

            this.clearAllFields();
            this.id = this.newZoneId;
            this.selectedPoly = myPolygon;

            this.setEnabled(true);
            myPolygon.editor.startDrawing();
        });
    }

    onEditZoneButtonClick() {
        this.setEnabled(true);
        this.selectedPoly.editor.startDrawing();
    }

    onSaveZoneButtonClick() {
        if (!this.selectedPoly) {
            alert('выберите зону');
            return;
        }

        if (this.zone_id) {
            // редактирование старой зоны
            if (this.selectedPoly.editor.state.get('editing')) {
                const zone_shelf = this.zone_shelf.nativeElement.value;
                const zone_tarif = this.zone_tarif.nativeElement.value;
                const zone_name = this.zone_name.nativeElement.value;
                const zone_group = this.zone_group.nativeElement.value;
                const zone_type = this.zone_type.nativeElement.value;
                const is_sameday = this.is_sameday.nativeElement.value;
                const zone_hub = this.zone_hub.nativeElement.value;

                const rotatedGeo = this.rotateGeometry(this.selectedPoly.geometry);
                const encodedRotatedGeo = this.ymaps.geometry.Polygon.toEncodedCoordinates(rotatedGeo);

                this.zoneIsSaving = true;
                this.api.updateZone({
                    zone_id: this.zone_id,
                    tarif: zone_tarif,
                    district_id: (this.selectedDistrict ? this.selectedDistrict.id : 0),
                    shelf: zone_shelf,
                    geometry: encodedRotatedGeo,
                    coordinates: rotatedGeo.getCoordinates(),
                    name: zone_name,
                    type: zone_type,
                    is_sameday: is_sameday,
                    hubId: zone_hub,
                    zoneGroupId: zone_group
                })
                    .subscribe(
                        (zone) => {

                            this.setPolygonProps(this.selectedPoly, zone);
                            this.setEnabled(false);
                            this.selectedPoly.editor.stopEditing();
                            this.loadZones();
                            this.zoneIsSaving = false;
                        },
                        (error => {
                            this.zoneIsSaving = false;
                        }));
            }
        } else {
            // создание новой зоны
            // завершаем редактирование других зон
            if (this.selectedPoly && this.id !== this.newZoneId) {
                if (this.selectedPoly.editor.state.get('editing')) {
                    this.selectedPoly.editor.stopEditing();
                }
            }

            if (this.selectedPoly && this.id === this.newZoneId) {
                if (this.selectedPoly.editor.state.get('editing')) {
                    const zone_type = +this.zone_type.nativeElement.value;
                    const zone_hub = +this.zone_hub.nativeElement.value;
                    if (!zone_hub) {
                        alert('Не указан хаб!');
                        return;
                    }

                    const zone_shelf = this.zone_shelf.nativeElement.value;
                    if (!zone_shelf && zone_type !== this.TYPE_CLIENT) {
                        alert('Не указана полка!');
                        return;
                    }

                    const zone_tarif = this.zone_tarif.nativeElement.value;
                    if (!zone_tarif && zone_type !== this.TYPE_CLIENT) {
                        alert('Не указан тариф!');
                        return;
                    }

                    const zone_group = this.zone_group.nativeElement.value;

                    const zone_name = this.zone_name.nativeElement.value;
                    if (zone_name.length === 0 && zone_type === this.TYPE_CLIENT) {
                        alert('Не указано название зоны!');
                        return;
                    }

                    const rotatedGeo = this.rotateGeometry(this.selectedPoly.geometry);
                    const encodedRotatedGeo = this.ymaps.geometry.Polygon.toEncodedCoordinates(rotatedGeo);

                    this.zoneIsSaving = true;
                    this.api.addZone({
                        tarif: zone_tarif,
                        district_id: (this.selectedDistrict ? this.selectedDistrict.id : 0),
                        shelf: zone_shelf,
                        geometry: encodedRotatedGeo,
                        coordinates: rotatedGeo.getCoordinates(),
                        name: zone_name,
                        type: zone_type,
                        is_sameday: this.is_sameday.nativeElement.value,
                        hubId: zone_hub,
                        zoneGroupId: zone_group
                    }).subscribe(
                        (zone) => {
                            this.setPolygonProps(this.selectedPoly, zone);

                            this.setEnabled(false);
                            this.selectedPoly.editor.stopEditing();
                            this.loadZones();

                            this.zoneIsSaving = false;
                        },
                        (error => {
                            this.zoneIsSaving = false;
                        }));
                }
            }
        }
    }

    clearAllFields() {
        this.selectedPoly = null;
        this.zone_id = null;
        this.zone_shelf.nativeElement.value = '';
        this.zone_tarif.nativeElement.value = '';
        this.zone_name.nativeElement.value = '';
        this.zone_group.nativeElement.value = '';
        this.zone_type.nativeElement.value = this.TYPE_COURIER;
        this.is_sameday.nativeElement.value = 0;
        this.zone_hub.nativeElement.value = null;
        this.isClientType = false;
        this.zone_district.setSelected('');
    }

    // Подгрузка зон
    loadZones() {
        // удаляем все гео-объекты с карты (
        this.clearAllFields();
        if (this.instance) {
            this.instance.geoObjects.removeAll();
        }
        this.addCircles();
        const tariffs = [];
        this.api.getZones(this.filter_tarif, null, this.filter_type, this.filter_hub, this.filter_is_sameday)
            .subscribe(zones => {
                // Проходимся по массиву
                for (const data of zones) {

                    try {
                        const poly = this.ymaps.geometry.Polygon.fromEncodedCoordinates(data.geometry);
                        const polygon = new this.ymaps.Polygon(poly);

                        if (data.type === this.TYPE_COURIER && tariffs.indexOf(+data.tarif) === -1) {
                            tariffs.push(+data.tarif);
                        }

                        this.setPolygonProps(polygon, data);
                        /* конвертация координат в нормальную последовательность */

                        const coors = polygon.geometry.getCoordinates();
                        const coorsTo = coors.map(cor => {
                            return cor.map(point => [point[1], point[0]]);
                        });
                        polygon.geometry.setCoordinates(coorsTo);
                        this.instance.geoObjects.add(polygon);

                        const center = this.ymaps.util.bounds.getCenter(polygon.geometry.getBounds());
                        this.instance.geoObjects.add(
                            new this.ymaps.GeoObject({
                                // Описание геометрии.
                                geometry: {
                                    type: 'Point',
                                    coordinates: center
                                },
                                // Свойства.
                                properties: {
                                    // Контент метки.
                                    iconContent: data.shelf
                                }
                            }, {
                                // Опции.
                                // Иконка метки будет растягиваться под размер ее содержимого.
                                preset: 'islands#blackStretchyIcon',
                                // Метку можно перемещать.
                                draggable: false
                            }),
                        );
                    } catch (e) {
                        console.log(e);
                    }
                }

                if (!this.filter_tarif) {
                    // сортируем тарифы по возрастанию
                    tariffs.sort((a, b) => {
                        return a - b;
                    });
                    this.filterTariffs = tariffs;
                }
            });
    }

    setPolygonProps(polygon, zone) {
        polygon.options.set({
            fillColor: (zone.color ? zone.color : '#554433'),
            strokeColor: '#3076B4',
            opacity: 0.5,
            strokeWidth: 1,
            objectID: zone.id,
            zone_id: zone.zone_id, // Место
            zone_shelf: zone.shelf, // Полка
            district_id: zone.district_id, // Направление ид
            district_name: (zone.district ? zone.district.name : ''), // Направление имя
            zone_name: zone.name, // Название зоны
            zone_group: zone.zone_group_id, // Название зоны
            zone_type: zone.type, // тип зоны
            is_sameday: zone.is_sameday, // номер смены
            zone_hub: zone.hub_id, // id хаба
            zone_tarif: zone.tarif
        });
    }

    addCircles() {
        const myPolyline33 = new this.ymaps.Polyline([[37.8202, 55.5176], [37.9589, 55.6246], [37.9538, 55.6624], [37.9699, 55.7063], [38.0097, 55.7508], [38.0097, 55.8062], [37.9747, 55.8572], [37.8703, 55.9185], [37.8270, 55.9539], [37.7213, 55.9771], [37.6413, 55.9869], [37.5324, 56.0001], [37.4535, 55.9911], [37.4027, 55.9697], [37.3446, 55.9542], [37.2785, 55.9263], [37.2715, 55.8988], [37.2166, 55.8394], [37.2042, 55.7771], [37.1862, 55.7413], [37.1828, 55.7235], [37.2305, 55.6814], [37.2632, 55.6502], [37.2826, 55.6200], [37.3115, 55.5944], [37.3437, 55.5621], [37.3966, 55.5321], [37.5326, 55.4818], [37.5683, 55.4761], [37.5780, 55.4734], [37.6247, 55.4758], [37.7414, 55.4886], [37.8204, 55.5167]],
            {
                balloonContent: '30 км от МКАД'
            }, {
                balloonCloseButton: true,
                strokeColor: '#000000',
                strokeWidth: 3,
                strokeOpacity: 1
            });

        const myPolyline3 = new this.ymaps.Polyline([[38.1598, 55.4374], [38.1743, 55.4549], [38.1860, 55.4677], [38.2285, 55.5008], [38.2615, 55.5380], [38.2807, 55.5574], [38.2889, 55.5810], [38.2786, 55.6315], [38.2851, 55.6725], [38.3150, 55.7297], [38.3116, 55.7843], [38.3167, 55.8301], [38.2978, 55.8905], [38.2769, 55.9254], [38.2484, 55.9557], [38.2192, 55.9655], [38.1282, 55.9954], [38.0418, 56.0778], [38.0033, 56.0843], [37.9593, 56.1076], [37.9113, 56.1244], [37.4973, 56.1610], [37.3143, 56.1267], [37.2599, 56.0926], [37.1956, 56.0840], [37.1093, 56.0708], [37.0049, 55.9877], [36.9458, 55.9199], [36.9211, 55.8241], [36.9060, 55.7398], [36.8799, 55.7072], [36.9678, 55.6280], [36.9911, 55.5503], [37.0200, 55.5234], [37.0914, 55.4653], [37.2356, 55.3966], [37.3475, 55.3544], [37.4608, 55.3207], [37.6091, 55.3215], [37.6998, 55.3199], [37.8234, 55.3270], [37.9689, 55.3630], [38.0692, 55.3700], [38.1255, 55.3755], [38.1653, 55.4372]],
            {
                balloonContent: '10 км от МКАД'
            }, {
                balloonCloseButton: true,
                strokeColor: '#000000',
                strokeWidth: 3,
                strokeOpacity: 1
            });

        const myPolyline1 = new this.ymaps.Polyline([[38.1725, 56.2355], [37.9885, 56.2638], [37.7067, 56.3308], [37.4763, 56.3493], [36.9311, 56.2072], [36.8034, 56.1402], [36.6290, 55.8975], [36.5548, 55.7561], [36.6097, 55.6960], [36.6427, 55.5635], [36.7951, 55.3896], [37.0437, 55.2392], [37.4569, 55.1248], [37.8318, 55.1327], [38.3070, 55.2380], [38.4203, 55.3508], [38.5094, 55.3737], [38.5259, 55.4402], [38.6193, 55.5641], [38.6344, 55.7528], [38.6344, 55.8502], [38.6262, 55.9474], [38.5362, 56.0556], [38.4270, 56.1388], [38.1737, 56.2350]],
            {
                balloonContent: '50 км от МКАД'
            }, {
                balloonCloseButton: true,
                strokeColor: '#000000',
                strokeWidth: 3,
                strokeOpacity: 1
            });


        this.rotatePolyline(myPolyline33);
        this.rotatePolyline(myPolyline3);
        this.rotatePolyline(myPolyline1);

        // Добавляем линии на карту.
        this.instance.geoObjects.add(myPolyline33);
        this.instance.geoObjects.add(myPolyline3);
        this.instance.geoObjects.add(myPolyline1);
    }

    rotatePolyline(myPolyline33) {
        const coors = myPolyline33.geometry.getCoordinates();
        const coorsTo = coors.map(point => [point[1], point[0]]);


        myPolyline33.geometry.setCoordinates(coorsTo);
        return myPolyline33;
    }
}

