import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';


@Component({
    selector: 'app-barcode-print',
    templateUrl: './barcode-print.component.html',
    styleUrls: ['./barcode-print.component.scss']
})
export class BarcodePrintComponent implements OnChanges {


    @Input('title') title: string;
    @Input('barcode') barcode: string;
    @Input('print') print: boolean;
    @Input('visible') visible;

    @Output('finishPrint') finishPrint = new EventEmitter<boolean>();

    constructor() {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.visible && this.print) {
            setTimeout(() => this.printFile(), 500);
        }
    }



    printFile() {

        $('#my-printer').click();


        this.finishPrint.emit(true);
    }

}
