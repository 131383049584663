/**
 * Описание моделей данных
 */
import {CoordsHelper} from "./helpers/coords.helper";

export class BaseModel {
    public id?: number;
    public created_at: Date;
    public updated_at: Date;

    constructor(data: any) {
        if (!data) {
            return;
        }
        for (const key of Object.keys(data)) {
            this[key] = data[key];
        }
    }
}

export class OrdersRequest {
    public statuses?: number[];
    public client_uids?: string[];
    public client_id?: string[];
    public courier_ids?: number[];
    public intervals?: number[];
    public district_ids?: number[];
    public zone_ids?: number[];
    public zone_id?: number;
    public is_important?: boolean;

    public courier_null?: boolean;

    public delivery?: { from: string, to?: string };
    public zones?: { from: number, to?: number };
    public shelfs: { from: number, to?: number };
    public weight?: { from: number, to?: number };

    public sort?: { [key: string]: string };

    public limit?: number | string;
    public page?: number;
    public map?: boolean;

    public hub_id?: number;

    public date_mode?: string;
    public date?: any | { from: string, to?: string };
    public delivery_date?: { from: string, to?: string };
    public created_at?: { from: string, to?: string };
    public stock_datetime?: { from: string, to?: string };

    public current_hubs?: string[];
    public unresolvedProblems?: boolean;
}

export class ClientInfo extends BaseModel {
    public matrix: string;
    public matrix_spb: string;
    public matrix_top_delivery: string;

    public kgt_type: string;
    public uid: string;
    public name: string;
    public client_uid: string;
    public client_uid_old: string;
    public full_name: string;
    public doc_num: string;
    public doc_date: Date;
    public inn: number;
    public kpp: number;
    public type: string;
    public parent_company: string;
    public parent_id: number;
    public active: boolean;
    public is_places: boolean;
    public is_round_weight: boolean;
    public api_key: string;
    public regional_agreement: boolean;
    public is_intermediary: boolean;
    public is_recipient_required: boolean;
    public parking_payment: number;
    public is_wc_delivery: boolean;
    public repeat_delivery_payment: boolean;
    public redelivery_percent: number;
    public is_regions_by_logsis: boolean;
    public is_ignore_kgt: boolean;
    public is_calc_spb_by_km: boolean;
    /* кастомное свойство для автокомплита */
    public full_name_uid: string;
    public default_hub_id: number;
    public default_hub: Hub;
    public force_orange_receipts_since: string;
    public cabinets: ClientUser[];
    public is_sameday_possible: boolean;
    public is_sameday_take_by_cargo: boolean;
}

export class ClientUser extends BaseModel {
    public id: number;
    public client_id: number;
    public email: string;
    public phone: string;
    public login: string;
    public name: string;
    public position: string;
    public password: string;
    public is_old_password: number;
    public last_login: Date;
    public deleted_at: Date;
}

export class MatrixCategoryResponse {
    public moscow: string[];
    public spb: string[];
    public top_delivery: string[];
}

export class ApiKey extends BaseModel {
    public apikey: string;
    public active: boolean;
}

export class UserGroup extends BaseModel {
    public id: number;
    public title: string;

}

export class User extends BaseModel {
    /* дефолтные группы пользователей */
    public ROLE_ADMIN: number = 1;
    public ROLE_DIRECTOR: number = 2; /* Руководтели */
    public ROLE_MANAGER: number = 3; /* Менеджеры по продажам */
    public ROLE_COURIER: number = 4; /* Курьеры */
    public ROLE_LOGISTIC: number = 5; /* Логистика */
    public ROLE_FINANCE: number = 6; /* Финансовый отдел */
    public ROLE_PROGRAMMER: number = 7; /* Программисты */
    public ROLE_STOCK: number = 8; /* Склад */
    public ROLE_SERVICE: number = 9; /* Клиентский сервис */
    public ROLE_CASHBOX: number = 10; /* Касса */
    public ROLE_CUSTOMER: number = 11; /* Клиенты */

    public id: number;
    public name: string;
    public email: string;
    public client_id: number;
    public active: boolean;
    public blocked: boolean;
    public role: number;
    public roles: any;
    public last_request: Date;
    public last_login: Date;
    public permissions: any;
    public groups: { id: number; title: string }[];
    public default_hub_id: number;
    public work_is_first_shift: number;
    public work_is_second_shift: number;
    public hubs: UserHub[];
    public default_hub: UserHub;


    public getRoleString() {
        return this.getRoleTitle()[this.role - 1];
    }

    public getRoleTitle() {
        return ['Admin', 'Руководитель', 'Менеджер по продажам', 'Курьер', 'Логист', 'Финансовый отдел', 'Программист', 'Склад', 'Клиентский сервис', 'Касса', 'Клиент'];
    }


    /**
     * @param role
     * @return boolean
     */

    /*public isRole(role) {
        return this.role === role;
    }*/
    public isRole(set_role) {
        for (const role of this.roles) {
            if (role.id === set_role) {
                return true;
            }
        }
    }

    /**
     * @return boolean
     */
    public isRoleAdmin() {
        return this.isRole(this.ROLE_ADMIN);
    }

    public isRoleDirector() {
        return this.isRole(this.ROLE_DIRECTOR);
    }

    public isRoleManager() {
        return this.isRole(this.ROLE_MANAGER);
    }

    public isRoleCourier() {
        return this.isRole(this.ROLE_COURIER);
    }

    public isRoleLogistic() {
        return this.isRole(this.ROLE_LOGISTIC);
    }

    public isRoleFinance() {
        return this.isRole(this.ROLE_FINANCE);
    }

    public isRoleStock() {
        return this.isRole(this.ROLE_STOCK);
    }

    public isRoleProgrammer() {
        return this.isRole(this.ROLE_PROGRAMMER);
    }

    public isRoleService() {
        return this.isRole(this.ROLE_SERVICE);
    }

    public isRoleCashBox() {
        return this.isRole(this.ROLE_CASHBOX);
    }

    public isRoleCustomer() {
        return this.isRole(this.ROLE_CUSTOMER);
    }

    public inGroup(id) {
        return this.groups.filter(item => item.id === id).length > 0;
    }

    public inPermission(name) {
        console.log(this);
        for (const user_permission of this.permissions) {
            if (name === user_permission.name) {
                return true;
            }
        }
        return false;
    }

    public inGroups(ids: number[]) {
        return this.groups.filter(item => ids.indexOf(item.id) > 0).length;
    }

    public hasHub(id: number) {
        console.log(this.hubs);
        return !!this.hubs.find((hub: UserHub) => hub.id === id);
    }
}

export class MatrixRegion extends BaseModel {
    public code: number;
    public title: string;
    public is_dist: boolean;
    public opt_sms: number;
    public opt_returned_doc: number;
    public opt_opening: number;
    public opt_buyback: number;
    public opt_fitting: number;
    public opt_skid_kgt: number;
    public opt_call: number;
    public opt_equipment: number;
    public opt_packaging: number;
}

export class MatrixData {
    /* массив дистанций */
    public dist: number[];
    /* массив дистанций */
    public mass: number[];
    /*
    сама матрица
    matrix[mass][dist] = [price, price_delta, is_edit]
    */
    public matrix: number[];
    /* массив платных опций */
    public options: { string: number };
    /* массив платных опций с ценами по умолчанию */
    public options_default: { string: number };
}

export class MatrixKgt {
    public mass: number[];
    public matrix: any
}

export class MatrixTakeAway {
    public dist_from: number;
    public dist_to: number;
    public price: number;
}

export class MatrixTakeAwayCfo {
    public zone_group_id: number;
    public price: number;
}

export class SubAgent extends BaseModel {
    public old_id: number;
    public name: string;
    public name_full: string;
    public contact_name: string;
    public contact_number: string;
    public contact_mail: string;
    public contract: string;
    public contract_date: string;
    public director: string;
    public type: number;
    public parent_company: number;
    public pvz_insurance: number;
    public pvz_cod_cash: number;
    public pvz_cod_card: number;
    public pvz_sms: number;
    public pvz_storage: number;
    public pvzs: Pvz[];
}

export class Pvz extends BaseModel {
    public old_id: number;
    public sub_agent_id: number;
    public name: string;
    public contract: string;
    public contract_date: Date;
    public address_id: number;
    public address: Address;
}

export class ShiftType {
    public static readonly FIRST_SHIFT = 1;
    public static readonly SECOND_SHIFT = 2;
    public static readonly description = [
        { name: '1 Смена', value: 1},
        { name: '2 Смена', value: 2},
    ];
}
/********************************************************
 * Модель заказа
 */
export class Order {
    // Новая заявка
    public static STATUS_NEW = 1;
    // Заявка принята
    public static STATUS_ACCEPTED = 2;
    // На складе
    public static STATUS_STORAGE = 3;
    // На доставке
    public static STATUS_DELIVERY = 4;
    // Доставлена
    public static STATUS_FINISH = 5;
    // Част. отказ
    public static STATUS_FAIL_PARTIAL = 6;
    // Полн. отказ
    public static STATUS_REFUSE = 7;
    // Отмена
    public static STATUS_CANCEL = 8;
    // Перемещение между хабами
    public static STATUS_TRANSIT = 9;

    // До скольки можно создать заказ sameday
    public static MAX_HOUR_CREATE_SAMEDAY_DELIVERY = 15;

    public id: number;
    public courier_id: number;
    public inner_n: string;
    public delivery_date: string;
    public delivery_time: number;
    public status: number;
    public request: string;
    public created_at: Date;
    public updated_at: Date;
    public uid: string;
    public client_id: number;
    public client_uid: string;
    public barcode: string;
    public been_on_warehouse: number;
    public zone_id: number;
    public returned_courier_id: number;
    public returned_storage: number;
    public sync_date: Date;
    public sms_counter: number;
    public substatus_id: number;
    public is_regional: number;

    public bill: OrderBill;
    public option: OrderOption;
    public target: OrderTarget;
    public client: ClientInfo;
    public zone: OrderZone;
    public goods: OrderGood[];
    public all_goods: OrderGood[];
    public bar_codes: OrderBarCode[];

    public log_status: OrderLogStatus[];
    public cab_logs: OrderCabLog[];
    public courier: User;
    public return_courier: User;
    public vendor_code: string;

    public hub_origin_id?: number;
    public hub_destination_id?: number;
    public hub_current_id?: number;

    public hub_origin?: Hub;
    public hub_destination?: Hub;
    public hub_current?: Hub;

    public shift_number?: number;
    public is_sameday?: boolean;
}

export class AktNumbers {
    public old_vact_id: string;
}

export class Mact {
    public macId: number;
}

export class OrderBill {
    public payment: number;
    public payment_cash: number;
    public payment_card: number;
    public tariff_total: number;
    public tariff_base: number;
    public tariff_buyback: number;
    public tariff_call: number;
    public tariff_card: number;
    public tariff_cash: number;
    public tariff_equipment: number;
    public tariff_fitting: number;
    public tariff_opening: number;
    public tariff_os: number;
    public tariff_packaging: number;
    public tariff_returned_doc: number;
    public tariff_skid_kgt: number;
    public tariff_sms: number;
    public calculator_result: number;
    public os: number;
    public is_np: number;
    public np: number;
    public price_client_delivery: number;
    public price_client_delivery_nds: number;
    public sms_counter: number;
    public tariff_return: number;
    public card_type: number;
    public real_price_courier: number;
    public calc_price_courier: number;
    public prepaid_amount: number;
}

export class OrderOption {
    public dimension_side1: number;
    public dimension_side2: number;
    public dimension_side3: number;
    public weight: number;
    public weight_validated: boolean;
    public count: number;
    public option_opening: number;
    public option_sms: number;
    public option_call: number;
    public option_returned_doc: number;
    public option_buyback: number;
    public option_fitting: number;
    public option_skid_kgt: number;
}

export class OrderTarget {
    public address_text: string;
    public region_code: number;
    public city: string;
    public city_kladr: number;
    public house: number;
    public building: number;
    public office: number;
    public intercom: string;
    public floor: number;
    public lift: boolean;
    public target_name: string;
    public target_contacts: string;
    public target_notes: string;
    public organization_name: string;
    public second_phone: string;
    public mkad_dist: number;
    public lat: number;
    public lon: number;
}

export class OrderGood {
    bar_code_id: number;
    count: number;
    is_cancel: number;
    name: string;
    nds: number;
    price: number;
    return_act_id: string;
    status: number;
    vendor_code: number;
    weight: number;
}

export class OrderBarCode {
    public bar_code: string;
    public is_cancel: boolean;
    public order_id: string;
    public weight: boolean;
    public status: number;
    public place_num: number;

}

export class OrderZone {
    public id: number;
    public place: any;
    public zone_id: number;
    public shelf: number;
    public color: string;
    public tarif: number;
    public district: District;
    public district_id: number;
    public geometry: string;
}

export class District {
    public id: number;
    public name: string;
}

export class OrderLogStatus {

}

export class OrderCabLog {
    public o_id: string;
    public who: number;
    public what: string;
    public old_val: string;
    public new_val: string;
    public timestamp: Date;
}

/********************************************************
 * Модели адресов
 */
class BaseAddress {
    public kladr_id: number;
    public fias_id: string;
    public name: string;
    public name_full: string;
    public type: string;
    public type_full: string;
}

export class Region extends BaseAddress {
    public region_code: number;
}

export class City extends BaseAddress {
}

export class Settlement extends BaseAddress {
}

export class Street extends BaseAddress {
}

export class House extends BaseAddress {
    public number: string;
    public block_type: string;
    public block_type_full: string;
    public block: string;
}

export class Qc {
    public qc: number;
    public qc_geo: number;
    public qc_complete: number;
    public qc_house: number;
}

export class Address {
    public id: number;
    public source: string;
    public result: string;
    public validate: boolean;
    public latlon: number[];
    public region_code: number;
    public behind_mkad: boolean;
    public mkad_distance: number;
    public region: Region;
    public city: City;
    public settlement: Settlement;
    public street: Street;
    public house: House;
    public qc: Qc
}

export class Courier {
    public allowAssembly: string;
    public user: [
        { id: string },
        { name: string }
        ];

    error: string;
}

export class CourierList {
    public id: number;
    public name: string;
    public email: string;
    public client_id: string;
    public active: number;
    public blocked: number;
    public role: number;
    public courier_option: CourierOption;
}

export class CourierOption extends BaseModel {
    public old_courier_id: number;
    public full_name: string;
    public phone: string;
    public mobile_phone1: string;
    public mobile_phone2: string;
    public car_mark: string;
    public car_model: string;
    public car_number: string;
    public printer_number: number;
    public terminal_serial: string;
    public device_token: string;
    public is_ios: number;
    public type: number;
    public company_id: number;
    public avatar: string;
    public cargo_tariff_id?: number;
    public shift_without_call?: number;
}

export class OrdersList {
    public id: string;
    public uid: string;
    public inner_n: string;
    public barcode: string;
}


export class ZorderList {
    public id: number;
    public old_zorder_id: number;
    public client_id: string;
    public courier_id: number;
    public inner_n: string;
    public date: string;
    public is_sameday: boolean | number;
    public time_begin: string;
    public time_end: string;
    public warehouse: any;
    public warehouse_id: number;
    public zone_id: number;
    public comments: string;
    public status: number;
    public price: number;
    public bill_id_1c: string;
    public weight: number;
    public capacity: number;
    public car_type: number;
    public places_count: number;
    public link_bill_id_1c: number;
    public sync_date: string;
    public mo_mkad: number;
    public real_price_courier: number;
    public calc_price_courier: number;
    public substatus: number;
    public client: ClientInfo;
    public zone: OrderZone;

}

export class Company {
    public id: number;
    public created_at: string;
    public updated_at: string;
    public name: string;
    public full_name: string;
    public inn: number;
    public ogrn: number;
    public kpp: number;
    public ur_address: string;
    public fact_address: string;
    public bank_name: string;
    public bank_bik: number;
    public bank_corr: number;
    public bank_account: number;
    public dir_fio: string;
    public dir_fio_rod: string;
    public dir_fio_short: string;
    public buh_fio: string;
    public buh_fio_rod: string;
    public buh_fio_short: string;
    public tax_type: string;
    public tax_type_print: string;
}

export class barCodes {
    public barcode: string;
}

export class CourierValid {
    name: string;
    email: string;
    phone: string;
}

export class Packs {
    public id: number;
    public status: number;
    public orders?: Order[];
    public transit_id: number;
}

export class Hub extends BaseModel {
    // Хабы Логсис
    public static TYPE_LOGSIS = 1;
    // Хабы ТопДеливери
    public static TYPE_TOPDELIVERY = 2;

    public name: string;
    public full_name_id: string;
    public map_zoom: number;
    public map_center_lat: number;
    public map_center_lon: number;
    public delivery_intervals?: HubDeliveryInterval[];
    public default_delivery_interval?: HubDeliveryInterval;
    public type: number;

    constructor(data: any) {
        super(data);

        this.full_name_id = this.name + ' [' + this.id + ']';

        if (data.hasOwnProperty('delivery_intervals') && data.delivery_intervals.length) {
            this.delivery_intervals = data.delivery_intervals.map(interval => {
                return new HubDeliveryInterval(interval);
            });

            this.default_delivery_interval = this.delivery_intervals.find((interval: HubDeliveryInterval) => {
                return interval.is_default_interval;
            });
        }
    }
}

export class ZoneGroup extends BaseModel {
    public hub_id: number;
    public hub: Hub;
    public name: string;
}

export class UserHub extends Hub {
    public is_default_hub: boolean;
    public pivot: {
        hub_id: number;
        is_default_hub: boolean;
        user_id: number;
    };

    constructor(data: any) {
        super(data);

        this.is_default_hub = this.pivot.is_default_hub = !!this.pivot.is_default_hub;
    }
}

export class HubDeliveryInterval extends BaseModel {
    public static NOT_PRESENT_INTERVAL_ID: number = 6;

    id: number;
    hub_id: number;
    time_start: string;
    time_end: string;
    hours: [
        (number | string),
        (number | string)
        ];
    hours_full: [
        (string),
        (string)
        ];
    is_default_interval?: boolean;
    shift_number: number;
    label: string;
    label_short: string;

    constructor(data: any) {
        super(data);

        this.is_default_interval = data.hasOwnProperty('is_default_interval') ? !!this.is_default_interval : false;

        if (data.id === HubDeliveryInterval.NOT_PRESENT_INTERVAL_ID) {
            this.label = 'Не передали интервал';
            this.label_short = 'Нет интервала';
        }
    }
}

export class Pallet extends BaseModel {
    public static TYPE_TRANSIT = 1;
    public static TYPE_RETURN = 2;

    public static TYPES_DESCRIPTION = [
        {'id': 1, 'name': 'Перемещение'},
        {'id': 2, 'name': 'Возврат'}
    ];
    public static STATUSES_DESCRIPTION = [
        {'id': 1, 'name': 'Новая'},
        {'id': 2, 'name': 'На доставке'},
        {'id': 3, 'name': 'Доставлена'}
    ];

    public type: number;
    public status: number;
    public hub_origin?: Hub;
    public hub_origin_id?: number;
    public hub_destination?: Hub;
    public hub_destination_id?: number;
    public transit_id?: number;
    public places?: any;
    public transit?: Transit;
}

export class CourierExtraExpences extends BaseModel {


    public static TYPES_DESCRIPTION = [
        {'id': 1, 'name': 'Парковка'},
        {'id': 2, 'name': 'Другое'},
        {'id': 3, 'name': 'Парковка в БЦ'},
    ];

    public static STATUSES_DESCRIPTION = [
        {'id': 0, 'name': 'Запрос'},
        {'id': 1, 'name': 'Одобрен'},
        {'id': 2, 'name': 'Отменен'},
    ];


}

export class Transit extends BaseModel {
    public static TYPES_DESCRIPTION = [
        {'id': 1, 'name': 'Перемещение'},
        {'id': 2, 'name': 'Возврат'}
    ];
    public static STATUSES_DESCRIPTION = [
        {'id': 1, 'name': 'Новое'},
        {'id': 2, 'name': 'В доставке'},
        {'id': 3, 'name': 'Доставлено'},
        {'id': 4, 'name': 'Отмена'}
    ];

    public static STATUS_NEW = 1;
    public static STATUS_ONDELIVERY = 2;
    public static STATUS_DELIVERED = 3;
    public static STATUS_CANCELED = 4;

    public static TYPE_TRANSIT = 1;
    public static TYPE_RETURN = 2;

    public packs: Packs[];
    public files: TransitFile[];
    public status: number;
    public start_date: string;
    public courier?: User;
    public hub_origin?: Hub;
    public hub_origin_id?: number;
    public hub_destination?: Hub;
    public hub_destination_id?: number;
    public transit_driver_id?: number;
    public transit_driver?: TransitDriver;
    public transit_vehicle_id?: number;
    public transit_vehicle?: TransitVehicle;
    public transit_carrier_id?: number;
    public transit_carrier?: TransitCarrier;
}

/** Файлы перемещений */
export class TransitFile extends BaseModel {
    public static fileTypes = [
        {type: 'waybill', name: 'Накладная'},
        {type: 'torg_13', name: 'Торг-13'}
    ];

    public transit_id: number;
    public file_type: string;
    public file_path: string;
    public file_url: string;
    public user_id: number;
}

/** Водитель, перевозящий перемещение */
export class TransitDriver extends BaseModel {
    public name: string;
    public phone?: string;
    public organization_name?: string;
    public organization_address?: string;
    public organization_phone?: string;

    public passport_number?: string;
    public passport_issuer?: string;
    public passport_date?: string;
    public passport_issuer_code?: string;
}

/** Машина, перевозящая перемещение */
export class TransitVehicle extends BaseModel {
    public static ownershipTypes = [
        {id: 1, name: 'собственность'},
        {id: 2, name: 'аренда'},
        {id: 3, name: 'лизинг'},
    ];

    public reg_number: string;
    public model: string;
    public weight_capacity?: number;
    public volume_capacity?: number;
    public ownership_type?: number;
}

/** Организация, перевозящая перемещение */
export class TransitCarrier extends BaseModel {
    public name: string;
    public inn: string;
    public kpp: string;
    public fact_address: string;
    public jur_address: string;
    public phones: string;
}

export class ChangesLogger extends BaseModel {
    public item_id: number;
    public user_id: number;
    public user: User;
    public action: string;

    public new_values;
    public old_values;
}

export class MatrixTopDeliveryZone extends BaseModel {
    public number: number;
    public is_central: number;
}

/**
 * Рабочий день курьера-грузовика
 */
export class CargoCourierWorkday extends BaseModel {
    public courier_id: number;
    public date: string;
    public time_start: string;
    public time_end: string;
    public hours: number;
    public mileage: number;
    public kgt_payments: number;
    public cargo_tariff_id: number;
    public hours_cost: number;
    public mileage_cost: number;
    public salary_calculated: number;
    public salary: number;
    public approved: boolean;
    public approve_datetime: string;
    public approve_manager_id: number;
    public canceled: boolean;
    public cancel_datetime: string;
    public cancel_manager_id: number;
    public logistic_notes: string;

    public courier_options?: CourierOption;
    public cargo_tariff?: CargoTariff;
    public salary_payed: boolean;
    public closed_by_warehouse: boolean;

    public time_start_hours?: string;
    public time_start_minutes?: string;
    public time_end_hours?: string;
    public time_end_minutes?: string;

    public hours_to_pay: number;

    constructor(data: any) {
        super(data);

        this.time_start = (data.time_start) ? data.time_start.substring(0, 5) : null;
        this.time_end = (data.time_end) ? data.time_end.substring(0, 5) : null;

        this.approved = !!this.approved;
        this.canceled = !!this.canceled;

        this.hours_to_pay = this.hours;

        this.courier_options = new CourierOption(data.courier_options);
        if (data.cargo_tariff) {
            this.cargo_tariff = new CargoTariff(data.cargo_tariff);

            /**
             * RESEARCH-1062: по часам оплачиваем
             * если нет минималки - целиком
             * если есть минималка - минималка плюс сверх 14
             */
            if (1 * this.cargo_tariff.minimal_salary) {
                this.hours_to_pay = Math.max(this.hours - 14, 0);
            }
        }

        this.salary_payed = !!this.salary_payed;
        this.closed_by_warehouse = !!this.closed_by_warehouse;

        this.time_start_hours = (data.time_start) ? data.time_start.substring(0, 2) : null;
        this.time_start_minutes = (data.time_start) ? data.time_start.substring(3, 5) : null;
        this.time_end_hours = (data.time_end) ? data.time_end.substring(0, 2) : null;
        this.time_end_minutes = (data.time_end) ? data.time_end.substring(3, 5) : null;
    }
}

/**
 * Тариф курьера-грузовика
 */
export class CargoTariff extends BaseModel {
    public id: number;
    public name: string;
    public hours_cost: number;
    public mileage_cost: number;
    public minimal_salary: number;

    public name_full: string;

    constructor(data: any) {
        super(data);

        this.name_full = data.name + ' [' + data.id + ']';
    }
}

/**
 * Пользователь-курьер
 */
export class CourierUser extends User {
    public old_courier_id: number;
}

export class ClientSetting extends BaseModel {
    public client_id: number;
    public setting_key: ClientSettingKey;
    public setting_value: string | boolean | number;
}

export class ClientSettingKey {
    /** @var string Всегда отправлять СМС */
    public static MASTER_SMS = 'master_sms';

    /** @var string Всегда звонить */
    public static MASTER_CALL = 'master_call';

    /** @var string Всегда вкладывать накладную */
    public static MASTER_DOCS = 'master_docs';

    /** @var string Всегда возвращать накладную */
    public static MASTER_DOCS_RETURN = 'master_docs_return';

    /** @var string Нельзя оплачивать картой */
    public static NO_CARD_PAYMENHT = 'no_card_payment';

    /** @var string Печатать внутр. номер на этикетке */
    public static PRINT_INNER_NUMBER_ON_LABEL = 'print_inner_number_on_label';

    /** @var string Не использовать объёмный вес при расчёте */
    public static CALC_USE_WEIGHT = 'calc_use_weight';

    /** @var string Использовать объёмный вес при расчёте в Москве и МО */
    public static CALC_USE_VOLUME_WEIGHT_MO = 'calc_use_volume_weight_mo';

    /** @var string Имеет доступ к API чеков */
    public static HAS_RECEIPTS_API = 'has_receipts_api';

    /** @var string Клиент по оферте */
    public static IS_OFFER = 'is_offer';

    /** @var string Кол-во заказов в месяц */
    public static ORDERS_COUNT_MONTHLY = 'orders_count_monthly';

    /** @var string Текст для подписи в клиентском приложении */
    public static TEXT_FOR_SIGNATURE = 'text_for_signature';

    /** @var string Примерка вещей отключена */
    public static FITTING_DISABLED = 'fitting_disabled';

    /** @var string Исключать из финансовых отчётов */
    public static EXCLUDE_FROM_FINANCIAL_REPORTS = 'exclude_from_financial_reports';

    /** @var string Занос КГТ разрешён */
    public static MASTER_SKID_KGT = 'master_skid_kgt';

    /** @var string Частичный выкуп разрешён */
    public static MASTER_BUYBACK = 'master_buyback';

    /** @var string максимальная оценочная стоимость */
    public static MAX_OS = 'max_os';

    /** @var string Показывать в статистике к собранию */
    public static SHOW_IN_MEETING_STAT = 'show_in_meeting_stat';

    /** @var string Тарификация только выкупленных заказов */
    public static CALC_ONLY_PURCHASED_ORDERS = 'calculate_only_purchased_orders';

    /** @var string Тарификация по почтовому индексу */
    public static CALC_BY_POSTAL_CODE = 'calculate_by_postal_code';

    /** @var string Тарификация по почтовому индексу, полученному от клиента */
    public static CALC_BY_CLIENT_POSTAL_CODE = 'calculate_by_client_postal_code';

    /** @var string Тестовый клиент */
    public static IS_TEST = 'is_test';

    /** @var string Клиент Логсис */
    public static IS_LOGSIS = 'is_logsis';

    /** @var string Email: отправка отчётов по статусам заказов */
    public static EMAIL_ORDER_STATUSES_REPORTS = 'email_order_statuses_reports';

    /** @var string Email: отправка отчётов по возвратам */
    public static EMAIL_ORDER_RETURNS_REPORTS = 'email_order_returns_reports';

    /** @var string Email: отправка отчётов по платёжным документам */
    public static EMAIL_PAYMENTS_REPORTS = 'email_payments_reports';

    /** @var string отправлять ли клиенту отчеты по платежам */
    public static SEND_PAYMENTS_REPORTS = 'send_payments_reports';

    /** @var string отправлять ли клиенту возвратные акты */
    public static SEND_RETURN_ACTS = 'send_return_acts';

    /** @var string минимальный интервал забора */
    public static MINIMAL_ZORDER_INTERVAL = 'minimal_zorder_interval';

    /** @var string Максимальный неконтролируемый вес */
    public static MAX_UNCONTROLLED_WEIGHT = 'max_uncontrolled_weight';

    /** @var string Печатать чеки для предоплаченных заказов */
    public static NEED_RECEIPT_FOR_PREPAID_ORDERS = 'need_receipt_for_prepaid_orders';

    /** @var float Коэф. за доставку день в день */
    public static SAMEDAY_COEF = 'sameday_coef';

    public static SPB_TO_MSK = 'spb_to_msk';

    public key: string;
    public name: string;
    public is_editable: number;
    public type: string;
    public created_at: Date;
    public updated_at: Date;
}

export class ParentCompany extends BaseModel {
    public is_active: number;
    public name: string;
    public full_name: string;
    public inn: string;
    public ogrn: string;
    public okpo: string;
    public ur_address: string;
    public fact_address: string;
    public bank_name: string;
    public bank_bik: string;
    public bank_corr: string;
    public bank_account: string;
    public dir_fio: string;
    public dir_fio_rod: string;
    public dir_fio_short: string;
    public buh_fio: string;
    public buh_fio_rod: string;
    public buh_fio_short: string;
    public tax_type: string;
    public tax_type_print: string;
    public selected?: boolean;
}

export class DocReturnAct extends BaseModel {
    // Формируется
    public static STATUS_OPENED = 1;

    // Сформирован
    public static STATUS_CLOSED = 2;

    // Подписан
    public static STATUS_SIGNED = 3;

    public static STATUS_DESCRIPTION = [
        '',
        'Формируется',
        'Сформирован',
        'Подписан',
    ];

    public old_akt_id?: number;
    public client_id: number;
    public status: number;
    public act_date: string;
    public signed_act_return_date?: string;
    public client?: DocReturnActClientInfo;
    public act_orders_with_info?: DocReturnActOrder[];
}

export class DocReturnActOrder extends BaseModel {
    // Актирован
    public static STATUS_ACTIVE = 1;

    // Удалён из акта
    public static STATUS_DELETED = 2;

    public static STATUS_DESCRIPTION = [
        '',
        'Актирован',
        'Удалён из акта',
    ];

    public doc_return_act_id?: number;
    public old_akt_order_id?: number;
    public client_id: number;
    public order_id?: number;
    public order_uid?: string;
    public barcode?: string;
    public inner_n?: string;
    public date_add: string;
    public status?: number;

    public order_status?: number;
    public order_target_name?: string;
    public order_recipient_name?: string;
}

export class DocReturnActClientInfo extends BaseModel {
    public id: number;
    public client_uid: string;
    public client_uid_old: string;
    public name: string;
    public full_name: string;
    public ceo?: string;
    public ceo_rod?: string;
    public parent_id?: number;
    public company?: DocReturnActClientParentCompanyInfo;
}

export class DocReturnActClientParentCompanyInfo extends BaseModel {
    public id: number;
    public full_name: string;
    public dir_fio_rod: string;
    public dir_fio_short: string;
}

export class DocReturnActOrderResponseUnit extends BaseModel {
    public order_id: number;
    public order_uid: string;
    public order_inner_n: string;
    public order_barcode: string;
    public order_delivery_date: string;
    public order_status: number;
    public order_target_name: string;
    public order_recipient_name: string;
    public order_option_returned_doc: number;

    public client_id: number;
    public client_uid: string;
    public client_uid_old: string;
    public client_name: string;
    public client_full_name: string;

    public courier_id?: number;
    public courier_name?: string;
    public courier_full_name?: string;

    public doc_return_act_id?: number;
    public doc_return_act_date?: string;
    public doc_return_act_status?: number;
    public doc_return_act_order_date_add?: string;
    public doc_return_act_order_status?: number;
}

export class JobProgress extends BaseModel {
    public static STATUS_CREATED = 'created';
    public static STATUS_STARTED = 'started';
    public static STATUS_PROCESSED = 'processed';

    public status: string;
    public job_name: string;
    public description: string;
    public status_description: string;
    public errors_array: string;

    public progress: number;
    public total_items: number;

    public started_at: Date;
    public processed_at: Date;

    public user: User;
}

export class BadAddressedTarget extends BaseModel {
    uid?: string | null;
    inner_n?: string | null;
    date?: string | null;
    status?: string | null;
    target_type: string | null;
    target_lat: string | number | null;
    target_lon: string | number | null;
    target_address_text: string;

    address_id: number;
    address_lat: string | number | null;
    address_lon: string | number | null;
    address_geo_lat: string | number | null;
    address_geo_lon: string | number | null;
    address_qc: number;
    address_qc_geo: number;
    address_quality: number;

    address_requires_call_id?: number;
    address_blacklist_coords_id?: number;

    x_address_requires_call: boolean = false;
    x_address_blacklist_coords: boolean = false;
    x_address_quality_bad_forever: boolean = false;
    x_address_quality_bad: boolean = false;
    x_address_quality_neutral: boolean = false;
    x_address_quality_good: boolean = false;
    x_address_coords_empty: boolean = false;
    x_target_coords_empty: boolean = false;
    x_coords_equals: boolean = false;
    x_coords_mismatch: boolean = false;
    x_bad_dadata: boolean = false;

    constructor(data: any) {
        super(data);

        this.x_address_requires_call = !!this.address_requires_call_id;
        this.x_address_blacklist_coords = !!this.address_blacklist_coords_id;

        this.x_address_quality_bad_forever = (-128 === this.address_quality);
        this.x_address_quality_bad = (0 > this.address_quality);
        this.x_address_quality_neutral = (0 === this.address_quality);
        this.x_address_quality_good = (0 < this.address_quality);

        this.x_bad_dadata = (this.address_qc_geo >= 4 && this.address_qc > 2);

        this.x_address_coords_empty = (null === this.address_lat || null === this.address_lon);
        this.x_target_coords_empty = (null === this.target_lat || null === this.target_lon);

        this.x_coords_equals = CoordsHelper.IsCoordsEquals([
            this.target_lat, this.target_lon
        ], [
            this.address_lat, this.address_lon
        ]);

        this.x_coords_mismatch = (!this.x_address_coords_empty && !this.x_target_coords_empty && !this.x_coords_equals);
    }
}

export class Warehouse extends BaseModel {
    old_sklad_id?: number;
    name?: string;
    client_id: number;
    comments?: string;
    contact_name?: string;
    contact_phone?: string;
    mapper_id?: number;
    mapper_result: string;
    address_text: string;
    working_hours?: string;
    inner_n?: string;
    returns_possible: boolean;
    lat?: number;
    lon?: number;
    hub_id: number;
    client_code?: string;
    visible: boolean;

    constructor(data: any) {
        super(data);

        this.returns_possible = !!data.returns_possible;
        this.visible = !!data.visible;

        if (data.lat && data.lon) {
            this.lat = ('string' === typeof data.lat) ? Number.parseFloat(data.lat) : data.lat;
            this.lon = ('string' === typeof data.lon) ? Number.parseFloat(data.lon) : data.lon;
        }
    }
}

export class Zorder extends BaseModel {
    public static readonly STATUS_NEW = 1;
    public static readonly STATUS_APPROVED = 2;
    public static readonly STATUS_DONE = 3;
    public static readonly STATUS_CANCEL = 4;
    public static readonly STATUS_DATE_CHANGED = 6;
    public static readonly STATUS_ON_STOCK = 7;

    public static readonly STATUS_DESCRIPTION = [
        '',
        'Новый',
        'Подтвержден',
        'Выполнен',
        'Отменен',
        '',
        'Перенос',
        'На складе',
    ];

    old_zorder_id?: number;
    client_id: string | number;
    courier_id?: number;
    inner_n?: string;
    date?: string;
    is_sameday: boolean | number;
    time_begin?: string;
    time_end?: string;
    warehouse_id?: number;
    zone_id?: number;
    comments?: string;
    status: number;
    price?: number;
    bill_id_1c?: string;
    weight?: number;
    capacity?: number;
    car_type: number;
    places_count?: number;
    link_bill_id_1c?: string;
    sync_date: string;
    mo_mkad?: number;
    real_price_courier?: number;
    calc_price_courier?: number;
    substatus?: number;
    test_calc_price_courier: number;
    test_real_price_courier: number;
    warehouse_address_id?: number;
    address_text?: string;
    warehouse_hub_id?: number;
    contact_name?: string;
    contact_phone?: string;
    lat?: number;
    lon?: number;

    warehouse?: Warehouse;
    client?: any;
    courier?: any;

    constructor(data: any) {
        super(data);

        if (data.lat && data.lon) {
            this.lat = ('string' === typeof data.lat) ? Number.parseFloat(data.lat) : data.lat;
            this.lon = ('string' === typeof data.lon) ? Number.parseFloat(data.lon) : data.lon;
        }
    }

    /**
     * Возвращает признак возможности пересчёта стоимости забора по его статусу
     * @param zorder
     */
    public static isZorderRecalcable(zorder: Zorder): boolean {
        return (zorder.status === Zorder.STATUS_DONE || zorder.status === Zorder.STATUS_ON_STOCK);
    }

    /**
     * Возвращает признак возможности пересчёта стоимости текущего забора по его статусу
     */
    public isRecalcable(): boolean {
        return Zorder.isZorderRecalcable(this);
    }
}

export class ShippingQueue extends BaseModel {
    public static readonly statuses = ['Пришёл', 'В режиме сборки', 'Собран'];
    public static readonly COURIER_WAIT = 1;
    public static readonly COURIER_ASSEMBLE = 2;
    public static readonly COURIER_READY = 3;
}
