import {Router} from '@angular/router';
import {LaraService} from '../../service/lara.service';
import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

    constructor(
        private api: LaraService,
        private router: Router
    ) {
    }

    ngOnInit() {
    }

    onExit() {
        this.api.logout();
        this.router.navigate(['login']);
    }
}
