import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GridModule, RowFilterModule} from '@progress/kendo-angular-grid';

import {PvzComponent} from './pvz/pvz.component';
import {RefundComponent} from './refund/refund.component';
import {PickupDeliveryComponent} from './pickup-delivery/pickup-delivery.component';
import {NewsComponent} from './news/news.component';
import {FinancialDocumentsComponent} from './financial-documents/financial-documents.component';
import {ActsComponent} from './acts/acts.component';

@NgModule({
    declarations: [
        ActsComponent,
        FinancialDocumentsComponent,
        PvzComponent,
        NewsComponent,
        PickupDeliveryComponent,
        RefundComponent
    ],
    imports: [
        CommonModule,
        GridModule,
        RowFilterModule
    ],
    exports: [
        ActsComponent,
        FinancialDocumentsComponent,
        PvzComponent,
        NewsComponent,
        PickupDeliveryComponent,
        RefundComponent
    ]
})
export class PagesModule {
}
