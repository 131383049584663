import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-acts',
    templateUrl: './acts.component.html',
    styleUrls: ['./acts.component.scss']
})
export class ActsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
