import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientInfo, Hub, OrderZone, User, UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {Subject} from 'rxjs';
import {HubsService} from '../../service/hubs.service';
import {AppComponent} from '../../app.component';
import {DailyMeetingOrdersComponent} from '../daily-meeting/daily-meeting-orders/daily-meeting-orders.component';
import {IntervalsStatDetailsComponent} from './intervals-stat-details/intervals-stat-details.component';

@Component({
    selector: 'app-interval-stat',
    templateUrl: './intervals-stat.component.html',
    styleUrls: ['./intervals-stat.component.scss']
})
export class IntervalsStatComponent implements OnInit {
    public stat;
    public date = new Date();
    public formList: FormGroup;
    public isLoading = false;
    public rowIndex = 1;
    public selectedHubs: UserHub[];

    public days: Day[] = [
        {value: 'all', name: 'Все дни'},
        {value: 'workdays', name: 'Будни'},
        {value: 'holidays', name: 'Выходные'}
    ];

    public courierTypes: CourierType[] = [
        {value: 1, name: 'Авто'},
        {value: 2, name: 'Пеший'},
        {value: 3, name: 'Грузовой'},
        {value: 4, name: 'Стажер'},
        {value: 5, name: 'Наёмник'},
        {value: 6, name: 'Fastzilla'},
    ];

    public intervals: any[] = [];
    public selectedClients: ClientInfo[] = [];
    public selectedUsers: User[] = [];
    public selectedZones: OrderZone[] = [];

    public selectedIntervals: any[] = [];
    public hubsList: Hub[] = null;
    public selectedHubId: number;
    public appUserSelectEventsSubject: Subject<any> = new Subject<any>();



    ngOnInit(): void {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
            this.refillDeliveryIntervals();
        });

        const hub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [hub, ];
        this.selectedHubId = hub.id;
        this.formList.controls.hub_id.setValue(hub.id);
        this.refillDeliveryIntervals();
    }

    constructor(
        protected app: AppComponent,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        protected dataPipe: DatePipe,
        protected hubsService: HubsService,
    ) {
        this.formList = new FormGroup({
            day: new FormControl('all'),
            courier_type: new FormControl(),
            zones: new FormControl(),
            hub_id: new FormControl(0),
            date_start: new FormControl(this.date, Validators.required),
            date_end:  new FormControl(this.date, Validators.required),
            intervals: new FormControl()
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }


    onChangeHub(hub) {
        this.stat = null;

        this.selectedHubs = [hub, ];
        this.selectedHubId = hub.id;
        this.formList.controls.hub_id.setValue(hub.id);
        this.refillDeliveryIntervals();
        this.appUserSelectEventsSubjectEmit();
    }

    appUserSelectEventsSubjectEmit() {
        this.appUserSelectEventsSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    refillDeliveryIntervals() {
        if (null === this.hubsList) {
            this.selectedIntervals = [];
            this.formList.controls.hub_id.setValue(0);
            return;
        }

        this.intervals = [];
        const selectedHub = this.selectedHubs[0] || null;
        if (!selectedHub) {
            this.selectedIntervals = [];
            this.formList.controls.hub_id.setValue(0);
            return;
        }

        const hub = this.hubsList.find((hub) => {
            return hub.id === selectedHub.id;
        });

        this.intervals = hub.delivery_intervals;
        this.selectedIntervals = this.selectedIntervals.filter((selectedInterval) => {
            return hub.delivery_intervals.find(interval => {
                return interval.id === selectedInterval.id;
            });
        });
    }
    onSelectClient(clients: ClientInfo[]) {
        this.selectedClients = clients;
    }

    onSelectCourier(users: User[]) {
        this.selectedUsers = users;
    }

    onChangeZone(zones: OrderZone[]) {
        this.selectedZones = zones;
    }

    prepareQuery() {
        const query = this.formList.value;
        query.date_start = this.datePipe.transform(this.formList.value.date_start, 'yyyy-MM-dd');
        query.date_end = this.datePipe.transform(this.formList.value.date_end, 'yyyy-MM-dd');
        query.client_ids = this.selectedClients.map((client: ClientInfo) => client.id);
        query.courier_ids = this.selectedUsers.map((user: User) => user.id);
        query.zones = this.selectedZones.map((zone: OrderZone) => zone.zone_id);
        query.intervals = this.selectedIntervals.map((interval) => interval.id);
        query.hub_id = this.selectedHubId;

        return query;
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }

        this.isLoading = true;
        this.statisticsService.getIntervalsStat(this.prepareQuery()).subscribe(data => {
            this.isLoading = false;
            this.stat = data;
        }, error => {
            this.isLoading = false;
        });
    }

    public getFailedRate(key: string) {
        return Math.round(10000 * this.stat.current.failed[key] / this.stat.current.total[key]) / 100;
    }

    public getChangeRate(param: string, key: string) {
        return Math.round(10000 * (this.stat.current[param][key] - this.stat.previous[param][key]) / this.stat.previous[param][key]) / 100;
    }

    public getChangeRateColor(param: string, key: string, invert: boolean = false) {
        if (this.getChangeRate(param, key) > 0) {
            return !invert ? 'text-success' : 'text-danger';
        } else {
            return !invert ? 'text-danger' : 'text-success';
        }
    }

    public getSideText() {
        let text = null;
        switch (this.rowIndex) {
            case 1: {
                text = 'Точки';
                break;
            }
            case 2: {
                text = 'Нарушен интервал';
                break;
            }
            case 3: {
                text = 'Нарушен интервал из-за недозвона';
                break;
            }
            case 4: {
                text = 'Процент нарушения';
                break;
            }
        }
        this.rowIndex++;
        return text;
    }

    public getDetails() {
        this.isLoading = true;
        this.statisticsService.getIntervalStatDetails(this.prepareQuery()).subscribe(data => {
            this.isLoading = false;

            this.app.createDialog(IntervalsStatDetailsComponent, {
                orders: data
            }).onDestroy(() => {

            })
        }, error => {
            this.isLoading = false;
        });
    }
}

interface Day {
    value: string;
    name: string;
}

interface CourierType {
    value: number;
    name: string;
}
