import {Component, OnInit} from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {ClientInfo} from '../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';

import {Title} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';

import {HelpersService} from '../../service/helpers.service';

import {StatisticsService} from '../statistics.service';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
    selector: 'app-bad-adresses',
    templateUrl: './bad-adresses.component.html',
    styleUrls: ['./bad-adresses.component.scss']
})
export class BadAdressesComponent implements OnInit {

    public gridData: GridDataResult;

    private pageTitle = 'Плохо распознанные адреса';
    public pageSize = 50;
    public skip = 0;

    public selectedClient: ClientInfo = null;

    public query: any;


    public isLoading = false;
    public excelIsLoading = false;

    public formList: FormGroup;

    constructor(private title: Title,
                private datePipe: DatePipe,
                public helpers: HelpersService,
                private statisticsService: StatisticsService,
                private fileDownloadService: FileDownloadService
    ) {
        this.title.setTitle(this.pageTitle);

        const today = new Date();
        this.formList = new FormGroup({
            dateStart: new FormControl('', Validators.required),
            dateEnd: new FormControl('', Validators.required),
            qcSelect: new FormControl('-1')
        });

    }

    ngOnInit() {

    }


    public loadAddresses() {

        const query = {
            'pageSize': this.pageSize,
            'skip': this.skip,
            'date_start': this.datePipe.transform(this.formList.value.dateStart, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.dateEnd, 'yyyy-MM-dd'),
            'client_id': (this.selectedClient ? this.selectedClient.id : 0),
            'qc': this.formList.value.qcSelect
        };


        console.log(query);
        this.isLoading = true;
        this.statisticsService.getBadAddressesReport(query).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data.data, total: data.total};

        });

    }

    onSelectClient(client: any) {
        if (client) {
            this.selectedClient = client;
        } else {
            this.selectedClient = null;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadAddresses();
    }

    onExcelClick() {
        const query = {

            'date_start': this.datePipe.transform(this.formList.value.dateStart, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.dateEnd, 'yyyy-MM-dd'),
            'client_id': (this.selectedClient ? this.selectedClient.id : 0),
            'qc': this.formList.value.qcSelect
        };

        let fileName = 'BadAdresses-' + (this.selectedClient ? this.selectedClient.client_uid_old + '-' : '');
        fileName += query.date_start + ' - ' + query.date_end;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/statistics/getBadAddressesReport/excel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }


}
