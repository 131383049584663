import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {ClientInfo, Hub, Order, OrdersRequest, User} from '../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from '../service/helpers.service';
import {OrdersListService} from './orders-list.service';
import {OrderLoadingService} from './order-loading.service';
import {OrdersService} from '../service/orders.service';
import {DialogService} from '../components/dialog/dialog.service';
import {DatePipe} from '@angular/common';
// import {OrdersMapComponent} from '../components/orders-map/orders-map.component';
import {ZordersService} from '../service/zorders.service';
import {Router} from '@angular/router';

import {StatusSelectComponent} from '../components/status-select/status-select.component';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {CurrentUserService} from '../service/current-user.service';
import {Subject} from 'rxjs';
import {HubsService} from '../service/hubs.service';
import {EventerService} from '../service/eventer.service';
import {FileDownloadService} from '../http/file-download.service';

@Component({
    selector: 'app-orders-list',
    templateUrl: './orders-list.component.html',
    styleUrls: ['./orders-list.component.scss'],
    providers: [
        OrdersListService
    ]
})
export class OrdersListComponent implements OnInit {
    @ViewChild('input_status') statusSelectComp: StatusSelectComponent;
    @ViewChild('closeModal') closeModal: ElementRef;

    public appUserSelectEventsSubject: Subject<any> = new Subject<any>();

    public oldV;
    public minimiz;
    public selectedClients: ClientInfo[] = [];

    public selectedUsers: User[] = [];

    public selectedStatuses: number[] = [];

    public formFilter: FormGroup;

    public formEdit: FormGroup;

    public query: OrdersRequest = new OrdersRequest();

    public selectedOrders: string[] = [];
    public selectedOrdersInMap: string[] = [];
    public selectedZordersInMap: string[] = [];

    public printOrders: Order[] = [];

    public statuses = [];
    public substatuses = [];
    public cancelStatus;
    public cancel_substatuses = [];
    public modal;
    public dloading = false;
    public cloading = false;

    public intervals: any[] = [];
    public selectedIntervals: any[] = [];

    protected multiCourier: User;
    public toDay = new Date();
    public loadSetCourier = false;
    public minimized = false;
    public oldVersion = true;
    public pageSizeDefault = 100;
    public pageSize = this.pageSizeDefault;
    public pageLimit_500 = false;

    public selectedHubs: Hub[] = [];
    public selectedCurrentHubs: Hub[] = [];
    public selectedHubId: number;

    public hubsList: Hub[] = null;

    // protected map: OrdersMapComponent;
    img = 'img';
    barCode: any;
    monospace: string;
    black: string;
    center: string;
    bottom: string;
    CODE128: string;
    svg: string;
    dateObj: String;
    status: String;
    deliveryDate: String;
    innerNumber: String;
    uid: String;

    public statistic: {
        total: number,
        total_order: number,
        total_zorder: number,
        total_moscow: number,
        total_mo: number,
        total_region: number,
        total_barcodes: number,
        total_before_14: number,
        total_after_18: number
    } = {
        total: 0,
        total_order: 0,
        total_zorder: 0,
        total_moscow: 0,
        total_mo: 0,
        total_region: 0,
        total_barcodes: 0,
        total_before_14: 0,
        total_after_18: 0
    };

    public showShelfsFilter = true;
    public showDateToFilter = true;
    public excelIsLoading = false;
    public onDeliveryActive = false;

    constructor(
        public helper: HelpersService,
        private eventer: OrderLoadingService,
        private eventerService: EventerService,
        protected api: OrdersService,
        protected apiZorder: ZordersService,
        private fileDownloadService: FileDownloadService,
        protected dialog: DialogService,
        protected dataPipe: DatePipe,
        protected currentUserService: CurrentUserService,
        protected hubsService: HubsService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private router: Router
    ) {
        this.formFilter = new FormGroup({
            hub_id: new FormControl(0),
            delivery: new FormGroup({
                from: new FormControl(this.dataPipe.transform(this.toDay, 'yyyy-MM-dd')),
                to: new FormControl('')
            }),
            zones: new FormGroup({
                from: new FormControl(''),
                to: new FormControl('')
            }),
            shelfs: new FormGroup({
                from: new FormControl(''),
                to: new FormControl('')
            }),
            weight: new FormGroup({
                from: new FormControl(''),
                to: new FormControl('')
            }),
            hash_bar_codes_count: new FormGroup({
                from: new FormControl(''),
                to: new FormControl('')
            }),
            courier_null: new FormControl(''),
            late: new FormControl(''),
            courier_dummy: new FormControl(''),
            in_hand: new FormControl(''),
            is_important: new FormControl(''),
            is_very_important: new FormControl(''),

            is_doc_return: new FormControl(''),
            not_document: new FormControl(''),
            not_unresolved_problems: new FormControl(''),
            shifts: new FormControl(null)
        });

        this.formEdit = new FormGroup({
            delivery_date: new FormControl(''),
            substatus_datechange: new FormControl('')
        });

        for (const status of [1, 2, 3, 4, 5, 6, 7, 8, 9]) {
            this.statuses.push({
                status: status,
                title: this.helper.getNameOrderStatus(status)
            });
        }


        this.query.delivery = {
            from: this.dataPipe.transform(this.toDay, 'yyyy-MM-dd')
        };

        this.eventerService.primaryHubChange.subscribe((hub) => {
            console.info('eventerService.primaryHubChange', hub);
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
            this.refillDeliveryIntervals();
        });

        const hub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;
        this.formFilter.controls.hub_id.setValue(hub.id);
        this.refillDeliveryIntervals();

        this.api.getChangeDateSubstatuses().subscribe((data: any) => {
            this.substatuses = data;
        });

        this.showShelfsFilter = true;
        this.showDateToFilter = true;

        if (this.router.url === '/admin/orders/list/ondelivery') {
            this.onDeliveryActive = true;
            this.selectedStatuses = [4];
            this.showShelfsFilter = false;
            this.showDateToFilter = false;
            this.statusSelectComp.selectedStatuses = this.selectedStatuses;
        } else if (this.router.url === '/admin/orders/list/onware') {
            this.selectedStatuses = [3];
        } else if (this.router.url === '/admin/orders/list') {
            this.selectedStatuses = [2, 3];
        }
        this.statusSelectComp.selectedStatuses = this.selectedStatuses;
        this.selectedOrders = [];
        this.getOrderCancelSubstatuses();
        if (innerWidth <= 1280) {
            this.minimized = true;
        }
        this.getCache();
    }

    onSelectClient(clients: ClientInfo[]) {
        this.selectedClients = clients;
    }

    onSelectCourier(users: User[]) {
        this.selectedUsers = users;
    }

    getSearchQuery() {
        const query: OrdersRequest = this.formFilter.value;
        query.statuses = this.selectedStatuses;
        query.client_uids = this.selectedClients.map((client: ClientInfo) => client.client_uid);
        query.courier_ids = this.selectedUsers.map((user: User) => user.id);
        query.intervals = this.selectedIntervals;
        query.delivery.from = this.dataPipe.transform(query.delivery.from, 'yyyy-MM-dd');
        query.delivery.to = this.dataPipe.transform(query.delivery.to, 'yyyy-MM-dd');
        query.hub_id = this.selectedHubId;
        query.current_hubs = this.selectedCurrentHubs.map((hub: Hub) => hub.id + '');
        if (this.onDeliveryActive) {
            query.unresolvedProblems = true;
        }
        this.query = query;
        console.log(query);
        return this.query
    }

    getStatistic() {
        this.api.getStatistic(this.getSearchQuery()).subscribe(statistic => this.statistic = statistic);
    }

    // setMapInstance(map: any) {
    //     this.map = map;
    // }

    onUseSetStatusCancel() {
        this.cloading = true;
        this.api.setCancelSubstatus(this.selectedOrders, this.cancelStatus).subscribe(data => {
            this.onSearchOrders();
            if (data.message) {
                this.dialog.alert(data.message, 500);
            }
            this.closeModal.nativeElement.click();
            this.cloading = false;
        });
        /*const dialog = this.dialog.confirm('Отменить ' +
            this.selectedOrders.length + ' заказов?').subscribe(result => {
            if (result) {
                this.api.setCancelSubstatus(this.selectedOrders, this.cancelStatus).subscribe(data => {
                    this.onSearchOrders();
                    if (data.message) {
                        this.dialog.alert(data.message, 500);
                    }
                });
            }
            dialog.unsubscribe();
        })*/
    }

    setCancelStatus(event) {
        this.cancelStatus = event.target.value;
    }

    getOrderCancelSubstatuses() {
        this.api.getOrdersCancelSubstatuses().subscribe((data: any) => {
            this.cancel_substatuses = data;
        });
    }

    onUseSetStatusDelivery() {
        this.dloading = true;
        this.api.setDeliveryStatusOrders(this.selectedOrders).subscribe(data => {
            this.onSearchOrders();
            this.dloading = false;
            this.closeModal.nativeElement.click();
            if (data.message) {
                this.dialog.alert(data.message, 500);
            }
        });

    }

    onUseSetCancelDelivery() {

        const dialog = this.dialog.confirm(
            'Вернуть заказы с доставки?'
        ).subscribe(result => {
            if (result) {
                this.api.cancelDeliveryForOrders(this.selectedOrders).subscribe(data => {
                    this.onSearchOrders();
                    this.dloading = false;
                    this.closeModal.nativeElement.click();
                    if (data.message) {
                        this.dialog.alert(data.message, 500);
                    }
                });
            }
            dialog.unsubscribe();
        })
    }


    onUseSetStatusFinished(status) {

        const dialog = this.dialog.confirm(
            'Закрыть заказы как доставленные?'
        ).subscribe(result => {

            this.api.setFinishedStatusOrders(this.selectedOrders, status).subscribe(data => {
                this.onSearchOrders();
                this.dloading = false;
                this.closeModal.nativeElement.click();
                if (data.message) {
                    this.dialog.alert(data.message, 500);
                }
            });
            dialog.unsubscribe();
        })
    }

    onSearchOrders() {
        this.selectedOrders = [];
        this.selectedOrdersInMap = [];
        if (this.minimized !== this.minimiz ||
            this.oldVersion !== this.oldV
        ) {
            this.minimized = this.minimiz;
            this.oldVersion = this.oldV;
            this.setCache();
        }
        if (this.pageLimit_500) {
            this.pageSize = 500;
        } else {
            this.pageSize = this.pageSizeDefault;
        }
        this.eventer.loading.emit(this.getSearchQuery());
        this.getStatistic();
    }

    onSelectedInMap(data) {
        this.selectedOrdersInMap = data[0];
        this.selectedZordersInMap = data[1];
    }

    onSetSelectCourier(user: User) {
        this.multiCourier = user;
    }

    onUseSetCourier() {
        if (!this.multiCourier) {
            this.dialog.alert('Нужно выбрать курьера');
            return;
        }

        const uids = this.selectedOrdersInMap.length ? this.selectedOrdersInMap : this.selectedOrders;

        if (uids.length) {
            this.loadSetCourier = true;
            this.api.setCourierOrders(uids, this.multiCourier.id).subscribe(data => {
                if (data.errors) {
                    this.dialog.alert(data.errors, 500);
                }
                this.onSearchOrders();
                this.loadSetCourier = false;
            });
        }

    }

    onUseReDelivery() {
        const delivery_date = this.dataPipe.transform(this.formEdit.get('delivery_date').value, 'yyyy-MM-dd');
        const substatus_datechange = this.formEdit.get('substatus_datechange').value;
        console.log(substatus_datechange);

        if (!delivery_date || !substatus_datechange) {
            this.dialog.alert('Нужно выбрать дату и причину');
            return;
        }
        let msg = '';
        if (delivery_date) {
            msg += 'Новая дата доставки: ' + delivery_date + '\r\n';
        }
        if (substatus_datechange) {
            let substatus;
            for (const reason of this.substatuses) {
                if (reason.substatus_id == substatus_datechange) {
                    substatus = reason.name;
                }
            }
            msg += 'Причина: ' + substatus + '\r\n';
        }
        const dialog = this.dialog.confirm(msg + ' для ' + this.selectedOrders.length + ' заказов').subscribe(result => {
            if (result) {
                this.api.setDeliveryDate(this.selectedOrders, delivery_date, substatus_datechange).subscribe(data => {
                    this.onSearchOrders();
                });
            }
            dialog.unsubscribe();
        });
    }

    onSelectInterval(event) {
        this.selectedIntervals.push(event.interval);
    }

    onDeSelectInterval(event) {
        this.selectedIntervals = this.selectedIntervals.filter(st => st !== event.interval);
    }

    onSelectAllInterval() {
        this.selectedIntervals = this.selectedIntervals.map(st => st.interval);
    }

    onDeSelectAllInterval() {
        this.selectedIntervals = [];
    }


    onPrintLabel() {
        this.api.getUids(this.selectedOrders).subscribe((orders: Order[]) => this.printOrders = orders);
    }

    onSelectStatus(event) {
        this.selectedStatuses = event;
    }

    formatNum(value) {
        return parseFloat(value).toFixed(2);
    }

    viewSettings(e, target) {
        switch (target) {
            case 'version' : {
                this.oldV = e.target.checked;
                break;
            }
            case 'minimized' : {
                this.minimiz = !e.target.checked;
                break;
            }
            case 'pageLimit_500' : {
                this.pageLimit_500 = !this.pageLimit_500;
                this.setCache();
                break;
            }
        }
    }

    setCache() {
        this.storage.set('raspr', {
            minimized: this.minimized,
            oldVersion: this.oldVersion,
            pageLimit_500: this.pageLimit_500
        });
    }

    getCache() {
        const setts = this.storage.get('raspr');
        if (setts) {
            this.minimized = setts.minimized;
            this.oldVersion = setts.oldVersion;
            this.minimiz = setts.minimized;
            this.oldV = setts.oldVersion;
            this.pageLimit_500 = setts.pageLimit_500;
        } else {
            this.setCache();
        }
    }

    onChangeHub(hub) {
        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;

        this.formFilter.controls.hub_id.setValue(hub.id);

        this.refillDeliveryIntervals();

        this.appUserSelectEventsSubjectEmit();
    }

    onChangeCurrentHub(hubs) {
        this.selectedCurrentHubs = hubs;
    }


    appUserSelectEventsSubjectEmit() {
        this.appUserSelectEventsSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    refillDeliveryIntervals() {
        if (null === this.hubsList) {
            this.selectedIntervals = [];
            this.formFilter.controls.hub_id.setValue(0);
            return;
        }

        this.intervals = [];
        const selectedHub = this.selectedHubs[0] || null;
        if (!selectedHub) {
            this.selectedIntervals = [];
            this.formFilter.controls.hub_id.setValue(0);
            return;
        }

        const hub = this.hubsList.find((hub) => {
            return hub.id === selectedHub.id;
        });

        for (const deliveryInterval of hub.delivery_intervals) {
            this.intervals.push({
                interval: deliveryInterval.id,
                title: deliveryInterval.label,
            });
        }

        this.selectedIntervals = this.selectedIntervals.filter((selectedInterval) => {
            return hub.delivery_intervals.find(interval => {
                return interval.id === selectedInterval.interval;
            });
        });
    }

    exportExcel() {
        const query = this.getSearchQuery();
        query.limit = '200000';
        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/order/listOrdersForCheck?downloadAsExcel', 'Список заказов.xls', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    getRowClass(context) {
        if ('undefined' === typeof context) {
            return;
        }
        const isImportant = context.dataItem.is_important === 1;
        const isVeryImportant = context.dataItem.is_important > 1;
        return {
            'is_important': isImportant,
            'is_very_important': isVeryImportant,
        };
    }

    onChangeShift(shifts: number[]) {
        this.formFilter.controls.shifts.setValue(shifts);
        let shift = null;
        if (shifts.length === 1) {
            shift = shifts[0];
        }
        this.appUserSelectEventsSubject.next({type: 'filterShiftNumberUpdated', shiftNumber: shift});
    }
}
