import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FinanceService} from '../../service/finance.service';
import {DatePipe} from '@angular/common';
import {JobProgressService} from '../../service/job-progress.service';

@Component({
    selector: 'app-recalculate-orders',
    templateUrl: './recalculate-orders.component.html',
    styleUrls: ['./recalculate-orders.component.scss']
})
export class RecalculateOrdersComponent implements OnInit {

    public form: FormGroup;

    @ViewChild('jobs_progress_component') jobsProgressComponent;
    public jobsList = [JobProgressService.ORDER_RECALCULATE, JobProgressService.ZORDER_RECALCULATE];

    constructor(
        protected api: FinanceService,
        protected dataPipe: DatePipe
    ) {
        this.form = new FormGroup({
            date_from: new FormControl(0, Validators.required),
            date_to: new FormControl(0, Validators.required),
            client_id: new FormControl(0, Validators.min(1))
        });
    }

    ngOnInit() {
    }


    /**
     * При выборе клиента
     * @param event
     */
    onSelectClient(event) {
        this.form.get('client_id').setValue('undefined' !== event.id ? event.id : null);
    }

    onOrdersRecalculateClick() {
        this.api.recalcOrders(this.getFormValue())
            .subscribe(data => {
                this.reloadJobsList();
            })
    }

    onZordersRecalculateClick() {
        this.api.recalcZorders(this.getFormValue())
            .subscribe(data => {
                this.reloadJobsList();
            })
    }

    private getFormValue() {
        const query = this.form.value;
        query.date_from = this.dataPipe.transform(query.date_from, 'yyyy-MM-dd');
        query.date_to = this.dataPipe.transform(query.date_to, 'yyyy-MM-dd');

        return query;
    }

    private reloadJobsList() {
        this.jobsProgressComponent.reloadList();
    }
}
