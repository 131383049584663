import {Component, OnInit} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {DialogService} from '../../components/dialog/dialog.service';

@Component({
    selector: 'app-check',
    templateUrl: './check.component.html',
    styleUrls: ['./check.component.scss']
})
export class CheckComponent implements OnInit {

    constructor(protected api: LaraService,
                public dialog: DialogService) {
    }

    ngOnInit() {
    }

    check() {
        const q = this.dialog.confirm('Внимание, распределение за сегодня будет перезаписано, продолжить?').subscribe(result => {
            if (result) {
                this.api.checkLogist().subscribe(data => {
                    console.log(data);
                    this.dialog.alert('Готово').unsubscribe();
                })
            } else {
                q.unsubscribe();
            }
        })
    }
}
