import {Component, Inject} from '@angular/core';
import {ClientInfo, Hub, User} from '../../service/models';
import {FormControl, FormGroup} from '@angular/forms';
import {HelpersService} from '../../service/helpers.service';
import {OrderLoadingService} from '../../orders-list/order-loading.service';
import {EventerService} from '../../service/eventer.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {DatePipe, formatCurrency} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {HubsService} from '../../service/hubs.service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';
import {Router} from '@angular/router';
import {FileDownloadService} from '../../http/file-download.service';
import {FinanceService} from '../../service/finance.service';
import {AppComponent} from '../../app.component';
import {EKassirEncashmentModalComponent} from '../e-kassir-encashment-modal/e-kassir-encashment-modal.component';

@Component({
    selector: 'app-e-kassir',
    templateUrl: './e-kassir.component.html',
    styleUrls: ['./e-kassir.component.scss']
})

export class EKassirComponent {
    public formFilter: FormGroup;
    public query;

    public orders;

    public selectedUser?: User;
    public encashmentInfo;

    public dataIsLoading: boolean = false;
    public excelIsLoading: boolean = false;
    public currentPage: number = 1;
    public pageSize: number = 20;
    public pageOffset: number = 0;

    public totalDeposited: number = 0;
    public pageDeposited: number = 0;

    constructor(
        public helper: HelpersService,
        private eventer: OrderLoadingService,
        private eventerService: EventerService,
        protected api: FinanceService,
        protected dialog: DialogService,
        protected dataPipe: DatePipe,
        protected currentUserService: CurrentUserService,
        protected hubsService: HubsService,
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        private router: Router,
        protected app: AppComponent,
        private fileDownloadService: FileDownloadService
    ) {
        const today = new Date();
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        this.formFilter = new FormGroup({
            date_type: new FormControl('created_at'),
            date_from: new FormControl(yesterday),
            date_to: new FormControl(today)
        });

        this.loadEncashmentInfo();
    }

    loadEncashmentInfo(till_time: Date = null) {
        this.api.getEncashmentInfo({till_time: till_time})
            .subscribe((data) => {
                this.encashmentInfo = data;
            }, error => {

            })
    }

    onSelectCourier(user?: User) {
        this.selectedUser = user;
    }

    loadDeposits() {
        this.dataIsLoading = true;

        this.api.getEkassirDeposits(this.getSearchQuery())
            .subscribe((data) => {
                this.orders = data;
                this.currentPage = data.currentPage;
                this.totalDeposited = data.totalDeposited;

                this.pageDeposited = 0;
                for (let i = 0; i < data.data.length; i++) {
                    this.pageDeposited += parseFloat(data.data[i].deposited);
                }

                this.dataIsLoading = false;
            }, error => {
                this.dataIsLoading = false;
            })
    }

    /**
     * Форматируем деньги
     * @param money
     */
    formatMoney(money) {
        if (isNaN(money)) {
            return money;
        }

        if (+money - money === 0) {
            return formatCurrency(money, 'ru-RU', '', 'RUB', '1.0-0');
        }

        return formatCurrency(money, 'ru-RU', '', 'RUB');
    }

    onExcelClick() {
        const reportParams = this.getSearchQuery();
        let fileName = 'EkassirStat_';

        fileName += reportParams.date_from + ' - ' + reportParams.date_to;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/e-kassir/stat/excel', fileName + '.xlsx', 'post', reportParams)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    formatNum(value) {
        return parseFloat(value).toFixed(2);
    }

    onDataLoadClick() {
        // сбрасываем пагинацию
        this.pageOffset = 0;
        this.loadDeposits();
    }

    onPageChange(event) {
        this.pageOffset = event.skip;
        this.loadDeposits();
    }

    onEncashmentNowClick() {
        this.app.createDialog(EKassirEncashmentModalComponent).onDestroy(data => {
            this.loadEncashmentInfo();
            this.loadDeposits();
        })
    }

    getSearchQuery() {
        const query = this.formFilter.value;

        query.skip = this.pageOffset;
        query.take = this.pageSize;

        query.date_from = this.dataPipe.transform(query.date_from, 'yyyy-MM-dd');
        query.date_to = this.dataPipe.transform(query.date_to, 'yyyy-MM-dd');

        query.courier_id = this.selectedUser ? this.selectedUser.id : 0;

        this.query = query;
        return this.query
    }
}
