import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {environment} from '../../../environments/environment';
import {ClientService} from '../../service/client.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {FormBuilder, FormControl, ValidationErrors, Validators, FormGroup} from '@angular/forms';
import {AcceptValidator, MaxSizeValidator} from '@angular-material-components/file-input';
import {NotifierService} from '../../service/notifier.service';
import {HttpNoInterceptorService} from '../../service/http-no-interceptor.service';
import {ApiResult} from '../../service/api_result';
import {HttpErrorResponse} from '@angular/common/http';
import {map} from 'rxjs/operators';
@Component({
    selector: 'app-clients-sends',
    templateUrl: './clients-sends.component.html',
    styleUrls: ['./clients-sends.component.scss']
})
export class ClientsSendsComponent implements OnInit {
    public tiny_mce_key = environment.tiny_mce_key;
    public defaultMessage = 'Данное письмо создано автоматически ' +
                            'Не направляйте сообщений в ответ. ' +
                            'В случае возникновения вопросов, ' +
                            'Вы можете написать на почту Info@logsis.ru';
    public loadingEmails = false;

    /* сервисные почты */
    public emailService = [];
    public emailServiceList: string[] = []

    /* почты клиентов */
    public emailClients = [];
    public emailClientsList: string[] = [];

    /* список компаний */
    public companyList = [];

    /* выбранные почты */
    public selectedEmails: string[] = [];

    /* список всех почт */
    public allEmailList: string[] = []
    public isSelectAll = false;
    public isSelectAllClients = false;
    public isSelectAllService = false;
    public filterCompany = 'Все';

    public accept = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' +
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' +
    'application/msword' +
    'application/xml' +
    'application/pdf' +
    'application/ogg' +
    'image/jpeg' +
    'image/gif' +
    'image/pjpeg' +
    'image/png' +
    'image/svg+xml' +
    'image/webp' +
    'image/vnd.wap.wbmp';

    /* 30 МБ в байтах */
    public maxSize = (30) * (1024 * 1024);
    public fileForm: FormGroup;
    /* Url загруженного вложения на сервере */
    public urlLoadedFile = null;
    /* Будут ли вложения в письме */
    public isAttachmentExist = false;
    public loading = false;

    ngOnInit(): void {
    }

    constructor(
        private formBuilder: FormBuilder,
        private api: ClientService,
        private http: HttpNoInterceptorService,
        private dialog: DialogService,
        private notifier: NotifierService,
    ) {
        this.loadCompany();
        this.loadEmails();
        this.fileForm = new FormGroup({
            file: new FormControl(null, [
                Validators.required,
                MaxSizeValidator(this.maxSize),
                AcceptValidator(this.accept)
            ]),
            mailMessage: new FormControl(this.defaultMessage, Validators.required),
            title: new FormControl(null, Validators.required),
        })
    }


    /**
     * Загрузка email адресов
     * @private
     */
    private loadEmails(company = null) {
        this.loadingEmails = true;
        let params = null;
        if (company) {
            params = {
                company: company,
            }
        }
        this.isSelectAll = false;
        this.isSelectAllClients = false;
        this.isSelectAllService = false;
        this.selectedEmails = [];

        this.api.getEmails(params).subscribe(data => {
            if (data.error) {
                return this.dialog.alert(data.error, 500);
            }

            this.emailClientsList = data.clients.map(user => (
                user.email
            ));

            this.emailServiceList = data.service.map(user => (
                user.email
            ));

            this.allEmailList = [...this.emailServiceList, ...this.emailClientsList];

            /* Разбивание массива клиентских почт на n массивов по 6 элементов */
            const array = data.clients, result = [], length = 6;
            while (array.length) {
                result.push(array.splice(0, length));
            }
            this.emailClients = result;

            this.emailService = data.service;
            this.loadingEmails = false;
        });
    }

    /**
     * Вызывается при измененнии чекбокса выбора всех почт
     */
    onSelectAllChange() {
        this.checkEnableSelectAll(true);
    }

    /**
     * Вызывается при измененнии чекбокса выбора всех клиентских почт
     */
    onSelectAllClientsChange() {
        this.checkEnableSelectAll();
        this.changeSelectItem(this.emailClientsList, !this.isSelectAllClients);
    }

    /**
     * Вызывается при измененнии чекбокса выбора всех служебных почт
     */
    onSelectAllServiceChange() {
        this.checkEnableSelectAll();
        this.changeSelectItem(this.emailServiceList, !this.isSelectAllService);
    }

    /**
     * Проверяет и меняет активности выбора всех адресов
     */
    checkEnableSelectAll(isSelectAll = false) {
        if (isSelectAll) {
            this.isSelectAllClients = this.isSelectAll;
            this.isSelectAllService = this.isSelectAll;
            this.onSelectAllServiceChange();
            this.onSelectAllClientsChange();
            return;
        }
        if (this.isSelectAllService && this.isSelectAllClients) {
            this.isSelectAll = true;
            return
        }
        this.isSelectAll = false;
    }

    /**
     * Загрузка списка компаний
     * @private
     */
    private loadCompany() {
        this.api.getParentCompanies().subscribe(data => {
            if (data.error) {
                return this.dialog.alert(data.error, 500);
            }
            this.companyList = data;
        });
    }

    /**
     * Смена компании
     * @param event
     */
    changeCompany(event) {
        let company = null;
        if (event !== 'Все') {
            company = event;
        }
        this.loadEmails(company);
    }

    /**
     * Удаление / Добавление элементов
     * @param event
     * @param remove
     */
    changeSelectItem(event, remove = true) {
        let newValues = [];
        event.option ? newValues = [event.option.value] : newValues = event;

        let temp = this.selectedEmails;
        newValues.forEach((newVal) => {
            const objIndex = this.selectedEmails.findIndex(value => value === newVal)
            if (objIndex > -1) {
                if (remove) {
                    temp.splice(objIndex, 1);
                }
            } else {
                temp = [...temp, ...[newVal]];
            }
        });

        /* добавляем новые значения по 50 элементов, для плавной работы ng Model */
        const length = 50;
        this.selectedEmails = [];
        while (temp.length) {
            this.selectedEmails = [...this.selectedEmails, ...temp.splice(0, length)];
        }
        return event.option ? this.checkEnableSelectSection(event) : null;
    }

    /**
     * Проверка активности галочки выбора всех элементов раздела
     * @param event
     * @private
     */
    private checkEnableSelectSection(event: any) {
        this.isSelectAllService = this.contains(this.selectedEmails, this.emailServiceList);
        this.isSelectAllClients = this.contains(this.selectedEmails, this.emailClientsList);
        this.checkEnableSelectAll();
    }

    /**
     * Проверяет содержится ли 1 массив в другом
     * @param where
     * @param what
     * @private
     */
    private contains(where, what) {
        for (let i = 0; i < what.length; i++) {
            if (where.indexOf(what[i]) === -1) {
                return false;
            }
        }
        return true;
    }

    /**
     * Отправка писем
     */
    sendEmails() {
        const params = {
            emails: this.selectedEmails,
            message: this.fileForm.controls.mailMessage.value,
            title: this.fileForm.controls.title.value,
            urlFile: this.urlLoadedFile
        };
        this.api.sendEmails(params).subscribe(data => {
            this.loading = false;
            this.notifier.openNotifier('Письма отправлены!', null, {duration: 3000});
            this.isAttachmentExist = false;
            this.isSelectAll = false;
            this.isSelectAllClients = false;
            this.isSelectAllService = false;
            this.urlLoadedFile = null;
            this.selectedEmails = [];
            this.fileForm.controls.file.setValue(null)
            this.fileForm.controls.title.setValue(null);
            this.fileForm.controls.mailMessage.setValue(this.defaultMessage);
        }, error => {
            this.loading = false;
        });
    }

    /**
     * Очистка инпута файлов
     */
    clearFileInput() {
        this.fileForm.controls.file.setValue(null)
        this.notifier.openNotifier('Вложение удалено', null, {duration: 3000});
        this.isAttachmentExist = false;
    }

    /**
     * Событие при выборе файла
     * @constructor
     */
    OnfileChange() {
        this.isAttachmentExist = !!this.fileForm.controls.file.value;
    }

    async prepareSendMessage() {
        this.loading = true;
        if (this.isAttachmentExist) {
            this.onFileUpload();
        } else {
            this.sendEmails();
        }
    }

    /**
     * Отправка файлов на сервер
     */
     onFileUpload() {
        const formData = new FormData();
        formData.append('file', this.fileForm.controls.file.value);
        this.http.post('/client-service/clients-sends/load-files', formData)
            .pipe(map((data) => {
                return (<ApiResult>data.body).response;
            }))
            .subscribe((data) => {
                this.urlLoadedFile = data;
                this.sendEmails();
            }, (error: HttpErrorResponse) => {
                this.loading = false;
                if (!error.error.response) {
                    this.dialog.alert('Произошла ошибка загрузки. Пожалуйста, обновите страницу и попробуйте снова');
                } else {
                    this.dialog.alert(error.error.response.error);
                }
            });
    }
}
