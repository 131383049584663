import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HelpersService} from '../../service/helpers.service';
import {LaraService} from '../../service/lara.service';

@Component({
    selector: 'app-couriers-assembly',
    templateUrl: './couriers-assembly.component.html',
    styleUrls: ['./couriers-assembly.component.scss']
})
export class CouriersAssemblyComponent implements OnInit {

    public date;
    public title = 'Выберите дату выгрузки';
    public summ = [];
    public cnt = [];
    public time = [];
    public stat = [];
    public loading = false;
    @ViewChild('input_date') input_date: ElementRef;
    constructor(
        protected helpers: HelpersService,
        private api: LaraService
    ) { }

    ngOnInit() {
    }

    onDateChange() {
        this.date = this.helpers.formatDateForSQL(this.input_date.nativeElement.value)
    }
    resetAll() {
        this.summ = [];
        this.cnt = [];
        this.time = [];
        this.stat = [];
    }
    submit() {
        this.loading = true;
        if (this.date) {
            this.api.getCouriersShipingReport({date: this.date}).subscribe(data => {
                this.resetAll();
                if (data.stat.length !== 0) {
                    this.statEdit(data.stat);
                    this.title = 'Статистика за ' + this.input_date.nativeElement.value;
                } else {
                    this.title = 'Результаты за ' + this.input_date.nativeElement.value + ' отсутствуют';
                    this.loading = false;
                }
            })
        } else {this.title = 'Выберите дату выгрузки'; this.loading = false;}
    }
    statEdit(data) {
        this.setTime(data);
        this.stat = data;
        this.time = data.map(x => x.interv);
        this.summ = data.map(x => x.sum);
        this.cnt = data.map(x => x.cnt);
        this.loading = false;
    }
    setTime(data) {
        for (const time of data) {
            const fullmins = time.interv * 5;
            const hours = Math.floor(fullmins / 60);
            const minutes = (fullmins % 60).toString();
            time.interv = hours + ':' + (minutes.length === 1 ? minutes + '0' : minutes);
        }
    }
}
