import { Component, OnInit } from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {Hub, User} from '../../service/models';
import {HelpersService} from '../../service/helpers.service';
import {DataStateChangeEvent, GridDataResult} from '@progress/kendo-angular-grid';
import {State} from '@progress/kendo-data-query';
import {CurrentUserService} from '../../service/current-user.service';
import {HubsService} from "../../service/hubs.service";

@Component({
  selector: 'app-couriers',
  templateUrl: './couriers.component.html',
  styleUrls: ['./couriers.component.scss']
})
export class CouriersComponent implements OnInit {

    public couriersData: GridDataResult;
    public hubsList: Hub[];
    public selectedHubs: Hub[] = [];
    public pageSize = 10;
    public skip = 0;
    public enableFilter = false;
    public searchFilter = {
        hubs: null,
        courier: '',
        type: '',
        phone: '',
        active: true
    }
    public currentUser: User;
    public state: State = {
        take: 10,

        filter: {
            logic: 'and',
            filters: []
        }
    };
    constructor(
        protected api: LaraService,
        public helper: HelpersService,
        protected hubsService: HubsService,
        protected currentUserService: CurrentUserService
    ) {
        this.currentUserService.get().subscribe((user: User) => {
            this.currentUser = user;
            console.log(this.currentUser);
        });

        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
        });
    }

    ngOnInit() {
        this.getCouriersList();
    }

    getCouriersList() {
        this.api.getCouriersList(this.skip, this.pageSize).subscribe(data => {
            data.couriers.data = data.couriers.data.map(item => {
                item.user.default_hub = this.hubsList.find((hub: Hub) => hub.id === item.user.default_hub_id);
                return item;
            });
            this.couriersData = {data: data.couriers.data, total: data.couriers.total};
        })
    }
    public pageChange(e: DataStateChangeEvent): void {
        this.skip = e.skip;
        this.pageSize = e.take;
        if (this.enableFilter) {
            this.filteredList();
        } else {
            this.getCouriersList();
        }
    }

    onSelectUser(user: User) {
        console.log(user);
    }

    setFilter (e, type) {
        if (type === 'type') {
            this.searchFilter.type = e.target.value;
        } else if (type === 'courier') {
            this.searchFilter.courier = e.target.value;
        } else if (type === 'phone') {
            this.searchFilter.phone = e.target.value;
        } else if (type === 'active') {
            this.searchFilter.active = e.target.checked;
        } else if (type === 'hub') {
            this.selectedHubs = e;
            this.searchFilter.hubs = this.selectedHubs.map((hub: Hub) => {
                return hub.id;
            });
        }
        if (Object.keys(this.searchFilter).length > 0) {
            this.enableFilter = true;
        } else {
            this.enableFilter = false;
        }
    }

    applyFilter() {
        this.skip = 0;
        this.filteredList();
    }

    filteredList() {
        this.api.setCouriersListFilter( this.searchFilter.courier,
                                        this.searchFilter.type, this.searchFilter.phone,
                                        this.searchFilter.active,
                                        this.searchFilter.hubs,
                                        this.skip, this.pageSize)
                                            .subscribe(data => {
                                                data.couriers.data = data.couriers.data.map(item => {
                                                    item.user.default_hub = this.hubsList.find((hub: Hub) => hub.id === item.user.default_hub_id);
                                                    return item;
                                                });
            this.couriersData = {data: data.couriers.data, total: data.couriers.total};
        })
    }

    getCourierType(type) {
        return this.helper.getCourierType(type);
    }
    getPrinterNumber(pr_number) {
        if (pr_number == 0 || pr_number == '') {
            return 'Нет';
        } else {
            return 'Logsis_' + pr_number;
        }
    }
}
