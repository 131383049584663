import {BrowserModule} from '@angular/platform-browser';
import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {NgxBarcodeModule} from 'ngx-barcode';
import {ReactiveFormsModule} from '@angular/forms';
import {RouterModule, Routes} from '@angular/router';

import {AdminLayoutComponent} from './admin/admin-layout/admin-layout.component';
import {EmptyComponent} from './layouts/empty/empty.component';
import {PageNotFoundComponent} from './components/page-not-found/page-not-found.component';
import {PrintlabelComponent} from './storage/printlabel/printlabel.component';
import {SberbankComponent} from './sberbank/sberbank.component';
import {ClaimLetterComponent} from './storage/printer/claim-letter/claim-letter.component';
import {PrintReturnActComponent} from './storage/printer/print-return-act/print-return-act.component';
import {PrintCourierMactComponent} from './storage/printer/print-courier-mact/print-courier-mact.component';
import {ZoneRedactorComponent} from './logistics/zone-redactor/zone-redactor.component';
import {PrintMactcrComponent} from './storage/printer/print-mactcr/print-mactcr.component';
import {TePrintComponent} from './storage/printer/te-print/te-print.component';
import {TransitPrintComponent} from './storage/printer/transit-print/transit-print.component';
import {LabelPrintListFirstComponent} from './storage/printer/label-print-list-first/label-print-list-first.component';
import {LogistMapComponent} from './components/logist-map/logist-map.component';
import {MainMapComponent} from './components/main-map/main-map.component';
import {RightsFailureComponent} from './components/rights-failure/rights-failure.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'printlabel',
        component: PrintlabelComponent
    },
    {
        path: '',
        component: EmptyComponent,
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'clients',
        component: AdminLayoutComponent,
        loadChildren: './personalcabinet/clients.module#ClientsModule'
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        loadChildren: './admin/admin.module#AdminModule'
    },
    {
        path: 'printlabel',
        component: PrintlabelComponent
    },
    {
        path: 'print-return-acts',
        component: PrintReturnActComponent
    },
    {
        path: 'print-courier-mact',
        component: PrintCourierMactComponent
    },
    {
        path: 'te-print',
        component: TePrintComponent
    },
    {
        path: 'print-mactcr',
        component: PrintMactcrComponent
    },
    {
        path: 'claim-letter',
        component: ClaimLetterComponent
    },
    {
        path: 'sberbank',
        component: SberbankComponent
    },
    {
        path: 'zone-redactor',
        component: ZoneRedactorComponent
    },
    {
        path: 'logist-map',
        component: LogistMapComponent
    },
    {
        path: 'main-map',
        component: MainMapComponent
    },
    {
        path: 'transit-print',
        component: TransitPrintComponent
    },
    {
        path: '**',
        component: PageNotFoundComponent,
    },
    {
        path: '',
        redirectTo: 'login',
        pathMatch: 'full',
    },
    {
        path: 'printlabel',
        component: PrintlabelComponent
    },
    {
        path: 'label-print-list-first',
        component: LabelPrintListFirstComponent
    },
    {
        path: '',
        component: EmptyComponent,
        loadChildren: './login/login.module#LoginModule'
    },
    {
        path: 'clients',
        component: AdminLayoutComponent,
        loadChildren: './personalcabinet/clients.module#ClientsModule'
    },
    {
        path: 'admin',
        component: AdminLayoutComponent,
        loadChildren: './admin/admin.module#AdminModule'
    },
    {
        path: 'printlabel',
        component: PrintlabelComponent
    },
    {
        path: 'print-return-acts',
        component: PrintReturnActComponent
    },
    {
        path: 'print-courier-mact',
        component: PrintCourierMactComponent
    },
    {
        path: 'print-mactcr',
        component: PrintMactcrComponent
    },
    {
        path: 'claim-letter',
        component: ClaimLetterComponent
    },
    {
        path: 'sberbank',
        component: SberbankComponent
    },
    {
        path: 'zone-redactor',
        component: ZoneRedactorComponent
    }
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        BrowserModule,
        NgxBarcodeModule,
        RouterModule.forRoot(routes)
    ],
    exports: [
        ReactiveFormsModule
    ],
})

export class AppRoutingModule {
}
