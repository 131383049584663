import {CalculatorsTabComponent} from './../calculators/calculators-tab/calculators-tab.component';
import {OrdersComponent} from './orders/orders.component';
import {NewsComponent} from './pages/news/news.component';
import {NewsEditComponent} from '../client-service/news/edit/news-edit.component';
import {RefundComponent} from './pages/refund/refund.component';
import {ActsComponent} from './pages/acts/acts.component';
import {PickupDeliveryComponent} from './pages/pickup-delivery/pickup-delivery.component';
import {FinancialDocumentsComponent} from './pages/financial-documents/financial-documents.component';
import {PersonalOfficeComponent} from './personal-office/personal-office.component';
import {EditComponent} from './edit/edit.component';
import {KgtComponent} from '../matrixes/kgt/kgt.component';
import {PvzComponent} from './pages/pvz/pvz.component';
import {MatrixComponent} from '../matrixes/matrix/matrix.component';
import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {IndexComponent} from './index/index.component';
import {TakeAwayComponent} from 'app/matrixes/take-away/take-away.component';
import {TaCalculatorComponent} from 'app/calculators/ta-calculator/ta-calculator.component';
import {MatrixesTabComponent} from 'app/matrixes/matrixes-tab/matrixes-tab.component';
import {TakeAwayCfoComponent} from '../matrixes/take-away-cfo/take-away-cfo.component';
import {MatrixHubsComponent} from '../matrixes/matrix-hubs/matrix-hubs.component';
import {MatrixTopdeliveryComponent} from '../matrixes/matrix-topdelivery/matrix-topdelivery.component';
import {ClientUsersEditComponent} from '../components/client-users/client-users-edit/client-users-edit.component';
import {ClientUsersCreateComponent} from "../components/client-users/client-users-create/client-users-create.component";

const routes: Routes = [
    {path: 'index', component: IndexComponent},
    {path: 'matrixes', component: MatrixesTabComponent, children: [
        {path: 'matrix-moscow', component: MatrixComponent},
        {path: 'matrix-hubs/:id', component: MatrixHubsComponent},
        {path: 'matrix-kgt', component: KgtComponent},
        {path: 'matrix-takeaway-moscow', component: TakeAwayComponent},
        {path: 'matrix-takeaway-cfo', component: TakeAwayCfoComponent},
        {path: 'topdelivery', component: MatrixTopdeliveryComponent},
    ]},
    {path: 'calculators', component: CalculatorsTabComponent},
    {path: 'pvz', component: PvzComponent},
    {path: 'edit/:id', component: EditComponent},
    {path: 'user/edit/:id', component: ClientUsersEditComponent},
    {path: 'user/create/:client_id', component: ClientUsersCreateComponent},
    {path: 'ta-calculator', component: TaCalculatorComponent},
    {
        path: 'clients',
        component: PersonalOfficeComponent,
        children: [
            {
                path: 'financial-documents',
                component: FinancialDocumentsComponent
            },
            {
                path: 'pickup-delivery',
                component: PickupDeliveryComponent,
            },
            {
                path: 'acts',
                component: ActsComponent,
            },
            {
                path: 'refund',
                component: RefundComponent,
            },
            {
                path: 'news',
                component: NewsComponent,
            },
            {
                path: 'orders',
                component: OrdersComponent
            },
            {
                path: 'pvz',
                component: PvzComponent
            }
        ]
    }
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule]
})
export class ClientsRoutingModule {
}
