import {Component, EventEmitter, Inject, Input, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {HelpersService} from '../../service/helpers.service';
import {OrdersService} from '../../service/orders.service';
import {LOCAL_STORAGE, StorageService} from 'ngx-webstorage-service';


@Component({
    selector: 'app-substatus-select',
    templateUrl: './substatus-select.component.html',
    styleUrls: ['./substatus-select.component.scss']
})
export class SubstatusSelectComponent implements OnInit {

    @Input() public types = []; // типы подстатусов в виде массива
    @Input() public multi: boolean = false;
    @Input() public disabled: boolean = false;


    @Input() public selectedStatuses: SubStatusItem[] = [];
    @Input() public selectedStatus: SubStatusItem = null;


    @Input() public customStatusesNames = null;

    public statusesList: SubStatusItem[] = [];

    @Output() onChange = new EventEmitter<SubStatusItem | SubStatusItem[]>();

    public settings: IDropdownSettings = {};
    @Input() public placeHolder: string = 'Подстатусы';

    constructor(
        @Inject(LOCAL_STORAGE) private storage: StorageService,
        protected helper: HelpersService,
        private api: OrdersService
    ) {
    }

    ngOnInit() {
        this.settings = {
            idField: 'substatus_id',
            textField: 'name',
            singleSelection: !this.multi,
            closeDropDownOnSelection: !this.multi,
            itemsShowLimit: 2,
            defaultOpen: false
        };
        const substatuses = this.storage.get('Substatuses: ' + this.types.join(','));
        if (substatuses) {
            if ((new Date().getTime() - substatuses.timestamp) / 1000 / 60 / 60 >= 1) {
                this.reload();
            }
            this.statusesList = substatuses.data;
        } else {
            this.reload();
        }
    }

    public reload() {
        this.api.getSubstatusesByType(this.types).subscribe(data => {
            this.statusesList = data;
            this.storage.set('Substatuses: ' + this.types.join(','),
                {timestamp: new Date().getTime(), data: data});
        });

    }

    protected findModel(id) {
        const list = this.statusesList.filter((item: SubStatusItem) => {
            return item.substatus_id === parseInt(id, 10);
        });
        if (!list.length) {
            return;
        }
        return list[0];
    }

    onChangeStatus() {
        this.onChange.emit(this.multi ? this.selectedStatuses : this.selectedStatus);
    }

    onSelectItem(event) {

        if (this.multi) {
            const obj = this.findModel(event.substatus_id);
            if (obj) {
                this.selectedStatuses.push(obj);
            }
        } else {
            this.selectedStatus = this.findModel(event.substatus_id);
        }

        this.onChangeStatus();
    }

    onSelectAll() {
        this.selectedStatuses = this.statusesList;
        this.onChangeStatus();
    }

    onDeSelect(event) {
        const st: SubStatusItem = this.findModel(event.substatus_id);
        if (this.multi) {

            this.selectedStatuses = this.selectedStatuses.filter((item: SubStatusItem) => {
                return item.substatus_id !== st.substatus_id;
            });
        } else {
            this.selectedStatus = null;
        }
        this.onChangeStatus();
    }

    onDeSelectAll() {
        this.selectedStatuses = [];
        this.selectedStatus = null;
    }
}

class SubStatusItem {
    substatus_id: number;
    name: string
}
