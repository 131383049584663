import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {Hub, User, UserHub} from "../../../service/models";
import {Title} from "@angular/platform-browser";
import {CurrentUserService} from "../../../service/current-user.service";
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {MemberService} from "../../../service/member.service";
import {ColumnResizingService} from "@progress/kendo-angular-grid";
import {HubsService} from "../../../service/hubs.service";

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss'],
    providers: [ColumnResizingService]
})
export class EditComponent implements OnInit {
    public userId: number;

    public user: User = new User({});

    public currentUser: User = new User({});

    public hubsList: Hub[];
    private defaultHubId: number;
    public selectedRoles = [];

    private userIsCourier: boolean = false;
    private userIsCourierAndMore: boolean = false;

    private hubsUserMap: {
        [key: number]: {
            hub_id: number,
            is_accessible: boolean,
            is_default_hub: boolean,
        }
    } = {};

    public formUser: FormGroup;

    public save = false;

    public rePassword = false;

    public isNew = false;

    public roles;
    public groups;
    public settings = {
        idField: 'id',
        textField: 'description',
        singleSelection: false,
        enableCheckAll: false,
        itemsShowLimit: 7,
        closeDropDownOnSelection:  false
    };

    constructor(
        private api: MemberService,
        private route: ActivatedRoute,
        private router: Router,
        private hubsService: HubsService,
        public title: Title,
        private serviceCurrentUser: CurrentUserService
    ) {
        this.isNew = this.router.url === '/admin/users/new';

        if (this.isNew) {
            this.title.setTitle('Создание нового пользователя');
        } else {
            this.title.setTitle('Редактирование пользователя');
        }

        this.formUser = new FormGroup({
            email: new FormControl({value: '', disabled: !this.isNew}, Validators.required),
            name: new FormControl('', Validators.required),
            roles: new FormControl('', Validators.required),
            active: new FormControl(''),
            blocked: new FormControl(''),

            password1: new FormControl({
                value: '',
                disabled: !this.isNew
            }, Validators.required),
            password2: new FormControl({
                value: '',
                disabled: !this.isNew
            }, Validators.required),

            hubs: new FormControl([], Validators.required),
            default_hub: new FormControl(null, Validators.required),

            groups: new FormArray([])
        });
    }

    ngOnInit() {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs.filter((hub: Hub) => {
                return hub.type === Hub.TYPE_LOGSIS;
            });
        });

        /*   this.api.rolesList().subscribe((data) => {
              this.roles = data;
              console.log(this.roles);
          }); */

        /*this.roles = this.user.getRoleTitle();
        console.log(this.roles);*/

        /*this.api.groupsList().subscribe((data) => {
            this.groups = data;
            this.updateGroupForm();
        });*/
        this.getRolesList();

        this.serviceCurrentUser.get().subscribe((user: User) => {
            this.currentUser = user;

            /* нового пользователя может создавать только админ */
            if (!this.currentUser.isRoleAdmin() && this.isNew) {
                this.router.navigate(['/']);
            }

            this.route.paramMap.subscribe((params: ParamMap) => {
                this.userId = parseInt(params.get('id'));
                if (!this.userId && !this.isNew) {
                    this.userId = this.currentUser.id;
                }
                this.loadUser();
            });
        });
    }

    loadUser() {
        if (this.isNew) {
            return;
        }
        this.api.getUserInfo(this.userId).subscribe((data) => {
            this.user = new User(data);
            /* если пользователь не админ, то можеть редактировать только свой профиль */
            if (!this.currentUser.isRoleAdmin() && this.currentUser.id !== this.user.id) {
                this.router.navigate(['/']);
            }
            this.commonProcessHubsAndRoles();
            this.updateForm();
        });
    }

    getRolesList() {
        this.api.rolesList().subscribe(data => {
            this.roles = data;
        })
    }

    updateForm() {
        this.formUser.get('email').setValue(this.user.email);
        this.formUser.get('name').setValue(this.user.name);
        this.formUser.get('roles').setValue(this.user.roles);
        this.formUser.get('active').setValue(this.user.active);
        this.formUser.get('blocked').setValue(this.user.blocked);

        if (this.user.active) {
            this.formUser.get('active').disable();
        }
        if (!this.currentUser.isRoleAdmin() && !this.isNew) {
            this.formUser.get('email').disable();
            this.formUser.get('name').disable();
            this.formUser.get('role').disable();
            this.formUser.get('active').disable();
            this.formUser.get('blocked').disable();
        }

        this.updateGroupForm();
        this.updateHubsForm();
    }

    updateGroupForm() {
        if (!this.groups) {
            return;
        }
        const controls = [];
        for (let group of this.groups) {
            let enable = false;
            if (this.user.groups) {
                for (let gr of this.user.groups) {
                    if (gr.id === group.id) {
                        enable = true;
                        break;
                    }
                }
            }
            controls.push(
                new FormGroup({
                    enable: new FormControl({
                        value: enable,
                        disabled: !this.currentUser.isRoleAdmin()
                    }),
                    group_id: new FormControl(group.id)
                })
            );
        }
        this.formUser.setControl('groups', new FormArray(controls));
    }

    onRePassword() {
        this.rePassword = true;
        this.formUser.get('password1').enable();
        this.formUser.get('password2').enable();
    }

    onSubmit() {
        this.save = false;
        this.formUser.value.roles = this.formUser.value.roles.map(x => x.id);
        if (this.isNew) {
            this.api.createUser(this.formUser.value).subscribe((user: User) => {
                this.user = new User(user);
                this.router.navigate(['admin', 'users', 'edit', this.user.id]);
            });
        } else {
            this.api.updateUser(this.user.id, this.formUser.value).subscribe((user: User) => {
                this.user = new User(user);
                this.commonProcessHubsAndRoles();
                this.updateForm();
                this.updateHubsForm();
                this.save = true;
            });
        }
    }

    /**
     * Обработка хабов и вызов обработки ролей для загруженного пользователя
     * @private
     */
    private commonProcessHubsAndRoles() {
        if (this.user.hubs) {
            this.user.hubs = this.user.hubs.map((userHub: UserHub) => {
                return new UserHub(userHub);
            });
            this.extractHubsUserMap(this.user.hubs);
        }
        this.checkUserIsCourier();
    }

    /**
     * Имеет ли редактируемый пользователь доступ к хабу
     * @param hub
     */
    public isHubAccessibleByUser(hub: Hub): boolean {
        if (!Object.keys(this.hubsUserMap).length) {
            return false;
        }

        return (this.hubsUserMap.hasOwnProperty(hub.id) && this.hubsUserMap[hub.id].is_accessible);
    }

    /**
     * Является ли хаб для редактируемого пользователя хабом по умолчанию
     * @param hub
     */
    public isDefaultHubForUser(hub): boolean {
        if (!Object.keys(this.hubsUserMap).length) {
            return false;
        }

        if (!this.hubsUserMap.hasOwnProperty(hub.id)) {
            return false;
        }

        return this.hubsUserMap[hub.id].is_default_hub;
    }

    /**
     * Формирует рабочий массив связей пользователя с хабами
     * @param hubs
     * @private
     */
    private extractHubsUserMap(hubs: (UserHub[] | null) = null) {
        this.hubsUserMap = {};
        if (!hubs) {
            return;
        }

        for (let hub of hubs) {
            this.hubsUserMap[hub.id] = {
                hub_id: hub.id,
                is_accessible: true,
                is_default_hub: hub.is_default_hub,
            };

            if (hub.is_default_hub) {
                this.defaultHubId = hub.id;
            }

        }
    }

    /**
     * Имеет ли редактируемый пользователь роль курьера и роли помимо курьерской
     * @param rolesList
     * @private
     */
    private checkUserIsCourier(rolesList = null) {
        if (null === rolesList) {
            rolesList = this.user.roles;
        } else {
            rolesList = rolesList.map((item) => {
                item.id = 1 * item.id;
                return item;
            });
        }

        this.userIsCourier = false;
        this.userIsCourierAndMore = false;

        let nonCourierRoles = false;
        for (let role of rolesList) {
            if (4 === role.id) {
                this.userIsCourier = true;
            } else {
                nonCourierRoles = true;
            }
        }

        if (nonCourierRoles && this.userIsCourier) {
            this.userIsCourierAndMore = true;
        }
    }

    /**
     * Обрабочик изменения доступа к хабу
     * @param event
     */
    public onHubAccessChange(event) {
        let hubId = 1 * event.target.value;
        let hubsIds = Object.keys(this.hubsUserMap);

        if (!event.target.checked && 1 === hubsIds.length && event.target.value == hubsIds[0]) {
            event.target.checked = true;
            return;
        }

        if (event.target.checked) {
            this.hubsUserMap[hubId] = {
                hub_id: hubId,
                is_accessible: true,
                is_default_hub: false,
            }

            if (this.userIsCourier && !this.userIsCourierAndMore) {
                for (let loopHubId in this.hubsUserMap) {
                    if (this.hubsUserMap[loopHubId].is_accessible && this.hubsUserMap[loopHubId].hub_id !== hubId) {
                        delete this.hubsUserMap[loopHubId];
                    }
                }

                this.seekNewDefaultHub();
                return;
            }

            this.updateHubsForm();
            return;
        }

        if (this.hubsUserMap.hasOwnProperty(hubId)) {
            let isDefaultHub = this.hubsUserMap[hubId].is_default_hub;
            delete this.hubsUserMap[hubId];
            if (isDefaultHub) {
                this.seekNewDefaultHub();
                return;
            }
        }

        this.updateHubsForm();
    }

    /**
     * Обработчик изменения дефолтного хаба
     * @param event
     */
    public onDefaultHubChange(event) {
        let hubId = 1 * event.target.value;
        if (!this.hubsUserMap.hasOwnProperty(hubId)) {
            event.target.checked = false;
            this.seekNewDefaultHub();
            return;
        }

        this.hubsUserMap[hubId].is_default_hub = true;
        this.defaultHubId = hubId;
        this.updateHubsForm();
    }

    /**
     * Поиск нового дефолтного хаба (при отключении прав доступа к текущему)
     * @protected
     */
    protected seekNewDefaultHub() {
        for (let hubId in this.hubsUserMap) {
            if (this.hubsUserMap[hubId].is_accessible) {
                this.hubsUserMap[hubId].is_default_hub = true;
                this.defaultHubId = this.hubsUserMap[hubId].hub_id;
                this.updateHubsForm();
                return;
            }
        }
    }

    /**
     * Возвращает число хабов, к которым у пользователя есть доступ
     */
    public hubUserMapSize() {
        return Object.keys(this.hubsUserMap).length;
    }

    /**
     * Обработчик измненений в списке ролей пользователя
     */
    public onRolesListChange() {
        this.checkUserIsCourier(this.selectedRoles);
    }

    /**
     * Обновление контроллеров хабов в форме
     * @private
     */
    private updateHubsForm() {
        let hubs = [];
        for (let hubId in this.hubsUserMap) {
            hubs.push(this.hubsUserMap[hubId].hub_id);
        }

        this.formUser.get('hubs').setValue(hubs);
        this.formUser.get('default_hub').setValue(this.defaultHubId);
    }

}
