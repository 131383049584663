import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {User, UserGroup} from '../../../../service/models';
import {Title} from '@angular/platform-browser';
import {CurrentUserService} from '../../../../service/current-user.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {MemberService} from '../../../../service/member.service';

/*import {ColumnResizingService} from '@progress/kendo-angular-grid';*/

@Component({
    selector: 'app-editgroup',
    templateUrl: './editgroup.component.html',
    styleUrls: ['./editgroup.component.scss']
})


export class EditgroupComponent implements OnInit {
    public groupId: number;

    public usergroup: UserGroup = new UserGroup({});

    public currentUser: User = new User({});

    public formUserGroup: FormGroup;

    public save = false;

    public isNew = false;

    public selectedUser: User;


    constructor(
        private api: MemberService,
        private route: ActivatedRoute,
        private router: Router,
        public title: Title,
        private serviceCurrentUser: CurrentUserService
    ) {
        this.isNew = this.router.url === '/admin/users/groups/new';

        if (this.isNew) {
            this.title.setTitle('Создание группы ');
        } else {
            this.title.setTitle('Редактирование группы');
        }

        this.formUserGroup = new FormGroup({
            title: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {

        this.serviceCurrentUser.get().subscribe((user: User) => {
            this.currentUser = user;

            /* группы может редактировать только админ */
            if (!this.currentUser.isRoleAdmin()) {
                this.router.navigate(['/']);
            }

            this.route.paramMap.subscribe((params: ParamMap) => {
                this.groupId = parseInt(params.get('id'));
                if ((!this.groupId) && (!this.isNew)) {
                    alert('не передан id группы!');
                }
                this.loadGroup();
            });
        });
    }

    loadGroup() {

        if (this.isNew) {
            return;
        }
        this.api.getGroupInfo(this.groupId).subscribe((data) => {
            this.usergroup = new UserGroup(data);
            this.updateForm();
        });
    }

    updateForm() {
        this.formUserGroup.get('title').setValue(this.usergroup.title);

    }

    onSubmit() {
        this.save = false;
        if (this.isNew) {
            this.api.createUserGroup(this.formUserGroup.value).subscribe((usergroup: UserGroup) => {
                this.usergroup = new UserGroup(usergroup);
                this.router.navigate(['admin', 'users', 'groups', 'editgroup', this.usergroup.id]);
            });
        } else {
            this.api.updateUserGroup(this.usergroup.id, this.formUserGroup.value).subscribe((usergroup: UserGroup) => {
                this.usergroup = new UserGroup(usergroup);
                this.updateForm();
                this.save = true;
            });
        }
    }

    public onChangeUser(user: User) {

        this.selectedUser = user;
    }


}

