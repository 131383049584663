import {Injectable} from '@angular/core';
import {LaraService} from './lara.service';

@Injectable({
    providedIn: 'root'
})

export class DocReturnService extends LaraService {
    /**
     * Добавление заказа в акт
     * @param payload
     */
    public addOrder(payload) {
        return this.post('/doc-return/addOrder', payload);
    }

    /**
     * Список актов возврата документов
     * @param params
     */
    public listActs(params) {
        return this.get('/doc-return/listActs?' + this.getQueryStringFromArray(params));
    }

    /**
     * Список заказов ожидающих возврата документов
     * @param params
     */
    public listOrdersDebt(params) {
        return this.get('/doc-return/listOrdersDebt?' + this.getQueryStringFromArray(params));
    }

    /**
     * Поиск заказов среди актов
     * @param payload
     */
    public lookupOrder(payload) {
        return this.post('/doc-return/lookupOrder', payload);
    }

    /**
     * Формирование акта
     * @param act_id
     */
    public closeAct(act_id) {
        return this.post('/doc-return/closeAct', {
            act_id,
        })
    }

    /**
     * Приём подписанного акта
     * @param act_id
     */
    public adoptSignedAct(act_id) {
        return this.post('/doc-return/adoptSignedAct', {
            act_id,
        })
    }

    /**
     * Массовая смена статуса актов
     * @param act_ids
     * @param new_status
     */
    public massChangeActsStatus(act_ids, new_status) {
        return this.post('/doc-return/massChangeActsStatus', {
            act_ids,
            new_status,
        });
    }

    /**
     * Массовое добавление заказов в акты
     * @param order_ids
     */
    public massAddOrders(order_ids) {
        return this.post('/doc-return/massAddOrders', {
            order_ids,
        });
    }

    /**
     * Получение подробной информации по акту
     * @param act_id
     * @param withDeleted
     */
    public getAct(act_id, withDeleted = false) {
        return this.get('/doc-return/getAct/' + act_id + (withDeleted ? '?withDeleted' : ''));
    }

    /**
     * Удаление записи заказа/штрихкода из акта
     * @param id
     * @param act_id
     */
    public removeRecordFromAct(id, act_id) {
        return this.post('/doc-return/removeRecordFromAct', {
            id,
            act_id,
        });
    }

    /**
     * Восстановление записи заказа/штрихкода в акте
     * @param id
     * @param act_id
     */
    public restoreRecordInAct(id, act_id) {
        return this.post('/doc-return/restoreRecordInAct', {
            id,
            act_id,
        });
    }
}
