import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {FileDownloadService} from "../../http/file-download.service";

@Component({
    selector: 'app-reasons-perenos',
    templateUrl: './reasons-perenos.component.html',
    styleUrls: ['./reasons-perenos.component.scss']
})
export class ReasonsPerenosComponent implements OnInit {
    public gridData: GridDataResult;
    public date = new Date();
    public dates;
    public formList: FormGroup;
    isLoading = false;
    public selectedHubs: UserHub[];
    excelIsLoading = false;

    ngOnInit(): void {

    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        private fileDownloadService: FileDownloadService,
    ) {
        this.formList = new FormGroup({
            date_start: new FormControl(this.date, Validators.required),
            date_end:  new FormControl(this.date, Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        this.isLoading = true;
        const query = this.prepareQuery();
        this.statisticsService.getReasonsForPerenos(query).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data, total: data.length};
        }, error => {
            this.isLoading = false;
        });
    }

    onExcelClick() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        const query = this.prepareQuery();
        const fileName = 'Причины переноса  - ' + query.date_start + ' - ' + query.date_end;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/statistics/getReasonsForPerenosExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    private prepareQuery() {
        return {
            'date_start': this.datePipe.transform(this.formList.value.date_start, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.date_end, 'yyyy-MM-dd'),
            'hub_ids': this.selectedHubs.map(x => x.id)
        };
    }
}
