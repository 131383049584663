import {CourierValid} from '../../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {SkladService} from 'app/service/sklad.service';
import * as $ from 'jquery';
import {HelpersService} from '../../../service/helpers.service';
import {Order, User} from '../../../service/models';
import {ErrorDialogService} from '../../../components/error-dialog/errordialog.service';
import {environment} from '../../../../environments/environment';

@Component({
    selector: 'app-reception-couriers',
    templateUrl: './reception-couriers.component.html',
    styleUrls: ['./reception-couriers.component.scss']
})
export class ReceptionCouriersComponent implements OnInit {
    public formcheckCourier: FormGroup;
    public formorderCourier: FormGroup;

    public orders = [];
    public zorders = [];
    public printOrder;
    public printReturn = false;

    hideButton = false;
    loadingZOrder = false;
    loadingOrder = false;

    courier: User;
    courierDate = null;
    courierValid: CourierValid = new CourierValid();

    barCodeResult: any;
    public classAlert: string;

    /* устанавливаем фокус на input штрихкода */
    @ViewChild('barCodeInput') barCodeInput: ElementRef;

    @ViewChild('courierId') courierId: ElementRef;
    @ViewChild('datePicker') datePicker: ElementRef;
    public returnDocuments = [];

    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        public error: ErrorDialogService
    ) {
        this.formcheckCourier = new FormGroup({
            oldCourierId: new FormControl('', Validators.required),
            dateCour: new FormControl('', Validators.required)
        });

        this.formorderCourier = new FormGroup({
            barCode: new FormControl('', Validators.required)
        });

    }

    ngOnInit() {
        $.getScript('assets/js/tabs.js');
    }

    setDate() {
        this.formcheckCourier.value.dateCour = this.courierDate;
    }

    getPrintLink(order: Order) {
        return [
            '/printlabel',
            {
                'uid': order.uid,
                'inner_n': order.inner_n,
                'delivery_date': order.delivery_date,
                'status': this.helpers.getNameOrderStatus(order.status)
            }
        ]
    }

    checkStatusZOrder(zorder: any) {
        return zorder.status === 3 || zorder.status === 7;
    }

    onDateChange(date) {
        if (!date) {
            return;
        }
        console.log(this, date);
        this.courierDate = this.helpers.formatDateForSQL(date);
        this.loadDebt();
    }

    loadDebt(func = null) {
        if (!this.courier) {
            this.error.openDialog({
                body: {
                    response: {
                        error: 'Не выбран курьер'
                    },
                    status: 400
                }
            });
            return;
        }
        if (!this.courierDate) {
            this.error.openDialog(
                {
                    body: {
                        response: {
                            error: 'Не выбрана дата'
                        },
                        status: 400
                    }
                }
            );
            this.datePicker.nativeElement.focus();
            return;
        }
        this.loadingZOrder = this.loadingOrder = true;
        this.orders = this.zorders = [];
        this.api.courierDebt({userId: this.courier.id, date: this.courierDate}).subscribe(orders => {
            this.returnDocuments = orders.returnDocuments;
            this.loadingZOrder = this.loadingOrder = false;
            this.hideButton = orders.orders.length === 0 && orders.zorders.length === 0;
            this.orders = orders.orders;
            this.zorders = orders.zorders;
            if (func) {
                func(orders);
            }

            this.barCodeInput.nativeElement.focus();
        });
    }

    keyDownFunction(event) {
        if (event.keyCode !== 13) {
            return;
        }
        if (this.formcheckCourier.value.dateCour === '') {
            this.courierId.nativeElement.focus();
        } else {
            this.loadCourier();
        }
    }

    loadCourier() {
        this.orders = this.zorders = [];
        this.api.getNewCourierId(this.formcheckCourier.value).subscribe(courier => {
            this.courier = courier;
            this.datePicker.nativeElement.focus();
        });
    }

    returnSomethingOnStock(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.loadingOrder = true;
        this.barCodeResult = null;
        this.api.returnSomethingOnStock({
            userId: this.courier.id,
            barCode: this.formorderCourier.value
        }).subscribe(barcode => {
            this.barCodeResult = barcode;

            if (this.barCodeResult.order.is_need_new_label) {
                this.printReturn = false;
                this.printOrder = this.barCodeResult.order.uid;
                this.api.setLabelPrinted(this.barCodeResult.order.uid).subscribe((data: any) => {
                    console.log('printed');
                });
            }

            this.formorderCourier.reset();
            this.loadDebt();
            if (this.barCodeResult.order.status == 6 || this.barCodeResult.order.status == 7 || this.barCodeResult.order.status == 8) {
                this.classAlert = 'return';
                return;
            } else {
                const diff = new Date(this.barCodeResult.order.delivery_date).getDate() - new Date().getDate();

                if (diff == 0) {
                    this.classAlert = 'today';
                } else {
                    this.classAlert = 'nextday';
                }

                if (this.barCodeResult.order.courier.id == environment.not_founded_courier_id) {
                    this.classAlert = 'notfounded';
                }
                if (this.barCodeResult.order.courier.id == environment.problem_courier_id) {
                    this.classAlert = 'problem';
                }
            }


        });
    }

    putZorderOnStock(id) {
        this.loadingZOrder = true;
        this.api.putZorderOnStock({zorderId: id}).subscribe(courier => {
            console.log(courier);
            /** todo refactoring - возвращать с сервера объект */
            this.barCodeResult = {
                message: courier,
                order: {
                    id: id
                }
            };
            this.loadDebt();
        });
    }

    closeCourierOnStock() {
        this.loadingZOrder = this.loadingOrder = true;
        this.api.closeCourierOnStock({
            userId: this.courier.id,
            date: this.courierDate,
            returnDocuments: this.returnDocuments
        }).subscribe(message => {
            /*
            @todo сделать отдельный alert и confirm!
            принимающий текст и callback-и
             */
            alert(message.message);
            this.barCodeResult = null;
            this.courier = null;
            this.loadingZOrder = this.loadingOrder = false;
            // this.formcheckCourier.reset();
            this.formcheckCourier.patchValue({
                oldCourierId: ''
            })
            // this.courierId.nativeElement.value = '';
            // this.formcheckCourier.value.oldCourierId = '';
            this.courierId.nativeElement.focus();
        });
    }

    /**
     * Проверка статуса вовзратных документов
     */
    checkStatusReturnDoc() {
        let result = true;
        this.returnDocuments.forEach(returnDoc => {
            if (returnDoc.is_document_returned === null) {
              return result = false;
            }
        });
        return result;
    }

    printReturnLabel(order) {
        this.printReturn = true;
        this.printOrder = order.uid;
    }
}
