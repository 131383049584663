import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {User, UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {Subject} from 'rxjs';
import {FileDownloadService} from "../../http/file-download.service";

@Component({
    selector: 'app-courier-indicators',
    templateUrl: './courier-indicators.component.html',
    styleUrls: ['./courier-indicators.component.scss'],
    encapsulation: ViewEncapsulation.Emulated,
    styles: [
        `kendo-grid {
        height: 610px;
      }
      kendo-grid .k-grid-content {
        height: 600px;
      }`
    ],
})
export class CourierIndicatorsComponent implements OnInit {
    public gridData: GridDataResult;
    public date = new Date();
    public formList: FormGroup;
    public selectedCouriers;
    public isLoading = false;
    public loadingExcel = false;
    public selectedHubs: UserHub[];
    public appUserSelectEventsSubject: Subject<any> = new Subject<any>();


    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        private fileDownloadService: FileDownloadService,
    ) {
        this.formList = new FormGroup({
            date_start: new FormControl(this.date, Validators.required),
            date_end:  new FormControl(this.date, Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
        this.appUserSelectEventsSubjectEmit();
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        this.appUserSelectEventsSubjectEmit();
        if (!this.selectedHubs.length) {
          return this.notifier.openNotifier('Не выбран не 1 хаб', null, {class: 'danger'});
        }
        if (this.formList.controls.date_start.value > this.formList.controls.date_end.value) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger'});
        }

        this.isLoading = true;
        this.statisticsService.getCourierIndicators(this.prepareQuery()).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data, total: data.length};
        }, error => {
            this.isLoading = false;
        });
    }

    private prepareQuery() {
        return {
            'date_start': this.datePipe.transform(this.formList.value.date_start, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.date_end, 'yyyy-MM-dd'),
            'selected_hub': this.selectedHubs.map(x => x.id),
            'selected_courier': this.selectedCouriers.map(x => x.id),
        };
    }

    /**
     * Событие при выборе курьера
     * @param users
     */
    onSelectCourier(users: User[]) {
        this.selectedCouriers = users;
    }

    appUserSelectEventsSubjectEmit() {
        this.appUserSelectEventsSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    onExcelClick() {
        if (this.loadingExcel) {
            return;
        }
        this.loadingExcel = true;
        const fileName = 'courier_indicators_' + this.formList.value.date_start + ' - ' +  this.formList.value.date_end;
        this.fileDownloadService
            .downloadFile('/statistics/getCourierIndicatorsExcel', fileName + '.xlsx', 'post', this.prepareQuery())
            .subscribe(response => {
                this.loadingExcel = false;
            }, error => {
                this.loadingExcel = false;
            });
    }
}
