import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientInfo, User} from '../../service/models';
import {SkladService} from '../../service/sklad.service';
import {PageChangeEvent} from '@progress/kendo-angular-grid';
import {HelpersService} from '../../service/helpers.service';

@Component({
    selector: 'app-report-returns',
    templateUrl: './report-returns.component.html',
    styleUrls: ['./report-returns.component.scss']
})
export class ReportReturnsComponent implements OnInit {

    public formReturns: FormGroup;
    public selectedClient: ClientInfo;
    public selectedCourier: User;
    public selectedStatuses = [];
    public gridData;
    public total;
    public skip = 0;
    public pageSize = 20;
    @ViewChild('input_datestart') input_datestart: ElementRef;
    @ViewChild('input_dateend') input_dateend: ElementRef;
    @ViewChild('has_return_act') has_return_act: ElementRef;
    public loading = false;
    public datestart;
    public dateend;
    public statuses = [{id: 0, status: 'Новый'}, {id: 1, status: 'На доставке'}, {id: 2, status: 'Доставлен'}];
    public selectedStats = [];
    public settings = {
        idField: 'id',
        textField: 'status',
        singleSelection: false,
        enableCheckAll: false,
        itemsShowLimit: 1,
        closeDropDownOnSelection:  false
    };
    constructor(
        private api: SkladService,
        public helpers: HelpersService
    ) {
        this.formReturns = new FormGroup({
            statuses: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {
    }

    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
    }
    onSelectUser(user: User) {
        this.selectedCourier = user;
    }
    onDateChange(date) {
        date === 1 ?
            this.datestart =
                this.helpers.formatDateForSQL(this.input_datestart.nativeElement.value) :
            this.dateend =
                this.helpers.formatDateForSQL(this.input_dateend.nativeElement.value);
    }
    submitFilter() {
        this.skip = 0;
        this.getReturns();
    }

    getReturns() {
        this.loading = true;
        if (!this.datestart || !this.dateend) {
            alert('Введите период выгрузки');
            return;
        }
        const filter = this.setFilter();
        this.api.getOrderReturns(filter).subscribe(data => {
            this.gridData = data.orders;
            this.total = data.total;
            this.loading = false;
        })
    }
    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.getReturns();
    }
    setFilter() {
        this.formReturns.value.statuses ?
            this.formReturns.value.statuses = this.formReturns.value.statuses.map(v => v.id) :
            this.formReturns.value.statuses = [];
        const filter: any = {};
        filter.status = this.selectedStats;
        filter.date_start = this.datestart;
        filter.date_end = this.dateend;
        if (this.has_return_act.nativeElement.value != 'null') {
            filter.has_return_act = +this.has_return_act.nativeElement.value;
        }
        this.selectedCourier ? filter.courier_id = this.selectedCourier.id : filter.courier_id = 0;
        this.selectedClient ? filter.client_id = this.selectedClient.id : filter.client_id = 0;
        filter.skip = this.skip;
        filter.pageSize = this.pageSize;
        return filter;
    }
    protected findModel(id) {
        const list = this.statuses.filter(item => {
            return item.id === id;
        });
        if (!list.length) {
            return;
        }
        return list[0];
    }
    onSelectItem(event) {
        const stat = this.findModel(event.id);
        this.selectedStats.push(stat.id);
    }

    onDeSelect(event) {
        const stat = this.findModel(event.id);
        this.selectedStats = this.selectedStats.filter(item => {
            return item !== stat.id;
        });
    }
}
