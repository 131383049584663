import {Component, OnDestroy, OnInit} from '@angular/core';
import {CurrentUserService} from '../../service/current-user.service';
import {NavigationEnd, Router} from '@angular/router';
import {User} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {Title} from '@angular/platform-browser';
import {Subscription} from 'rxjs';
import {EventerService} from '../../service/eventer.service';

declare interface RouteInfo {
    path: string;
    title: string;
    icon?: string;
    problemsCount?: boolean;
    urlDiversion: boolean;
    simpleLink?: boolean;
    view?: (user: User) => boolean;
    items?: RouteInfo[];
}

@Component({
    selector: 'app-admin-navbar',
    templateUrl: './admin-navbar.component.html',
    styleUrls: ['./admin-navbar.component.scss']
})

export class AdminNavbarComponent implements OnInit, OnDestroy {
    menu: RouteInfo[];
    public user: User;
    public loading = false;
    private routerSubscription: Subscription;
    private sidebarToggleSubscription: Subscription;

    private activeRootItem: RouteInfo;
    private activeItem: RouteInfo;

    public activatedParentItems: string[] = [];
    public sidebarIsOpened: boolean = false;
    public sidebarIsMinified: boolean = false;

    private menuSource: RouteInfo[] = [
        {
            title: 'Клиенты',
            path: '/clients/index',
            urlDiversion: false,
            icon: 'icon-findocs',
            items: [
                {
                    path: '/clients/index',
                    title: 'Клиенты',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('clients:view')
                },
                {
                    path: '/clients/matrixes',
                    title: 'Матрицы',
                    urlDiversion: false,
                    items: [
                        {
                            path: '/clients/matrixes/matrix-moscow',
                            title: 'Тарифная матрица М и МО',
                            urlDiversion: false,
                            view: (user: User) => true
                        },
                        {
                            path: '/clients/matrixes/matrix-hubs/2',
                            title: 'Матрица СПб',
                            urlDiversion: false,
                            view: (user: User) => true
                        },
                        {
                            path: '/clients/matrixes/matrix-kgt',
                            title: 'КГТ матрица',
                            urlDiversion: false,
                            view: (user: User) => true
                        },
                        {
                            path: '/clients/matrixes/matrix-takeaway-moscow',
                            title: 'Матрица заборов М и МО',
                            urlDiversion: false,
                            view: (user: User) => true
                        },
                        {
                            path: '/clients/matrixes/matrix-takeaway-cfo',
                            title: 'Матрица заборов ЦФО',
                            urlDiversion: false,
                            view: (user: User) => true
                        },
                        {
                            path: '/clients/matrixes/matrix-topdelivery',
                            title: 'Матрица TopDelivery',
                            urlDiversion: false,
                            view: (user: User) => true
                        },
                    ],
                    view: (user: User) => user.inPermission('matrix:update-client')
                },
                {
                    path: '/clients/calculators',
                    title: 'Калькулятор',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('clients:calculators-view')
                }
            ]
        },
        {
            title: 'Пользователи',
            path: '/admin/users/index',
            icon: 'icon-retunrs',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/users/index',
                    title: 'Пользователи',
                    urlDiversion: false,
                    view: (user: User) => (user.inPermission('users:create') || user.inPermission('users:update'))
                },
                {
                    path: '/admin/users/roles-control',
                    title: 'Управление ролями',
                    urlDiversion: false,
                    view: (user: User) => (user.inPermission('roles:create') || user.inPermission('user-roles:update'))
                }
            ]
        },
        {
            title: 'Персонал',
            path: '/admin/staff/couriers',
            icon: 'icon-user',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/staff/couriers',
                    title: 'Курьеры',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:couriers')
                },
                {
                    path: '/admin/staff/penalties',
                    title: 'Штрафы',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:penalties')
                }
            ]
        },
        {
            title: 'Склад',
            path: '/admin/sklad/onlinetablo',
            urlDiversion: false,
            icon: 'icon-in-out',
            items: [
                {
                    path: '/admin/sklad/onlinetablo',
                    title: 'Табло',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:online-tablo')
                },
                {
                    path: '/admin/sklad/reception',
                    title: 'Прием курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:reception-courier')
                },
                {
                    path: '/admin/sklad/return-orders',
                    title: 'Быстрый возврат',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return-orders')
                },
                {
                    path: '/admin/sklad/reception_order',
                    title: 'Прием заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:reception-order')
                },
                {
                    path: '/sberbank',
                    title: 'Сбербанк',
                    urlDiversion: false,
                    view: (user: User) => true
                },
                {
                    path: '/admin/sklad/second-shift/assembling-control',
                    title: 'Контроль сборки 2 смены',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:second-shift-assembling')
                },
                {
                    path: '/admin/sklad/second-shift/assembling',
                    title: 'Сборка 2 смены',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:second-shift-assembling')
                },
                {
                    path: '/admin/sklad/return',
                    title: 'Обработка возвратов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return')
                },
                {
                    path: '/admin/sklad/return_acts',
                    title: 'Акты возврата',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:return-acts')
                },
                {
                    path: '/admin/sklad/actlist',
                    title: 'Акты приема-передачи',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:act-list')
                },
                {
                    path: '/admin/sklad/act-create',
                    title: 'Создать акт ПП',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:act-create')
                },
                {
                    path: '/admin/sklad/mactlist',
                    title: 'Акты МатОтв',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:mact-list')
                },
                {
                    path: '/admin/sklad/pallets',
                    title: 'Палеты',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:transits')
                },
                {
                    path: '/admin/sklad/transits',
                    title: 'Перемещения',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:transits')
                },

                {
                    path: '/admin/sklad/transits/receive',
                    title: 'Принять перемещение',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:transits')
                },
                /*
               {
                    path: '/admin/sklad/create-te',
                    title: 'Собрать ТД',
                    urlDiversion: false,
                    view: (user: User) => true
                },
                {
                    path: '/admin/sklad/te-list',
                    title: 'Палеты ТД',
                    urlDiversion: false,
                    view: (user: User) => true
                },*/
                /**
                 * RESEARCH-939
                 *
                {
                    path: '/admin/sklad/transit-list',
                    title: 'Старые перемещения',
                    urlDiversion: false,
                    view: (user: User) => true
                },
                */
                {
                    path:
                        'https://docs.google.com/document/d/1apfIVyNRSdXWeTGYFjKDaYBva55wn0d0WSkYroQIkvo/edit',
                    title: 'Инструкция склад',
                    urlDiversion: true,
                    simpleLink: true,
                    view: (user: User) => true
                },
                {
                    path: '/sklad/pre-assembly',
                    title: 'Предсборка',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:predsbor')
                },
                {
                    path: '/sklad/placement-check',
                    title: 'Проверка размещения',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:orders-placement-check')
                },
                {
                    path: '/admin/sklad/placement-report',
                    title: 'Отчет о размещении',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('storage:orders-placement-report')
                },
            ]
        },
        {
            title: 'Логистика',
            path: '/admin/logistics/courier-status',
            urlDiversion: false,
            icon: 'icon-pvz',
            items: [
                {
                    path: '/admin/logistics/courier-status',
                    title: 'Приход курьеров',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:courier-status')
                },
                {
                    path: '/admin/logistics/unrecognized-addresses',
                    title: 'Нераспознанные адреса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:unrecognized-addresses')
                },
                {
                    path: '/admin/zorders/list',
                    title: 'Заявки на забор',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:zorders-list')
                },
                {
                    path: '/admin/orders/list',
                    title: 'Распределение',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:orders-list')
                },
                {
                    path: '/main-map',
                    title: 'Карта',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:main-map')
                },
                {
                    path: '/admin/orders/list/ondelivery',
                    title: 'На доставке',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:orders-list')
                },
                {
                    path: '/admin/orders/list/check-orders',
                    title: 'Контроль заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:orders-list')
                },
                {
                    path: '/admin/orders/list/onware',
                    title: 'На складе',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:orders-list')
                },
                {
                    path: '/logist-map',
                    title: 'Курьер-контроль',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:logist-map')
                },
                {
                    path: '/admin/courier-docs',
                    title: 'Курьерские документы',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:courier-docs')
                },
                {
                    path: '/admin/logistics/courier-tarifficator',
                    title: 'Тарификатор курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:courier-tarifficator')
                },
                {
                    path: '/admin/logistics/check',
                    title: 'Чек логистов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:check')
                },
                {
                    path: '/zone-redactor',
                    title: 'Редактирование зон',
                    urlDiversion: true,
                    view: (user: User) => user.inPermission('log:zone-redactor')
                },
                {
                    path: '/admin/logistics/problems',
                    title: 'Проблемы',
                    problemsCount: true,
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:problems')
                },
                {
                    path: '/admin/logistics/sberbank-control',
                    title: 'Управление очередью',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:sberbank-control')
                },
                {
                    path: '/admin/logistics/regional-orders',
                    title: 'Региональные заказы',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:regional-orders')
                },
                {
                    path: '/admin/logistics/refused-on-spot',
                    title: 'Отказы на месте',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:refused-orders')
                },
                {
                    path: '/admin/logistics/spb-control',
                    title: 'Контроль заказов в СПб',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:spb-control')
                },
                {
                    path: '/admin/logistics/cargo-workday',
                    title: 'Рабочий день грузовика',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:cargo-courier-workday')
                },
                {
                    path: '/admin/logistics/courier-extra-expenses',
                    title: 'Парковки',
                    urlDiversion: false,

                    view: (user: User) => user.inPermission('log:manage-extra-expences')
                },
                {
                    path: '/admin/client-service/doc-return',
                    title: 'Возврат документов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:doc-return-acts')
                },
            ]
        },
        {
            title: 'Уведомления',
            path: '/admin/mailer/push-notifications',
            urlDiversion: false,
            icon: 'icon-notification',
            items: [
                {
                    path: '/admin/mailer/push-notifications',
                    title: 'Push уведомления',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:couriers:send-push')
                },
                {
                    path: '/admin/mailer/dispatched-notifications',
                    title: 'Отправленные уведомления',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('staff:dispatched-notifications')
                },
            ]
        },
        {
            title: 'Статистика',
            path: '/admin/statistics/report-returns',
            icon: 'icon-statistic',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/statistics/main',
                    title: 'Статистика по заказам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:main-report')
                },
                {
                    path: '/admin/statistics/report-returns',
                    title: 'Отчёт по возвратам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('return-report:view')
                },
                {
                    path: '/admin/statistics/couriers-assembly',
                    title: 'Сборка курьеров',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:shipping-report')
                },
                {
                    path: '/admin/statistics/logsis-pulse',
                    title: 'Пульс Логсиса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:logsis-pulse')
                },

                {
                    path: '/admin/statistics/bad-addresses',
                    title: 'Проблемные адреса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:bad-addresses-reports')
                },
                {
                    path: '/admin/statistics/suspisious-addresses',
                    title: 'Подозрительные адреса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:bad-addresses-reports')
                },
                {
                    path: '/admin/statistics/top20-delivery-warehouse',
                    title: 'Топ-20 привоза на склад',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:top20-report')
                },
                {
                    path: '/admin/statistics/delivery-warehouse',
                    title: 'Привоз на склад',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:received-orders-in-stock-by-day-report')
                },
                {
                    path: '/admin/statistics/courier-stat',
                    title: 'Статистика по курьерам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:courier-report')
                },
                {
                    path: '/admin/statistics/courier-salary',
                    title: 'Выплаты курьерам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:courier-salary')
                },
                {
                    path: '/admin/statistics/reasons-perenos',
                    title: 'Причины переноса',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:reasons-perenos')
                },
                {
                    path: '/admin/statistics/daily-meeting',
                    title: 'К собранию',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:daily-meeting')
                },
                {
                    path: '/admin/statistics/intervals-stat',
                    title: 'Статистика по интервалам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:intervals')
                },
                {
                    path: '/admin/statistics/clients-stat',
                    title: 'Статистика по клиентам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:clients-report')
                },
                {
                    path: '/admin/statistics/clients-stat-with-filter',
                    title: 'По клиентам с фильтрами',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:clients-report')
                },
                {
                    path: '/admin/statistics/warehouse-stat',
                    title: 'Складская статистика',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:storeg-report')
                },
                {
                    path: '/admin/statistics/new-stat',
                    title: 'Новая статистика',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:new-stat')
                },
                {
                    path: '/admin/statistics/zorders-stat',
                    title: 'Статистика по заборам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:fence-report')
                },
                {
                    path: '/admin/statistics/indicators-im',
                    title: 'Показатели ИМ',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:indicator-im--report')
                },
                {
                    path: '/admin/statistics/courier-indicators',
                    title: 'Показатели курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('stat:courier-indicators')
                },

            ]
        },
        {
            title: 'Бухгалтерия',
            path: '/admin/finance/courier-calculation',
            icon: 'icon-finance',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/finance/courier-calculation',
                    title: 'Расчёт курьера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:crmoney-view')
                },
                {
                    path: '/admin/finance/close-list',
                    title: 'Лист закрытия',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:close-list')
                },
                {
                    path: '/admin/statistics/courier-salary-fact',
                    title: 'Отчёт по фактической дате ЗП',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:view-salaries-by-date')
                },
                {
                    path: '/admin/finance/close-list-logist',
                    title: 'Лист закрытия логиста',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('log:close-list')
                },
                {
                    path: '/admin/finance/checking-orders',
                    title: 'Проверка заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:orders-report')
                },
                {
                    path: '/admin/finance/e-kassir',
                    title: 'Электронный кассир',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:e-kassir')
                },
                {
                    path: '/admin/finance/kassa-report',
                    title: 'Отчёт по кассе',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:kassa-report')
                },
                {
                    path: '/admin/finance/transfer-orders',
                    title: 'Передача заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:view-transfer-orders')
                },
                {
                    path: '/admin/finance/recalculate-orders',
                    title: 'Пересчёт заказов',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('buh:recalculate-orders')
                }
            ]
        },
        {
            title: 'Клиентский отдел',
            path: '/admin/client-service/number-settings',
            icon: 'icon-client-service',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/client-service/number-settings',
                    title: 'Настройка номера',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('mango:change-extension')
                },
                {
                    path: '/admin/client-service/news',
                    title: 'Новости',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('news:view')
                },
                {
                    path: '/admin/client-service/clients-sends',
                    title: 'Рассылка клиентам',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('clients-sends:view')
                }
            ]
        },
        {
            title: 'Курьеры',
            path: '/admin/couriers/map',
            icon: 'fa fa-truck',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/couriers/map',
                    title: 'Карта',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('couriers:map')
                },
                {
                    path: '/admin/couriers/route-list',
                    title: 'Маршрутный лист',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('couriers:route-list')
                },
                {
                    path: '/admin/couriers/claim-letter',
                    title: 'Претензионное письмо',
                    urlDiversion: false,
                    view: (user: User) => user.inPermission('couriers:map')
                },
            ]
        },
        {
            title: 'Отчёты',
            path: '/admin/reports',
            icon: 'fa fa-list-alt',
            urlDiversion: false,
            items: [
                {
                    path: '/admin/reports/detmir-remains',
                    title: 'Детмир отчёт по остаткам',
                    urlDiversion: false,
                    view: (user: User) => (user.inPermission('report:detmir-remains'))
                },
            ]
        },
    ];

    is = (fileName: string, ext: string) => new RegExp(`.${ext}\$`).test(fileName);

    constructor(
        private currentUser: CurrentUserService,
        private router: Router,
        protected api: LaraService,
        private eventerService: EventerService,
        private title: Title
    ) {
    }

    ngOnInit() {
        this.loading = true;
        this.currentUser.get().subscribe((user: User) => {
            this.user = new User(user);
            if (!user) {
                this.menu = [];
            } else if (user.isRoleAdmin()) {
                this.menu = this.menuSource;
            } else {
                const filter = item => item.view(user);
                this.menu = this.menuSource.filter(item => {
                    if (item.items) {
                        item.items = item.items.filter(filter);
                    }
                    item = this.checkingPermissionToAccessMenuSection(item);
                    return filter(item);
                });
            }

            this.setTitleFromMenu();
            this.setActiveParent();
        });

        this.routerSubscription = this.router.events.subscribe((event) => {
            if (event instanceof NavigationEnd) {
                this.activeRootItem = null;
                this.activeItem = null;
                this.activatedParentItems = [];

                this.setTitleFromMenu();
                this.setActiveParent();
            }
        })

        this.sidebarToggleSubscription = this.eventerService.adminSidebarToggle.subscribe(opened => {
            this.sidebarIsOpened = opened;

            if (window.innerWidth > 767 && opened) {
                this.sidebarIsMinified = true;
                this.activatedParentItems = [];
            } else {
                this.sidebarIsMinified = false;
            }
        })

        this.loading = false;
    }

    ngOnDestroy() {
        this.routerSubscription.unsubscribe();
        this.sidebarToggleSubscription.unsubscribe();
    }

    public onParentClick(itemIndex) {
        const findedItemIndex = this.activatedParentItems.indexOf(itemIndex);
        if (findedItemIndex > -1) {
            this.activatedParentItems.splice(findedItemIndex, 1);
        } else {
            if (this.sidebarIsMinified) {
                this.activatedParentItems = [];
            }
            this.activatedParentItems.push(itemIndex);
        }
    }

    public parentIsActive(parentIndex) {
        return this.activatedParentItems.indexOf(parentIndex) > -1;
    }

    public iconClass({text, items}: any): any {
        return {
            'k-i-file-pdf': this.is(text, 'pdf'),
            'k-i-folder': items !== undefined,
            'k-i-html': this.is(text, 'html'),
            'k-i-image': this.is(text, 'jpg|png'),
            'k-icon': true
        };
    }

    isActiveItem(item) {
        if (this.activeItem) {
            return item.path === this.activeItem.path;
        }

        if (this.router.url === item.path) {
            this.activeItem = item;
            return true;
        } else {
            if (this.urlHasPathWithParams(item.path)) {
                this.activeItem = item;
                return true;
            }

            return false;
        }
    }

    /**
     * Рекурсивно проверяем дочерние элементы
     * @param item
     */
    private getActiveSubItem(item) {
        if (this.isActiveItem(item)) {
            return item;
        }

        if (!item.items || item.items.length === 0) {
            return null;
        }

        let activeChild;
        item.items.forEach((submenu) => {
            const activeChildSubitem = this.getActiveSubItem(submenu);
            if (activeChildSubitem) {
                activeChild = activeChildSubitem;
                return;
            }
        });

        if (activeChild) {
            return activeChild;
        }

        return null;
    }

    setActiveParent() {
        if (this.sidebarIsMinified) {
            this.activatedParentItems = [];
            return;
        }

        this.menu.forEach((item) => {
            if (this.getActiveSubItem(item)) {
                this.activatedParentItems.push(item.path);
            }
        });
    }

    /**
     * Проверяем, не содержит ли url путь текущего элемента с параметром /id
     * @param path
     */
    private urlHasPathWithParams(path) {
        if (this.router.url.includes(path)) {
            return path === this.router.url.replace(/\/\d+$/, '');
        }

        return false;
    }

    /**
     * Установить Title по активному пункту меню
     */
    private setTitleFromMenu() {
        this.menu.forEach((item) => {
            const activeSubItem = this.getActiveSubItem(item);
            if (activeSubItem && activeSubItem.title) {
                this.title.setTitle(activeSubItem.title);
            }
        });
    }

    /**
     * Проверка, есть ли у пользователя доступ к разделу меню.
     * Если есть доступ хотябы к 1 подпункту меню, открываем доступ к разделу.
     * @private
     */
    private checkingPermissionToAccessMenuSection(item: RouteInfo): RouteInfo {
        item.view = (user: User) => (item.items.length >= 1)
        return item;
    }
}
