import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss']
})
export class ToolbarComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

    onNewOrder() {
        console.log('Новый заказ!');
    }

    onWorkWithPlaces() {
        console.log('Работа с местами!');

    }

    onLoadXLS() {
        console.log('Загрузка XLS!');
    }

    onTheConformition() {
        console.log('Подтвердить!');
    }

    onPrint() {
        console.log('Печать!');
    }

    onCreateAct() {
        console.log('Создать акт!');
    }

    onExport() {
        console.log('Экспорт!');
    }

    onCancelOrder() {
        console.log('Отменить заказ!');
    }
}
