import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {Hub} from '../../service/models';
import {FileDownloadService} from "../../http/file-download.service";


@Component({
    selector: 'app-courier-stat',
    encapsulation: ViewEncapsulation.Emulated,
    styles: [
        `kendo-grid {
        height: 610px;
      }
      kendo-grid .k-grid-content {
        height: 600px;
      }`
    ],
    templateUrl: './courier-stat.component.html',
    styleUrls: ['./courier-stat.component.scss']
})

export class CourierStatComponent implements OnInit {
    public gridData: GridDataResult;
    public clients;
    public dates;
    public formList: FormGroup;
    isLoading = false;
    public selectedHub: Hub [];
    excelIsLoading = false;
    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        private fileDownloadService: FileDownloadService,
    ) {
        this.formList = new FormGroup({
            percent: new FormControl(85, Validators.required),
            dateStart: new FormControl('', Validators.required),
            dateEnd: new FormControl('', Validators.required),
        });
        this.selectedHub = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHub = [selectedHubs];
    }

    /**
     * Загрузка отчета
     */
    loadReport() {
        if (this.formList.controls.dateStart.value > this.formList.controls.dateEnd.value) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        this.isLoading = true;
        const query = this.prepareQuery();
        this.statisticsService.getCourierStat(query).subscribe(data => {
            this.isLoading = false;
            console.warn(data);
            this.gridData = {data: data.couriers, total: data.couriers.length};
            this.dates = data.dates;
        }, error => {
            this.isLoading = false;
        });
    }

    /**
     * Задний фон ячейки
     * @param item
     */
    getBackgroundColor(item) {
        return item.value.deliveries_count ? (((item.value.success_count / item.value.deliveries_count * 100) >= this.formList.controls.percent.value) ? '' : 'alert-danger'  ) : '';
    }

    onExcelClick() {
        if (this.formList.controls.dateStart.value > this.formList.controls.dateEnd.value) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        const query = this.prepareQuery();

        const fileName = 'Курьерская статистика -' + query.date_start + ' - ' + query.date_end;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/statistics/getCourierStatExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    private prepareQuery() {
        return {
            'date_start': this.datePipe.transform(this.formList.value.dateStart, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.dateEnd, 'yyyy-MM-dd'),
            'selected_hub': [this.selectedHub[0].id],
        };
    }
}
