import {Component, OnInit} from '@angular/core';
import {DialogService} from '../../components/dialog/dialog.service';
import {LaraService} from '../../service/lara.service';
import {NotifierService} from '../../service/notifier.service';
import {HelpersService} from '../../service/helpers.service';
import {ShippingQueue} from '../../service/models';
import {AppComponent} from '../../app.component';
import {SecondShiftAssemblingOrdersComponent} from '../second-shift-assembling-orders/second-shift-assembling-orders.component';

@Component({
    selector: 'app-second-shift-control-assembling',
    templateUrl: './second-shift-control-assembling.component.html',
    styleUrls: ['./second-shift-control-assembling.component.scss']
})
export class SecondShiftControlAssemblingComponent implements OnInit {

    public assemblingInfoLoading: boolean = false;
    public assemblingStartLoading: boolean = false;
    public assemblingStarted: boolean = true;

    public couriersInfo;
    public courierStatuses = ShippingQueue.statuses;

    constructor(
        private dialog: DialogService,
        private api: LaraService,
        private notifier: NotifierService,
        public helpers: HelpersService,
        protected app: AppComponent,
    ) {
    }

    ngOnInit() {
        this.getAssemblingStatus();
    }

    getAssemblingStatus() {
        this.assemblingInfoLoading = true;
        this.api.getSecondShiftAssemblingInfo()
            .subscribe(data => {
                this.processInfoResult(data);

                this.assemblingInfoLoading = false;
            }, error => {
                this.assemblingInfoLoading = false;
            })
    }

    private processInfoResult(result) {
        this.assemblingStarted = (result.assemblingStatus !== null);
        this.couriersInfo = result.couriersInfo;
    }

    onStartAssemblingClick() {
        const dialog = this.dialog.confirm('Вы уверены? Действие нельзя отменить').subscribe(result => {
            if (result) {
                this.assemblingStartLoading = true;
                this.api.startSecondShiftAssembling()
                    .subscribe(data => {
                        this.notifier.openNotifier('Сборка началась', null, {duration: 5000});
                        this.processInfoResult(data);
                        this.assemblingStartLoading = false;
                    }, error => {
                        this.assemblingStartLoading = false;
                    })
            }

            dialog.unsubscribe();
        })
    }

    getCourierClassName(courier) {
        switch (courier.status) {
            case ShippingQueue.COURIER_ASSEMBLE:
                return 'table-info';
            case ShippingQueue.COURIER_READY:
                return 'table-success';
        }

        return null;
    }

    /**
     * Показать статус сборки заказов курьера
     * @param courierId
     */
    showCourierAssemblingInfo(courierId) {
        this.app.createDialog(SecondShiftAssemblingOrdersComponent, {
            courierId: courierId
        }).onDestroy(data => {

        })
    }
}
