import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {AppComponent} from '../../app.component';
import {OrderDialogComponent} from './order-dialog/order-dialog.component';
import {Order} from '../../service/models';
import {OrderDialogMiniComponent} from './order-dialog-mini/order-dialog-mini.component';
import {CurrentUserService} from "../../service/current-user.service";
import {HelpersService} from "../../service/helpers.service";
import {HubsService} from "../../service/hubs.service";

@Component({
    selector: 'app-order-card',
    templateUrl: './order-card.component.html',
    styleUrls: ['./order-card.component.scss']
})
export class OrderCardComponent implements OnInit {
    @Output() onClosed = new EventEmitter();
    @Output() onTransfer = new EventEmitter();
    @Output() onProblemResolved = new EventEmitter();

    @Input('order_id') public orderId: string;
    @Input('order') public order: Order;

    @Input('template') public template: string = 'full';

    public loading = false;
    public user;

    protected templates = {
        full: OrderDialogComponent,
        mini: OrderDialogMiniComponent
    };

    constructor(
        protected api: LaraService,
        protected app: AppComponent,
        protected currentUser: CurrentUserService,
        protected hubsService: HubsService,
        public helper: HelpersService
    ) {
        this.currentUser.get().subscribe(data => {
            this.user = data;
        })
    }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getOrder(this.getOrderId()).subscribe(async (data: Order) => {

            this.app.createDialog(this.templates[this.template], {
                order: await this.hubsService.defineOrderHubs(data),
                parent: this

            });
            this.bodyClick();
            this.loading = false;
        });
    }
    bodyClick() {
        $('body').click();
    }

    public DialogClosed() {
        this.onClosed.emit();
    }

    public DialogOnTransfer() {
        this.onTransfer.emit();
    }

    public getOrderId() {
        if (this.orderId) {
            return this.orderId;
        }
        return this.order.uid;
    }

    public DialogOnProblemResolved () {
        this.onProblemResolved.emit();
    }

}
