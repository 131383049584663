import {Injectable} from '@angular/core';

@Injectable({providedIn: 'root'})

export class ObjectsHelper {
    /**
     * Оставляем только НЕ null свойства объекта
     * @param oldObject
     */
    public excludeNullValues(oldObject: Object) {
        let newObject = {};

        const keys = Object.keys(oldObject);
        keys.forEach((key) => {
            if (oldObject[key] === null) {
                return;
            }

            newObject[key] = oldObject[key];
        })

        return newObject;
    }
}
