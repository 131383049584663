import {Component, EventEmitter, Input, OnInit, Output, SimpleChanges} from '@angular/core';
import {AddressService} from "../../service/address.service";
import {AppComponent} from "../../app.component";
import {AddressEditorMapDialogComponent} from "./address-editor-map-dialog/address-editor-map-dialog.component";
import {HelpersService} from "../../service/helpers.service";
import {LocationMapComponent} from "../location-map/location-map.component";
import {CoordsHelper} from "../../service/helpers/coords.helper";
import {CurrentUserService} from "../../service/current-user.service";
import {User} from "../../service/models";

@Component({
    selector: 'app-address-editor-map',
    templateUrl: './address-editor-map.component.html',
    styleUrls: ['./address-editor-map.component.scss']
})
export class AddressEditorMapComponent extends LocationMapComponent implements OnInit {
    @Input('addressId') public addressId: number = null;
    @Input('addressLat') public addressLat: number = null;
    @Input('addressLon') public addressLon: number = null;
    @Input('addressLabel') public addressLabel: string = null;
    @Input('addressLogId') public addressLogId: number = null;
    public editorButtonEnabled: boolean = false;
    public copyCoorsBlockAvailable: boolean = false;

    // Исходящие данные
    @Output() dialogOpenedChange = new EventEmitter<any>();
    public ccc: number = 0;

    // Я.Карта - инстанс маркера адреса
    protected addressPoint: any = null;
    public addressPointVisible: boolean = false;

    //

    public loading = false;

    public address: any;
    public ordersCount: number = null;
    public warehousesCount: number = null;
    public zordersCount: number = null;

    public latitude: number = null;
    public longitude: number = null;
    public visible = false;
    public message = '';
    public dialogWidth: number;
    public dialogHeight: number;

    public loadingAddressInfo: boolean = false;
    public addressInfo = null;
    public mapOpened: boolean = false;
    public savingClonedCoords: boolean = false;
    public canCopyCoordsFromAddressToLocation: boolean = false;
    public canCopyCoordsFromLocationToAddress: boolean = false;
    public readonly addressQualityValues = [
        {
            value: -1,
            label: 'Плохой',
            perm: 'log:set-address-quality-bad',
            className: 'text-danger',
        },
        {
            value: 0,
            label: 'Нейтральный',
            perm: 'log:set-address-quality-neutral',
            className: 'text-muted',
        },
        {
            value: 1,
            label: 'Хороший',
            perm: 'log:set-address-quality-good',
            className: 'text-success',
        }
    ];

    public attachAddressLogsList: number[] = [];
    public attachingAddressLogs: boolean = false;
    public toggleAddressPointVisible: boolean = false;


    constructor(
        protected app: AppComponent,
        protected api: AddressService,
        protected currentUserService: CurrentUserService,
        public helpers: HelpersService,
    ) {
        super(
            api,
            helpers,
        );

        this.dialogWidth = window.innerWidth * 0.8;
        this.dialogHeight = Math.max(window.innerHeight * 0.95, 500);
    }

    ngOnInit() {
        this.currentUserService.get().subscribe(async (user: User) => {
            this.copyCoorsBlockAvailable = ('s.ivanov@logsis.ru' === user.email);
        });

        super.ngOnInit();

        if (null === this.addressLabel) {
            this.addressLabel = 'Адрес ' + this.addressId;
        }
    }

    /**
     * Обработка родительских изменений значений входящих переменных
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        console.warn('address-editor-map changes', changes);
        super.ngOnChanges(changes);

        if (null !== this.addressPoint && ('undefined' !== typeof changes['addressLat'] || 'undefined' !== typeof changes['addressLon'])) {
            this.addressPoint.geometry.setCoordinates([this.addressLat, this.addressLon]);
        }
    }

    /**
     * Обработка клика по кнопке открытия диалога
     */
    onOpen() {
        console.warn('address-editor-map onOpen');

        this.getAddressInfo();
        this.loadLocationObjectData();

        this.app.createDialog(AddressEditorMapDialogComponent, {
            P: this,
        });

        this.dialogOpenedChange.emit(true);
    }

    /**
     * Обработка клика по кнопке закрытия диалога
     */
    onClose() {
        this.dialogOpenedChange.emit(false);
    }

    /**
     * Инициализация объектов Я.Карты по её готовности
     * @param event
     */
    onMapLoad(event) {
        this.locationPointVisible = (null !== this.lat && null !== this.lon);
        super.onMapLoad(event);

        this.updateAddressPointVisibility();

        this.addressPoint = new this.ymaps.GeoObject({
                type: 'Feature',
                properties: {
                    iconContent: this.addressLabel,
                    pointtype: 'address'
                },
                geometry: {
                    type: 'Point',
                    coordinates: [this.addressLat, this.addressLon]
                }
            },
            {
                preset: 'islands#greenStretchyIcon',
                draggable: false,
                visible: this.addressPointVisible,
            }
        );

        this.setAddressPointVisibility(this.addressPointVisible);
        this.updateToggleAddressPointVisible();

        this.autoScale();
    }

    /**
     * Обновление координат и текста маркера адреса
     * @param autoScale
     */
    updateAddressPoint(autoScale: boolean = false) {
        if (null === this.addressPoint) {
            return;
        }

        this.addressPoint.geometry.setCoordinates([this.addressLat, this.addressLon]);
        this.addressPoint.properties.set({
            iconContent: this.addressLabel,
            objectID: this.addressLabel,
        });

        if (autoScale) {
            this.autoScale();
        }
    }

    /**
     * Обработка клика по кнопке переключения видимости маркера адреса на карте
     */
    onToggleAddressPointVisibility() {
        this.setAddressPointVisibility(!this.addressPointVisible);
    }

    /**
     * Установка значения видимости маркера адреса на карте
     * @param visible
     */
    setAddressPointVisibility(visible: boolean) {
        this.addressPointVisible = visible;
        if (null === this.addressPoint) {
            return;
        }

        this.addressPoint.options.set({
            visible: this.addressPointVisible,
        });

        if (this.addressPointVisible) {
            this.instance.geoObjects.add(this.addressPoint);
        } else {
            this.instance.geoObjects.remove(this.addressPoint);
        }
    }

    /**
     * Обновляет признак видимости маркера адреса на карте
     * @param apply
     */
    updateAddressPointVisibility(apply = false) {
        this.addressPointVisible = (null !== this.addressLat && null !== this.addressLon && !CoordsHelper.IsCoordsEquals([
            this.lat, this.lon
        ], [
            this.addressLat, this.addressLon
        ]));

        if (apply) {
            this.setAddressPointVisibility(this.addressPointVisible);
        }

        return this.addressPointVisible;
    }

    /**
     * Обновляет признак видимости кнопки переключения видимости маркера адреса на карте
     */
    updateToggleAddressPointVisible() {
        this.toggleAddressPointVisible = (null !== this.addressLat && null !== this.addressLon);

        return this.toggleAddressPointVisible;
    }

    // todo refactor
    changeAddress() {
        if (!confirm('Изменения коснется всех точек с таким адресом! ')) {
            return;
        }
        this.api.setAddressCoordinates(this.addressId, this.latitude, this.longitude).subscribe(() => {
            this.message = 'Адрес изменен';
        });
    }

    /**
     * Получает актуальную информацию про адрес
     */
    getAddressInfo() {
        let nullCoords = (null === this.addressLat || null === this.addressLon);
        this.loadingAddressInfo = true;
        this.api.getAddress(this.addressId).subscribe(responsePayload => {
            this.addressInfo = responsePayload.address;
            this.ordersCount = responsePayload.usage.ordersCount;
            this.warehousesCount = responsePayload.usage.warehousesCount;
            this.zordersCount = responsePayload.usage.zordersCount;
            this.addressLat = null;
            this.addressLon = null;

            if (this.addressInfo.lat && this.addressInfo.lon) {
                this.addressLat = this.addressInfo.lat;
                this.addressLon = this.addressInfo.lon;
            }

            this.updateAddressPointVisibility(true);
            this.updateToggleAddressPointVisible();
            this.setAddressPointVisibility(this.addressPointVisible || (nullCoords && this.toggleAddressPointVisible));
            this.updateAddressPoint(true);
            this.updateCanCopyCoords();

            this.loadingAddressInfo = false;
        }, () => {
            this.loadingAddressInfo = false;
        });
    }

    /**
     * Установка значения качества адреса
     * @param addressId
     * @param quality
     */
    setAddressQuality(addressId, quality) {
        this.loadingAddressInfo = true;
        this.api.setAddressQuality(addressId, quality).subscribe(responsePayload => {
            this.addressInfo.quality = responsePayload.quality;
            this.loadingAddressInfo = false;
        }, () => {
            this.loadingAddressInfo = false;
        });
    }

    /**
     * Обработчик изменений в чекбоксе "Требуется прозвон"
     * @param event
     */
    onToggleLocationAddressRequiresCall(event) {
        console.warn('onToggleLocationAddressRequiresCall', event, event.target.checked);
        this.setLocationAddressRequiresCall(this.objectType, this.objectId, event.target.checked ? 1 : 0);
    }

    /**
     * Обновление флага "Требуется прозвон"
     * @param objectType
     * @param objectId
     * @param callRequired
     */
    setLocationAddressRequiresCall(objectType, objectId, callRequired) {
        this.loadingAddressInfo = true;
        this.api.setLocationAddressRequiresCall(objectType, objectId, callRequired).subscribe(responsePayload => {
            this.objectData.address_requires_call = responsePayload.address_requires_call;
            this.loadingAddressInfo = false;
        }, () => {
            this.loadingAddressInfo = false;
        });
    }

    /**
     * Обработка выбора нового значения качества адреса
     * @param quality
     */
    onSetAddressQuality(quality: number) {
        return this.setAddressQuality(this.addressInfo.id, quality);
    }

    /**
     * Обновление признаков возможности копирования координат между адресом и локацией
     * @protected
     */
    protected updateCanCopyCoords() {
        this.canCopyCoordsFromAddressToLocation = false;
        this.canCopyCoordsFromLocationToAddress = false;

        if (this.editableByPermissions
            && null !== this.addressLat && null !== this.addressLon
            && null !== this.id && null !== this.type
            && (
                (null === this.lat || null === this.lon)
                || !CoordsHelper.IsCoordsEquals([this.lat, this.lon], [this.addressLat, this.addressLon]))
        ) {
            this.canCopyCoordsFromAddressToLocation = true;
        }

        if (this.helpers.checkPermissions('log:edit-address-coords')
            && null !== this.lat && null !== this.lon && null !== this.addressId && (
                (null === this.addressLat && null === this.addressLon)
                || !CoordsHelper.IsCoordsEquals([this.lat, this.lon], [this.addressLat, this.addressLon])
            )) {
            this.canCopyCoordsFromLocationToAddress = true;
        }
    }

    /**
     * Обработка клика по кнопке копирования координат локации в координаты адреса
     */
    onCopyCoordsFromLocationToAddress() {
        console.warn('copy to address', [this.lat, this.lon]);
        if (null !== this.lat && null !== this.lon) {
            this.savingClonedCoords = true;
            this.api.setAddressCoordinates(this.addressId, this.lat, this.lon)
                .subscribe(data => {
                    console.log('response of setAddressCoordinates', data);
                    this.getAddressInfo();

                    this.savingClonedCoords = false;
                }, () => {
                    this.savingClonedCoords = false;
                });
        }
    }

    /**
     * Обработка клика по кнопке копирования координат локации в координаты адреса и установки положительного качества адреса
     */
    onCopyCoordsFromLocationToAddressAndMarkAsGood() {
        console.warn('copy to address + good quality', [this.lat, this.lon]);
        if (null !== this.lat && null !== this.lon) {
            this.savingClonedCoords = true;
            this.api.setAddressCoordinates(this.addressId, this.lat, this.lon, 1).subscribe(() => {
                this.savingClonedCoords = false;
                this.getAddressInfo();
            }, () => {
                this.savingClonedCoords = false;
            });
        }
    }

    /**
     * Обработка клика по кнопке копирования координат адреса в координаты локации
     */
    onCopyCoordsFromAddressToLocation() {
        console.warn('copy from address to location', [this.addressLat, this.addressLon]);
        this.applyCoords([this.addressLat, this.addressLon]);
    }

    /**
     * Обработка изменения значения чекбокса выбора варианта адреса для перепривязки к вечно плохому
     * @param event
     * @param id
     */
    onToggleAttachAddressLogsCheckbox(event, id) {
        // console.warn(event, id);
        // console.info('--', this.attachAddressLogsList);

        this.attachAddressLogsList = this.attachAddressLogsList.filter(item => {
            return item != id;
        });

        if (event.target.checked) {
            this.attachAddressLogsList.push(id);
        }

        // console.info('++', this.attachAddressLogsList);
    }

    /**
     * Обработка клика по кнопке перепривязки выбранных вариантов адреса к вечно плохому
     */
    onAttachAddressLogsToSystemReservedBadAddress() {
        this.attachAddressLogsToSystemReservedBadAddress();
    }

    /**
     * Перепривязка выбранных вариантов адреса к вечно плохому
     */
    attachAddressLogsToSystemReservedBadAddress() {
        if (!this.attachAddressLogsList.length) {
            return;
        }

        this.attachingAddressLogs = true;
        this.api.attachAddressLogsToSystemReservedBadAddress(this.attachAddressLogsList).subscribe(() => {
            this.attachAddressLogsList = [];
            this.attachingAddressLogs = false;

            this.getAddressInfo();
        }, () => {
            this.attachingAddressLogs = false;
        })
    }
}
