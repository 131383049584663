import {Injectable} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {BehaviorSubject, Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {OrdersService} from '../service/orders.service';
import {Router} from "@angular/router";

@Injectable({
    providedIn: 'root'
})
export class OrdersListService extends BehaviorSubject<GridDataResult> {
    constructor(
        private api: OrdersService,
        private router: Router
    ) {
        super(null);
    }

    public query(state: any): void {
        this.fetch(state)
            .subscribe(x => super.next(x));
    }

    protected fetch(state: any): Observable<GridDataResult> {
        if (this.router.url === '/admin/orders/list/ondelivery' && !state.statuses) {
            state.statuses = [4];
        } else if (this.router.url === '/admin/orders/list/onware' && !state.statuses) {
            state.statuses = [3];
        } else if (this.router.url === '/admin/orders/list' && !state.statuses) {
            state.statuses = [2, 3];
        }
        return this.api.getList(state).pipe(
            map((response) => {
                return (<GridDataResult>{
                    data: response.orders.data,
                    total: parseInt(response.orders.total, 10)
                })
            })
        );
    }

}
