import { Component, OnInit } from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {FinanceService} from '../../service/finance.service';
import {DatePipe} from '@angular/common';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
  selector: 'app-detmir-remains',
  templateUrl: './detmir-remains.component.html',
  styleUrls: ['./detmir-remains.component.scss']
})
export class DetmirRemainsComponent implements OnInit {

    public form: FormGroup;
    public excelIsLoading: boolean = false;

    constructor(
        protected api: FinanceService,
        protected dataPipe: DatePipe,
        private fileDownloadService: FileDownloadService
    ) {
        this.form = new FormGroup({
            date: new FormControl(0, Validators.required)
        });
    }

  ngOnInit() {
  }

    getExcel() {
        const date = this.form.get('date').value;
        const fileName = 'Отчёт на ' + this.dataPipe.transform(date, 'dd.MM.yyyy');

        const reportParams = {
            date: this.dataPipe.transform(date, 'yyyy-MM-dd')
        }

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/reports/detmir-remains', fileName + '.xlsx', 'post', reportParams)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }
}
