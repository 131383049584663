import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';

@Component({
    selector: 'app-warehouse-stat',
    templateUrl: './warehouse-stat.component.html',
    styleUrls: ['./warehouse-stat.component.scss']
})
export class WarehouseStatComponent implements OnInit {
    public gridShipping: GridDataResult;
    public gridZone: GridDataResult;
    public date = new Date();
    public dates;
    public formList: FormGroup;
    isLoading = false;
    public selectedHubs: UserHub[];

    ngOnInit(): void {

    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
    ) {
        this.formList = new FormGroup({
            date_start: new FormControl(this.date, Validators.required),
            date_end:  new FormControl(this.date, Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        this.isLoading = true;
        const query = {
            'date_start': this.datePipe.transform(this.formList.value.date_start, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.date_end, 'yyyy-MM-dd'),
            'selected_hub': this.selectedHubs
        };
        this.statisticsService.getStorageStat(query).subscribe(data => {
            this.isLoading = false;
            console.warn(data);
            this.gridShipping = {data: data.shipping_stat, total: data.shipping_stat.length};
            this.gridZone = {data: data.zone_stat, total: data.zone_stat.length};
        }, error => {
            this.isLoading = false;
        });
    }
}
