import {HelpersService} from './../../service/helpers.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {SkladService} from 'app/service/sklad.service';
import {CounterComponent} from './counter.component';
import {EventerService} from "../../service/eventer.service";
import {CurrentUserService} from "../../service/current-user.service";
import {UserHub} from "../../service/models";

@Component({
    selector: "app-board-online",
    templateUrl: "./board-online.component.html",
    styleUrls: ["./board-online.component.scss"]
})

export class BoardOnlineComponent implements OnInit {

    public notReadyCouriers = [];
    public index;
    public todayCouriers = [];
    public viewOrder = false;
    public loading = false;
    public selectedHub: UserHub = null;
    private subscr = null;

    @ViewChild("counter", {read: CounterComponent})
    private counter: CounterComponent;

    counterState = "counter is ticking";


    constructor(
        private api: SkladService,
        public helpers: HelpersService,
        private eventerService: EventerService,
        private currentUserService: CurrentUserService,
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.selectedHub = hub;
            this.loadingCourier();
        });

    }


    ngOnInit() {
        this.counter.startAt = 120;
        this.selectedHub = this.currentUserService.getCurrentHub();
        this.loadingCourier();

        this.subscr= this.counter.counterState.subscribe(msg => {
            if (msg === "COMPLETE") {
                this.counterState = "counter has stopped";
                this.counter.start();
                this.loadingCourier();
            }
        });
        this.counter.start();
    }

    ngOnDestroy() {
        this.counter.stop();
      //  if (this.subscr) {
       //     this.counter.counterState.unsubscribe();
       // }
    }

    loadingCourier() {
        this.loading = true;
        this.api.getTodayCouriers(this.selectedHub.id).subscribe(data => {
            this.todayCouriers = data.todayCouriers;
            this.notReadyCouriers = data.notReadyCouriers;
            this.loading = false;
        });
    }

    checkStatus(id) {
        if (id === this.index) {
            this.viewOrder = !this.viewOrder;
        } else {
            this.index = id;
            this.viewOrder = true;
            return this.index;
        }

    }
}
