import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {CurrentUserService} from '../../service/current-user.service';
import {DatePipe} from '@angular/common';
import {StatisticsService} from '../statistics.service';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {HelpersService} from '../../service/helpers.service';
import {Hub} from '../../service/models';

@Component({
    selector: 'app-top20-delivery-warehouse',
    encapsulation: ViewEncapsulation.Emulated,
    styles: [
        `kendo-grid {
        height: 610px;
      }
      kendo-grid .k-grid-content {
        height: 600px;
      }`
    ],
    templateUrl: './top20-delivery-warehouse.component.html',
    styleUrls: ['./top20-delivery-warehouse.component.scss']
})

export class Top20DeliveryWarehouseComponent implements OnInit {
    public gridData: GridDataResult;
    public clients;
    public dates;
    public formList: FormGroup;
    isLoading = false;
    public selectedHubs;
    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
    ) {
        this.formList = new FormGroup({
            dateStart: new FormControl('', Validators.required),
            dateEnd: new FormControl('', Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }

    /**
     * Загрузка отчета
     */
    loadTop20() {
        if (this.formList.controls.dateStart.value > this.formList.controls.dateEnd.value) {
           return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        this.isLoading = true;
        const query = {
            'date_start': this.datePipe.transform(this.formList.value.dateStart, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.dateEnd, 'yyyy-MM-dd'),
            'selected_hub': this.selectedHubs,
        };
        this.statisticsService.getOrdersStockDateTime(query).subscribe(data => {
            this.isLoading = false;
            this.gridData = {data: data.clients, total: data.clients.length};
            this.clients = data.clients;
            this.dates = data.dates;
        }, error => {
            this.isLoading = false;
        });
    }
}
