import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {Title} from '@angular/platform-browser';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientService} from '../../../service/client.service';
import {DialogService} from '../../../components/dialog/dialog.service';
import {HelpersService} from '../../../service/helpers.service';
import { DatePipe } from '@angular/common';
import {environment} from '../../../../environments/environment';
import {AppComponent} from '../../../app.component';

@Component({
    selector: 'app-news-edit',
    templateUrl: './news-edit.component.html',
    styleUrls: ['./news-edit.component.scss']
})
export class NewsEditComponent implements OnInit {
    public newsGroup: FormGroup;
    public newsId;
    public news;
    public loadingNews = false;
    @ViewChild('input_date') input_date: ElementRef;
    public tiny_mce_key = environment.tiny_mce_key;
    constructor(
        private route: ActivatedRoute,
        private router: Router,
        public title: Title,
        private api: ClientService,
        protected dialog: DialogService,
        protected helpers: HelpersService,
        protected datePipe: DatePipe,
        protected app: AppComponent,
    ) {
        this.newsGroup = new FormGroup({
            title: new FormControl('', Validators.required),
            body: new FormControl('', Validators.required),
            active: new FormControl(''),
            date: new FormControl('', Validators.required),
        });
    }

    ngOnInit(): void {
        if (!this.helpers.checkPermissions('news:edit')) {
            this.app.closeDialog(this);
        }
        this.getParamsId();
        if (this.newsId) {
            this.title.setTitle('Редактирование новости');
            this.loadNews();
        } else {
            this.title.setTitle('Создание новости');
            this.newsGroup.controls.date.setValue(new Date());
            this.newsGroup.controls.active.setValue(true);
        }
    }

    /**
     * Загрузка новости по идентификатору
     * @private
     */
    private loadNews() {
        this.loadingNews = true;
        this.api.getNewsById(this.newsId).subscribe(data => {
            this.loadingNews = false;
            if (data.error) {
                return this.dialog.alert(data.error, 500);
            }
            this.newsGroup.controls.title.setValue(data.title);
            this.newsGroup.controls.date.setValue(data.date);
            this.newsGroup.controls.active.setValue(data.active);
            this.newsGroup.controls.body.setValue(data.body);
        });
    }

    /**
     * Получение идентификатора новости
     */
    public getParamsId()   {
        this.route.paramMap.subscribe((params: ParamMap) => {
            this.newsId = parseInt(params['params'].id, 10);
        });
    }

    /**
     * Сохранение или создание новости
     */
    public saveNews() {
        const values = this.getTouchedValues(this.newsGroup);
        if (!values.countChange) {
            return this.dialog.alert('Изменения не обнаружены. Сохранение не произведено');
        }
        if (!this.newsId) {
            values.query['date'] = this.helpers.formatDateForSQL(this.input_date.nativeElement.value);
        } else {
            values.query['id'] = this.newsId;
        }
        this.api.editOrCreate(values.query).subscribe(data => {
            if (!this.newsId) {
                this.dialog.alert('Новость создана!');
            } else {
                this.dialog.alert('Изменения успешно сохранены!');
            }
            this.newsGroup.markAsUntouched();
        });
    }

    /**
     * Возвращает только изменённые поля формы
     * @param form
     */
    private getTouchedValues(form) {
        let countChange = 0;
        const query = form.value;
        for (const i in form.controls) {
            countChange += 1;
            if (form.controls[i] instanceof FormArray) {
                query[i] = <FormArray>form.controls[i].value;
            } else {
                if (!form.controls[i].touched) {
                    delete query[i];
                    countChange -= 1;
                }
            }
        }
        return {
            'query': query,
            'countChange': countChange,
        };
    }

    /**
     * Смена формата даты, для сохранения в бд
     */
    onDateChange() {
        this.newsGroup.get('date').setValue(this.helpers.formatDateForSQL(this.input_date.nativeElement.value));
    }
}
