import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {SkladService} from "../../../service/sklad.service";
import {CourierList, ZorderList} from "../../../service/models";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-power-of-attorney',
    templateUrl: './power-of-attorney.component.html',
    styleUrls: ['./power-of-attorney.component.scss']
})
export class PowerOfAttorneyComponent implements OnInit {

    dataApiZorders: ZorderList[] = [];
    dataApiCouriers: CourierList[] = [];

    public loading = false;

    date: string;
    courierParam: number;
    dateTwoDays: string;
    name: string;

    todayISOString: string = new Date().toISOString();

    constructor(
        public datePipe: DatePipe,
        private route: ActivatedRoute,
        private api: SkladService,
    ) {
    }

    ngOnInit() {
        this.loading = true;

        // get url params
        this.route.params.subscribe(params => {
            this.date = params['date'];
            this.courierParam = params['courier'];
        });

        // get data from API
        this.api.getAttorneys(this.date, this.courierParam).subscribe((data) => {
            this.dataApiCouriers = data.courier;
            this.dataApiZorders = data.zorders;
        });

        this.loading = false;
    }

    // MARK: Преобразование даты
    getDateTwoDays(data) {
        const dateStr = data;
        const date = new Date(dateStr);
        date.setDate(date.getDate() + 1);
        const dateNStr = this.datePipe.transform(date, 'dd.MM.yyyy');
        return dateNStr;
    }


}
