import {AdminRoutingModule} from './admin-routing.module';
import {ComponentsModule} from '../components/components.module';
import {CommonModule} from '@angular/common';
import {BodyModule, GridModule, RowFilterModule} from '@progress/kendo-angular-grid';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {NgModule} from '@angular/core';
import {
    MAT_DATE_LOCALE,
    MAT_TABS_CONFIG,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule
} from '@angular/material';
import {StorageModule} from 'app/storage/storage.module';
import {AdminHeaderComponent} from './admin-header/admin-header.component';
import {AdminNavbarComponent} from './admin-navbar/admin-navbar.component';
import {UsersComponent} from './users/users.component';
import {EditComponent} from './users/edit/edit.component';
import {EditgroupComponent} from './users/groups/editgroup/editgroup.component';
import {SearchComponent} from '../search/search.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {NgxBarcodeModule} from 'ngx-barcode';
import {NgxPrintModule} from 'ngx-print';
import {GroupsComponent} from './users/groups/groups.component';
import {OrdersCheckComponent} from '../logistics/orders-check/orders-check.component';
import {OrdersListComponent} from '../orders-list/orders-list.component';
import {OrdersListDirective} from '../orders-list/orders-list.directive';
import {ZordersListComponent} from '../logistics/zorders-list/zorders-list.component';
import {CheckComponent} from '../logistics/check/check.component';
import {CourierTarifficatorComponent} from '../logistics/courier-tarifficator/courier-tarifficator.component';
import {CourierStatusComponent} from '../logistics/courier-status/courier-status.component';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {CourierDocsComponent} from '../logistics/courier-docs/courier-docs.component';
import {UnrecognizedAddressesComponent} from '../logistics/unrecognized-addresses/unrecognized-addresses.component';
import {HttpConfigInterceptor} from '../interceptor/httpconfig.interceptor';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {AngularYandexMapsModule} from 'angular8-yandex-maps';
import {CouriersComponent} from '../staff/couriers/couriers.component';
import {PenaltiesComponent} from '../staff/penalties/penalties.component';
import { CourierCardComponent } from '../components/courier-card/courier-card.component';
import { CourierDialogComponent } from '../components/courier-card/courier-dialog/courier-dialog.component';
import {ProblemsComponent} from '../logistics/problems/problems.component';
import {CargoWorkdayComponent} from '../logistics/cargo-workday/cargo-workday.component';
import {CourierExtraExpensesComponent} from '../logistics/courier-extra-expenses/courier-extra-expenses.component'
import {PushNotificationsComponent} from '../mailer/push-notifications/push-notifications.component';
import {ReportReturnsComponent} from '../statistics/report-returns/report-returns.component';
import {BadAdressesComponent} from '../statistics/bad-adresses/bad-adresses.component';
import {SuspiciousAdressesComponent} from '../statistics/suspisious-adresses/suspisious-adresses.component';
import {CustomDateAdapter} from '../service/custom-date-adapter';
import {DateAdapter, MatOptionModule} from '@angular/material/core';
import {SberbankControlComponent} from '../logistics/sberbank-control/sberbank-control.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {ProblemCounterComponent} from './admin-navbar/problem-counter.component';
import { RolesControlComponent } from './users/roles-control/roles-control.component';
import {CouriersAssemblyComponent} from '../statistics/couriers-assembly/couriers-assembly.component';
import {ChartsModule} from '@progress/kendo-angular-charts';
import 'hammerjs'
import {CourierCalculationComponent} from '../finance/courier-calculation/courier-calculation.component';
import {MatMenuModule} from '@angular/material/menu';
import {CloseListComponent} from '../finance/close-list/close-list.component';
import {NumberSettingsComponent} from '../client-service/number-settings/number-settings.component';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {CheckingOrdersComponent} from '../finance/checking-orders/checking-orders.component';
import {RegionalOrdersComponent} from '../logistics/regional-orders/regional-orders.component';
import {RefusedOnSpotComponent} from '../logistics/refused-on-spot/refused-on-spot.component';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {CloseListLogistComponent} from '../finance/close-list-logist/close-list-logist.component';
import {MainStatComponent} from '../statistics/main-stat/main-stat.component';
import {LogsisPulseComponent} from '../statistics/logsis-pulse/logsis-pulse.component';
import {MatTooltipModule} from '@angular/material/tooltip';
import {EKassirEncashmentModalComponent} from '../finance/e-kassir-encashment-modal/e-kassir-encashment-modal.component';
import {NewsComponent} from '../client-service/news/news.component';
import {NewsEditComponent} from '../client-service/news/edit/news-edit.component';
import {EditorModule} from '@tinymce/tinymce-angular';
import {DocumentsComponent} from '../documents/documents.component';
import {MatListModule} from '@angular/material/list';
import {ClientsSendsComponent} from '../client-service/clients-sends/clients-sends.component';
import {MatCardModule} from '@angular/material/card';
import {MatChipsModule} from '@angular/material/chips';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatIconModule} from '@angular/material/icon';
import {MatRadioModule} from '@angular/material/radio';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatSelectModule} from '@angular/material/select';
import {MatButtonModule} from '@angular/material/button';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {DeliveryWarehouseComponent} from '../statistics/delivery-warehouse/delivery-warehouse.component';
import {MatTabsModule} from '@angular/material/tabs';
import {Top20DeliveryWarehouseComponent} from '../statistics/top20-delivery-warehouse/top20-delivery-warehouse.component';
import {CourierStatComponent} from '../statistics/courier-stat/courier-stat.component';
import {DailyMeetingComponent} from '../statistics/daily-meeting/daily-meeting.component';
import {ReasonsPerenosComponent} from '../statistics/reasons-perenos/reasons-perenos.component';
import {DailyMeetingClientsComponent} from '../statistics/daily-meeting/daily-meeting-clients/daily-meeting-clients.component';
import {DailyMeetingOrdersComponent} from '../statistics/daily-meeting/daily-meeting-orders/daily-meeting-orders.component';
import {CourierSalaryComponent} from '../statistics/courier-salary/courier-salary.component';
import {CourierSalaryListComponent} from '../statistics/courier-salary/courier-salary-list/courier-salary-list.component';
import {IntervalsStatDetailsComponent} from '../statistics/intervals-stat/intervals-stat-details/intervals-stat-details.component';
import {ClientsStatComponent} from '../statistics/clients-stat/clients-stat.component';
import {WarehouseStatComponent} from '../statistics/warehouse-stat/warehouse-stat.component';
import {CourierIndicatorsComponent} from '../statistics/courier-indicators/courier-indicators.component';
import {CouriersMapComponent} from '../couriers/couriers-map/couriers-map.component';
import {CouriersRouteListComponent} from '../couriers/couriers-route-list/couriers-route-list.component';
import {DocReturnComponent} from '../client-service/doc-return/doc-return.component';
import {PrintDocReturnActComponent} from '../client-service/doc-return/print-doc-return-act/print-doc-return-act.component';
import {DispatchedNotificationsComponent} from '../mailer/dispatched-notifications/dispatched-notifications.component';
import {ZordersStatComponent} from '../statistics/zorders-stat/zorders-stat.component';
import {IndicatorsImComponent} from '../statistics/Indicators-im/Indicators-im.component';
import {IntervalsStatComponent} from '../statistics/intervals-stat/intervals-stat.component';
import {NewStatComponent} from '../statistics/new-stat/new-stat.component';
import {DetmirRemainsComponent} from '../reports/detmir-remains/detmir-remains.component';
import {CouriersClaimLetterComponent} from '../couriers/couriers-claim-letter/couriers-claim-letter.component';
import {NewStatOrdersComponent} from '../statistics/new-stat/new-stat-orders/new-stat-orders.component';
import {CourierSalaryFactComponent} from '../statistics/courier-salary-fact/courier-salary-fact.component';
import {ClientsStatWithFilterComponent} from '../statistics/clients-stat-with-filter/clients-stat-with-filter.component';
import {MatSlideToggleModule} from "@angular/material/slide-toggle";

@NgModule({
    declarations: [
        UsersComponent,
        EditComponent,
        GroupsComponent,
        EditgroupComponent,
        AdminNavbarComponent,
        AdminHeaderComponent,
        SearchComponent,
        OrdersCheckComponent,
        OrdersListComponent,
        OrdersListDirective,
        ZordersListComponent,
        CourierStatusComponent,
        UnrecognizedAddressesComponent,
        CourierDocsComponent,
        CheckComponent,
        CourierTarifficatorComponent,
        CouriersComponent,
        PenaltiesComponent,
        CourierCardComponent,
        CourierDialogComponent,
        ProblemsComponent,
        CargoWorkdayComponent,
        PushNotificationsComponent,
        ReportReturnsComponent,
        LogsisPulseComponent,
        SberbankControlComponent,
        ProblemCounterComponent,
        RolesControlComponent,
        CouriersAssemblyComponent,
        CourierCalculationComponent,
        CloseListComponent,
        NumberSettingsComponent,
        ClientsSendsComponent,
        NewsComponent,
        NewsEditComponent,
        CheckingOrdersComponent,
        RefusedOnSpotComponent,
        RegionalOrdersComponent,
        CloseListLogistComponent,
        EKassirEncashmentModalComponent,
        CourierExtraExpensesComponent,
        SuspiciousAdressesComponent,
        DeliveryWarehouseComponent,
        Top20DeliveryWarehouseComponent,
        ReasonsPerenosComponent,
        MainStatComponent,
        CourierStatComponent,
        CourierSalaryComponent,
        CourierSalaryListComponent,
        IntervalsStatComponent,
        IntervalsStatDetailsComponent,
        BadAdressesComponent,
        DocumentsComponent,
        DocReturnComponent,
        PrintDocReturnActComponent,
        DailyMeetingComponent,
        DailyMeetingClientsComponent,
        ClientsStatComponent,
        ClientsStatWithFilterComponent,
        DailyMeetingOrdersComponent,
        WarehouseStatComponent,
        CourierSalaryFactComponent,
        CourierIndicatorsComponent,
        CouriersMapComponent,
        CouriersRouteListComponent,
        CouriersClaimLetterComponent,
        DispatchedNotificationsComponent,
        ZordersStatComponent,
        IndicatorsImComponent,
        NewStatComponent,
        NewStatOrdersComponent,
        DetmirRemainsComponent
    ],
    imports: [
        CommonModule,
        GridModule,
        RowFilterModule,
        MDBBootstrapModule.forRoot(),
        ComponentsModule,
        StorageModule,
        WindowModule,
        NgMultiSelectDropDownModule,
        AdminRoutingModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        NgxBarcodeModule,
        MatInputModule,
        AngularYandexMapsModule,
        NgMultiSelectDropDownModule,
        NgxPrintModule,
        DragDropModule,
        ChartsModule,
        MatTabsModule,
        MatMenuModule,
        MatButtonToggleModule,
        MatSnackBarModule,
        MatTooltipModule,
        BodyModule,
        EditorModule,
        MatListModule,
        MatCardModule,
        MatChipsModule,
        MatOptionModule,
        MatAutocompleteModule,
        MatIconModule,
        MatRadioModule,
        MatCheckboxModule,
        MatSelectModule,
        MatButtonModule,
        NgxMatFileInputModule,
        MatSlideToggleModule
    ],
    providers: [
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        {
            provide: HTTP_INTERCEPTORS,
            multi: true,
            useClass: HttpConfigInterceptor,
        },
        {
            provide: MAT_TABS_CONFIG,
            useValue: {
                animationDuration: '0ms'
            }
        },
        {
            provide: DateAdapter,
            useClass: CustomDateAdapter
        },
        [
            {
                provide: MAT_DATE_LOCALE,
                useValue: 'ru-RU'
            }
        ]
    ],
    exports: [
        AdminNavbarComponent,
        AdminHeaderComponent,
    ],
    entryComponents: [
        CourierDialogComponent,
        EKassirEncashmentModalComponent,
        MainStatComponent,
        DailyMeetingClientsComponent,
        NewStatOrdersComponent,
        DailyMeetingOrdersComponent,
        CourierSalaryListComponent,
        IntervalsStatDetailsComponent
    ]
})

export class AdminModule {
}
