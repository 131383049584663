import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-label-print-list-first',
    templateUrl: './label-print-list-first.component.html',
    styleUrls: ['./label-print-list-first.component.scss']
})
export class LabelPrintListFirstComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
