import {Component, OnInit, ViewChild} from '@angular/core';
import {PageChangeEvent, RowClassArgs} from '@progress/kendo-angular-grid';
import {LaraService} from '../../service/lara.service';
import {HelpersService} from '../../service/helpers.service';
import {EventerService} from '../../service/eventer.service';
import {NotifierService} from '../../service/notifier.service';
import {DialogService} from '../../components/dialog/dialog.service';
import {Hub, User, UserHub} from "../../service/models";
import {CurrentUserService} from "../../service/current-user.service";
import {Subject} from "rxjs";
import {FormControl, FormGroup} from "@angular/forms";
import {HubsService} from "../../service/hubs.service";
import {DatePipe} from "@angular/common";

@Component({
    selector: 'app-problems',
    templateUrl: './problems.component.html',
    styleUrls: ['./problems.component.scss']
})
export class ProblemsComponent implements OnInit {

    @ViewChild('closeModal') private closeModal;
    public problemList: any;
    public substatuses: any;
    public substatus: any;
    public problemIndex: any;
    public filter: any;
    public filterLoader = false;
    public order;
    public cancelLoader = false;
    public cancelOrderId = '';
    public loading;
    public work_status = 'all';
    public state = {take: 50, skip: 0, filter: ''};

    public selectedHubId: number;
    public selectedHubs: UserHub[];

    public userHubs: UserHub[];

    public selectedUser: User = null;
    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public formFilter: FormGroup;

    constructor(
        private datePipe: DatePipe,
        protected api: LaraService,
        protected helper: HelpersService,
        protected eventer: EventerService,
        public dialog: DialogService,
        private currentUserService: CurrentUserService,
        private notifier: NotifierService,

    ) {
        this.eventer.apiError.subscribe(data => {
            this.setLoadingParameter();
        });

        this.eventer.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });

        const today = new Date();
        this.formFilter = new FormGroup({
            date: new FormControl(today),
        });

    }

    public hideModal() {
        this.closeModal.nativeElement.click();
    }

    setPredstatus(e) {
        this.filter = e.target.value;
        this.state.skip = 0;
        this.getProblemList();
    }

    setOrderFilter(e) {
        this.order = e.target.value;
    }

    ngOnInit() {


        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            let hub = this.currentUserService.getCurrentHub();

            this.selectedHubs = [hub,];
            this.selectedHubId = hub.id;

            this.getProblemList();
            this.getOrderCancelSubstatuses();
        });
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    getOrderCancelSubstatuses() {
        this.api.getOrderCancelSubstatuses().subscribe((data: any) => {
            this.substatuses = data;
        });
    }

    setLoadingParameter() {
        for (const problem of this.problemList) {
            problem.loading = 0;
        }
    }

    setLoading(index, status) {

        this.problemList.data[index]['loading'] = status;
    }

    makeCall(phone) {
        this.api.makeCall(phone).subscribe(data => {
            console.log(data);
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000})
        })
    }


    getProblemList() {
        this.loading = true;
        const filter: any = {};
        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.type_id = this.filter ? this.filter : 0;
        filter.work_status = this.work_status;
        filter.hub_id = this.selectedHubId;
        filter.courier_id = this.selectedUser ? this.selectedUser.id : 0;
        filter.date = this.datePipe.transform(this.formFilter.value.date, 'yyyy-MM-dd');
        console.log(filter);
        if (this.order) {
            filter.order_id = this.order;
        }
        this.api.getProblemList(filter).subscribe(data => {
            this.loading = false;
            this.problemList = data;
            this.problemList.from = 0;
            this.problemList.last_page = 0;
            this.problemList.last_page = 0;
            this.problemList.data.forEach(function (problem) {
                if (problem.processed_time) {
                    problem.processed_time = problem.processed_time.split(' ', 2)[1];
                }
            });
        })
    }


    onSelectUser(user: User) {
        if (user) {
            this.selectedUser = user;
        } else {
            this.selectedUser = null;
        }
    }

    getKgtInWork(id, index) {
        this.setLoading(index, 1);
        this.api.getKgtInWork(id).subscribe(data => {
            this.setLoading(index, 0);
            this.problemList.data[index].manager_id = data.user.id;
            this.problemList.data[index].manager = data.user;
        })
    }

    process(manager, problemId, index) {

        index = index - this.state.skip;


        this.setLoading(index, 1);
        if (manager) {     // Решить проблему
            this.api.resolveProblem(problemId).subscribe(data => {
                // this.arrayChanges('delete', index, data);
                this.getProblemList();
                this.setLoading(index, 0);
            })
        } else {            // Взять в работу
            this.api.getProblemInWork(problemId).subscribe(data => {

                this.arrayChanges('inWork', index, data);
                this.setLoading(index, 0);
            })
        }
    }

    cancelOrder(orderId) {
        this.cancelLoader = true;
        if (orderId) {
            this.api.setCancelSubstatus([orderId], this.substatus).subscribe(data => {
                this.cancelLoader = false;

                if (data.message) {
                    this.dialog.alert(data.message, 500);
                }

                this.getProblemList();
                this.hideModal();
            })
        }
    }

    getSubstatus(e) {
        this.substatus = e.target.value;
    }

    arrayChanges(move, index, result) {
        if (move === 'delete') {
            this.problemList.data.splice(index, 1);
        }
        if (move === 'inWork') {
            this.problemList.data[index].manager_id = result.manager_id;
            this.problemList.data[index].manager = result.manager;
        }
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        this.state.skip = skip;
        this.state.take = take;
        this.getProblemList();
    }

    public rowCallback(context: RowClassArgs) {
        if (context.dataItem.order.is_important) {
            return 'is_important';
        }
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;
        this.appHubChangeEventSubjectEmit();
        this.getProblemList();
    }

    appHubChangeEventSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }
}
