import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class DateHelper {
    constructor(private datePipe: DatePipe) {
    }

    /**
     * Возвращает все дни переданного интервала
     * @param dateStart
     * @param dateEnd
     */
    public returnIntervalDays(dateStart: Date, dateEnd: Date) {
        if (dateStart > dateEnd) {
            return [];
        }

        const reportDates = [];
        while (dateStart <= dateEnd) {
            reportDates.push(this.datePipe.transform(dateStart, 'yyyy-MM-dd'));
            dateStart.setDate(dateStart.getDate() + 1);
        }

        return reportDates;
    }
}
