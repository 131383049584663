import {Component, OnInit} from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {HelpersService} from '../../service/helpers.service';
import {ClientService} from '../../service/client.service';

@Component({
    selector: 'app-news',
    templateUrl: './news.component.html',
    styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {
    public gridData: GridDataResult;
    private pageTitle = 'Новости';
    public headerFilter: any = [];
    public pageSize = 20;
    public skip = 0;
    public selectedNews: string[] = [];
    public loadingNews;

    constructor(
        private api: ClientService,
        public helpers: HelpersService
    ) {

    }

    ngOnInit() {
        this.loadNews();
    }

    /**
     * Смена страницы
     * @param skip
     * @param take
     */
    public pageChange({skip, take}: PageChangeEvent): void {
        this.skip = skip;
        this.pageSize = take;
        this.loadNews();
    }

    private loadNews() {
        this.loadingNews = true;
        this.selectedNews = [];
        const query = {
            'pageSize': this.pageSize,
            'skip': this.skip,
        };

        this.api.getNews(query).subscribe(data => {
            this.loadingNews = false;
            this.gridData = {data: data.data, total: data.total};
        });
    }
}
