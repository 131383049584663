import {Component, ElementRef, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {HubDeliveryInterval, Order, OrderZone, User} from '../../../service/models';
import {CurrentUserService} from '../../../service/current-user.service';
import {DatePipe} from '@angular/common';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LaraService} from '../../../service/lara.service';
import * as $ from 'jquery';
import {DaDataConfig, DaDataType} from '@kolkov/ngx-dadata';
import {environment} from '../../../../environments/environment';
import {DialogService} from '../../../components/dialog/dialog.service';
import {OrderCardComponent} from '../order-card.component';
import {HelpersService} from '../../../service/helpers.service';
import {NotifierService} from '../../../service/notifier.service';
import {HubsService} from '../../../service/hubs.service';
import {AddressService} from '../../../service/address.service';


@Component({
    selector: 'app-order-dialog',
    templateUrl: './order-dialog.component.html',
    styleUrls: ['./order-dialog.component.scss']
})
export class OrderDialogComponent implements OnInit {


    @Output() onClosed = new EventEmitter<boolean>();
    @ViewChild('closeModal') private closeModal;
    @ViewChild('emailReceipt') protected emailReceipt: ElementRef;
    @ViewChild('mobileNumberReceipt') protected mobileNumberReceipt: ElementRef;

    @ViewChild('goodNameMark') public goodNameMark: ElementRef;
    @ViewChild('goodMark') public goodMark: ElementRef;

    // Переменные для проверки ПРАВ
    public rights = {
        importantRed: false,         // Кнопки редактирования Важности
        indexRed: false,             // Кнопка редактирования Индекса
        addressRed: false,           // Кнопка редактирования Адреса
        commentRed: false,           // Кнопка редактирования Комментария
        commentKsRed: false,         // Кнопка редактирования Комментария КС
        delivery_dateRed: false,     // Кнопка редактирования Даты Доставки
        delivery_timeRed: false,     // Кнопка редактирования Времени Доставки
        courierRed: false,           // Кнопка редактирования Курьера
        weightRed: false,            // Кнопка редактирования Веса
        dimensionsRed: false,        // Кнопка редактирования Веса
        paymentTypeRed: false,       // Кнопка редактирования Заказа и ТП
        dopinfoCat: false,           // Кнопка ДОПИНФО
        fullHistory: false,          // кнопка полная история заказа
        sendSmsCat: false,           // Кнопка Отправить СМС
        sendReceipt: false,          // Кнопка Отправить чек клиенту
        modalOrdersBtnRed: false,    // Кнопка модальное окно редактирования заказа
        modalOrdersRedStatus: false, // Сброс статуса
        modalOrdersRed: false,       // Модальное окно редактирования заказа
        specialLogs: false,          // Спец логи
        recalcBtn: false,            // Пересчёт заказа
        checkFix: false,             // Исправление чеков
        editRecipientName: false,    // Редактирование фактического получателя заказа
        toggleWeightValidated: false, // Вес проверен
        toggleAddressValidated: false, // Вес проверен
        reDelivery: false            // Повторная доставка
    }
    // КОНЕЦ ПЕРЕМННЫХ ДЛЯ ПРОВЕРКИ ПРАВ

    public parent: OrderCardComponent;

    public index = 0;
    public width;
    public saving = false;
    public order: any;
    public orderModificated: any;
    public goodsCopy: any;
    public user: User;
    public zone: OrderZone;
    public Logs = [];
    public BarCodeScanLog = [];
    public orders = [];
    public paymentData: any;
    public EditOrder: FormGroup;
    public sendMessage: FormGroup;
    public signOnMail: FormGroup;
    public dimensions: string[] = [];
    public substatuses;
    public intervalSubstatuses: [];

    public changeAddressSubstatuses: [];

    public documents = null;

    public barcode_id = new Array;
    public selectedOrder;
    public selectedCourier;
    public loadBtn = false;
    public loadImportant = false;
    public loadStatus = false;
    public loadSms = false;
    public hideBtn = false;
    public opened = false;
    public signatures = [];
    public selectedSignatures = [];
    public isLoadSign = false;
    public intervalTabs = 1;
    public resentTypeTabs = 1;
    public saveBtn = false;
    public configDaData: DaDataConfig = {
        apiKey: environment.dadata_api_key,
        type: DaDataType.address
    };

    public returnActs: string[] = [];

    private value: any = {};
    showMyContainer = false;
    showLogs = false;
    LoadLogs = false;
    BarCodeScanLogLoaded = false;
    loadRecalc = false;
    modalEdit = '';
    num = 0;
    transitInfo: any;
    optionsArray = {};
    newStatus;
    courMesTemps;
    push_header;
    push_desc;
    /* Для чеков */
    receiptsList;
    receiptsListDescription;
    checkLoad = false;
    public changeGood = false;
    public changeOpt = false;
    public kgt_lifting = 1; // значение по умолчанию в диалоге подъем КГТ

    public receiptUrl: string = null; // url чека
    public receiptUrlShort: string = null; // короткий url чека
    public receiptUrlExist = false; // url чека существует?

    public hubDeliveryIntervals: HubDeliveryInterval[] = [];
    public invalidDeliveryInterval = false;
    public selectedDeliveryTime: number = null;

    public orderTransits = [];
    public orderTransitsLoading = false;

    public toggleWeightValidatedConfirmed = false;
    public toggleAddressValidatedConfirmed = false;

    public postmanBulkEdit = {};
    public postmanBulkEditLines = {};
    public postmanBulkEditLoad = {};

    public smsTemplates = [];

    public locationMapVisible = true;
    public locationMapOpened = false;
    public loadingLocationAddressRequiresCall = false;

    MASTER_DOCS = 'master_docs';
    MASTER_DOCS_RETURN = 'master_docs_return';

    constructor(
        protected app: AppComponent,
        protected currentUser: CurrentUserService,
        private datePipe: DatePipe,
        protected api: LaraService,
        protected apiAddress: AddressService,
        public dialog: DialogService,
        public helper: HelpersService,
        private hubsService: HubsService,
        private notifier: NotifierService
    ) {
        this.currentUser.get().subscribe((user: User) => {
            this.user = user;
        });
        this.width = 1000;

        this.EditOrder = new FormGroup({
            address: new FormControl(),
            post_code: new FormControl(),
            delivery_time: new FormControl(1),
            delivery_time1: new FormControl('10'),
            delivery_time2: new FormControl('14'),
            delivery_date: new FormControl(),
            is_sameday: new FormControl(),
            redelivery_date: new FormControl(),
            substatus: new FormControl(),
            dim1: new FormControl(),
            dim2: new FormControl(),
            dim3: new FormControl(),
            weight: new FormControl(),
            operator_comment: new FormControl(),
            client_comment: new FormControl(),
            status: new FormControl(),
            change_address_reason: new FormControl(),
            // RESEARCH-458
            interval_status: new FormControl(65),
            kgt_lift: new FormControl(),
            kgt_floor: new FormControl(),
            kgt_lifting: new FormControl(),
            // фактический получатель заказа
            recipient_name: new FormControl(''),
            recipient_name_clear: new FormControl(false),
            // Отправка чека клиенту
            email_resent_receipt: new FormControl(),
            number_resent_receipt: new FormControl()
        });
        this.sendMessage = new FormGroup({
            message: new FormControl()
        });

        this.signOnMail = new FormGroup({
            email: new FormControl('', Validators.required),
        });
    }

    ngOnInit() {
        this.checkRights();
        if (!this.helper.checkPermissions('orders:view')) {
            this.onClose();
        }
        ;
        this.hubDeliveryIntervals = this.order.hub_destination.delivery_intervals;

        this.opened = true;
        $.getScript('assets/js/tabs.js');
        this.setNums();
        this.setReceipt();
        this.getOrderSubstatuses();
        this.orderModificated = this.order;
        this.setGoodsCopy();
        this.getGoodPlaces();
        // платежи по карте показываем в любом случае
        this.getCardPayments();

        this.getOrderSignature();
        this.getTemplatesForCour();

        this.setEditOrderRecipientName();
        this.updateFormValues();
    }

    getTemplatesForCour() {
        this.api.getPushTemplates().subscribe(data => {
            this.courMesTemps = data;
            this.courMesTemps = this.courMesTemps.filter(x => x.topic === 'Клиентский отдел');
        })
    }

    setTemplate(header, desc) {
        this.push_header = header;
        this.push_desc = this.order.uid + ': ' + this.assignTemplateVars(desc);
    }

    assignTemplateVars(desc) {
        desc = desc.replaceAll('{delivery_interval}', this.checkInterval());

        return desc;
    }

    updTemplate(e, update) {
        switch (update) {
            case 'push_header': {
                this.push_header = e.target.value;
                break;
            }
            case 'push_desc': {
                this.push_desc = e.target.value;
                break;
            }
            default: {
                break;
            }
        }
    }

    /**
     * Исправление чеков
     */
    correctReceipts() {
        this.checkLoad = true;
        this.api.correctReceipts(this.order.uid).subscribe(data => {
            this.receiptsList = data;
            this.checkLoad = false;
        })
    }

    /**
     * Получение чеков по заказу
     */
    getOrderReceipts() {
        this.api.getOrderReceipts(this.order.uid).subscribe(data => {
            this.receiptsList = data;
            this.receiptsListDescription = data.needReceiptDescription;

            for (const receipt of this.receiptsList.receipts) {
                if (receipt.type_key === 'return') {
                    receipt.fill_class = 'alert-info';
                } else if (receipt.canceled_by) {
                    receipt.fill_class = 'alert-danger'
                } else {
                    receipt.fill_class = '';
                }
            }
        })
    }

    /**
     * -----------
     */

    setNums() {
        this.returnActs = [];
        let k = 1;
        for (let i = 0; i < this.order.bar_codes.length; i++) {
            for (let j = 0; j < this.order.bar_codes[i].goods.length; j++) {
                this.order.bar_codes[i].goods[j].index = k;
                k++;
            }
        }

        for (let i = 0; i < this.order.all_goods.length; i++) {
            if (this.order.all_goods[i].return_act) {
                this.add2Acts(this.order.all_goods[i].return_act.old_vact_id);
            }
        }
    }

    makeCall(phone) {
        this.api.makeCall(phone).subscribe(data => {
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000});
        })
    }

    sendMessagge(withParams = false, isSendOnEmail?, contactData?: string) {
        this.loadSms = true;

        if (!withParams) {
            this.api.sendOrderMessage(this.order.uid, this.sendMessage.value.message).subscribe(data => {
                alert('Сообщение отправлено!')
                this.sendMessage.patchValue({
                    message: ''
                })
                this.loadSms = false;
            }, error => {
                this.loadSms = false;
            })
        } else {
            if (isSendOnEmail) {
                this.api.sendOrderMessage(
                    this.order.uid,
                    this.sendMessage.value.message,
                    true,
                    isSendOnEmail,
                    contactData,
                    'Чек по заказу № ' + this.order.inner_n
                ).subscribe(data => {
                    alert('Сообщение на почту отправлено!')
                    this.sendMessage.patchValue({
                        message: ''
                    })
                    this.loadSms = false;
                }, error => {
                    this.loadSms = false;
                })
            }
            if (!isSendOnEmail) {
                this.api.sendOrderMessage(
                    this.order.uid,
                    this.sendMessage.value.message,
                    true,
                    isSendOnEmail,
                    contactData
                ).subscribe(data => {
                    alert('Сообщение на телефон отправлено!')
                    this.sendMessage.patchValue({
                        message: ''
                    })
                    this.loadSms = false;
                }, error => {
                    this.loadSms = false;
                })
            }
        }
    }

    setMessageValue(e) {
        this.sendMessage.patchValue({
            message: e.target.value
        })
    }

    add2Acts(act_id) {
        if (this.returnActs.indexOf(act_id) < 0) {
            this.returnActs.push(act_id);
        }
    }

    checkStatus(status: number) {
        switch (status) {
            case 1: {
                return 'Новая заявка';
            }
            case 2: {
                return 'Заявка принята';
            }
            case 3: {
                return 'На складе';
            }
            case 4: {
                return 'На доставке';
            }
            case 5: {
                return 'Доставлен';
            }
            case 6: {
                return 'Частичный отказ';
            }
            case 7: {
                return 'Полный отказ';
            }
            case 8: {
                return 'Отмена';
            }
            case 9: {
                return 'В перемещении';
            }
            default: {
                console.log('Invalid choice');
            }
        }
    }


    saveChanges() {
        const query = this.EditOrder.value;
        if (this.modalEdit === 'address') {
            if (query.address && query.address.length >= 7 && query.change_address_reason) {
                return true;
            }
        } else if (this.modalEdit === 'weight') {
            if (query.weight) {
                return true;
            }
        } else if (this.modalEdit === 'toggleWeightValidated') {
            if (this.toggleWeightValidatedConfirmed) {
                return true;
            } else {
                return false;
            }
        } else if (this.modalEdit === 'toggleAddressValidated') {
            if (this.toggleAddressValidatedConfirmed) {
                return true;
            } else {
                return false;
            }
        } else if (this.modalEdit === 'editRecipientName') {
            if (this.EditOrder.value.recipient_name.trim().length || this.EditOrder.value.recipient_name_clear) {
                return true;
            } else {
                return false;
            }
        } else if (this.modalEdit === 'interval') {
            if (this.intervalTabs === 1) {
                if (query.interval_status && query.delivery_time) {
                    return true;
                }
            }
            if (this.intervalTabs === 2) {
                if (query.interval_status && query.delivery_time1 && query.delivery_time2) {
                    return true;
                }
            }
        } else if (this.modalEdit === 'sendReceipt') {
            if (this.resentTypeTabs === 1) {
                if (this.emailReceipt &&
                    this.emailReceipt.nativeElement.value !== 'undefined' &&
                    this.emailReceipt.nativeElement.value) {
                    const value = this.emailReceipt.nativeElement.value;
                    if (value.length >= 1) {
                        return true;
                    }
                }
            }
            if (this.resentTypeTabs === 2) {
                if (this.mobileNumberReceipt &&
                    this.mobileNumberReceipt.nativeElement.value !== 'undefined' &&
                    this.mobileNumberReceipt.nativeElement.value) {
                    const value = this.mobileNumberReceipt.nativeElement.value;
                    if (value.length >= 1) {
                        return true;
                    }
                }
            }
        } else if (this.modalEdit === 'delivery_date') {
            if ((query.delivery_date || query.is_sameday) && query.substatus) {
                return true;
            }
        } else if (this.modalEdit === 'courier') {
            if (this.selectedCourier) {
                return true;
            }
        } else if (this.modalEdit === 'courierMessage') {
            if (this.push_header && this.push_desc) {
                return true;
            }
        } else if (this.modalEdit === 'operator_comment') {
            if (query.operator_comment) {
                return true;
            }
        } else if (this.modalEdit === 'client_comment') {
            if (query.client_comment) {
                return true;
            }
        } else if (this.modalEdit === 'post_code') {
            if (query.post_code.length === 6) {
                return true;
            }
        } else if (this.modalEdit === 'reDelivery') {
            if (query.redelivery_date) {
                return true;
            }
        } else {
            return true;
        }
    }


    LogsData() {
        this.api.getLogs(this.order.uid).subscribe((data: any) => {
            this.Logs = data;
            this.LoadLogs = true;
            for (const log of this.Logs) {
                if (log.timestamp) {
                    log.timestamp = this.datePipe.transform(log.timestamp, 'dd.MM.yyyy HH:mm:ss')
                }
            }
            for (const log of this.Logs) {
                // TODO - перенести в бэкенд
                if ((log.what === 'Новая система: Массовая смена курьера') || (log.what === 'Новая система: Назначение курьера')
                    || (log.what === 'CRM: Назначение курьера')
                    || (log.what === 'CRM: Массовая смена курьера')) {
                    this.api.getCourierFullName(log.new_val).subscribe(rez => {
                        log.new_val = rez.name;
                    })
                }
                if (log.who === 0) {
                    log.who = '';
                }
            }

            this.historyClick();

        });
    }

    /**
     * Проверяет является ли объект форматом Json
     * @param data
     */
    isJson(data) {
        try {
            // TODO переделать на отправку информации json не json с бэкэнда (дима)
            if (data[0] !== '{' || data.substring(0, 5) !== 'Array') {
                return false;
            }
            if (JSON.parse(data)) {
                return true;
            }
        } catch (e) {
            return false;
        }
    }

    /**
     * Парсит Json
     * @param data
     */
    jsonParse(data) {
        return JSON.parse(data);
    }

    getBarCodeScanLog() {
        this.api.getBarCodeScanLog(this.order.uid).subscribe((data: any) => {
            this.BarCodeScanLog = data;
            this.BarCodeScanLogLoaded = true;
        });
    }

    getDocuments() {
        this.api.getDocuments(this.order.uid).subscribe((data: any) => {
            this.documents = data;
        });
    }

    checkInterval() {
        if (this.order.delivery_time) {
            const deliveryIntervalId = parseInt(this.order.delivery_time, 10);
            const deliveryInterval = this.order.hub_destination.delivery_intervals.find((interval) => {
                return interval.id === deliveryIntervalId;
            });

            if (deliveryInterval) {
                this.invalidDeliveryInterval = false;
                return deliveryInterval.label;
            }

            this.invalidDeliveryInterval = true;
            return 'Не передали интервал';
        } else {
            return this.order.delivery_time_start + ':00-' + this.order.delivery_time_end + ':00';
        }
    }

    option_opening(option: number) {
        switch (option) {
            case 1: {
                return 'Разрешено';
            }
            case 2: {
                return 'Только внешней упаковки заказа';
            }
            case 3: {
                return 'Запрещено';
            }
            default: {
                return 'Не указано';
            }
        }
    }

    ndsName(nds: number) {
        switch (nds) {
            case 1: {
                return 'HДС 18%';
            }
            case 2: {
                return 'Без HДС';
            }
            case 3: {
                return 'HДС 10%';
            }
            case 4: {
                return 'HДС 18/118';
            }
            case 5: {
                return 'HДС 10/110';
            }
            case 6: {
                return 'НДС 0%';
            }
            case 7: {
                return 'НДС 20%';
            }
            case 8: {
                return 'НДС 20/120';
            }
            default: {
                return 'Н/У';
            }
        }
    }

    getOrderSubstatuses() {
        this.api.getChangeDateSubstatuses().subscribe((data: any) => {
            this.substatuses = data;
            for (let i = 0; i < this.substatuses.length; i++) {
                if (this.substatuses[i].substatus_id === 0) {
                    this.substatuses.splice(i, 1);
                    break;
                }
            }
        });
        this.api.getOrderIntervalSubstatuses().subscribe((data: any) => {
            this.intervalSubstatuses = data;
        });
        this.api.getSubstatusesByType(7).subscribe((data: any) => {
            this.changeAddressSubstatuses = data;
        });
    }


    getOrderSignature() {
        this.api.getOrderSignatures(this.order.uid).subscribe(data => {
            console.log(data);
        })
    }

    getCardPayments() {
        this.api.getOrderCardPayments(this.order.id).subscribe(data => {
            this.paymentData = data;
        });
    }

    changeStatus(e) {
        this.newStatus = e.target.value;
    }

    setNewStatus() {
        this.loadStatus = true;
        this.saving = true;
        if (this.newStatus === 3) {
            this.api.setStatusStorage(this.order.uid).subscribe(data => {
                this.finishSave(data);
                this.setGoodsCopy();
                this.loadStatus = false;
                this.newStatus = 0;
            }, error => {
                this.loadStatus = false;
                this.saving = false;
            })
        } else if (this.newStatus === 4) {
            this.api.setStatusDelivery(this.order.uid).subscribe(data => {
                this.finishSave(data);
                this.setGoodsCopy();
                this.loadStatus = false;
                this.newStatus = 0;
            }, error => {
                this.loadStatus = false;
                this.saving = false;
            })
        } else {
            alert('Статус не выбран');
        }
    }

    calcPrice(goods) {
        let sum = 0;
        for (let i = 0; i < goods.length; i++) {
            if (!goods[i].is_cancel) {
                if (!goods[i].price || !goods[i].count) {
                    continue;
                }
                sum += goods[i].price * goods[i].count;
            }
        }
        return sum;
    }

    getPlace(good, barcodes) {
        const places = [];
        for (let i = 0; i < barcodes.length; i++) {
            places[i].concat(barcodes[i])
        }
        return places;
    }


    itog(price: number, counts: number) {
        const allPrice = price * counts;
        return allPrice;
    }

    public hideModal() {
        this.closeModal.nativeElement.click();
    }

    public showEdit(what: string) {
        // копия интервала доставки для модального окна
        this.selectedDeliveryTime = this.order.delivery_time;

        this.saving = false;
        this.modalEdit = what;
    }

    public async finishSave(data) {
        data = await this.hubsService.defineOrderHubs(data);
        this.hubDeliveryIntervals = data.hub_destination.delivery_intervals;

        const deliveryIntervalId = data.delivery_time;
        const deliveryIntervalValid = this.hubDeliveryIntervals.find((interval) => {
            return interval.id === deliveryIntervalId;
        });

        if (!deliveryIntervalValid) {
            this.invalidDeliveryInterval = true;
        } else {
            this.invalidDeliveryInterval = false;
        }

        this.order = data;
        this.setNums();
        this.hideModal();
        this.checkRights();
        this.checkInterval();
        this.setEditOrderRecipientName();
        this.updateFormValues();

        this.saving = false;
    }

    updateFormValues() {
        this.EditOrder.get('is_sameday').setValue(this.order.is_sameday);
    }

    updateOrder(setting) {
        const query = this.EditOrder.value;


        this.dimensions = [
            (query.dim1 != null) ? query.dim1 : this.order.option.dimension_side1,
            (query.dim2 != null) ? query.dim2 : this.order.option.dimension_side2,
            (query.dim3 != null) ? query.dim3 : this.order.option.dimension_side3
        ];
        const address = query.address;
        this.saving = true;
        console.log('enable saving');
        switch (setting) {
            case 'address': {
                if (address) {
                    if (address.length <= 7) {
                        alert('Адрес слишком короткий, данные не сохранены')
                        break;
                    }
                    if (!query.change_address_reason) {
                        alert('Не указана причина');
                        break;
                    }
                    this.api.setAddressOrder(this.order.uid, query.address, query.change_address_reason).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }
            case 'weight': {
                if (query.weight) {
                    this.api.setWeightOrder(this.order.uid, query.weight).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }

                break;
            }
            case 'toggleWeightValidated': {
                if (this.toggleWeightValidatedConfirmed) {
                    this.api.setOrderWeightValidated(this.order.uid, !this.order.option.weight_validated).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }

                this.toggleWeightValidatedConfirmed = false;

                break;
            }
            case 'toggleAddressValidated': {
                if (this.toggleAddressValidatedConfirmed) {
                    this.api.setOrderAddressValidated(this.order.uid).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }

                //   this.toggleWeightValidatedConfirmed = false;

                break;
            }
            case 'editRecipientName':
                this.api.updateRecipientName(
                    this.order.uid,
                    this.EditOrder.value.recipient_name,
                    this.EditOrder.value.recipient_name_clear
                ).subscribe(data => {
                    this.finishSave(data);
                }, error => {
                    this.saving = false;
                });

                break;
            case 'dimension': {
                if (this.dimensions) {
                    this.api.setDimensionOrder(this.order.uid, this.dimensions).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'interval': {
                if (!query.interval_status) {
                    alert('Выберите причину смены интервала');
                    this.saving = false;
                    return;
                }
                // записываем значение из переменной связанной с модальным окном
                query.delivery_time = this.selectedDeliveryTime;
                if ((query.delivery_time) || (query.delivery_time1 && query.delivery_time2)) {
                    if (this.intervalTabs === 1) {
                        query.delivery_time = query.delivery_time ? query.delivery_time : this.order.delivery_time
                        this.api.setIntervalOrder(this.order.uid, query.delivery_time, query.interval_status).subscribe(data => {
                            this.finishSave(data);
                        }, error => {
                            this.saving = false;
                        });
                    } else if (this.intervalTabs === 2) {
                        this.api.setCustomInterval(this.order.uid, query.delivery_time1, query.delivery_time2,
                            query.interval_status).subscribe(data => {
                            this.finishSave(data);
                        }, error => {
                            this.saving = false;
                        })
                    }
                } else {
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }
            case 'delivery_date': {
                if (!query.substatus) {
                    return;
                }

                if (query.is_sameday) {
                    this.api.setSamedayDelivery(this.order.uid, {'substatus': query.substatus})
                        .subscribe(data => {
                            this.finishSave(data);
                        }, error => {
                            this.saving = false;
                        })

                    return;
                }

                if (query.delivery_date) {

                    this.api.setDeliveryDateOrder(this.order.uid, this.datePipe.transform(
                        query.delivery_date, 'yyyy-MM-dd'), query.substatus).subscribe(data => {
                        this.finishSave(data);
                        this.parent.DialogOnTransfer();
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }
            case 'sendReceipt': {
                if (query.email_resent_receipt || query.number_resent_receipt) {
                    if (this.resentTypeTabs === 1) {
                        /* Почта */
                        this.sendMessage.value.message = 'Чек по вашему заказу № ' + this.order.inner_n + ' ' + this.receiptUrl;
                        this.sendMessagge(true, true, query.email_resent_receipt);
                    }
                    if (this.resentTypeTabs === 2) {
                        /* Телефон */
                        this.sendMessage.value.message = 'Чек по заказу ' + this.order.inner_n + ' ' + this.receiptUrlShort;
                        this.sendMessagge(true, false, query.number_resent_receipt);
                    }
                    this.saving = false;
                    this.hideModal();
                }
                break;
            }
            case 'reDelivery': {
                if (query.redelivery_date) {
                    this.api.reDeliveryOrder(this.order.uid, this.datePipe.transform(
                        query.redelivery_date, 'yyyy-MM-dd')).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    })
                }
                break;
            }
            case 'courier': {
                const order = [this.order.uid];
                if (this.selectedCourier) {
                    this.api.updateCourierOrder(order, this.selectedCourier.id).subscribe(data => {
                        this.finishSave(data.changedOrders[0]);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'courierMessage': {
                if (this.push_header && this.push_desc) {
                    this.api.sendPushNotification(this.order.courier_id, this.push_header, this.push_desc)
                        .subscribe(data => {
                            this.hideModal();
                            this.saving = false;
                        }, error => {
                            this.saving = false;
                        })
                }
                break;
            }
            case 'courierMessageSMS': {
                if (this.push_header && this.push_desc) {
                    this.api.sendCourierSmsNotification([this.order.courier_id], this.push_desc, this.order.uid)
                        .subscribe(data => {
                            this.hideModal();
                            this.saving = false;
                        }, error => {
                            this.saving = false;
                        })
                }
                break;
            }
            case 'operator_comment': {
                if (query.operator_comment) {
                    this.api.setOperatorComment(this.order.uid, query.operator_comment).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'client_comment': {
                if (query.client_comment) {
                    const dataq = {target_notes: query.client_comment}
                    this.api.editOrder(this.order.uid, dataq).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            case 'post_code': {
                if (query.post_code && query.post_code.length === 6) {
                    const dataq = {post_code: query.post_code}
                    this.api.editOrder(this.order.uid, dataq).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                } else {
                    alert('Указан некорректный индекс');
                    this.saving = false;
                }
                break;
            }
            case 'payment_cash': {
                const dataq = {is_card: this.order.bill.payment_cash ? 1 : 0}
                this.api.setPaymentType(this.order.uid, dataq).subscribe(data => {
                    // если были платежи по карте, показываем их в любом случае
                    this.getCardPayments();
                    this.finishSave(data);
                }, error => {
                    this.saving = false;
                });
                break;
            }
            case 'kgt_request': {
                if (!query.kgt_floor) {
                    query.kgt_floor = this.order.target.floor;
                }

                if (query.kgt_lifting === null) {
                    query.kgt_lifting = this.kgt_lifting;
                }
                if (query.kgt_lift === null) {
                    query.kgt_lift = this.order.target.lift;
                }
                if ((query.kgt_floor !== null) || (query.kgt_lifting == null) || (query.kgt_lift == null)) {
                    this.api.setKGT(this.order.uid, query.kgt_floor, query.kgt_lift, query.kgt_lifting).subscribe(data => {
                        this.finishSave(data);
                    }, error => {
                        this.saving = false;
                    });
                }
                break;
            }
            default: {
                alert('Что-то пошло не так, обратитесь в техподдержку')
                break;
                this.saving = false;
            }
        }
    }

    /**
     * Получение списка транзитов для мест заказа
     */
    getOrderTransits() {
        this.orderTransitsLoading = true;
        this.api.getOrderTransits(this.order.uid).subscribe(data => {
            this.orderTransits = data;
            this.orderTransitsLoading = false;
        });
    }


    checkRights() {
        const fullHistoryAccess = this.helper.checkPermissions('orders:full-history');
        const operator = this.helper.checkPermissions('orders:change');
        const chiefOperator = this.helper.checkPermissions('orders:change-critical');
        const changePayment = this.helper.checkPermissions('orders:change-payment-type');
        const dopinfo = this.helper.checkPermissions('admin:dopinfo');
        this.rights.checkFix = this.helper.checkPermissions('orders:correct-check');

        this.rights.reDelivery = this.helper.checkPermissions('orders:re-delivery');
        this.rights.importantRed = this.helper.checkPermissions('orders:make-important');
        const status = this.order.status < 5 || this.order.status === 9; // Конечный статус или перемещение между хабами
        if (operator) {
            this.rights.indexRed = status;                  // Кнопка редактирования Индекса
            this.rights.addressRed = true;                  // Кнопка редактирования Адреса
            this.rights.commentRed = status;                // Кнопка редактирования Комментария
            this.rights.commentKsRed = true;                // Кнопка редактирования Комментария КС
            this.rights.delivery_dateRed = status;          // Кнопка редактирования Даты Доставки
            this.rights.delivery_timeRed = status;          // Кнопка редактирования Времени Доставки
            this.rights.courierRed = status;                // Кнопка редактирования Курьера
            this.rights.weightRed = true;                   // Кнопка редактирования Веса
            this.rights.dimensionsRed = true;               // Кнопка редактирования Габаритов
            this.rights.sendReceipt = true;                 // Кнопка Отправить чек клиенту
            this.rights.sendSmsCat = status;                // Кнопка Отправить СМС
            this.rights.modalOrdersBtnRed = status;         // Кнопка модальное окно редактирования заказа
            this.rights.modalOrdersRed = status;            // Модальное окно редактирования заказа
            this.rights.specialLogs = false;                // Спец логи
            this.rights.recalcBtn = true;                   // Спец логи
            this.rights.modalOrdersRedStatus = status;      // Кнопка редактирования Статус

        }

        // can:orders:make-important
        if (chiefOperator && changePayment) {
            this.rights.specialLogs = true;                  // Спец логи
//            this.rights.importantRed = status;                 // Кнопка редактирования Важность
            this.rights.modalOrdersBtnRed = true;            // Кнопка модальное окно редактирования заказа
            this.rights.modalOrdersRedStatus = true;         // Кнопка редактирования Статус
            this.rights.paymentTypeRed = true;               // Кнопка Смены Типа оплаты
        }
        if (dopinfo) {
            this.rights.dopinfoCat = true;                   // Кнопка Допинфо
            this.rights.specialLogs = true;                  // Спец логи
        }

        // Доступ к полной истории заказа
        if (fullHistoryAccess) {
            this.rights.fullHistory = true;
        }

        // Вес проверен
        const canToggleWeightValidatedOnDelivered = this.helper.checkPermissions('orders:toggle-weight-validated-on-delivered-orders');
        const canToggleWeightValidated = this.helper.checkPermissions('orders:toggle-weight-validated')
            || canToggleWeightValidatedOnDelivered;

        this.rights.toggleWeightValidated = (
            (status && canToggleWeightValidated)
            ||
            (!status && canToggleWeightValidatedOnDelivered)
        );

        // Редактирование фактического получателя заказа
        this.rights.editRecipientName = (!status && this.helper.checkPermissions('orders:edit-recipient-name'));
    }

    openTabs(index) {
        this.index = index;
        if (index === 3) {
            this.isLoadSign = true;
            this.api.getOrderSignatures(this.order.uid).subscribe(data => {
                this.isLoadSign = false;
                this.signatures = data;
            }, error => {
                this.isLoadSign = false;
            });
        }
    }

    IntervalTabs(index) {
        this.intervalTabs = index;
    }

    ResentTypeTabs(index) {
        this.resentTypeTabs = index;
    }

    recalc() {
        this.loadRecalc = true;
        this.api.orderRecalc(this.order.uid).subscribe(data => {
            this.finishSave(data);
            this.loadRecalc = false;
        }, error => {
            this.loadRecalc = false;
        })
    }

    checkPayment() {
        if ((this.order.status === 5 || this.order.status === 6)
            && this.order.bill.payment_card > 0
            && (this.order.bill.np !== this.paymentData.sum)
        ) {
            return false;
        } else {
            return true;
        }
    }

    setGoodsCopy() {
        this.goodsCopy = this.order.all_goods.slice();
    }

    setNewInfoOrder(uid) {
        this.api.getOrder(this.order.uid).subscribe(async (data: Order) => {
            this.order = await this.hubsService.defineOrderHubs(data);

            this.goodsCopy = data.all_goods.slice();
            this.setNums();
        });
    }

    public selected(value: any): void {
        console.log('Selected value is: ', value);
    }

    public refreshValue(value: any): void {
        this.value = value;
    }

    onSelectUser(user: User) {
        this.selectedCourier = user;
    }

    setImportantOrder(set) {
        this.loadImportant = true;
        const data = {is_important: set};
        this.api.makeImportant(this.order.uid, data).subscribe(async data => {
            this.order = await this.hubsService.defineOrderHubs(data);
            this.loadImportant = false;
            this.setNums();
        }, error => {
            this.loadImportant = false;
        })
    }

    /**
     * Можно ли включить sameday
     */
    samedayCanBeEnabled() {
        const now = new Date();

        if (this.order.client.is_sameday_possible
            && this.order.delivery_date === this.datePipe.transform(now, 'yyyy-MM-dd')
            && now.getHours() < Order.MAX_HOUR_CREATE_SAMEDAY_DELIVERY
        ) {
            return true;
        }

        return false;
    }

    checkVolumeWeight() {
        if (this.order.hub_destination_id !== 2) {
            return false;
        }
        if (this.helper.getClientSetting(this.order.client_settings, 'calc_use_weight')) {
            return false;
        }

        if (((this.order.option.dimension_side1 * this.order.option.dimension_side2 * this.order.option.dimension_side3) / 5000) > this.order.option.weight) {
            return true;
        } else {
            return false;
        }
    }

    checkEmail() {
        return ((this.order.client.finance_email != null) && (this.order.client.finance_email != ''));
    }

    sendEmail(uid) {
        this.loadBtn = true;
        this.api.sendDialOutMail(uid).subscribe(data => {
            if (!data) {
                alert('Письмо не было отправлено!');
                return;
            }
            this.loadBtn = false;
            this.order.email_counter += 1;
            alert('Письмо отправлено!');
        }, error => {
            this.loadBtn = false;
        })
    }

    getGoodPlaces() {
        for (const barcode of this.order.bar_codes) {
            this.barcode_id.push(barcode.id);
        }
    }

    addGoodOrder() {
        const goodTemplate = {
            name: '',
            weight: 0,
            count: 1,
            price: 0,
            is_cancel: 0,
            nds: 2,
            bar_code_id: this.barcode_id[0]
        };
        this.goodsCopy.push(goodTemplate);
    }

    editGoodOrder(event, index, key) {
        switch (key) {
            case 'name' : {
                this.goodsCopy[index].name = event.target.value;
                break;
            }
            case 'count' : {
                this.goodsCopy[index].count = event.target.value;
                break;
            }
            case 'vendor_code' : {
                this.goodsCopy[index].vendor_code = event.target.value;
                break;
            }
            case 'price' : {
                this.goodsCopy[index].price = event.target.value;
                break;
            }
            case 'nds' : {
                this.goodsCopy[index].nds = event.target.value;
                break;
            }
            case 'color' : {
                this.goodsCopy[index].color = event.target.value;
                break;
            }
            case 'size' : {
                this.goodsCopy[index].size = event.target.value;
                break;
            }
            case 'bar_code_id' : {
                this.goodsCopy[index].bar_code_id = event.target.value;
                break;
            }

            default: {
                alert('Нераспознанное значение');
            }
        }
    }

    deleteGood(index) {
        this.goodsCopy.splice(index, 1);
    }

    editOptionOrder(event, key) {
        switch (key) {
            case 'inner_n' :
            case 'places_count' :
            case 'is_np': {
                this.optionsArray[key] = event.target.value;
                break;
            }

            case 'option_buyback': {
                this.optionsArray['option_buyback'] = event.target.value;
                break;
            }
            case 'os': {
                this.optionsArray['os'] = event.target.value;
                break;
            }
            case 'floor': {
                this.optionsArray['floor'] = event.target.value;
                break;
            }
            case 'target_contacts': {
                this.optionsArray['target_contacts'] = event.target.value;
                break;
            }
            case 'target_name': {
                this.optionsArray['target_name'] = event.target.value;
                break;
            }
            case 'option_sms': {
                this.optionsArray['option_sms'] = event.target.checked;
                break;
            }
            case 'option_returned_doc': {
                this.optionsArray['option_returned_doc'] = event.target.checked;
                break;
            }
            case 'option_opening': {
                this.optionsArray['option_opening'] = event.target.value;
                break;
            }
            case 'option_call': {
                this.optionsArray['option_call'] = event.target.checked;
                break;
            }
            case 'option_fitting': {
                this.optionsArray['option_fitting'] = event.target.value;
                break;
            }
            case 'option_skid_kgt': {
                this.optionsArray['option_skid_kgt'] = event.target.value;
                this.orderModificated.option.option_skid_kgt = event.target.value;
                break;
            }
            case 'lift': {
                this.optionsArray['lift'] = event.target.value;
                break;
            }
            case 'target_notes': {
                this.optionsArray['target_notes'] = event.target.value;
                break;
            }
            case 'price_client_delivery': {
                this.optionsArray['price_client_delivery'] = event.target.value;
                this.orderModificated.bill.price_client_delivery = event.target.value;
                break;
            }
            case 'price_client_delivery_nds': {
                this.optionsArray['price_client_delivery_nds'] = event.target.value;
                this.orderModificated.bill.price_client_delivery_nds = event.target.value;
                break;
            }
            case 'prepaid_amount': {
                this.optionsArray['prepaid_amount'] = event.target.value;
                this.orderModificated.bill.prepaid_amount = event.target.value;
                break;
            }

            default: {
                alert('Нераспознанное значение');
                break;
            }
        }
    }

    saveModifiedGoods() {

        for (const good of this.goodsCopy) {
            if (good.is_cancel == null) {
                good.is_cancel = 0;
            }
            if (!good.name || !good.vendor_code || good.count <= 0 || good.price < 0) {
                alert('Заполните все обязательные поля');
                return;
            }
        }

        if (!this.goodsCopy || this.goodsCopy.length === 0) {
            alert('Товарные позиции не переданы, отмена операции');
            return;
        }

        this.api.updateSettingsGoods(this.orderModificated.uid, {
            goods: this.goodsCopy,
            options: this.optionsArray
        }).subscribe(data => {
            this.finishSave(data);
            this.changeGood = false;
            this.notifier.openNotifier('Изменения сохранены...');
        });
    }

    saveModifiedOptions() {
        if (Object.keys(this.optionsArray).length > 0) {
            this.changeOpt = true;
            this.api.updateOptionsOrder(this.orderModificated.uid, this.optionsArray).subscribe(data => {
                this.finishSave(data);
                this.notifier.openNotifier('Изменения сохранены');
                this.changeOpt = false;
            }, error => {
                this.changeOpt = false;
            });
        }
    }

    setEditArray() {
        this.orderModificated = this.order;
    }

    mathPrice(value) {
        if (value < 0) {
            value = 0;
        }
        return parseFloat(value).toFixed(2);
    }

    onClose() {
        this.opened = false;
        this.app.closeDialog(this);
        this.parent.DialogClosed();
    }

    historyClick() {
        $('body').find('#results').click();
    }

    daysDelta(delivery) {
        const del = new Date(delivery);
        const today = new Date();
        const diff = Math.abs(today.getTime() - del.getTime());
        const days = Math.floor(diff / (8640 * 10000));
        if (!days || (days === 1 && today.getHours() < 17)) {
            return true;
        }
        return false;
    }

    resolveProblem(manager, problemId) {
        if (manager) {
            this.api.resolveProblem(problemId).subscribe(data => {
                this.setNewInfoOrder(this.order.uid);
            });
            this.parent.DialogOnProblemResolved();
        }
    }

    /**
     * Выбор интервала в модально окне
     * Пишем во временную переменную на случай если злодей закроет модал крестом а не сохранением
     * @param event
     */
    onDeliveryIntervalChange(event) {
        let id = null;
        if (event.hasOwnProperty('id')) {
            id = event.id;
        }
        this.selectedDeliveryTime = id;
    }

    /**
     * Подтверждение проверки или отмены проверки веса
     * @param event
     */
    onToggleWeightValidatedConfirm(event) {
        this.toggleWeightValidatedConfirmed = event.target.checked;
    }

    onToggleAddressValidatedConfirm(event) {
        this.toggleAddressValidatedConfirmed = event.target.checked;
    }


    /**
     * Запрашивает вводные данные запроса к API createorder из журнала в виде пригодном для вставки в Postman в режиме Bulk Edit
     * @param recordId
     */
    loadPostmanBulkEdit(orderId, recordId) {
        this.postmanBulkEditLoad['r' + recordId] = true;

        this.api.getCreateOrderInputFromHistoryRecordForPostmanBulkEdit(orderId).subscribe(response => {
            this.postmanBulkEditLines['r' + recordId] = +response.split(/\r\n|\r|\n/).length;
            this.postmanBulkEdit['r' + recordId] = response;
            this.postmanBulkEditLoad['r' + recordId] = false;
        });
    }

    /**
     * Устанавливает чек
     * @private
     */
    private setReceipt() {
        if (this.order.receipt && this.order.receipt.receipt_link) {
            this.receiptUrlExist = true;
            this.receiptUrl = this.receiptUrlShort = this.order.receipt.receipt_link;
            if ('undefined' !== typeof this.order.receipt.receipt_link_short && this.order.receipt.receipt_link_short) {
                this.receiptUrlShort = this.order.receipt.receipt_link_short;
            }
        }
    }

    /**
     * Возврат документов включен в настройках клиента ?
     */
    public isDocumentReturnInClientSettings() {
        return !!(
            this.helper.getClientSetting(this.order.client_settings, this.MASTER_DOCS_RETURN)
            || this.helper.getClientSetting(this.order.client_settings, this.MASTER_DOCS)
        );
    }

    /**
     * Переключает недоступность поля ввода имени фактического получателя по значению чекбокса "Удалить значение"
     */
    onToggleRecipientNameClear() {
        if (!this.EditOrder.value.recipient_name_clear) {
            this.EditOrder.controls.recipient_name.disable();
        } else {
            this.EditOrder.controls.recipient_name.enable();
        }
    }

    /**
     * Обновляет контролы редактора фактического получателя
     */
    setEditOrderRecipientName() {
        this.EditOrder.controls.recipient_name.reset({
            value: this.order.target.recipient_name ? this.order.target.recipient_name : '',
            disabled: false,
        });
        this.EditOrder.controls.recipient_name_clear.setValue(false);
    }

    /**
     * Копирование текста в буфер обмена
     */
    copyToClipboard(value, type = 1) {
        document.addEventListener('copy', (e: ClipboardEvent) => {
            e.clipboardData.setData('text/plain', (value));
            e.preventDefault();
            document.removeEventListener('copy', null);
        });
        document.execCommand('copy');
        let text = 'Текст скопирован буфер обмена';
        switch (type) {
            case 1:
                text = 'Ссылка скопирована в буфер обмена';
                break;
            case 2:
                text = 'Маркировка скопирована в буфер обмена';
                break;
        }
        this.notifier.openNotifier(text);
    }

    /** Получение шаблонов смс отправляемых клинтским отделом. */
    getSmsTemplates() {
        const query = {
            client_id: this.order.client_id,
            order_id: this.order.id,
        }
        const templates = this.api.getSmsTemplates(query).subscribe(data => {
            this.smsTemplates = data;
        })
    }

    onPointCoordsUpdated(newCoords) {
        console.warn('new point coords', newCoords);
        this.order.target.lat = newCoords[0];
        this.order.target.lon = newCoords[1];
    }

    /**
     * Отправка подписи на почту
     */
    sendSignOnMail() {
        if (!this.checkErrorsForSendSigns) {
            return;
        }
        const query = {
            uid: this.order.uid,
            email: this.signOnMail.controls.email.value,
            signatures: this.selectedSignatures
        }
        this.api.sendSignOnMail(query).subscribe(data => {
            this.notifier.openNotifier(data.message);
        })
    }

    checkErrorsForSendSigns() {
        if (!this.signOnMail.controls.email.value) {
            this.notifier.openNotifier('Не указана почта получателя!');
            return false;
        }
        if (this.selectedSignatures.length === 0) {
            this.notifier.openNotifier('Не выбрана не 1 подпись!');
            return false;
        }
        return true;
    }

    /**
     * Добавление / Удаление выбранной подписи
     * @param event
     */
    changeSelectedSign(event) {
        if (event.checked) {
            this.selectedSignatures.push(event.source.value);
        } else {
            const index = this.selectedSignatures.indexOf(event.source.value, 0);
            if (index > -1) {
                this.selectedSignatures.splice(index, 1);
            }
        }
    }

    /**
     * Отправка маршрутного листа по заказу с подписью на почту
     */
    sendMlistOnMail() {
        if (!this.checkErrorsForSendSigns) {
            return;
        }
        const query = {
            uid: this.order.uid,
            email: this.signOnMail.controls.email.value,
            signature: this.selectedSignatures[0],
            recipient_fio: this.signatures[0].recipient_fio,
        }
        this.api.sendMlistWithSignOnMail(query).subscribe(data => {
            this.notifier.openNotifier(data.message);
        })
    }

    onAddressEditorMapDialogOpenedChange(dialogOpened) {
        console.warn('onAddressEditorMapDialogOpenedChange', dialogOpened);
        this.locationMapVisible = !dialogOpened;
    }

    onLocationMapOpenedStateChange(locationMapOpened) {
        console.warn('onLocationMapOpenedStateChange', locationMapOpened);
        this.locationMapOpened = locationMapOpened;
    }

    /**
     * Обработчик изменений в чекбоксе "Требуется прозвон"
     * @param event
     */
    onToggleLocationAddressRequiresCall(event) {
        console.warn('onToggleLocationAddressRequiresCall', event, event.target.checked);
        this.setLocationAddressRequiresCall('order', this.order.id, event.target.checked ? 1 : 0);
    }

    /**
     * Обновление флага "Требуется прозвон"
     * @param objectType
     * @param objectId
     * @param callRequired
     */
    setLocationAddressRequiresCall(objectType, objectId, callRequired) {
        this.loadingLocationAddressRequiresCall = true;
        this.apiAddress.setLocationAddressRequiresCall(objectType, objectId, callRequired).subscribe(responsePayload => {
            this.order.address_requires_call = responsePayload.address_requires_call;
            this.loadingLocationAddressRequiresCall = false;
        }, () => {
            this.loadingLocationAddressRequiresCall = false;
        });
    }

    showGoodMark(good) {
        this.goodNameMark.nativeElement.innerText = '[' + good.vendor_code + ']' + good.name;
        this.goodMark.nativeElement.innerText = good.mark;
    }
}
