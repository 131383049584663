import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {CargoTariff, Hub, UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {FileDownloadService} from "../../http/file-download.service";

@Component({
    selector: 'app-zorders-stat',
    templateUrl: './zorders-stat.component.html',
    styleUrls: ['./zorders-stat.component.scss']
})
export class ZordersStatComponent implements OnInit {
    //public gridData: GridDataResult;
    public statData: any;
    public date = new Date();
    public dates;
    public formList: FormGroup;
    isLoading = false;
    public selectedHubs: UserHub[];
    public excelIsLoading = false;

    ngOnInit(): void {

    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        private fileDownloadService: FileDownloadService
    ) {
        this.formList = new FormGroup({
            date_start: new FormControl(this.date, Validators.required),
            date_end: new FormControl(this.date, Validators.required),
            orders_up_to: new FormControl(''),
            orders_from: new FormControl(''),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(),];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {
                class: 'danger',
                duration: 5000
            });
        }

        this.isLoading = true;
        const query=this.makeQuery();

        this.statisticsService.getZordersStat(query).subscribe(data => {
            this.isLoading = false;

            this.statData = data;
        }, error => {
            this.isLoading = false;
        });
    }

    makeQuery() {
        const query = this.formList.value;
        query.date_start = this.datePipe.transform(this.formList.value.date_start, 'yyyy-MM-dd');
        query.date_end = this.datePipe.transform(this.formList.value.date_end, 'yyyy-MM-dd');
        query.selected_hub = this.selectedHubs.map((hub: Hub) => {
            return hub.id;
        });
        return query;
    }

    loadDataExcel() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца, покайтесь и не грешите больше', null, {
                class: 'danger',
                duration: 5000
            });
        }

        const query=this.makeQuery();

        let fileName = 'zorder-stat-' + query.date_start + ' - ' + query.date_end;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/statistics/getZordersStatExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    isActiveHighlight(row) {
        return (!row.zorders_count)
    }
}
