import {Injectable} from '@angular/core';
import {LaraService} from '../service/lara.service';

@Injectable({
    providedIn: 'root'
})

export class StatisticsService extends LaraService {
    /**
     * Основная статистика
     * @param payload
     */
    public getMainStat(payload) {
        return this.post('/statistics/getMainStat', payload);
    }

    /**
     * Пульс Логсиса
     */
    public getLogsisPulse() {
        return this.get('/statistics/getLogsisPulse');
    }

    public getBadAddressesReport(data) {
        return this.post('/statistics/getBadAddressesReport', data);
    }

    // отчет с подозрительными адресами
    public getSuspiciousAddressesReport(data) {
        return this.post('/statistics/getSuspisiousAddressesReport', data);
    }

    /**
     * Заказы привезённые на склад с 6 утра предыдущего дня по 6 утра выбранной даты
     * @param data
     */
    public getReceivedOrdersInStockByDay(data) {
        return this.get('/statistics/getReceivedOrdersInStockByDay', data);
    }

    /** К собранию: за сегодня */
    public getMeetingTodayStat(hubId: number) {
        return this.get('/statistics/getMeetingTodayStat?hub_id=' + hubId);
    }

    /** К собранию: по дням */
    public getMeetingDailyStat(params) {
        return this.get('/statistics/getMeetingDailyStat', params);
    }

    /** К собранию: привоз по клиентам за сегодня */
    public getMeetingClientsTodayStat(params) {
        return this.get('/statistics/getMeetingClientsTodayStat', params);
    }

    /** К собранию: расшифровка по заказам */
    public getMeetingOrdersList(params) {
        return this.get('/statistics/getMeetingOrdersList', params);
    }

    /** ЗП курьеров */
    public getCourierSalaryStat(params) {
        return this.get('/statistics/getCourierSalaryStat', params);
    }
    public getCourierSalaryStatCourierList(params) {
        return this.get('/statistics/getCourierSalaryStatCourierList', params);
    }

    /** отчёт топ20 привоз на склад */
    public getOrdersStockDateTime(data) {
        return this.post('/statistics/getOrdersStockDateTime', data);
    }

    /**
     * Курьерская статистика
     * @param query
     */
    public getCourierStat(data) {
        return this.post('/statistics/getCourierStat', data);
    }

    /**
     * Причины переноса
     * @param data
     */
    public getReasonsForPerenos(data) {
        return this.post('/statistics/getReasonsForPerenos', data);
    }

    /**
     * Складская статистика
     * @param data
     */
    getStorageStat(data) {
        return this.post('/statistics/getStorageStat', data)
    }

    /**
     * Статистика по клиентам
     * @param data
     */
    getClientsStat(data) {
        return this.post('/statistics/getClientsStat', data);
    }

    /**
     * Статистика по заборам
     * @param data
     */
    getZordersStat(data) {
        return this.post('/statistics/getZordersStat', data);
    }

    /**
     * Статистика Показатели ИМ
     * @param data
     */
    getIndicatorIM(data) {
        return this.post('/statistics/getIndicatorIM', data);
    }

    /**
     * Показатели курьера
     * @param data
     */
    getCourierIndicators(data) {
        return this.post('/statistics/getCourierIndicators', data);
    }

    /**
     * Статистика по интервалам
     * @param prepareQuery
     */
    getIntervalsStat(data) {
        return this.post('/statistics/getIntervalsStat', data);
    }
    getIntervalStatDetails(data) {
        return this.post('/statistics/getIntervalsStatDetails', data);
    }

    /**
     * Стартовая информация для новой статистки
     */
    getNewStatStartInfo() {
        return this.get('/statistics/getNewStatStartInfo');
    }


    getNewStatistic(data) {
        return this.post('/statistics/getNewStatistic', data)
    }

    getNewStatisticDetails(data) {
        return this.post('/statistics/getNewStatisticDetails', data)
    }

    getSalariesByDate(data) {
        return this.post('/buh/getSalariesByDate', data)
    }

    /**
     * Статистика по клиентам c фильтрами
     * @param data
     */
    getClientsStatWithFilter(data) {
        return this.post('/statistics/getClientsStatWithFilter', data);
    }
}
