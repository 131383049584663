import {Component} from '@angular/core';
import {Router} from '@angular/router';

export interface Matrixes {
    label: string;
    link: string;
}

@Component({
    selector: 'app-matrixes-tab',
    templateUrl: './matrixes-tab.component.html',
    styleUrls: ['./matrixes-tab.component.scss']
})

export class MatrixesTabComponent {

    public matrixes: Matrixes[] = [
        {label: 'Тарифная матрица М, МО, СПБ по км', link: 'matrix-moscow'},
        // {label: 'Региональная матрица ЦФО', link: 'matrix-cfo'},
        {label: 'Санкт-Петербург', link: 'matrix-hubs/2'},
        {label: 'TopDelivery', link: 'topdelivery'},
        {label: 'КГТ', link: 'matrix-kgt'},
        {label: 'Заборы М и МО', link: 'matrix-takeaway-moscow'},
        {label: 'Заборы ЦФО', link: 'matrix-takeaway-cfo'},
    ];

    constructor(
        private router: Router
    ) {
    }

    public isActiveMatrix(link) {
        if (this.router.url === '/clients/matrixes/' + link) {
            return true;
        }

        return false;
    }
}
