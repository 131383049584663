import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {DatePipe} from "@angular/common";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {GridDataResult, PageChangeEvent} from "@progress/kendo-angular-grid";
import {Title} from '@angular/platform-browser';
import {StatisticsService} from "../statistics.service";
import {FileDownloadService} from "../../http/file-download.service";

@Component({
    selector: 'app-main-stat',
    templateUrl: './main-stat.component.html',
    styleUrls: ['./main-stat.component.scss']
})
export class MainStatComponent implements OnInit {
    public loading: boolean = false;
    public loadingExcel: boolean = false;
    public formFilter: FormGroup;
    public data: GridDataResult;
    public page: number = 1;
    public pageSize: number = 100;
    public pageOffset: number = 0;
    protected payloadBCC = {};
    public numColumnWidth: number = 2;
    protected visibleClientId: number = 0;

    @ViewChild('input_date_from') protected date_from: ElementRef;
    @ViewChild('input_date_to') protected date_to: ElementRef;

    constructor(
        protected api: StatisticsService,
        protected dataPipe: DatePipe,
        protected fileDownloadService: FileDownloadService,
        protected title: Title,
    ) {
        this.formFilter = new FormGroup({
            date_from: new FormControl(0, Validators.required),
            date_to: new FormControl(0, Validators.required),
            client_id: new FormControl(0, Validators.min(1)),
            order_status: new FormControl(0),
        });
    }

    ngOnInit() {
        this.title.setTitle('Статистика по заказам');
    }

    /**
     * Готовит данные для запроса
     * @protected
     */
    protected prepareRequestPayload() {
        if (!this.formFilter.valid || this.loading) {
            return;
        }

        let requestPayload = this.formFilter.value;
        for (let k in requestPayload) {
            if (!requestPayload.hasOwnProperty(k)) {
                continue;
            }

            if ('undefined' !== typeof this.payloadBCC[k] && this.payloadBCC[k] == requestPayload[k]) {
                continue;
            }

            this.pageOffset = 0;
            break;
        }

        this.payloadBCC = requestPayload;

        return requestPayload;
    }

    /**
     * Загружает главную статистику
     */
    loadStat() {
        let requestPayload = this.prepareRequestPayload();
        requestPayload.pageSize = this.pageSize;
        requestPayload.skip = this.pageOffset;

        this.loading = true;
        this.api.getMainStat(requestPayload).subscribe(responsePayload => {
            this.data = responsePayload;
            this.loading = false;
        }, error => {
            this.loading = false;
        });
    }

    /**
     * Экспортирует главную статистику в Excel
     */
    exportExcel() {
        let requestPayload = this.prepareRequestPayload();
        this.loadingExcel = true;
        this.fileDownloadService
            .downloadFile('/statistics/getMainStat?downloadAsExcel', 'Статистика по заказам ЛК ' + this.visibleClientId + '.xls', 'post', requestPayload)
            .subscribe(response => {
                this.loadingExcel = false;
            }, error => {
                this.loadingExcel = false;
            });
    }

    /**
     * При смене даты
     * @param event
     * @param field
     */
    onDateChange(event, field) {
        this.formFilter.get(field).setValue(this.dataPipe.transform(event.value, 'yyyy-MM-dd'));
    }

    /**
     * При выборе клиента
     * @param event
     */
    onSelectClient(event) {
        this.formFilter.get('client_id').setValue(('undefined' !== typeof event && 'undefined' !== typeof event.id) ? event.id : 0);
        this.visibleClientId = ('undefined' !== typeof event && 'undefined' !== typeof event.client_uid_old) ? event.client_uid_old.slice(2) : 0;
    }

    /**
     * При выборе статуса
     * @param event
     */
    onSelectStatus(event) {
        this.formFilter.get('order_status').setValue(null !== event ? event : 0);
    }

    /**
     * При клике по кнопке "Показать"
     */
    onLoadClick() {
        this.loadStat();
    }

    /**
     * При клике по кнопке "Excel"
     */
    onExcelClick() {
        this.exportExcel();
    }

    /**
     * Кликабельна ли кнопка "Показать"
     */
    isLoadClickable() {
        return this.formFilter.valid;
    }

    /**
     * Признак переноса доставки на другую дату
     * @param dataItem
     */
    isDeliveryDateShifted(dataItem) {
        return (dataItem.original_delivery_date && dataItem.delivery_date && dataItem.original_delivery_date != dataItem.delivery_date);
    }

    /**
     * Признак изменения интервала доставки на другое время
     * @param dataItem
     */
    isDeliveryIntervalChanged(dataItem) {
        return (dataItem.original_delivery_interval && dataItem.delivery_interval && dataItem.original_delivery_interval != dataItem.delivery_interval);
    }

    /**
     * При изменении страницы просмотра
     * @param event
     */
    onPageChange({skip, take}: PageChangeEvent) {
        this.pageSize = take;
        this.pageOffset = skip;

        this.loadStat();
    }

    /**
     * Калькулирует ширину колонки номера позиции в табличной части
     */
    getNumColumnWidth() {
        return 2 + Math.floor((this.pageOffset + this.pageSize).toString().length / 3);
    }
}
