import {Component, Input, OnInit} from '@angular/core';
import {CourierDialogComponent} from '../courier-card/courier-dialog/courier-dialog.component';
import {LaraService} from '../../service/lara.service';
import {AppComponent} from '../../app.component';

@Component({
  selector: 'app-courier-card',
  templateUrl: './courier-card.component.html',
  styleUrls: ['./courier-card.component.scss']
})
export class CourierCardComponent implements OnInit {
    @Input('courier_id') public courier_id: string;
    public loading = false;
    constructor(
        protected api: LaraService,
        private app: AppComponent
    ) { }

    ngOnInit() {
    }

    public onOpen() {
        this.loading = true;
        this.api.getCourierInfo(this.courier_id).subscribe(data => {
            this.app.createDialog(CourierDialogComponent, {
                courier: data
            });
            this.loading = false;
        });
    }

}
