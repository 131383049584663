import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {HelpersService} from '../../service/helpers.service';
import {User, UserHub} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {DataStateChangeEvent} from '@progress/kendo-angular-grid';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from "../../service/current-user.service";
import {EventerService} from "../../service/eventer.service";

@Component({
    selector: 'app-penalties',
    templateUrl: './penalties.component.html',
    styleUrls: ['./penalties.component.scss']
})
export class PenaltiesComponent implements OnInit {

    public date: string;
    public selectedUser: any;
    public loadingPenalties: boolean = false;
    public penaltiesData: any;
    public rulesList: any;
    public skip = 0;
    public pageSize = 10;
    public penToggle = false;
    public loadingStatus = false;
    public loadingAdd = false;
    public modal = null;
    public deletingPenalty;
    public customSumRequired = false;
    public onlyDeleted = false;
    public penaltyLogs = [];
    public penaltyLogsLoading = false;
    public penaltyLogsModal = false;

    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];


    public button = false;
    @ViewChild('pen_reason') pen_reason: ElementRef;
    @ViewChild('pen_notes') pen_notes: ElementRef;
    @ViewChild('pen_date') pen_date: ElementRef;
    @ViewChild('modalOpen') public modalOpen: ElementRef;
    @ViewChild('modalClose') public modalClose: ElementRef;
    @ViewChild('delete_reason') public delete_reason: ElementRef;
    @ViewChild('custom_sum') public custom_sum: ElementRef;
    @ViewChild('penaltyLogsModalOpen') public penaltyLogsModalOpen: ElementRef;
    @ViewChild('penaltyLogsModalClose') public penaltyLogsModalClose: ElementRef;

    constructor(
        public helper: HelpersService,
        protected api: LaraService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        private datePipe: DatePipe
    ) {

        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.getRulesList();
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            let hub = this.currentUserService.getCurrentHub();
            this.onChangeHub(hub);
        });
    }

    onSelectUser(user: User) {
        this.selectedUser = user;
        console.log(user);
    }

    monitoring() {
        let penReasonValue = 'undefined' !== typeof this.pen_reason ? this.pen_reason.nativeElement.value : null;
        this.customSumRequired = 'other' === penReasonValue;
        let custom_sum_value = 'undefined' !== typeof this.custom_sum ? (1 * this.custom_sum.nativeElement.value) : 0;

        if (
            (
                ('other' !== penReasonValue && penReasonValue) ||
                ('other' === penReasonValue && 0 !== custom_sum_value)
            ) &&
            this.pen_notes.nativeElement.value &&
            this.pen_date.nativeElement.value &&
            this.selectedUser) {
            this.button = true;
        } else {
            this.button = false;
        }

    }

    addPenalty() {
        this.button = false;
        this.loadingAdd = true;

        const courier = this.selectedUser.id;
        let reason = this.pen_reason.nativeElement.value;
        const pen_reason = ('other' !== reason ? reason : null);
        const custom_sum = 'other' === reason ? this.custom_sum.nativeElement.value : null;
        const pen_notes = this.pen_notes.nativeElement.value;
        const pen_date = this.helper.formatDateForSQL(this.pen_date.nativeElement.value);

        if (courier && (pen_reason || 0 < custom_sum) && pen_notes && pen_date) {
            const dataAdd = {
                courier_id: courier,
                notes: pen_notes,
                reason_id: pen_reason,
                date: pen_date,
                custom_sum: custom_sum
            };
            this.api.addPenalty(dataAdd).subscribe(data => {
                this.getPenaltyList();
                if ('other' === reason) {
                    this.custom_sum.nativeElement.value = '';
                }
                this.pen_reason.nativeElement.value = '';
                this.pen_notes.nativeElement.value = '';
                this.monitoring();
                this.loadingAdd = false;
            })
        } else {
            alert('Нехватка данных, операция отменена!');
        }
    }

    public pageChange(e: DataStateChangeEvent): void {
        this.skip = e.skip;
        this.pageSize = e.take;
        this.getPenaltyList();
    }

    getPenaltyList() {
        this.loadingPenalties = true;
        this.api.getPenaltyList(this.skip, this.pageSize, this.onlyDeleted, this.selectedHubId).subscribe(data => {
            this.penaltiesData = {data: data.penalties.data, total: data.penalties.total};
            this.loadingPenalties = false;
        })
    }

    getRulesList() {
        this.api.getRulesList().subscribe(data => {
            this.rulesList = data.penalties;
        })
    }

    confirmPenalty(penaltyId) {
        this.loadingStatus = true;
        this.api.confirmPenalty(penaltyId).subscribe(data => {
            this.changeStatusPenalty(penaltyId);
        })
    }

    changeStatusPenalty(penaltyId) {
        this.loadingStatus = false;
        for (const penalty of this.penaltiesData.data) {
            if (penalty.id == penaltyId) {
                penalty.isConfirmed = 1;
            }
        }
    }

    deletePenaltyBegin(penalty) {
        this.deletingPenalty = penalty;
        this.modalOpen.nativeElement.click();
    }

    deletePenaltyCancel() {
        this.modalClose.nativeElement.click();
    }

    deletePenaltyCommit() {
        this.api.deletePenalty(this.deletingPenalty.id, this.delete_reason.nativeElement.value).subscribe(data => {
            this.modalClose.nativeElement.click();
            this.delete_reason.nativeElement.value = '';
            this.getPenaltyList();
        });
    }

    changeDate(timestamp) {
        return this.datePipe.transform(timestamp, 'dd.MM.yyyy HH:mm:ss')
    }

    onOnlyDeletedChange() {
        this.getPenaltyList();
    }

    penaltyLogsOpen(penalty_id) {
        this.penaltyLogsModalOpen.nativeElement.click();
        this.getPenaltyLogs(penalty_id);
    }

    penaltyLogsClose() {
        this.penaltyLogsModalClose.nativeElement.click();
        this.penaltyLogs = [];
    }

    getPenaltyLogs(penalty_id) {
        this.penaltyLogsLoading = true;
        this.api.getPenaltyLogs(penalty_id).subscribe(data => {
            this.penaltyLogs = data.log;
            this.penaltyLogsLoading = false;
        });
    }

    /**
     * Обработчик события изменения хаба (в фильтре или глобально)
     * @param hub
     */
    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;
        this.getPenaltyList();
    }
}
