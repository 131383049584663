import {Component, ComponentFactoryResolver, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LaraService} from '../../service/lara.service';
import {AppComponent} from '../../app.component';
import {ZorderModalComponent} from './zorder-modal/zorder-modal.component';
import {HelpersService} from "../../service/helpers.service";

@Component({
    selector: 'app-zorder',
    templateUrl: './zorder.component.html',
    styleUrls: ['./zorder.component.scss']
})
export class ZorderComponent implements OnInit {

    @Output() onClosed = new EventEmitter();
    @Input('zorder_id') public zorder_id: string;

    public loading = false;

    constructor(private api: LaraService,
                private resolver: ComponentFactoryResolver,
                private app: AppComponent,
                public helper: HelpersService) {
    }

    ngOnInit() {
    }

    public onOpen() {

        this.loading = true;
        this.api.getZorder(this.zorder_id).subscribe((data) => {
            console.log(data);


            this.app.createDialog(ZorderModalComponent, {
                zorder: data.zorder,
                parent: this
            });
            console.log('done');
            this.bodyClick();
            this.loading = false;
        });


    }

    bodyClick() {
        $('body').click();
    }


    public DialogClosed() {
        this.onClosed.emit();
    }
}
