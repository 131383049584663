import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DatePipe} from '@angular/common';
import {ClientInfo, District, Order, OrdersRequest, OrderZone, User, ZorderList} from '../../service/models';
import {OrdersService} from '../../service/orders.service';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../dialog/dialog.service';
import {Subscription} from 'rxjs';
import {environment} from '../../../environments/environment';

@Component({
    selector: 'app-orders-map',
    templateUrl: './orders-map.component.html',
    styleUrls: ['./orders-map.component.scss']
})

/**
 * @deprecated Ganzal: не вижу использований
 */
export class OrdersMapComponent implements OnInit, OnDestroy {
    protected ymaps: any;
    protected instance: any;
    protected collectionClusterer: any;
    protected collectionObjects: any;
    protected paintButton: any;
    protected paintOnMap: any;
    protected paintPolygon: any = null;

    protected isPainting = false;

    public isOpen = false;

    @Input('isLoadZorder') public isLoadZorder = false;
    @Input('isLoadOrder') public isLoadOrder = true;
    @Input('query') public query: OrdersRequest;
    @Input('zquery') public zquery: OrdersRequest;

    @Input('isEnablePainting') public isEnablePainting = true;

    @Input('disabledOrderZorderSelected') public disabledOrderZorderSelected = false;

    public isClusterer = false;

    public orders: OrderMapPoint[] = [];
    public ordersInMap = 0;

    public zorders: ZorderMapPoint[] = [];
    public zordersInMap = 0;

    public limit = 1000;

    public totalOrders = 0;
    public totalZorders = 0;
    public totalZordersEmptyWarehouse;

    protected loaderOrder: Subscription;
    protected loaderZorder: Subscription;

    public openLocalFilter = false;
    public emptyRequest: OrdersRequest = new OrdersRequest();

    public isApplyFilter = false;

    public courierRoute = 'empty';
    public loadRoute = false;

    public statistic = {
        total: 0,
        total_orders: 0,
        total_zorders: 0,
        count_moscow: 0,
        count_mo: 0,
        count_regions: 0,
        barcodes: 0,
        interval_10_14: 0,
        interval_18_22: 0
    };

    public filters: {
        before_14: boolean,
        after_18: boolean,
        more_25kg: boolean,
        is_important: boolean,
        zones: OrderZone[],
        couriers: User[],
        clients: ClientInfo[],
        statuses: number[],
        districts: District[],
    } = {
        before_14: false,
        after_18: false,
        more_25kg: false,
        is_important: false,
        zones: [],
        couriers: [],
        clients: [],
        statuses: [],
        districts: [],
    };

    @Output() onChange = new EventEmitter<[string[], string[]]>();
    @Output() useMark = new EventEmitter<this>();

    public selectZoneId: number = null;
    @Input('selected') public ordersSelected: string[] = [];
    @Input('zselected') public zordersSelected: string[] = [];

    constructor(
        protected api: OrdersService,
        protected helper: HelpersService,
        protected dialog: DialogService,
        public datePipe: DatePipe
    ) {
    }

    ngOnInit() {
        this.orders = [];
        this.zorders = [];
        if (this.useMark) {
            this.useMark.emit(this);
        }
    }

    ngOnDestroy(): void {
        this.orders = [];
        this.zorders = [];
        if (this.loaderOrder) {
            this.loaderOrder.unsubscribe();
        }
        if (this.loaderZorder) {
            this.loaderZorder.unsubscribe();
        }
    }

    onOpen() {
        this.orders = [];
        this.zorders = [];
        this.isOpen = true;
    }

    onClusterer() {
        this.zordersSelected = [];
        this.ordersSelected = [];
        this.isClusterer = !this.isClusterer;
        this.orders.map(o => {
            o.is_render_map = false;
            return o;
        });
        this.zorders.map(o => {
            o.is_render_map = false;
            return o;
        });
        this.applyLocalFilter();
    }

    onLoadOrders() {
        if (this.disabledOrderZorderSelected) {
            return;
        }
        this.isLoadOrder = !this.isLoadOrder;
        this.renderPoints(this.orders, []);
        this.orders = [];
        this.totalOrders = 0;
        this.loadOrders();
    }

    onLoadZorders() {
        if (this.disabledOrderZorderSelected) {
            return;
        }
        this.isLoadZorder = !this.isLoadZorder;
        this.renderPoints(this.zorders, []);
        this.zorders = [];
        this.totalZorders = 0;
        this.loadZorders();
    }

    onClose() {
        this.isOpen = false;
        this.orders = [];
        this.ngOnDestroy();
    }

    loadMap(event) {
        this.ymaps = event.ymaps;
        this.instance = event.instance;
        let elem = this.instance.container.getElement().parentElement.parentElement;
        elem.classList.remove("container");
        elem.classList.add("map");
        this.instance.container.fitToViewport();
        if (this.isEnablePainting && !this.ymaps.modules.isDefined('ext.paintOnMap')) {
            const paintScriptElm = window.document.createElement('script');
            paintScriptElm.src = '/assets/js/paintOnMap.js';
            const scriptPaint = window.document.body.appendChild(paintScriptElm);
            scriptPaint.addEventListener('load', (e) => {
                this.initBaseMap();
                this.bindEventPaint();
            });
        } else {
            this.initBaseMap();
            if (this.isEnablePainting) {
                this.bindEventPaint();
            }
        }

    }

    initBaseMap() {
        this.collectionClusterer = new this.ymaps.Clusterer({
            clusterDisableClickZoom: true,
            hasBalloon: false
        });
        this.collectionObjects = new this.ymaps.Collection();

        this.instance.geoObjects.add(this.collectionClusterer);
        this.instance.geoObjects.add(this.collectionObjects);

        this.bindEventMap();
        this.loadZones();
        this.loadOrders();

        if (this.isLoadZorder) {
            this.loadZorders();
        }
    }

    public markPoint(ordersUid: string[], style: string) {
        if (!this.isOpen) {
            return;
        }
        for (const order of ordersUid) {
            const point = this.ymaps.geoQuery(this.instance.geoObjects)
                .search('properties.objectID = "' + order + '"')
                .get(0);
            if (point) {
                point.options.set('preset', style);
            }
        }
    }

    bindEventPaint() {
        this.ymaps.modules.require(['ext.paintOnMap'])
            .spread(ext => {
                this.paintOnMap = ext;
            }, error => {
                console.log('LOAD ERROR', error);
            });

        // PAINTING PROCESS //
        this.paintButton = new this.ymaps.control.Button({
            data: {content: '<b>Выделить область</b>'},
            options: {maxWidth: [150]}
        });

        this.instance.controls.add(this.paintButton);

        this.paintButton.events.add('click', e => {
            this.isPainting = !this.isPainting;
            if (!this.isPainting && this.paintPolygon) {
                this.instance.geoObjects.remove(this.paintPolygon);
                this.paintPolygon = null;
            }
        });

        const plygonStyle = {
            strokeColor: '#ff00ff',
            strokeOpacity: 0.7,
            strokeWidth: 3,
            fillColor: '#ff00ff',
            fillOpacity: 0.4
        };

        let paintProcess;

        this.instance.events.add('mousedown', e => {
            if (this.isPainting) {
                paintProcess = this.paintOnMap(this.instance, e, {style: plygonStyle});
            }
        }).add('mouseup', e => {
            if (paintProcess) {
                if (this.paintPolygon) {
                    this.instance.geoObjects.remove(this.paintPolygon);
                }
                const coordinates = paintProcess.finishPaintingAt(e);
                paintProcess = null;
                this.paintPolygon = new this.ymaps.Polygon([coordinates], {}, plygonStyle);
                this.instance.geoObjects.add(this.paintPolygon);

                const result = this.ymaps.geoQuery(
                    this.isClusterer ? this.collectionClusterer.getGeoObjects() : this.collectionObjects.getAll()
                )
                    .searchIntersect(this.paintPolygon)
                    .search('geometry.type == "Point"');

                this.ordersSelected = [];
                this.zordersSelected = [];
                result.each(object => {
                    this.addSelected(object.properties);
                });

                this.bodyClick();

                if (this.onChange) {
                    this.onChange.emit([this.ordersSelected, this.zordersSelected]);
                }
            }
        });
        // END PAINTING PROCESS //
    }

    /* TODO костыль из-за спицифики рендера yandex map */
    bodyClick() {
        $('body').click();
    }

    bindEventMap() {
        this.instance.geoObjects.events.add('click', e => {
            this.selectZoneId = null;
            this.ordersSelected = [];
            this.zordersSelected = [];

            const target = e.get('target');

            if (target.options.getName() === 'cluster') {
                /* Кластер точек */
                const result = target.getGeoObjects();
                for (const point of result) {
                    this.addSelected(point.properties);
                }
            } else if (target.geometry.getType() === 'Polygon') {
                /* зона */
                const result = this.ymaps.geoQuery(
                    this.isClusterer ? this.collectionClusterer.getGeoObjects() : this.collectionObjects.getAll()
                )
                    .searchIntersect(target)
                    .search('geometry.type == "Point"');

                this.selectZoneId = target.options.get('zone_id');
                this.getRouteCourier(this.selectZoneId);
                result.each(object => {
                    this.addSelected(object.properties);
                });
            } else if (target.geometry.getType() === 'Point') {
                /* точка */
                this.addSelected(e.get('target').properties);
            }
            if (this.onChange) {
                this.onChange.emit([this.ordersSelected, this.zordersSelected]);
            }
        });
    }

    addSelected(point) {
        if (point.get('objectType') === 'order') {
            this.ordersSelected.push(point.get('objectID'));
        } else {
            this.zordersSelected.push(point.get('objectID'));
        }
    }

    loadZones() {
        this.helper.getZones().subscribe((zones: OrderZone[]) => {
            for (const zone of zones) {
                const poly = this.ymaps.geometry.Polygon.fromEncodedCoordinates(zone.geometry);
                const polygon = new this.ymaps.Polygon(poly);
                polygon.options.set({
                    fillColor: zone.color,
                    strokeColor: '#3076B4',
                    opacity: 0.5,
                    strokeWidth: 1,
                    objectID: zone.id,
                    zone_id: zone.zone_id
                });
                /* конвертация координат в нормальную последовательность */
                const coors = polygon.geometry.getCoordinates();
                const coorsTo = coors.map(cor => {
                    return cor.map(point => [point[1], point[0]]);
                });
                polygon.geometry.setCoordinates(coorsTo);
                this.instance.geoObjects.add(polygon);
            }
        });
    }

    async loadZorders(page = 1) {
        this.zordersSelected = [];
        this.ordersSelected = [];
        if (!this.isLoadZorder) {
            this.calculateTotal();
            return;
        }

        let query;
        if (this.zquery) {
            query = this.zquery;
        } else {
            query = this.emptyRequest;
        }

        query.limit = this.limit;
        query.page = page;
        if (!query.date) {
            const date = new Date();
            query.date = this.datePipe.transform(date, 'yyyy-MM-dd')
        }

        console.log('ZORDERS query', query);

        this.loaderZorder = this.api.getZList(query).subscribe(data => {
            let zorders: ZorderMapPoint[] = data.zorders.data.map((zorder: ZorderMapPoint) => {
                if (!zorder.warehouse) {
                    return null;
                }
                zorder.point = this.renderZoderPoint(zorder);
                zorder.point_mini = this.renderZorderPointMini(zorder);
                zorder.is_render_map = false;
                return zorder;
            });
            this.totalZordersEmptyWarehouse = zorders.filter(item => !item).length;
            zorders = zorders.filter(item => item);

            this.zorders = this.zorders.concat(zorders);

            this.totalZorders = data.zorders.total;
            this.zordersInMap = this.zorders.length;

            this.bodyClick();

            const nextPage = page + 1;
            if (nextPage <= data.zorders.last_page) {
                this.loadZorders(nextPage);
            } else {
                this.calculateTotal();
                this.renderPoints(this.zorders, this.zorders);


            }
        });
    }

    async loadOrders(page = 1) {
        this.zordersSelected = [];
        this.ordersSelected = [];
        if (!this.isLoadOrder) {
            this.calculateTotal();
            return;
        }

        let query;
        if (this.query) {
            query = this.query;
        } else {
            query = this.emptyRequest;
        }
        query.map = true;
        query.page = page;
        query.sort = null;
        query.limit = this.limit;

        console.log('ORDERS query', query);

        this.loaderOrder = this.api.getList(query).subscribe((data) => {
            const orders = data.orders.data.map((order: OrderMapPoint) => {
                order.dimensions = [order.dimension_side1, order.dimension_side2, order.dimension_side3];
                /* объемный вес */
                order.volumeWeight = order.dimensions.reduce((dimension) => order.volumeWeight * dimension) / 5000;

                order.point = this.renderOrderPoint(order);
                order.point_mini = this.renderOrderPointMini(order);
                order.is_render_map = false;
                return order;
            });

            this.orders = this.orders.concat(orders);

            this.totalOrders = data.orders.total;

            this.bodyClick();

            const nextPage = page + 1;
            if (nextPage <= data.orders.last_page) {
                this.loadOrders(nextPage);
            } else {
                this.calculateTotal();
                this.renderPoints(this.orders, this.orders);
                this.ordersInMap = this.orders.length;

            }
        });
    }

    getRouteCourier(zone_id) {
        if (this.orders.length !== this.totalOrders) {
            return;
        }
        if (this.courierRoute['zone_id']) {
            if (this.courierRoute['zone_id'] === zone_id) {
                return;
            }
        }
        this.loadRoute = true;
        const query = this.query;
        query.zone_id = zone_id;
        this.api.getRouteCourier(query).subscribe(data => {
            this.courierRoute = data;
            this.courierRoute['zone_id'] = zone_id;
            this.loadRoute = false;
            this.bodyClick();
        })
    }

    floatRoute(number) {
        return parseFloat(number).toFixed(2);
    }

    calculateTotal() {
        this.statistic.total_orders = this.orders.length;
        this.statistic.total_zorders = this.zorders.length;
        this.statistic.total = this.statistic.total_orders + this.statistic.total_zorders;


        //order.delivery_time

        //this.statistic.count_moscow = this.orders.filter(item => item.region_code == 77).length;
        //this.statistic.count_mo = this.orders.filter(item => item.region_code == 50).length;
        //this.statistic.count_regions = this.orders.filter(item => item.region_code != 50 && item.region_code != 77).length;

        this.statistic.interval_10_14 = this.orders.filter(item => item.delivery_time == 1).length;
        this.statistic.interval_18_22 = this.orders.filter(item => item.delivery_time == 5).length;
        /*
                let countBarCodes = 0;
                this.orders.map(item => {
                    countBarCodes += item.hash_bar_codes_count
                });
                this.statistic.barcodes = countBarCodes;
        */
        this.bodyClick();
    }

    getOldZorderId(zorderId) {
        return this.zorders.filter((item) => item.id === zorderId)[0].old_zorder_id;
    }

    applyLocalFilter() {
        this.isApplyFilter = true;
        if (this.isLoadOrder) {
            this.applyOrderLocalFilter();
        }

        if (this.isLoadZorder) {
            this.applyZorderLocalFilter();
        }
    }

    applyZorderLocalFilter() {
        this.zordersSelected = [];
        console.log('FILTER ZORDERS', this.filters);

        const zorders = this.zorders.filter((zorder: ZorderMapPoint) => {
            let filter = true;

            /* курьеры */
            if (filter && this.filters.couriers.length) {
                const ids = this.filters.couriers.map(c => c.id);
                if (ids.indexOf(zorder.courier_id) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* клиенты */
            if (filter && this.filters.clients.length) {
                const ids = this.filters.clients.map(c => c.client_uid);
                if (ids.indexOf(zorder.client.client_uid) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* зоны */
            if (filter && this.filters.zones.length) {
                const ids = this.filters.zones.map(c => c.zone_id);
                if (ids.indexOf(zorder.zone.zone_id) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* КГТ */
            if (filter && this.filters.more_25kg) {
                if (zorder.weight > 25) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* направления */
            if (filter && this.filters.districts.length) {
                const ids = this.filters.districts.map(d => d.id);
                if (ids.indexOf(zorder.zone.district_id) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            return filter;
        });

        this.zordersInMap = zorders.length;

        this.renderPoints(this.zorders, zorders);
    }

    applyOrderLocalFilter() {
        this.ordersSelected = [];
        console.log('FILTER ORDERS', this.filters);
        const orders = this.orders.filter((order: OrderMapPoint) => {
            let filter = true;

            /* курьеры */
            if (filter && this.filters.couriers.length) {
                const ids = this.filters.couriers.map(c => c.id);
                if (ids.indexOf(order.courier_id) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* клиенты */
            if (filter && this.filters.clients.length) {
                const ids = this.filters.clients.map(c => c.client_uid);
                if (ids.indexOf(order.client_uid) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* статусы */
            if (filter && this.filters.statuses.length) {
                if (this.filters.statuses.indexOf(order.status) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* зоны */
            if (filter && this.filters.zones.length) {
                const ids = this.filters.zones.map(c => parseInt(c.zone_id.toString(), 10));
                if (ids.indexOf(order.zone_id) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* delivery_time */
            if (filter && this.filters.before_14) {
                /* до 14:00 */
                if (this.filters.before_14 && [1].indexOf(order.delivery_time) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }
            if (filter && this.filters.after_18) {
                /* после 18:00 */
                if (this.filters.after_18 && [5].indexOf(order.delivery_time) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* КГТ */
            if (filter && this.filters.more_25kg) {
                if (order.weight > 25 || order.volumeWeight > 25) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* важные */
            if (filter && this.filters.is_important) {
                if (order.is_important) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            /* направления */
            if (filter && this.filters.districts.length) {
                const ids = this.filters.districts.map(d => d.id);
                if (ids.indexOf(order.district_id) > -1) {
                    filter = true;
                } else {
                    filter = false;
                }
            }

            return filter;
        });

        this.ordersInMap = orders.length;

        (async () => {
            await this.renderPoints(this.orders, orders);
        })();
    }

    renderZoderPoint(zorder: ZorderMapPoint) {
        return this.renderZorderPointMini(zorder);
    }

    renderZorderPointMini(zorder: ZorderMapPoint) {
        let pointType;
        if (!zorder.courier_id) {
            if (zorder.client_id === '61') {
                pointType = 'islands#yellowStretchyIcon';
            } else {
                pointType = 'islands#blackStretchyIcon';
            }
        } else {
            if (zorder.client_id === '61') {
                pointType = 'islands#blueStretchyIcon';
            } else {
                pointType = 'islands#darkgreenStretchyIcon';
            }
        }

        const point = new this.ymaps.GeoObject({
                type: 'Feature',
                objectID: zorder.id,
                id: zorder.id,
                title: zorder.id,
                properties: {
                    // balloonContent: order.uid,
                    objectID: zorder.id,
                    objectType: 'zorder',
                    iconContent: '<strong>№' + zorder.id + '</strong> ' + zorder.time_begin + ' - ' + zorder.time_end,
                    hintContent: '<strong>Адрес: </strong>' +
                        (zorder.address_text ? zorder.address_text : (zorder.warehouse ? zorder.warehouse.address_text : 'Отсутствует')) +
                        ' <strong><br>Вес:</strong> ' + (zorder.weight ? zorder.weight : ' - ') +
                        ' <strong><br>Объём:</strong> ' + (zorder.capacity ? zorder.capacity : ' - '),
                    objectweight: zorder.weight
                },
                geometry: {
                    type: 'Point',
                    coordinates: (zorder.lat && zorder.lon) ? [zorder.lat, zorder.lon] : [zorder.warehouse.lat, zorder.warehouse.lon],
                }
            },
            {
                preset: pointType
            }
        );

        return point;
    }

    renderOrderPoint(order: OrderMapPoint) {
        return this.renderOrderPointMini(order);
    }

    renderOrderPointMini(order: OrderMapPoint) {
        const dimensions = [order.dimension_side1, order.dimension_side2, order.dimension_side3];
        /* объемный вес */
        const volumeWeight = dimensions.reduce((dimension) => volumeWeight * dimension) / 5000;

        /* сумма всех сторон */
        const sumDimension = dimensions.reduce((dimension) => volumeWeight + dimension);

        /* максимальная сторона */
        const maxDimension = Math.max.apply(null, dimensions);
        let addTitle = '';

        let iconColor = 'darkGreen',
            iconType = 'DotIcon';

        if (!order.courier_id) {
            iconColor = 'red';
            if (order.substatus_id) {
                iconColor = 'darkOrange';
            }
        } else if (order.courier_id === environment.problem_courier_id) {
            iconColor = 'pink';
            iconType = 'ParkIcon';
        }

        if (maxDimension >= 100 || sumDimension >= 140 || volumeWeight >= 20 || order.weight >= 20) {
            iconColor = 'black';
            iconType = 'DeliveryIcon';
            addTitle = ' (КГТ)'
        }

        const point = new this.ymaps.GeoObject({
                type: 'Feature',
                objectID: order.uid,
                id: order.uid,
                title: order.uid,
                properties: {
                    objectID: order.uid,
                    objectType: 'order',
                    hintContent: 'Заказ: ' + this.helper.getDeliveryTimePeriod(order.delivery_time) + addTitle,
                    objectweight: order.weight
                },
                geometry: {
                    type: 'Point',
                    coordinates: [order.lat, order.lon]
                }
            },
            {
                preset: 'islands#' + iconColor + iconType,
                //visible: false
            }
        );

        return point;
    }

    /**
     * вернет массив точек [те которые надо скрыть, те которые еще не нарисованны]
     *
     * @param points - все точки которые есть
     * @param pointRenders - те которые нужно нарисовать
     */
    prepareRenderPoint(points: any[], pointRenders: any[]) {
        // console.log('ORDERS GLOBAL', points);
        // console.log('ORDERS FILTER SHOW', pointRenders);

        /* только те которые на карте */
        const renderOrders = points.filter((point: OrderMapPoint | ZorderMapPoint) => point.is_render_map);
        // console.log('RENDERS ORDER', renderOrders);

        /* находим те что надо скрыть */
        const hideOrders = renderOrders.filter(item => pointRenders.indexOf(item) < 0);
        // console.log('HIDE ORDER', hideOrders);

        /* те которые надо нарисовать */
        const showPrepareOrders = points.filter(item => pointRenders.indexOf(item) > -1);
        // console.log('SHOW-PREPARE ORDER', showPrepareOrders);

        /* те которых не хватает */
        const showOrders = showPrepareOrders.filter(item => renderOrders.indexOf(item) < 0);
        // console.log('SHOW ORDER', showOrders);

        return [hideOrders, showOrders];
    }

    async renderPoints(orders: any, ordersRenders: any, template = 'mini') {
        console.log('start render', new Date());
        const points = this.prepareRenderPoint(orders, ordersRenders);
        console.log('prerender finish', new Date());

        for (const order of points[0]) {
            this.collectionObjects.remove(order.point_mini);
            this.collectionObjects.remove(order.point);
            this.collectionClusterer.remove(order.point_mini); // this.loadOrders()
            this.collectionClusterer.remove(order.point);
            orders.filter(o => o.id === order.id)[0].is_render_map = false;
        }
        console.log('remove finish', new Date());

        const geoObjects = [];
        for (const order of points[1]) {
            if (template === 'mini') {
                geoObjects.push(order.point_mini);
            } else {
                geoObjects.push(order.point);
            }
            order.is_render_map = true;
        }
        console.log('prepare add to map finish', new Date());

        if (this.isClusterer) {
            this.addPointsToMap(this.collectionClusterer, geoObjects);
        } else {
            this.collectionClusterer.removeAll();
            this.addPointsToMap(this.collectionObjects, geoObjects);
        }
        console.log('finish render', new Date());
        this.isApplyFilter = false;
    }

    addPointsToMap(geoObjects, points) {
        for (const point of points) {
            geoObjects.add(point);
        }
    }

    onSelectCourier(users: User[]) {
        this.filters.couriers = users;
    }

    onSelectClient(clients: ClientInfo[]) {
        this.filters.clients = clients;
    }

    onChangeZone(zones: OrderZone[]) {
        this.filters.zones = zones;
    }

    onChangeDistrict(districts: District[]) {
        this.filters.districts = districts;
    }

    onChangeStatuses(statuses) {
        this.filters.statuses = statuses;
    }

    updateCheckBox(event, key) {
        switch (key) {
            case 'before_14': {
                this.filters.before_14 = event.target.checked;
                break;
            }
            case 'after_18': {
                this.filters.after_18 = event.target.checked;
                break;
            }
            case 'more_25kg': {
                this.filters.more_25kg = event.target.checked;
                break;
            }
            case 'is_important': {
                this.filters.is_important = event.target.checked;
                break;
            }
        }
    }
}

class OrderMapPoint extends Order {
    point: any;
    point_mini: any;
    is_render_map: boolean = false;

    dimension_side1: number;
    dimension_side2: number;
    dimension_side3: number;
    weight: number;

    hash_bar_codes_count: number;

    is_important: boolean;

    region_code: number;

    lat: number;
    lon: number;

    shelf: number;

    dimensions: number[];
    volumeWeight: number;
    district_id: number;
}

class ZorderMapPoint extends ZorderList {
    point: any;
    point_mini: any;
    is_render_map: boolean = false;
    lat?: number | string;
    lon?: number | string;
    address_text?: string;
}
