import {Component, ElementRef, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewChild} from '@angular/core';
import {AppComponent} from '../../app.component';
import {HelpersService} from '../../service/helpers.service';
import {SkladService} from '../../service/sklad.service';
import {OrdersService} from '../../service/orders.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
    selector: 'app-label-print',
    templateUrl: './label-print.component.html',
    styleUrls: ['./label-print.component.scss']

})
export class LabelPrintComponent implements OnChanges {
    public uniqueIndex = Math.random().toString();
    public order;
    public orders;
    public barcodes_count = 0;
    public parent;
    private indexLabel = 0;
    @Input('uid') uid: string;
    @Input('uids') uids: any;
    @Input('barcode') barcode: string;
    @Input('cartNumber') cartNumber: string;
    @Input('regional') regional: boolean;
    @Input('title') title: string;
    @Input('disable') disable: boolean;
    @Input('print') print: boolean;
    @Input('visible') visible;
    @Input('icon') icon;
    @Input('return') return;
    /* Печать этикеток для маркировки возвратов */
    @Input('returnShop') returnShop;
    @Input('multi') multi;
    @Output('finishPrint') finishPrint = new EventEmitter<boolean>();

    @ViewChild('printButton') printButton: ElementRef;

    /** Печатать внутр. номер на этикете */
    PRINT_INNER_NUMBER_ON_LABEL = 'print_inner_number_on_label';

    constructor(
        protected app: AppComponent,
        private api: SkladService,
        private apio: OrdersService,
        private helper: HelpersService,
        private sanitizer: DomSanitizer
    ) {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (!this.visible && this.print) {
            this.getOrder();
        }
    }

    /**
     * Требуется ли печатать внутренний номер на этикетке
     * @param order
     */
    isPrintInnerNumber(order) {
        if (this.helper.getClientSetting(order.client_settings, this.PRINT_INNER_NUMBER_ON_LABEL)) {
            return true;
        }
        return false;
    }

    getOrder() {
        if (this.uid) {
            this.api.getOrder(this.uid).subscribe(data => {

                this.orders = [data];

                if (this.barcode) {
                    this.barcodes_count = this.orders[0].bar_codes.length;
                    this.orders[0].bar_codes = this.orders[0].bar_codes.filter(x => {
                        return x.id === this.barcode;
                    })
                    setTimeout(() => this.printFile(), 500);
                    return;
                }
                setTimeout(() => this.printFile(), 1000);
            })
        }
        if (this.multi && this.uids) {
            this.apio.getUids(this.uids).subscribe(data => {
                this.orders = data;
                setTimeout(() => this.printFile(), 1000);
            })
        }
    }

    printFile() {
        this.printButton.nativeElement.click();

        this.orders = [];
        this.finishPrint.emit(true);
    }

    /**
     * Требуется ли печатать в 1 колонку
     * @param index
     */
    printInColumn() {
        if (!this.returnShop) {
            return;
        }
        if (this.indexLabel !== 4 && this.indexLabel > 0 && this.indexLabel % 4 === 3 ) {
            this.indexLabel ++;
            return;
        }
        this.indexLabel ++;
        return this.sanitizer.bypassSecurityTrustStyle(`page-break-after:unset`);
    }
}
