import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {SidebarComponent} from './sidebar/sidebar.component';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {ClientSelectComponent} from './client-select/client-select.component';
import {CompanySelectComponent} from './company-select/company-select.component';
import {HubSelectComponent} from './hub-select/hub-select.component';
import {BodyModule, GridModule, RowFilterModule} from '@progress/kendo-angular-grid';
import {ClientCardComponent} from './client-card/client-card.component';
import {WindowModule} from '@progress/kendo-angular-dialog';
import {ClientDialogComponent} from './client-card/client-dialog/client-dialog.component';

import {UserCardComponent} from './user-card/user-card.component';
import {UserDialogComponent} from './user-card/user-dialog/user-dialog.component';
import {BackButtonComponent} from './back-button.component';
import {UserSelectComponent} from './user-select/user-select.component';
import {PageNotFoundComponent} from './page-not-found/page-not-found.component';
import {OrderCardComponent} from './order-card/order-card.component';
import {OrderDialogComponent} from './order-card/order-dialog/order-dialog.component';
import {OrderDialogMiniComponent} from './order-card/order-dialog-mini/order-dialog-mini.component';
import {ZorderComponent} from '../components/zorder/zorder.component';
import {ZorderModalComponent} from '../components/zorder/zorder-modal/zorder-modal.component';
import {OrdersMapComponent} from './orders-map/orders-map.component';
import {DocReturnActCardComponent} from './doc-return-act-card/doc-return-act-card.component';
import {DocReturnActDialogComponent} from './doc-return-act-card/doc-return-act-dialog/doc-return-act-dialog.component';



import {AngularYandexMapsModule} from 'angular8-yandex-maps';
import {environment} from '../../environments/environment';
import {HttpClientModule} from '@angular/common/http';
import {NgxDaDataModule} from '@kolkov/ngx-dadata';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatExpansionModule} from '@angular/material/expansion';
import {DistrictSelectComponent} from './district-select/district-select.component';
import {
    MAT_DATE_LOCALE,
    MAT_TABS_CONFIG, MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatNativeDateModule
} from '@angular/material';
import {OrderMapComponent} from './order-map/order-map.component';
import {LocationMapComponent} from "./location-map/location-map.component";
import {ZoneSelectComponent} from './zone-select/zone-select.component';
import {StatusSelectComponent} from './status-select/status-select.component';
import { LogistMapComponent } from './logist-map/logist-map.component';
import { MainMapComponent } from './main-map/main-map.component';
import { SubstatusSelectComponent } from './substatus-select/substatus-select.component';
import { RoleCardComponent } from './role-card/role-card.component';
import { RoleDialogComponent } from './role-card/role-dialog/role-dialog.component';
import { RightsFailureComponent } from './rights-failure/rights-failure.component';
import { LabelPrintComponent } from './label-print/label-print.component';
import {NgxPrintModule} from 'ngx-print';
import {NgxBarcodeModule} from 'ngx-barcode';
import { HubDeliveryIntervalSelectComponent } from './hub-delivery-interval-select/hub-delivery-interval-select.component';
import { AddressDialogComponent } from './address-card/address-dialog/address-dialog.component';
import { AddressCardComponent } from './address-card/address-card.component';
import { AddressEditorMapComponent } from './address-editor-map/address-editor-map.component';
import { AddressEditorMapDialogComponent } from './address-editor-map/address-editor-map-dialog/address-editor-map-dialog.component';
import { ChooseDateDialogComponent } from './choose-date-dialog/choose-date-dialog.component';
import { BarcodePrintComponent } from './barcode-print/barcode-print.component';
import {ChangesLoggerModalComponent} from './changes-logger-modal/changes-logger-modal.component';
import {ChangesLoggerComponent} from './changes-logger/changes-logger.component';
import { KendogridFilterComponent } from './kendogrid-filter/kendogrid-filter.component';
import {LsMoneyPipe} from '../system/pipes/ls-money.pipe';
import { JobsProgressComponent } from './jobs-progress/jobs-progress.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {InvoicePrintComponent} from './invoice-print/invoice-print.component';
import {CourierTypeSelectComponent} from './courier-type-select/courier-type-select.component';
import {MatButtonModule} from '@angular/material/button';
import {ClientUsersEditComponent} from './client-users/client-users-edit/client-users-edit.component';
import {NgxMatFileInputModule} from '@angular-material-components/file-input';
import {ClientUsersCreateComponent} from './client-users/client-users-create/client-users-create.component';
import {MatCheckboxModule} from "@angular/material/checkbox";
import {SamedaySelectComponent} from './sameday-select/sameday-select.component';
import {ShiftSelectComponent} from "./shift-select/shift-select.component";

@NgModule({
    imports: [
        CommonModule,
        RouterModule,
        MDBBootstrapModule.forRoot(),
        GridModule,
        RowFilterModule,
        WindowModule,
        AngularYandexMapsModule.forRoot({
            apikey: environment.yandex_api_key,
            lang: 'ru_RU',
        }),
        NgMultiSelectDropDownModule,
        HttpClientModule,
        NgxDaDataModule,
        MatDatepickerModule,
        MatNativeDateModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatDialogModule,
        MatInputModule,
        NgxPrintModule,
        NgxBarcodeModule,
        MatProgressBarModule,
        BodyModule,
        MatButtonModule,
        NgxMatFileInputModule,
        MatCheckboxModule

    ],
    declarations: [
        ChangesLoggerComponent,
        ChangesLoggerModalComponent,
        SidebarComponent,
        ClientSelectComponent,
        CompanySelectComponent,
        CourierTypeSelectComponent,
        HubSelectComponent,
        ClientCardComponent,
        ClientDialogComponent,
        ClientUsersEditComponent,
        ClientUsersCreateComponent,
        UserCardComponent,
        UserDialogComponent,
        BackButtonComponent,
        UserSelectComponent,
        PageNotFoundComponent,
        OrderDialogComponent,
        OrderCardComponent,
        OrderDialogMiniComponent,
        ZorderComponent,
        ZorderModalComponent,
        OrdersMapComponent,
        OrderMapComponent,
        LocationMapComponent,
        DistrictSelectComponent,
        ZoneSelectComponent,
        StatusSelectComponent,
        LogistMapComponent,
        MainMapComponent,
        SubstatusSelectComponent,
        RoleCardComponent,
        RoleDialogComponent,
        RightsFailureComponent,
        LabelPrintComponent,
        InvoicePrintComponent,
        HubDeliveryIntervalSelectComponent,
        AddressDialogComponent,
        AddressCardComponent,
        AddressEditorMapComponent,
        AddressEditorMapDialogComponent,
        ChooseDateDialogComponent,
        BarcodePrintComponent,
        KendogridFilterComponent,
        LsMoneyPipe,
        DocReturnActCardComponent,
        DocReturnActDialogComponent,
        JobsProgressComponent,
        SamedaySelectComponent,
        ShiftSelectComponent,
    ],
    exports: [
        SidebarComponent,
        ClientSelectComponent,
        CompanySelectComponent,
        HubSelectComponent,
        ClientCardComponent,
        UserCardComponent,
        BackButtonComponent,
        OrderCardComponent,
        ZorderComponent,
        UserSelectComponent,
        OrdersMapComponent,
        OrderMapComponent,
        LocationMapComponent,
        LogistMapComponent,
        DistrictSelectComponent,
        ZoneSelectComponent,
        StatusSelectComponent,
        RoleCardComponent,
        RoleDialogComponent,
        LabelPrintComponent,
        BarcodePrintComponent,
        ChangesLoggerComponent,
        KendogridFilterComponent,
        LsMoneyPipe,
        DocReturnActCardComponent,
        JobsProgressComponent,
        InvoicePrintComponent,
        CourierTypeSelectComponent,
        HubDeliveryIntervalSelectComponent,
        SamedaySelectComponent,
        ShiftSelectComponent,
    ],
    providers: [
        MatDatepickerModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatInputModule,
        MatExpansionModule,
        {provide: MAT_TABS_CONFIG, useValue: {animationDuration: '0ms'}},
        [{provide: MAT_DATE_LOCALE, useValue: 'ru-RU'}]
    ],
    entryComponents: [
        ChangesLoggerComponent,
        ChangesLoggerModalComponent,
        ClientDialogComponent,
        AddressDialogComponent,
        AddressEditorMapDialogComponent,
        UserDialogComponent,
        ZorderModalComponent,
        OrderDialogComponent,
        OrderDialogMiniComponent,
        RoleCardComponent,
        RoleDialogComponent,
        LabelPrintComponent,
        BarcodePrintComponent,
        DocReturnActDialogComponent,
        ChooseDateDialogComponent
    ]
})
export class ComponentsModule {
}
