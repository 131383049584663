import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {District} from '../../service/models';
import {LaraService} from '../../service/lara.service';
import {AutoCompleteComponent} from '@progress/kendo-angular-dropdowns';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';

@Component({
    selector: 'app-district-select',
    templateUrl: './district-select.component.html',
    styleUrls: ['./district-select.component.scss']
})
/* дока: https://github.com/nileshpatel17/ng-multiselect-dropdown#readme */
export class DistrictSelectComponent implements OnInit {
    @ViewChild('autocomplete') public autocomplete: AutoCompleteComponent;
    @Input() public selectedDistricts: District[] = [];
    @Input() public selectedDistrict: District = null;

    @Output() onChange = new EventEmitter<District | District[]>();
    @Input() protected multi: boolean = true;
    @Input() public disabled: boolean = false;

    public settings: IDropdownSettings = {};
    public districtList: District[] = [];
    public selectedItems = [];


    constructor(
        private api: LaraService
    ) {
    }

    protected findModel(id) {
        const list = this.districtList.filter(item => {
            return item.id === id;
        });
        if (!list.length) {
            return;
        }
        return list[0];
    }

    ngOnInit() {
        this.settings = {
            idField: 'id',
            textField: 'name',
            allowSearchFilter: true,
            singleSelection: !this.multi,
            enableCheckAll: this.multi,
            itemsShowLimit: 4,
            closeDropDownOnSelection: this.multi,
            searchPlaceholderText: 'искать',
            noDataAvailablePlaceholderText: 'ничего не найдено',
            defaultOpen: false
        };
        /* грузим список направлений */
        this.api.getDistrictList().subscribe(data => {
            this.districtList = data;
        });
    }

    onChangeDistrict() {
        this.onChange.emit(this.multi ? this.selectedDistricts : this.selectedDistrict);
    }

    onSelectItem(event: District) {
        if (this.multi) {
            this.selectedDistricts.push(event);
        } else {
            this.selectedDistrict = event;
        }
        this.onChangeDistrict();
    }

    onSelectAll() {
        this.selectedDistricts = this.districtList;
        this.onChangeDistrict();
    }

    onDeSelect(event) {
        const district = this.findModel(event.id);
        if (this.multi) {
            this.selectedDistricts = this.selectedDistricts.filter((item: District) => {
                return item.id !== district.id;
            });
        } else {
            this.selectedDistrict = null;
        }
        this.onChangeDistrict();
    }

    onDeSelectAll() {
        this.selectedDistricts = [];
        this.selectedDistrict = null;
    }


    public setSelected(id) {

        this.selectedItems = this.districtList.filter((item: any) => {
            return item.id === id;
        });
        if (this.multi) {
            this.selectedDistricts = this.selectedItems;
        } else {
            this.selectedDistrict = this.selectedItems.length ? this.selectedItems[0] : null;
        }

        this.onChangeDistrict();
    }
}
