import {Component, OnInit, ViewEncapsulation} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {FileDownloadService} from "../../http/file-download.service";

@Component({
    selector: 'app-clients-stat',
    encapsulation: ViewEncapsulation.Emulated,
    styles: [
        `kendo-grid {
        height: 610px;
      }
      kendo-grid .k-grid-content {
        height: 600px;
      }`
    ],
    templateUrl: './clients-stat.component.html',
    styleUrls: ['./clients-stat.component.scss']
})

export class ClientsStatComponent implements OnInit {
    public gridData: GridDataResult;
    public clients;
    public days;
    public formList: FormGroup;
    isLoading = false;
    public selectedHubs;
    excelIsLoading = false;
    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        private fileDownloadService: FileDownloadService,
        public helpers: HelpersService,
    ) {
        this.formList = new FormGroup({
            dateStart: new FormControl('', Validators.required),
            dateEnd: new FormControl('', Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(), ];
    }

    /**
     * Изменение хаба
     * @param selectedHubs
     */
    onSelectHub(selectedHubs) {
        this.selectedHubs = selectedHubs;
    }

    /**
     * Загрузка отчета
     */
    loadReport() {
        if (this.formList.controls.dateStart.value > this.formList.controls.dateEnd.value) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        const query = this.prepareQuery();
        this.isLoading = true;

        this.statisticsService.getClientsStat(query).subscribe(data => {
            this.isLoading = false;
            console.warn(data);
            this.gridData = {data: data.clients, total: data.clients.length};
            this.clients = data.clients;
            this.days = data.days;
        }, error => {
            this.isLoading = false;
        });
    }

    onExcelClick() {
        if (this.formList.controls.dateStart.value > this.formList.controls.dateEnd.value) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {class: 'danger', duration: 5000});
        }
        const query = this.prepareQuery();

        const fileName = 'Клиентская статистика -' + query.date_start + ' - ' + query.date_end;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/statistics/getClientsStatExcel', fileName + '.xlsx', 'post', query)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    private prepareQuery() {
        return {
            'date_start': this.datePipe.transform(this.formList.value.dateStart, 'yyyy-MM-dd'),
            'date_end': this.datePipe.transform(this.formList.value.dateEnd, 'yyyy-MM-dd'),
            'hub_ids': this.selectedHubs.map(x => x.id)
        };
    }
}
