import {LaraService} from './lara.service';
import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';

@Injectable({
    providedIn: 'root'
})

export class LogisticService extends LaraService {

    /**
     * @param data
     */
    // получить доплаты курьеров
    public getExtraExpenses(data) {
        return this.post('/logistic/getExtraExpenses', data);
    }

    // одобрить доплату
    public approveExpense(id) {
        return this.post('/logistic/approveExpense', {id: id});
    }

    // отменить доплату
    public declineExpense(id) {
        return this.post('/logistic/declineExpense', {id: id});
    }

    public changeExpensePrice(id, price) {
        return this.post('/logistic/changeExpensePrice', {id: id, price: price});
    }

    public getExpensePhoto(id) {
        return this.get('/logistic/getExpensePhoto', {id: id});
    }

    // Изменить тип
    public changeExpenseType(id, type) {
        return this.post('/logistic/changeExpenseType', {id: id, type: type});
    }
}
