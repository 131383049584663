import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';

import {HelpersService} from '../../service/helpers.service';
import {FinanceService} from '../../service/finance.service';
import {EventerService} from '../../service/eventer.service';
import {UserHub} from '../../service/models';
import {CurrentUserService} from '../../service/current-user.service';
import {FileDownloadService} from '../../http/file-download.service';

@Component({
    selector: 'app-close-list',
    templateUrl: './close-list.component.html',
    styleUrls: ['./close-list.component.scss']
})
export class CloseListComponent implements OnInit {
    @ViewChild('modal') public modal: ElementRef;
    @ViewChild('input_datestart') public input_datestart: ElementRef;
    @ViewChild('input_dateend') public input_dateend: ElementRef;

    public factSalaryFilter: string = 'all';
    public selfEmployedFilter: string = 'all';

    public couriers;
    public couriersSource;

    public penalties;
    public loading = false;
    public excelIsLoading: boolean = false;

    public dateStart;
    public dateEnd;
    public selectedHub: UserHub = null;

    public stylePrint = {
        table: {'border-collapse': 'collapse', 'width': '100%'},
        td: {'border': '1px solid black', 'padding': '5px', 'font-size': '12px', 'background': 'transparent'},
        th: {'border': '1px solid black', 'padding': '5px', 'font-size': '12px', 'font-weight': 'bold'},
        '.red': {'color': 'red'},
        '*': {'font-family': 'Arial'},
        '@page': {'size': 'landscape'},
        '.footer': {'font-weight': 'bold'}
    };
    public stylePrintForSignature = {
        table: {'border-collapse': 'collapse'},
        td: {'border': '1px solid black', 'padding': '5px', 'font-size': '12px', 'background': 'transparent'},
        th: {'border': '1px solid black', 'padding': '5px', 'font-size': '12px', 'font-weight': 'bold'},
        '.red': {'color': 'red'},
        '*': {'font-family': 'Arial'},
        '@page': {'size': 'portrait'},
        '.custom-table': {'width': 'auto'},
        '#print-signature': {'display': 'block'},
        'thead': {'display': 'table-header-group', 'border-top': '1px solid black'},
        '.text-right': {'text-align': 'right'},
        '.footer': {'font-weight': 'bold'}
    };

    public CRTYPE_CARGO: number = 3;

    public totals: any;
    public showTotals: boolean = false;
    public errors: any;

    constructor(
        protected api: FinanceService,
        protected helper: HelpersService,
        private eventerService: EventerService,
        private currentUserService: CurrentUserService,
        private fileDownloadService: FileDownloadService
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.selectedHub = hub;
        });
    }

    ngOnInit() {
        this.selectedHub = this.currentUserService.getCurrentHub();
    }

    onDateChange(date) {
        date === 1 ?
            this.dateStart = this.input_datestart.nativeElement.value :
            this.dateEnd = this.input_dateend.nativeElement.value;
    }

    onSubmit() {
        if (this.loading) {
            return;
        }

        this.loading = true;
        this.showTotals = false;
        this.factSalaryFilter = 'all';
        this.selfEmployedFilter = 'all';
        this.errors = [];
        this.couriers = [];
        this.couriersSource = [];

        this.api.getCourierCloseList(
                this.helper.formatDateForSQL(this.dateStart),
                this.helper.formatDateForSQL(this.dateEnd),
                this.selectedHub.id
            )
            .subscribe(data => {
                this.couriers = data.couriers;
                this.couriersSource = data.couriers;
                this.errors = data.errors;

                this.calcTotals();

                this.loading = false;
            }, error => {
                this.loading = false;
            })
    }

    onSalaryFilterChanged(filter: string) {
        this.filterBySalary(filter);
    }

    private filterBySalary(filter) {
        this.couriers = this.couriersSource.filter(a => {
            switch (filter) {
                case 'all':
                    return true;
                case 'issued':
                    return a['fact_salary'] !== null;
                case 'not_issued':
                    return a['fact_salary'] === null;
                case 'issued_by_ekassir':
                    return a['fact_salary_ekassir'] !== null;
                case 'not_issued_by_ekassir':
                    return a['fact_salary_ekassir'] === null;
                case 'issued_not_by_ekassir':
                    return a['fact_salary'] !== null && a['fact_salary_ekassir'] === null;
            }
        });

        this.calcTotals();
    }

    /**
     * Фильтр по самозанятости
     * @param filter
     */
    onSelfEmployedFilterChange(filter: string) {
        this.couriers = this.couriersSource.filter(a => {
            switch (filter) {
                case 'all':
                    return true;
                case 'self-employed':
                    return +a['courier_is_self_employed'] === 1;
                case 'not-self-employed':
                    return +a['courier_is_self_employed'] === 0;
            }
        });

        this.calcTotals();
    }

    calcTotals() {
        this.totals = {
            'sum_cash': 0,
            'ekassir_deposited': 0,
            'sum_card': 0,
            'sum_all': 0,

            'calc_salary_points': 0,
            'calc_salary_redelivery': 0,
            'calc_salary_kgt': 0,
            'calc_salary_parking': 0,
            'calc_salary_other': 0,
            'calc_salary': 0,

            'recalculated_salary_points': 0,
            'recalculated_salary_redelivery': 0,
            'recalculated_salary_kgt': 0,
            'recalculated_salary_parking': 0,
            'recalculated_salary_other': 0,
            'recalculated_salary': 0,

            'fact_salary': 0,
            'fact_salary_ekassir': 0,
        };

        for(const courierId in this.couriers) {
            for(const key in this.totals) {
                if (this.couriers[courierId][key]) {
                    this.totals[key] += +this.couriers[courierId][key];
                }
            }
        }

        this.showTotals = true;
    }

    /**
     * Return row color for courier
     * @param courier
     */
    getCourierRowColor(courier) {
        if (+courier.courier_type === this.CRTYPE_CARGO || +courier.fact_salary === 0) {
            return '';
        }

        if (courier.calc_salary > courier.fact_salary) {
            return '#DBFED2';
        } else if (courier.calc_salary < courier.fact_salary) {
            return '#FCDCE5';
        }
    }

    onExcelClick() {
        const reportParams = {
            start_date: this.helper.formatDateForSQL(this.dateStart),
            end_date: this.helper.formatDateForSQL(this.dateEnd),
            hub_id: this.selectedHub.id
        };

        let fileName = 'CloseList_';
        fileName += reportParams.start_date + ' - ' + reportParams.end_date;

        this.excelIsLoading = true;
        this.fileDownloadService
            .downloadFile('/buh/getCloseListForCashier/excel', fileName + '.xlsx', 'post', reportParams)
            .subscribe(response => {
                this.excelIsLoading = false;
            }, error => {
                this.excelIsLoading = false;
            });
    }

    public getCashCellColorClass(row) {
        // если внесения денег в кассира не было
        if (row.ekassir_deposited === 0) {
            return;
        }

        // если в кассира внесена сумма, но ещё не выдана ЗП
        // и остаток больше ЗП, подсветим красным
        if (row.fact_salary === null
            && ((row.calc_salary + row.ekassir_deposited) > Math.round(row.sum_cash))
        ) {
            return 'cell-background__error';
        }

        const calculatedCashSum = row.fact_salary + row.ekassir_deposited;
        // если всё внесено через Екассира, выдана ЗП
        // и сумма внесения + ЗП равна наличной выручке
        // ИЛИ разница в рубль (ошибка округления)
        // подкрасим зелёным
        if (row.fact_salary !== null
            && (Math.abs(calculatedCashSum - Math.round(row.sum_cash)) <= 1)
        ) {
            return 'cell-background__success';
        }

        // если разница больше, подсветим красным
        if (row.fact_salary !== null
            && (Math.abs(calculatedCashSum - Math.round(row.sum_cash)) > 1)
        ) {
            return 'cell-background__danger';
        }

        return;
    }
}
