import {Component, ComponentFactoryResolver, Input, OnInit} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {AppComponent} from '../../app.component';
import {ActModalComponent} from './act-modal/act-modal.component';

@Component({
    selector: 'app-act',
    templateUrl: './act.component.html',
    styleUrls: ['./act.component.scss']
})
export class ActComponent implements OnInit {

    @Input('act_id') public act_id: string;

    public loading = false;

    constructor(private api: SkladService,
                private resolver: ComponentFactoryResolver,
                private app: AppComponent) {
    }

    ngOnInit() {
    }

    public onOpen() {

        this.loading = true;
        this.api.getClientActById(this.act_id).subscribe((data) => {
            console.log(data);


            this.app.createDialog(ActModalComponent, {
                act: data.trans_act
            });
            console.log('done');
            this.loading = false;
        });
    }

}
