import {Component, OnInit} from '@angular/core';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../../statistics/statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {FileDownloadService} from '../../http/file-download.service';
import {LaraService} from '../../service/lara.service';
import {User} from '../../service/models';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';

@Component({
    selector: 'app-couriers-route-list',
    templateUrl: './couriers-route-list.component.html',
    styleUrls: ['couriers-route-list.component.scss']
})
export class CouriersRouteListComponent implements OnInit {
    public isLoading = false;
    public user: User = null;
    public date = new Date();
    public data;
    public paramsCourierId = null;
    public paramsCourierDate = null;
    public paramsCourierShiftNumber = null;

    ngOnInit(): void {
    }

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private notifier: NotifierService,
        private datePipe: DatePipe,
        public currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        private fileDownloadService: FileDownloadService,
        private api: LaraService
    ) {
        this.getParamsCourierIdAndDate();
        this.currentUserService.get().subscribe(data => {
            if (data instanceof User) {
                this.user = data;
                this.loadData();
            }
        })
    }

    loadData() {
        this.isLoading = true;
        if (!this.user) {
            return this.notifier.openNotifier('Ошибка определения пользователя', null, {class: 'danger', duration: 5000});
        }
        this.api.getCourierRouteList(
            this.paramsCourierId ? this.paramsCourierId : this.user.id,
            this.paramsCourierDate ? this.paramsCourierDate : null,
            this.paramsCourierShiftNumber ? this.paramsCourierShiftNumber : null,
        ).subscribe((data: any) => {
            this.data = data;
            this.isLoading = false;
            setTimeout(() =>  window.print(), 500);
        }, error => {
            console.warn(error);
            this.isLoading = false;
        });
    }

    getRowSpan(order) {
        return order.client_id === 129 || order.client_id === 326 ? 3 : -1;
    }

    hideRow(order) {
        return order.client_id === 129 || order.client_id === 326;
    }

    private getParamsCourierIdAndDate() {
        this.route.paramMap.subscribe((params: ParamMap) => {
          this.paramsCourierId = parseInt(params['params'].courier_id, 10);
          this.paramsCourierDate = params['params'].date;
          this.paramsCourierShiftNumber = params['params'].shift_number;
          if (this.paramsCourierDate) {
              this.date = this.paramsCourierDate;
          }
        });
    }
}
