import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientsApiService} from '../../clients/clients-api.service';
import {ParentCompany} from '../../service/models';
import {DialogService} from '../../components/dialog/dialog.service';
import {FinanceService} from '../../service/finance.service';
import {DatePipe} from '@angular/common';
import {KassaReportOrdersComponent} from './kassa-report-orders/kassa-report-orders.component';
import {AppComponent} from '../../app.component';
import {KassaReportProblemsComponent} from './kassa-report-problems/kassa-report-problems.component';
import {DateHelper} from '../../service/helpers/date.helper';

@Component({
    selector: 'app-kassa-report',
    templateUrl: './kassa-report.component.html',
    styleUrls: ['./kassa-report.component.scss']
})
export class KassaReportComponent implements OnInit {

    public formFilter: FormGroup;

    public parentCompanies: ParentCompany[];
    protected selectedParentCompanyId: number = 0;

    public dataIsLoading: boolean = false;

    public reportDates: string[];

    public report;

    constructor(
        private clientsApi: ClientsApiService,
        private financeApi: FinanceService,
        private datePipe: DatePipe,
        protected app: AppComponent,
        protected dialog: DialogService,
        private dateHelper: DateHelper
    ) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);
        const yesterday = new Date();
        yesterday.setDate(today.getDate() - 1);

        this.formFilter = new FormGroup({
            parent_company: new FormControl(0),
            date_from: new FormControl(yesterday, Validators.required),
            date_to: new FormControl(yesterday, Validators.required)
        });
    }

    ngOnInit() {
        this.clientsApi.getActiveParentCompanies()
            .subscribe(data => {
                this.parentCompanies = data;
            })
    }

    onDataLoadClick() {
        if (this.formFilter.invalid) {
            this.dialog.alert('Пожалуйста, заполните дату начала и окончания отчёта');
            return;
        }

        const query = {
            parent_company: this.formFilter.get('parent_company').value,
            date_to:        this.datePipe.transform(this.formFilter.get('date_to').value, 'yyyy-MM-dd'),
            date_from:      this.datePipe.transform(this.formFilter.get('date_from').value, 'yyyy-MM-dd')
        }

        // заполним reportDates днями из выбранного интервала
        this.reportDates = this.dateHelper.returnIntervalDays(new Date(query.date_from), new Date(query.date_to));

        this.dataIsLoading = true;
        this.report = null;
        this.financeApi.getKassaReport(query)
            .subscribe(data => {
                this.report = data;

                this.dataIsLoading = false;
            }, error => {
                this.dataIsLoading = false;
            })
    }

    /**
     * Изменение родительской компании
     * @param event
     */
    public onParentCompanyChange(event: Event) {
        const value = +(<HTMLSelectElement>event.srcElement).value;
        this.selectedParentCompanyId = value;
    }

    /** Возвращает значения формы для списков */
    private getFormValue(date: string) {
        const query = this.formFilter.value;

        query.date_to = date;
        query.date_from = date;

        return query;
    }

    /**
     * Получить сумму наличной выручки за день
     * @param reportDate
     */
    public getCashSum(reportDate: string) {
        if (this.report.paymentSums[reportDate] !== undefined) {
            return this.report.paymentSums[reportDate].payment_cash;
        }

        return 0;
    }

    /**
     * Получить сумму безналичной выручки за день
     * @param reportDate
     */
    public getCardSum(reportDate: string) {
        if (this.report.paymentSums[reportDate] !== undefined) {
            return this.report.paymentSums[reportDate].payment_card;
        }

        return 0;
    }

    /**
     * Платежи через Ibox
     * @param reportDate
     */
    public getIboxSum(reportDate: string) {
        if (this.report.iboxPayments[reportDate] !== undefined) {
            return (this.report.iboxPayments[reportDate].payment_income - this.report.iboxPayments[reportDate].payment_return);
        }

        return 0;
    }

    /**
     * Сумма чеков ApiShip
     * @param reportDate
     */
    public getApiShipReceiptsSum(reportDate: string) {
        return this.getReceiptsSumByKey('apiship_income', reportDate);
    }

    /**
     * Сумма наличных чеков по Оранджу
     * @param reportDate
     */
    public getOrangeCashSum(reportDate: string) {
        return (this.getReceiptsSumByKey('orange_cash_income', reportDate) - this.getReceiptsSumByKey('orange_cash_return', reportDate));
    }

    /**
     * Сумма безналичных чеков по Оранджу
     * @param reportDate
     */
    public getOrangeCardSum(reportDate: string) {
        return (this.getReceiptsSumByKey('orange_card_income', reportDate) - this.getReceiptsSumByKey('orange_card_return', reportDate));
    }

    /**
     * Разница в наличных
     * @param reportDate
     */
    public getCashDifference(reportDate: string) {
        return (this.getCashSum(reportDate) - this.getOrangeCashSum(reportDate) - this.getApiShipReceiptsSum(reportDate));
    }

    /**
     * Есть ошибки за переданную дату
     * @param reportDate
     */
    public reportDateHasErrors(reportDate: string) {
        const cardSum = this.getCardSum(reportDate);

        if (Math.abs(this.getCashDifference(reportDate)) > 0.01
            || Math.abs(cardSum - this.getOrangeCardSum(reportDate)) > 0.01
            || Math.abs(cardSum - this.getIboxSum(reportDate)) > 0.01
        ) {
            return true;
        }

        return false;
    }

    /**
     * Подсветка ячейки наличных
     * @param reportDate
     */
    public getCashCellBackground(reportDate: string) {
        if (Math.abs(this.getCashDifference(reportDate)) >= 0.01) {
            return 'old-bg-warning';
        }

        return 'old-bg-success';
    }

    /**
     * Подсветка ячейки безналичных
     * @param reportDate
     */
    public getCardCellBackground(reportDate: string) {
        const cardSum = this.getCardSum(reportDate);

        if (Math.abs(cardSum - this.getOrangeCardSum(reportDate)) > 0.01) {
            return 'old-bg-danger';
        } else if (Math.abs(cardSum - this.getIboxSum(reportDate)) > 0.01) {
            return 'old-bg-warning';
        }

        return 'old-bg-success';
    }

    /**
     * Показать ошибки за день
     * @param reportDate
     */
    public showErrors(reportDate: string) {
        this.app.createDialog(KassaReportProblemsComponent, {
            parentCompanyId: this.selectedParentCompanyId,
            reportDate: reportDate
        }).onDestroy(data => {

        })
    }

    /**
     * Подсчёт суммы по чекам
     * @param key
     * @param reportDate
     */
    public getReceiptsSumByKey(key: string, reportDate: string) {
        let sum = 0;

        if (this.report.receipts[reportDate] !== undefined) {
            sum += +this.report.receipts[reportDate][key];
        }

        if (this.report.cabReceipts[reportDate] !== undefined) {
            sum += +this.report.cabReceipts[reportDate][key];
        }

        return sum;
    }

    /**
     * Вывод заказов в модалке
     * @param type
     */
    public onLoadOrdersClick(date: string, type) {
        this.app.createDialog(KassaReportOrdersComponent, {
            type: type,
            query: this.getFormValue(date)
        }).onDestroy(data => {

        })
    }
}
