import {ActivatedRoute} from '@angular/router';
import {Component, OnInit} from '@angular/core';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {State} from '@progress/kendo-data-query';
import {User} from '../../service/models';
import {Title} from '@angular/platform-browser';
import {MemberService} from '../../service/member.service';
import {HelpersService} from '../../service/helpers.service';


@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})

export class UsersComponent implements OnInit {
    public users;
    public rolesList: any = [];
    public headerFilter: any = [];
    public role = 0;
    public gridData: GridDataResult;
    public state: State = {
        take: 50,
        skip: 0,

        filter: {
            logic: 'and',
            filters: []
        }
    };

    constructor(
        protected api: MemberService,
        private title: Title,
        private route: ActivatedRoute,
        private helpers: HelpersService
    ) {
        this.title.setTitle('Список пользователей');
        this.loadUsers();
    }

    ngOnInit() {
        this.getRolesList();
        this.gridData = this.route.snapshot.data['user'];
    }

    loadUsers() {
        const filter: any = {};
        const headerFilter = this.headerFilter;
        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.head = headerFilter;
        filter.role = this.role;
        this.api.usersListPanel(filter).subscribe(data => {
            this.gridData = data;
        });
    }

    public pageChange({skip, take}: PageChangeEvent): void {
        console.log(this.state.skip);
        this.state.skip = skip;
        this.state.take = take;
        this.loadUsers();
    }

    getRole(data: User) {
        return (new User(data)).getRoleString();
    }

    setFilter (e) {
        this.headerFilter = e.filters;
        this.loadUsers();
        this.state.skip = 0;
    }

    setRole (e) {
        this.role = +e.target.value;
        this.loadUsers();
        this.state.skip = 0;
    }

    getRolesList() {
        this.api.rolesList().subscribe(data => {
            this.rolesList = data;
        })
    }
}
