import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown/multiselect.model';
import {ShiftType} from '../../service/models';

@Component({
    selector: 'app-shift-select',
    templateUrl: './shift-select.component.html',
    styleUrls: ['./shift-select.component.scss']
})
export class ShiftSelectComponent implements OnInit {
    @Input() protected multi = false;
    @Input() placeHolder = 'Смена';
    @Output() onChange = new EventEmitter<number[]>();

    dropdownList = ShiftType.description;
    selectedItems = [];
    dropdownSettings: IDropdownSettings = {};

    ngOnInit(): void {
        this.dropdownSettings = {
            singleSelection: !this.multi,
            idField: 'value',
            textField: 'name',
            selectAllText: 'Выбрать всё',
            unSelectAllText: 'Убрать всё',
            searchPlaceholderText: 'Поиск',
            itemsShowLimit: 1,
            allowSearchFilter: false
        };
    }

    onChangeModel() {
        this.onChange.emit(this.selectedItems.map((item) => item.value));
    }
}
