import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {GridDataResult, PageChangeEvent} from '@progress/kendo-angular-grid';
import {LaraService} from './../../service/lara.service';
import {State} from '@progress/kendo-data-query';
import {Title} from '@angular/platform-browser';
import {DialogService} from '../../components/dialog/dialog.service';
import {User, UserHub} from "../../service/models";
import {CurrentUserService} from "../../service/current-user.service";
import {EventerService} from "../../service/eventer.service";

@Component({
    selector: 'app-refused-on-spot',
    templateUrl: './refused-on-spot.component.html',
    styleUrls: ['./refused-on-spot.component.scss']
})

export class RefusedOnSpotComponent implements OnInit {

    public reasonsList: any = [];
    public ordersListResult: GridDataResult;

    public loadingReasons = false;
    public loadingOrders = false;

    public formConfirm: FormGroup;

    public headerFilter: any = [];

    public selectedReasons: any;

    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];

    public state: State = {
        take: 10,
        skip: 0,

        filter: {
            logic: 'and',
            filters: []
        }
    };

    constructor(
        private api: LaraService,
        public dialog: DialogService,
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        private title: Title,
    ) {
        this.title.setTitle('Отказы на месте');

        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });

        this.formConfirm = new FormGroup({});
        this.selectedReasons = {};
    }

    ngOnInit() {
        this.loadReasonsList();
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            let hub = this.currentUserService.getCurrentHub();
            this.onChangeHub(hub);
        });
    }

    /**
     * Загружает список причин отказа на месте
     */
    loadReasonsList() {
        this.loadingReasons = true;
        this.api.getRefuseReasonsList().subscribe(data => {
            this.reasonsList = data;
            this.loadingReasons = false;
        })
    }

    /**
     * Загружает страницу списка неподтверждённых заказов с отказом на месте
     */
    loadOrdersPage() {
        this.loadingOrders = true;

        const filter: any = {};
        const headerFilter = this.headerFilter;
        filter.hub_id = this.selectedHubId;
        filter.skip = this.state.skip;
        filter.pageSize = this.state.take;
        filter.head = headerFilter;

        this.api.getRefusedOrders(filter).subscribe(data => {
            for (let order of data.data) {
                if ('undefined' === typeof this.selectedReasons[order.order_uid]) {
                    this.selectedReasons[order.order_uid] = 0;
                }
            }
            this.ordersListResult = data;
            // this.selectedReasons = {};
            this.loadingOrders = false;
        });
    }

    /**
     * Обработчик выбора причины
     * @param e Событие
     * @param uid Номер заказа
     */
    onReasonSelectChange(e, uid) {
        this.selectedReasons[uid] = 1 * e.target.value;
    }

    /**
     * Обработчик клика по кнопке подтверждения
     * @param e Событие
     * @param uid Номер заказа
     */
    onConfirm(e, uid) {
        let val = this.selectedReasons[uid] || false;

        if (!val) {
            this.dialog.alert("Не выбрана причина!", 500);
            return;
        }

        let data = {
            'order_uid': uid,
            'substatus_id': val,
        };

        this.api.confirmRefusedOrder(data).subscribe(data => {
            if (data.message) {
                this.dialog.alert(data.message, 500);
            }

            this.loadOrdersPage();
        })
    }

    /**
     * Обработчик изменения страницы
     * @param param
     */
    public onPageChange({skip, take}: PageChangeEvent): void {
        this.state.skip = skip;
        this.state.take = take;
        this.loadOrdersPage();
    }

    /**
     * Возвращает признак выделенности причины для заказа
     * @param uid Номер заказа
     * @param substatus_id Идентификатор причины
     */
    isResonSelected(uid, substatus_id) {
        return (this.selectedReasons[uid] || 0) === substatus_id;
    }

    /**
     * Обработчик события изменения хаба (в фильтре или глобально)
     * @param hub
     */
    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id)) {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;
        this.loadOrdersPage();
    }
}

// TODO технический долг Иванова: сделано на костылях а хотелось на группах форм
// TODO технический долг Иванова: ну или хотя бы привязаться к моделям
