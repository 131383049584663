import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {ApiClientInfo} from '../../service/api_result';
import {MatrixService} from '../../service/matrix.service';
import {Title} from '@angular/platform-browser';
import {Hub, MatrixCategoryResponse} from '../../service/models';
import {HubsService} from '../../service/hubs.service';
import {HelpersService} from '../../service/helpers.service';
import {DatePipe} from '@angular/common';

@Component({
    selector: 'app-edit',
    templateUrl: './edit.component.html',
    styleUrls: ['./edit.component.scss']
})
export class EditComponent implements OnInit {
    public clientUid;
    public client: ApiClientInfo = new ApiClientInfo();
    public categoryList: MatrixCategoryResponse;
    public kgtTypes = ['none', 'floor', 'fix', 'mixed'];
    public kgtTitle = ['Не носим', 'Поэтажная', 'Фиксированная', 'Комбинированная (фикс+поэтажная)'];

    public matrixes = {
        'moscow': {
            'name': 'МСК и МО',
            'default': 'A',
        },
        'spb': {
            'name': 'СПб',
            'default': 'A',
        },
        'top_delivery': {
            'name': 'Top-Delivery',
            'default': 'A',
        }
    };

    public formClient: FormGroup;

    public selectedHubs: Hub[] = [];

    public save = false;

    constructor(
        private api: MatrixService,
        private route: ActivatedRoute,
        private router: Router,
        private hubsService: HubsService,
        private title: Title,
        public helper: HelpersService,
        private datePipe: DatePipe
    ) {
        this.initForm();
    }

    ngOnInit() {
        this.api.getMatrixCategoryList().subscribe(data => this.categoryList = data);

        this.route.paramMap.subscribe((params: ParamMap) => {
            this.clientUid = params.get('id');
            if (!this.clientUid) {
                alert('Клиент не выбран!');
                this.router.navigate(['/']);
            }
            this.loadClient();
        });
    }

    loadClient() {
        this.api.getClientInfo(this.clientUid).subscribe(async (client: ApiClientInfo) => {
            this.client = client;
            let hub = await this.hubsService.find(this.client.client.default_hub_id);
            this.selectedHubs = [];
            if (hub) {
                this.selectedHubs.push(hub);
            }
            this.initForm();
            this.title.setTitle('Редактирование клиента: ' + this.client.client.client_uid + ': ' + this.client.client.full_name)
        });
    }

    initForm() {
        this.formClient = new FormGroup({
            'matrix_moscow': new FormControl(this.client.client ? this.client.client.matrix : this.matrixes.moscow.default, Validators.required),
            'matrix_spb': new FormControl(this.client.client ? this.client.client.matrix_spb : this.matrixes.spb.default, Validators.required),
            'matrix_top_delivery': new FormControl(this.client.client ? this.client.client.matrix_top_delivery : this.matrixes.top_delivery.default, Validators.required),

            'parking_payment': new FormControl(this.client.client ? this.client.client.parking_payment : 1, Validators.required),
            'is_wc_delivery': new FormControl(this.client.client ? this.client.client.is_wc_delivery : 1, Validators.required),
            'active': new FormControl(this.client.client ? this.client.client.active : false, Validators.required),
            'is_round_weight': new FormControl(this.client.client ? this.client.client.is_round_weight : true),
            'kgt_type': new FormControl(this.client.client ? this.client.client.kgt_type : 'floor', Validators.required),

            'regional_agreement': new FormControl(this.client.client ? this.client.client.regional_agreement : false),
            'redelivery_percent': new FormControl(this.client.client ? this.client.client.redelivery_percent : false),
            'is_regions_by_logsis': new FormControl(this.client.client ? this.client.client.is_regions_by_logsis : false),
            'is_intermediary': new FormControl(this.client.client ? this.client.client.is_intermediary : false),
            'is_recipient_required': new FormControl(this.client.client ? this.client.client.is_recipient_required : false),
            'is_places': new FormControl(this.client.client ? this.client.client.is_places : false),
            'is_calc_spb_by_km': new FormControl(this.client.client ? this.client.client.is_calc_spb_by_km : false),
            'force_orange_receipts_since': new FormControl(this.getForceOrangeReceiptsSinceValue()),
            'is_ignore_kgt': new FormControl(
                {
                    value: this.client.client ? this.client.client.is_ignore_kgt : false,
                    disabled: !this.helper.checkPermissions('clients:edit_is_ignore_kgt')
                }),
            'is_sameday_possible': new FormControl(this.client.client ? this.client.client.is_sameday_possible : false),
            'is_sameday_take_by_cargo': new FormControl(this.client.client ? this.client.client.is_sameday_take_by_cargo : false),

            'default_hub_id': new FormControl(this.client.client ? this.client.client.default_hub_id : null, Validators.required),

            'settings': this.getSettingsFormArray()
        });


    }

    private getForceOrangeReceiptsSinceValue() {
        if (!this.client.client || !this.client.client.force_orange_receipts_since) {
            return null;
        }

        const date = new Date(this.client.client.force_orange_receipts_since);

        return date;
    }

    /**
     * Возвращает контролы для настроек
     */
    private getSettingsFormArray() {
        const optionsFormArray = new FormGroup({});

        if (this.client.setting_keys) {
            Object.keys(this.client.setting_keys).forEach(key => {
                const clientSetting = this.client.settings.find(setting => {
                    return setting.setting_key.key === key;
                })

                optionsFormArray.addControl(key, new FormControl(clientSetting ? clientSetting.setting_value : null))
            })
        }

        return optionsFormArray;
    }

    /** Контролы настроек */
    get settingsControls() {
        return (<FormGroup>this.formClient.get('settings')).controls;
    }

    /** Получить тип настройки по ключу */
    getSettingType(key) {
        return this.client.setting_keys[key].type;
    }

    onSubmit() {
        this.save = false;

        const query = this.formClient.value;
        if (query.force_orange_receipts_since) {
            query.force_orange_receipts_since = this.datePipe.transform(query.force_orange_receipts_since, 'yyyy-MM-dd');
        }

        this.api.updateClient(this.clientUid, query).subscribe((res) => {

            this.save = res;
            this.loadClient()
        });
    }

    onChangeHub(hub) {
        this.formClient.controls['default_hub_id'].setValue(hub ? hub.id : null);
    }

}
