import {
    AfterContentInit,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import {HelpersService} from '../../service/helpers.service';
import {LaraService} from '../../service/lara.service';
import {Order} from "../../service/models";

@Component({
    selector: 'app-location-map',
    templateUrl: './location-map.component.html',
    styleUrls: ['./location-map.component.scss']
})
export class LocationMapComponent implements AfterContentInit, OnInit, OnChanges {
    // Я.Карта - библиотека, инстанс карты, инстанс маркера, инстанс поиска
    protected ymaps: any = null;
    protected instance: any = null;
    protected locationPoint: any = null;
    protected searchControl: any = null;

    // Я.Карта - инстансы кнопок
    protected applyCoordsButton: any = null;
    protected movementButton: any = null;
    protected movementRollbackButton: any = null;

    // Входящие данные
    @Input('type') public type: string | null = null;
    @Input('id') public id: number | string | null = null;
    @Input('lat') public lat: number | null = null;
    @Input('lon') public lon: number | null = null;
    @Input('text') public text: string | null = null;
    @Input('label') public label: string | null = null;
    @Input('editable') public editable: boolean = true;
    @Input('editorButtonEnabled') public editorButtonEnabled: boolean = true;
    @Input('opened') public opened: boolean;
    @Input('header') public header: boolean;

    // Исходящие данные
    @Output() addrUpdate = new EventEmitter<any>();
    @Output() mapOpenedStateChange = new EventEmitter<boolean>();
    @Output() pointCoordsUpdated = new EventEmitter<any>();
    @Output() progress = new EventEmitter<any>();

    // Служебные свойства компонента и его шаблона
    @ViewChild('componentPlaceholder') protected componentPlaceholderRef: ElementRef;
    public loadingObjectData: boolean = false;
    public objectData: any;
    public objectId: any;
    public objectType: any;
    public showDefaultPlaceholder: boolean = false;
    public isOpen: boolean = false;
    public btnHide: boolean = false;
    public headerShow: boolean = true;
    public autoScalable: boolean = false;
    public updatedAddr: Array<string> = Array(1);
    protected editableByStatus: boolean = false;
    protected editableByPermissions: boolean = false;
    public overallEditable: boolean = false;
    protected searchResults = [];
    public searchResultCoords = null;
    public locationPointVisible: boolean = true;
    public toggleLocationPointVisible: boolean = false;
    public validBounds = [];
    protected movementInitialCoords = [];
    protected movementModeEnabled: boolean = false;
    public addressId: number = null;
    public addressLat: number = null;
    public addressLon: number = null;

    protected readonly MOVEMENT_BUTTON_TEXT_DEFAULT = '<strong>Передвинуть</strong>';
    protected readonly MOVEMENT_BUTTON_TEXT_IN_PROGRESS = '<strong>Сохранить</strong>';

    protected readonly locationTypes = {
        order: {
            label: {
                nominative: 'Заказ',
                genitive: 'Заказа',
            },
            permissions: [
                'orders:change',
            ],
        },
        zorder: {
            label: {
                nominative: 'Забор',
                genitive: 'Забора',
            },
            permissions: [
                'log:zorder-change',
            ],
        },
    };

    constructor(
        protected api: LaraService,
        public helpers: HelpersService,
    ) {
    }

    ngOnInit() {
        if (this.opened) {
            this.isOpen = true;
        }
        if (!this.header) {
            this.headerShow = false;
            this.btnHide = true;
        }

        if (null === this.label && null !== this.id) {
            this.label = this.id.toString();
        }

        let editableByPermissions = true;
        if ('undefined' !== typeof this.locationTypes[this.type]) {
            this.locationTypes[this.type].permissions.map(permission => {
                editableByPermissions = editableByPermissions && this.helpers.checkPermissions(permission);
            });
        }

        this.editableByPermissions = editableByPermissions;
        this.updateOverallEditable();
    }

    /**
     * Обработка родительских изменений значений входящих переменных
     * @param changes
     */
    ngOnChanges(changes: SimpleChanges) {
        console.warn('location-map changes', changes);

        if (null !== this.locationPoint && ('undefined' !== typeof changes['lat'] || 'undefined' !== typeof changes['lon'])) {
            this.locationPoint.geometry.setCoordinates([this.lat, this.lon]);
        }
    }

    /**
     * Обработка завершения инициализации контента для определения
     * необходимости отображения дефолтного плейсхолдера компонента
     */
    ngAfterContentInit() {
        // console.log(this.componentPlaceholderRef.nativeElement.childNodes.length);
        this.showDefaultPlaceholder = !this.componentPlaceholderRef.nativeElement.childNodes.length;
    }

    /**
     * Обработка кликп по кнопке открытия карты
     */
    onOpen() {
        console.warn('location-map onOpen');
        this.loadLocationObjectData();
        this.isOpen = true;
        this.btnHide = true;
        this.mapOpenedStateChange.emit(true);
    }

    /**
     * Обработка клика по кнопке закрытия карты
     */
    onClose() {
        this.isOpen = false;
        this.btnHide = false;
        this.mapOpenedStateChange.emit(false);
    }

    /**
     * Инициализация объектов Я.Карты по её готовности
     * @param event
     */
    onMapLoad(event) {
        this.ymaps = event.ymaps;
        this.instance = event.instance;

        this.locationPoint = new this.ymaps.GeoObject(
            {
                type: 'Feature',
                objectID: this.label,
                id: this.label,
                properties: {
                    iconContent: this.label,
                    objectID: this.label,
                    pointtype: this.type,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [this.lat, this.lon]
                },
            },
            {
                preset: 'islands#blackStretchyIcon',
                draggable: false,
            },
        );

        this.applyCoordsButton = new this.ymaps.control.Button({
            data: {
                content: '<strong>Применить найденное</strong>'
            },
            options: {
                maxWidth: [175],
                visible: false,
            },
            state: {
                selected: true,
            },
        });

        this.movementButton = new this.ymaps.control.Button({
            data: {
                content: this.MOVEMENT_BUTTON_TEXT_DEFAULT,
            },
            options: {
                maxWidth: [150]
            }
        });

        this.movementRollbackButton = new this.ymaps.control.Button({
            data: {
                content: '<strong style="color:darkred">Отменить</strong>',
            },
            options: {
                maxWidth: [150],
                visible: false,
            }
        });

        this.searchControl = this.instance.controls.get('searchControl');
        this.searchControl.events
            .add('load', () => {
                this.searchResults = this.searchControl.getResultsArray();

                if (1 === this.searchResults.length) {
                    this.searchResultCoordsSet(this.searchResults[0].geometry.getCoordinates());
                } else {
                    this.searchResultCoordsSet(null);
                }
            }, this)
            .add('resultshow', e => {
                let result = null;
                if ('undefined' !== typeof this.searchResults[e.originalEvent.index]) {
                    result = this.searchResults[e.originalEvent.index];
                }

                this.searchResultCoordsSet(result ? result.geometry.getCoordinates() : null);
            }, this)
        ;

        this.applyCoordsButton.events
            .add('click', () => {
                if (this.searchResultCoords) {
                    this.searchControl.clear();
                    this.locationPoint.geometry.setCoordinates(this.searchResultCoords);
                    this.applyCoords(this.searchResultCoords);
                    this.searchResultCoordsSet(null);
                }
            })
        ;

        this.movementButton.events
            .add('click', () => {
                if (!this.movementModeEnabled) {
                    this.movementBegin();
                } else {
                    this.movementCommit();
                }
            })
        ;

        this.movementRollbackButton.events
            .add('click', () => {
                this.movementRollback();
            })
        ;

        this.setLocationPointVisibility(this.locationPointVisible);
        this.updateToggleLocationPointVisible();

        this.updateOverallEditable();
    }

    /**
     * Запуск режима перемещения точки по карте
     * @protected
     */
    protected movementBegin() {
        this.movementInitialCoords = this.locationPoint.geometry.getCoordinates();
        this.locationPoint.options.set({draggable: true});
        this.movementButton.data.set({content: this.MOVEMENT_BUTTON_TEXT_IN_PROGRESS});
        this.movementRollbackButton.options.set({visible: true});
        this.movementModeEnabled = true;
    }

    /**
     * Завершение режима перемещения точки по карте применением полученного результата
     * @protected
     */
    protected movementCommit() {
        this.movementDone();
        this.applyCoords(this.locationPoint.geometry.getCoordinates());
    }

    /**
     * Завершение режима перемещения точки по карте возвратом исходных координат
     * @protected
     */
    protected movementRollback() {
        this.movementDone();
        this.locationPoint.geometry.setCoordinates(this.movementInitialCoords);
    }

    /**
     * Общие рутины завершения режима перемещения точки по карте
     * @protected
     */
    protected movementDone() {
        this.locationPoint.options.set({draggable: false});
        this.movementButton.data.set({content: this.MOVEMENT_BUTTON_TEXT_DEFAULT});
        this.movementRollbackButton.options.set({visible: false});
        this.movementModeEnabled = false;
    }

    /**
     * Загрузка актуального состояния объекта для отображения на карте
     */
    loadLocationObjectData() {
        let nullCoords = (null === this.lat || null === this.lon);
        switch (this.type) {
            case 'order':
                this.loadingObjectData = true;
                this.api.getOrder(this.id).subscribe((order) => {
                    this.objectType = this.type;
                    this.objectId = order.id;
                    this.objectData = order;
                    this.label = order.uid.toString();
                    this.lat = order.target.lat;
                    this.lon = order.target.lon;

                    if (this.editorButtonEnabled) {
                        this.addressId = order.target.addressKladr.id;
                        this.addressLat = order.target.addressKladr.latlon[1];
                        this.addressLon = order.target.addressKladr.latlon[0];
                    }

                    this.editableByStatus = ((order.status < 5) || (order.status == 9));
                    this.afterLocationObjectDataLoaded({
                        nullCoords,
                    });
                    this.loadingObjectData = false;
                }, () => {
                    this.loadingObjectData = false;
                });
                break;
            case 'zorder':
                this.loadingObjectData = true;
                this.api.getZorder(this.id).subscribe((data) => {
                    this.objectType = this.type;
                    this.objectId = data.zorder.id;
                    this.objectData = data.zorder;
                    this.label = data.zorder.id.toString();
                    this.lat = data.zorder.lat;
                    this.lon = data.zorder.lon;
                    if (null === this.lat || null === this.lon) {
                        this.lat = data.zorder.warehouse.lat;
                        this.lon = data.zorder.warehouse.lon;
                    }

                    if (this.editorButtonEnabled) {
                        this.addressId = data.zorder.warehouse.mapper_id;
                    }

                    this.editableByStatus = true;
                    this.afterLocationObjectDataLoaded({
                        nullCoords,
                    });
                    this.loadingObjectData = false;
                }, () => {
                    this.loadingObjectData = false;
                });
                break;
        }
    }

    /**
     * Общие рутины по окончанию загрузки объекта для отображения на карте
     * @param data
     * @protected
     */
    protected afterLocationObjectDataLoaded(data) {
        this.updateToggleLocationPointVisible();
        this.setLocationPointVisibility(this.locationPointVisible || (data.nullCoords && this.toggleLocationPointVisible));
        this.updateLocationPoint(true);
        this.updateAddressPointVisibility(true);
        this.updateToggleAddressPointVisible();
        this.updateCanCopyCoords();
        this.updateOverallEditable();
    }

    /**
     * Обновление координат и текста маркера точки
     * @param autoScale
     */
    updateLocationPoint(autoScale: boolean = false) {
        if (null === this.locationPoint) {
            return;
        }

        this.locationPoint.geometry.setCoordinates([this.lat, this.lon]);
        this.locationPoint.properties.set({
            iconContent: this.label,
            objectID: this.label,
        });

        if (autoScale) {
            this.autoScale();
        }
    }

    /**
     * Запись координат в точки в объект
     * @param coords
     */
    applyCoords(coords) {
        if (null === this.type || null === this.id) {
            alert('Не могу сохранить координату: тип и/или идентификатор объекта не переданы!');
            return;
        }

        const myRevengeGeocoder = new this.ymaps.geocode(coords);
        myRevengeGeocoder.then(res => {
            const mdata = res.geoObjects.get(0).properties.get('metaDataProperty');
            console.log(mdata.GeocoderMetaData.text);
            this.updatedAddr[0] = mdata.GeocoderMetaData.text;
        });

        switch (this.type) {
            case 'order':
                this.progress.emit(true);
                this.loadingObjectData = true;
                this.api.setCoordinatesOrder(coords, this.id).subscribe(order => {
                    console.info('LM applyCoords [order]', order);
                    this.lat = order.target.lat;
                    this.lon = order.target.lon;
                    let freshCoords = [this.lat, this.lon];

                    this.loadingObjectData = false;
                    this.afterLocationObjectDataLoaded({
                        nullCoords: false,
                    });
                    this.pointCoordsUpdated.emit(freshCoords);
                    this.progress.emit(false);
                }, () => {
                    this.loadingObjectData = false;
                });
                break;

            case 'zorder':
                this.progress.emit(true);
                this.loadingObjectData = true;
                this.api.setCoordinatesZorder(coords, this.id).subscribe(zorder => {
                    console.info('LM applyCoords [zorder]', zorder);
                    this.lat = zorder.lat;
                    this.lon = zorder.lon;
                    if (null === this.lat || null === this.lon) {
                        this.lat = zorder.warehouse.lat;
                        this.lon = zorder.warehouse.lon;
                    }

                    this.loadingObjectData = false;
                    this.afterLocationObjectDataLoaded({
                        nullCoords: false,
                    });
                    let freshCoords = [this.lat, this.lon];
                    this.updatedAddr[1] = freshCoords.join(', ');
                    this.addrUpdate.emit(this.updatedAddr);
                    this.pointCoordsUpdated.emit(freshCoords);
                    this.progress.emit(false);
                }, () => {
                    this.loadingObjectData = false;
                });
                break;
        }
    }

    delay(ms: number) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    /**
     * Установка координат точки, например, по координатам выбранного результата поиска
     * @param coords
     * @protected
     */
    protected searchResultCoordsSet(coords) {
        this.searchResultCoords = coords;

        if (!this.overallEditable || null === this.applyCoordsButton) {
            return;
        }

        this.applyCoordsButton.options.set({visible: !!coords});
    }

    /**
     * Обработка событий балунов на карте (в нашем случае это результаты поиска)
     * @param event
     */
    onMapBaloon(event) {
        if ('balloonopen' === event.event.originalEvent.type) {
            const balloonData = event.event.originalEvent.currentTarget.balloon.getData();
            this.searchResultCoordsSet(balloonData.geometry.getCoordinates());
        }

        if ('balloonclose' === event.event.originalEvent.type) {
            this.searchResultCoordsSet(null);
        }
    }

    /**
     * Обновляет признак возможности выполнения автоподгона масштаба карты
     * @protected
     */
    protected updateMapAutoScalable() {
        this.validBounds = this.getBoundsWithValidCoords(this.instance.geoObjects.getBounds(), true);
        this.autoScalable = (1 < this.validBounds.length);

        return this.autoScalable;
    }

    /**
     * Автоподгон масштаба и положения карты для отображения всех гео-объектов
     */
    autoScale() {
        if (!this.updateMapAutoScalable()) {
            return;
        }

        this.instance.setBounds(this.validBounds, {
            checkZoomRange: true,
            zoomMargin: 50,
        });
    }

    /**
     * Обработчик клика по кнопке автоподгона масштаба
     */
    onAutoScale() {
        this.autoScale();
    }

    /**
     * Обновляет признак возможности редактирования координаты
     * @protected
     */
    protected updateOverallEditable() {
        // console.info('overallEditable: --', this.overallEditable, [this.editable, this.editableByPermissions, this.editableByStatus]);
        this.overallEditable = this.editable && this.editableByPermissions && this.editableByStatus;
        // console.info('overallEditable: ++', this.overallEditable);

        if (this.instance) {
            if (this.overallEditable) {
                this.instance.controls.add(this.applyCoordsButton);
                this.instance.controls.add(this.movementRollbackButton);
                this.instance.controls.add(this.movementButton);
            } else {
                this.instance.controls.remove(this.applyCoordsButton);
                this.instance.controls.remove(this.movementRollbackButton);
                this.instance.controls.remove(this.movementButton);
            }
        }

        return this.overallEditable;
    }

    /**
     * Обработка клика по кнопке переключения видимости маркера локации на карте
     */
    onToggleLocationPointVisibility() {
        this.setLocationPointVisibility(!this.locationPointVisible);
    }

    /**
     * Установка значения видимости маркера локации на карте
     * @param visible
     */
    setLocationPointVisibility(visible) {
        this.locationPointVisible = visible;
        if (null === this.locationPoint) {
            return;
        }

        this.locationPoint.options.set({
            visible: this.locationPointVisible,
        });

        if (this.locationPointVisible) {
            this.instance.geoObjects.add(this.locationPoint);
        } else {
            this.instance.geoObjects.remove(this.locationPoint);
        }
    }

    /**
     * Обновляет признак видимости кнопки переключения видимости маркера локации на карте
     */
    updateToggleLocationPointVisible() {
        this.toggleLocationPointVisible = (null !== this.lat && null !== this.lon);

        return this.toggleLocationPointVisible;
    }

    /**
     * Возвращает лейбл типа локации в нужном падеже в опциональном нижнем регистре
     * @param labelLangCase
     * @param lower
     */
    getLocationTypeLabel(labelLangCase, lower = false) {
        if ('undefined' === typeof this.locationTypes[this.type]) {
            return '*ошибка*';
        }

        let label = this.locationTypes[this.type].label.nominative;
        if ('undefined' !== typeof this.locationTypes[this.type].label[labelLangCase]) {
            label = this.locationTypes[this.type].label[labelLangCase];
        }

        return lower ? label.toLowerCase() : label;
    }

    /**
     * Фильтрует массив координат границ, убирая дубликаты и опционально убирая точки за пределами РФ
     * @param bounds
     * @param filterNonRussian
     */
    getBoundsWithValidCoords(bounds, filterNonRussian = false) {
        // console.warn('getBoundsWithValidCoords', bounds, filterNonRussian);

        if (null === bounds) {
            return [];
        }

        let output = [];

        // грубый фильтр по границам РФ
        // https://ru.wikipedia.org/wiki/Крайние_точки_России#Крайние_точки
        // Включая острова и эксклавы
        // Северная точка — окрестности мыса Флигели, Земля Франца-Иосифа, Архангельская область[1]
        //  81°50′35″ с. ш. 59°14′22″ в. д.
        // Южная точка — не именованная на картах точка с высотой свыше 3500 м расположена в 2,2 км к востоку от горы Рагдан и к юго-западу от гор Несен (3,7 км) и Базардюзю (7,3 км), Дагестан[2]
        //  41°11′07″ с. ш. 47°46′55″ в. д.
        // Западная точка — погранзастава Нормельн[3], Балтийская коса, Калининградская область[4]
        //  54°27′45″ с. ш. 19°38′19″ в. д.
        // Восточная точка — остров Ратманова, Чукотский автономный округ[5]
        //  65°47′ с. ш. 169°01′ з. д.
        if (filterNonRussian) {
            bounds = bounds.filter(b => {
                if (null === b[0] || null === b[1]) {
                    return true;
                }

                if (b[0] < 40 || b[0] > 82) {
                    return false;
                }

                if ((b[1] >= 19 && b[1] <= 180) || (b[1] >= -180 && b[1] <= -168)) {
                    return true;
                }

                return false;
            });

            // console.info('bound without non-Russian', bounds);
        }

        output = bounds.filter(b => {
            return (-1 === output.indexOf(b));
        });

        // console.info('getBoundsWithValidCoords pre-output', output);

        if (!output.length) {
            return [];
        }

        // немного колдуем с координатами чтоб сделать хорошие границы и карта не встала зумом "в квартиру"
        let minLat = 360 + output[0][0];
        let minLon = 360 + output[0][1];
        let maxLat = minLat;
        let maxLon = minLon;

        for (let coords of output) {
            minLat = Math.min(minLat, 360 + coords[0]);
            maxLat = Math.max(maxLat, 360 + coords[0]);
            minLon = Math.min(minLon, 360 + coords[1]);
            maxLon = Math.max(maxLon, 360 + coords[1]);
        }

        let avgLat = (minLat + maxLat) / 2;
        let avgLon = (minLon + maxLon) / 2;

        // console.info('min', [minLat, minLon]);
        // console.info('max', [maxLat, maxLon]);
        // console.info('avg', [avgLat, avgLon]);

        const deltaLat = 0.005;
        const deltaLon = 0.005;

        output = [
            [
                (Math.min(minLat, avgLat - deltaLat) - 360),
                (Math.min(minLon, avgLon - deltaLon) - 360),
            ],
            [
                (Math.max(maxLat, avgLat + deltaLat) - 360),
                (Math.max(maxLon, avgLon + deltaLon) - 360),
            ]
        ];

        // console.info('getBoundsWithValidCoords output', output);

        return output;
    }

    /**
     * Обновление признаков возможности копирования координат между адресом и локацией
     * (заглушка в базовом классе)
     * @protected
     */
    protected updateCanCopyCoords() {
    }

    /**
     * Обновляет признак видимости маркера адреса на карте
     * (заглушка в базовом классе)
     * @param apply
     */
    updateAddressPointVisibility(apply = false) {
    }

    /**
     * Обновляет признак видимости кнопки переключения видимости маркера адреса на карте
     * (заглушка в базовом классе)
     */
    updateToggleAddressPointVisible() {
    }

}
