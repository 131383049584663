import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {AppComponent} from '../../../app.component';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {LaraService} from '../../../service/lara.service';
import {NotifierService} from '../../../service/notifier.service';
import {HelpersService} from "../../../service/helpers.service";
import {HubsService} from "../../../service/hubs.service";
import {CargoTariff, Hub} from "../../../service/models";
import 'rxjs/add/operator/first';

@Component({
    selector: 'app-courier-dialog',
    templateUrl: './courier-dialog.component.html',
    styleUrls: ['./courier-dialog.component.scss']
})
export class CourierDialogComponent implements OnInit {
    public width;
    public index = 1;
    public courier;
    public form: FormGroup;
    public button = false;
    public loading = false;
    public loadingSms = false;
    public opened = false;
    public default_hub: Hub;
    public default_hub_id_original: number;
    public selectedHubs: Hub[];
    public hubsList: Hub[];
    public changesMade: boolean = false;
    public cargoTariffsList: CargoTariff[] = [];
    public cargoTariffsListLoading: boolean = false;


    constructor(
        protected app: AppComponent,
        protected api: LaraService,
        private notifier: NotifierService,
        protected hubsService: HubsService,
        public helper: HelpersService
    ) {
        this.width = window.innerWidth * 0.5;
        const disabled = !helper.checkPermissions('staff:courier-edit');
        const disableEditCargoTariff = !helper.checkPermissions('log:cargo-courier-workday')
        this.form = new FormGroup(
            {
                active: new FormControl({value: 0, disabled: disabled}),
                is_self_employed: new FormControl({value: 0, disabled: disabled}),
                shift_without_call: new FormControl({value: 0, disabled: disabled}),
                phone: new FormControl({value: '', disabled: disabled}, Validators.required),
                mobile_phone1: new FormControl({value: '', disabled: disabled}),
                mobile_phone2: new FormControl({value: '', disabled: disabled}),
                driver_license: new FormControl({value: '', disabled: disabled}),
                type: new FormControl({value: '', disabled: disabled}, Validators.required),
                cargo_tariff_id: new FormControl({value: '', disabled: (disabled || disableEditCargoTariff)}),
                corporate_phone: new FormControl({value: '', disabled: disabled}),
                ekashier_allowed: new FormControl({value: '', disabled: disabled}),
                fact_address: new FormControl({value: '', disabled: disabled}),
                car_mark: new FormControl({value: '', disabled: disabled}),
                car_model: new FormControl({value: '', disabled: disabled}),
                car_number: new FormControl({value: '', disabled: disabled}),
                terminal_serial: new FormControl({value: '', disabled: disabled}, Validators.required),
                work_is_first_shift: new FormControl({value: 0, disabled: disabled}),
                work_is_second_shift: new FormControl({value: 0, disabled: disabled}),
            }
        );

        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
        });
    }

    ngOnInit() {
        this.getCargoTariffsList();

        // инициализируем элементы формы
        console.log(this.courier);
        this.form.patchValue({
            active: this.courier.active ? this.courier.active : 0,
            is_self_employed: this.courier.courier_option.is_self_employed ? this.courier.courier_option.is_self_employed : 0,
            shift_without_call: this.courier.courier_option.shift_without_call ? this.courier.courier_option.shift_without_call : 0,
            type: this.courier.courier_option.type ? this.courier.courier_option.type : '',
            cargo_tariff_id: ((this.courier.courier_option.type && 3 == this.courier.courier_option.type) ? this.courier.courier_option.cargo_tariff_id : 0),
            phone: this.courier.courier_option.phone,
            mobile_phone1: this.courier.courier_option.mobile_phone1,
            mobile_phone2: this.courier.courier_option.mobile_phone2,
            driver_license: this.courier.courier_option.driver_license,
            corporate_phone: this.courier.courier_option.corporate_phone,
            ekashier_allowed: this.courier.courier_option.ekashier_allowed,
            fact_address: this.courier.courier_option.fact_address,
            car_mark: this.courier.courier_option.car_mark,
            car_model: this.courier.courier_option.car_model,
            car_number: this.courier.courier_option.car_number,
            terminal_serial: this.courier.courier_option.terminal_serial,
            work_is_first_shift: this.courier.courier_option.work_is_first_shift,
            work_is_second_shift: this.courier.courier_option.work_is_second_shift,
        });
        this.opened = true;
        this.default_hub = this.hubsList.find((hub: Hub) => {
            return hub.id === this.courier.default_hub_id;
        });
        this.selectedHubs = [this.default_hub,];
        this.default_hub_id_original = this.default_hub.id;

        // подписываемся на любые изменения, чтобы раздизейблить кнопку сохранения
        this.form.valueChanges.subscribe(val => {
            this.changesMade = true;
        });
    }

    onClose() {
        this.opened = false;
        this.app.closeDialog(this);
    }

    /**
     * Требуется ли указывать
     * данные автомобиля
     */
    dataCarIsRequired() {
        if (this.form.value.type === 2) {
            return true;
        }
        if (this.form.value.car_mark.length > 0 && this.form.value.car_model.length > 0 && this.form.value.car_number.length > 0) {
            return true;
        }
        return false;
    }

    setCourierSettings() {
        if (this.form.status === 'VALID' && this.dataCarIsRequired()) {
            this.button = false;
            this.loading = true;
            const formValue = this.form.value;
            formValue.default_hub_id = this.courier.default_hub_id;
            formValue.courier_id = this.courier.courier_option.id;

            this.api.setCourierInfo(formValue).subscribe(data => {
                this.courier = data;
                this.changesMade = false;
                this.loading = false;
                this.notifier.openNotifier('Изменения сохранены', null, {duration: 3000});
            })
        } else {
            this.notifier.openNotifier('Заполните все поля', null, {duration: 3000, class: 'danger'});
        }
    }

    openTabs(index) {
        this.index = index;
    }

    makeCall(phone) {
        this.api.makeCall(phone).subscribe(data => {
            console.log(data);
            this.notifier.openNotifier('Звонок инициирован: ' + phone, null, {duration: 3000});
        })
    }

    sendCourierPassword() {
        this.loadingSms = true;
        this.api.sendCourierPassword(this.courier.courier_option.user_id).subscribe(data => {
            this.loadingSms = false;
            alert('Логин: ' + data.login + "\n" + 'Пароль: ' + data.password);
            console.log(data.login + ':' + data.password);
        })
    }

    onChangeHub(event) {
        this.default_hub = event;
        this.courier.default_hub_id = event.id;
        this.changesMade = true;
    }

    /**
     * Получает список тарифов курьеров-грузовиков
     */
    getCargoTariffsList() {
        this.cargoTariffsListLoading = true;
        this.api.getCargoTariffsList().subscribe(data => {
            this.cargoTariffsList = data.map(item => {
                return new CargoTariff(item);
            });

            this.cargoTariffsListLoading = false;
        });
    }

    /**
     * Метод вызывается при изменении статуса куреьра
     * @param value
     */
    statusCourierChange(value) {
        if (value == 0) {
            this.form.get('phone').clearValidators()
            this.form.get('terminal_serial').clearValidators();
            if (confirm('Желаете ли вы очистить следующие поля?\r\n-Контактный номер\r\n-Доп. телефон 1\r\n-Доп. телефон 2\r\n-Тел. Мегафон\r\n-Серийный номер iBox') === true) {
                this.form.get('phone').setValue(null);
                this.form.get('mobile_phone1').setValue(null);
                this.form.get('mobile_phone2').setValue(null);
                this.form.get('corporate_phone').setValue(null);
                this.form.get('terminal_serial').setValue(null);
                this.notifier.openNotifier('Поля очищены', null, {duration: 3000});
            }
        } else if (value == 1) {
            this.form.controls['phone'].setValidators(Validators.required);
            this.form.controls['terminal_serial'].setValidators(Validators.required);
            this.form.controls['phone'].updateValueAndValidity();
            this.form.controls['terminal_serial'].updateValueAndValidity();
        }
    }
}
