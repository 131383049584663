import {ChangeDetectorRef, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';

import {OrdersService} from '../../service/orders.service';
import {HelpersService} from '../../service/helpers.service';
import {DialogService} from '../../components/dialog/dialog.service';


import {FormControl, FormGroup} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {ClientInfo, District, OrderZone, User, UserHub} from '../../service/models';
import {DatePipe} from '@angular/common';
import {Title} from '@angular/platform-browser';
import {CurrentUserService} from "../../service/current-user.service";
import {Subject} from "rxjs";

import {ActivatedRoute} from "@angular/router";
import {EventerService} from "../../service/eventer.service";
import {constant} from "@progress/kendo-data-query/dist/npm/funcs";

@Component({
    selector: 'app-logist-map',
    templateUrl: './logist-map.component.html',
    styleUrls: ['./logist-map.component.scss']
})
export class LogistMapComponent implements OnInit {
    protected ymaps: any;
    protected instance: any;
    public isOpen = true;
    private selectedPoly: any = null;

    public appHubChangeEventSubject: Subject<any> = new Subject<any>();
    public appHubChangeOrBeginLookupEventSubject: Subject<any> = new Subject<any>();

    @Output() onChange = new EventEmitter<string[]>();
    @Output() useMark = new EventEmitter<this>();

    @Input('selected') public selected: string[] = [];
    @ViewChild('input_date') inputDate: ElementRef;

    public openLocalFilter = true;
    public toDay = new Date();

    public formFilter: FormGroup;

    public filterButton;
    public isShowFilters = false;

    public pointsCount: number = -1;

    private loadingObjectManager: any = null;

    public zone_id: number;
    public selectedHub: UserHub = null;

    public hubChanged = true;

    public filters: {
        load_orders: boolean,
        load_zorders: boolean,
        load_perenos: boolean,
        load_too_late: boolean,
        date: any,
        before_14: boolean,
        after_18: boolean,
        before_18: boolean,
        more_25kg: boolean,
        is_important: boolean,
        zones: OrderZone[],
        couriers: User[],
        clients: ClientInfo[],
        statuses: number[],
        districts: District[],
        without_courier: boolean,
        substatuses: any[],
        shifts: number[],

    } = {
        load_orders: true,
        load_zorders: true,
        load_perenos: true,
        load_too_late: false,
        date: new Date(),
        before_14: false,
        before_18: false,
        after_18: false,
        more_25kg: false,
        is_important: false,
        zones: [],
        couriers: [],
        clients: [],
        statuses: [],
        districts: [],
        without_courier: false,
        substatuses: [],
        shifts: [],
    };

    public selectZoneId: number = null;
    public ordersSelected: string[] = [];
    public zordersSelected: string[] = [];

    public mapCenter: [number, number] = [55.751952, 37.600739];
    public mapZoom: number = 8;
    /* Дата доставки */
    public delivery_date;
    /* Идентификатор курьера */
    public courier_ids;

    constructor(
        protected api: OrdersService,
        protected helper: HelpersService,
        protected dialog: DialogService,
        private currentUserService: CurrentUserService,
        private changeDetectorRef: ChangeDetectorRef,
        protected dataPipe: DatePipe,
        private route: ActivatedRoute,
        public eventer: EventerService,
        protected title: Title) {
        this.formFilter = new FormGroup({
            inputDate: new FormControl(this.dataPipe.transform(this.toDay, 'yyyy-MM-dd'))
        });

        this.eventer.userLookupResponse.subscribe(user => {
            this.onLookup(user);
        });
        this.selectedHub = this.currentUserService.getCurrentHub();
        this.route.queryParams.subscribe((params) => {
            if (params['hub_id']) {
                this.selectedHub =this.currentUserService.getHubById(params['hub_id']);
                this.mapCenter = [this.selectedHub.map_center_lat, this.selectedHub.map_center_lon];
            }
        });
    }

    ngOnInit() {
        // this.setEnabled(false);
        if (this.useMark) {
            this.useMark.emit(this);
        }

        this.title.setTitle('Курьер-контроль');
        //this.selectedHub = this.currentUserService.getCurrentHub();


    }

    ngOnDestroy(): void {

    }

    onOpen() {
        this.isOpen = true;
    }


    loadMap(event) {

        this.ymaps = event.ymaps;
        this.instance = event.instance;

        let elem = this.instance.container.getElement().parentElement.parentElement;
        elem.classList.remove('container');
        elem.classList.add('map');
        this.instance.container.fitToViewport();

        this.mapZoom = 14;
        this.mapCenter = [this.selectedHub.map_center_lat, this.selectedHub.map_center_lon];
        this.instance.setCenter(this.mapCenter, this.mapZoom);

        this.initMap();
        this.bindEventMap();
        this.loadZones();

        this.mapCenter = [this.selectedHub.map_center_lat, this.selectedHub.map_center_lon];
        this.instance.setCenter(this.mapCenter, this.mapZoom);


    }


    getSelectedObjects(target) {
        const result = this.ymaps.geoQuery(this.loadingObjectManager.objects).searchIntersect(target)
            .search('geometry.type == "Point"');
        const arr = [];
        const zarr = [];

        result.each(function (pt) {

            if (pt.properties.get('pointtype') === 'order') {
                arr.push(pt.properties.get('objectID'));
            } else {
                zarr.push(pt.properties.get('objectID'));
            }
        });

        this.ordersSelected = arr;
        this.zordersSelected = zarr;

    }


    bindEventMap() {
        this.instance.geoObjects.events.add('click', e => {
            this.selectZoneId = null;

            const target = e.get('target');
            if (!target.geometry) {
                return;
            }
            if (target.geometry.getType() === 'Polygon') {

                this.selectZoneId = target.options.get('zone_id');
                this.getSelectedObjects(target);

            }
        });
    }


    initMap() {
        const myMap = this.instance;
        const query = this.makeQuery();


        console.log(query);
        const $url = environment.lara_api_url + '/maps/getPointsForMap?' + query;

        this.loadingObjectManager = new this.ymaps.LoadingObjectManager($url, {
            paddingTemplate: 'myCallback_%c',
            splitRequests: false,
            clusterize: false,
        });


        this.loadingObjectManager.objects.events.add('click', e => {


            const objectId = e.get('objectId');
            const object = this.loadingObjectManager.objects.getById(objectId);

            if (object.geometry.type === 'Point') {
                if (object.properties.pointtype === 'order') {
                    this.ordersSelected = [object.properties.objectID];
                    console.log(this.ordersSelected);
                    this.zordersSelected = [];

                } else {
                    this.zordersSelected = [object.properties.objectID];
                    console.log(this.zordersSelected);
                    this.ordersSelected = [];

                }
            }
            this.changeDetectorRef.detectChanges();
            this.bodyClick();
        });

        this.instance.geoObjects.add(this.loadingObjectManager);
        this.getPointsCount();
        console.log('objects added');

    }

    /* TODO костыль из-за специфики рендера yandex map */
    bodyClick() {
        $('body').click();
    }


    getOldZorderId(objectId) {
        const object = this.loadingObjectManager.objects.getById(objectId);
        if (object.geometry.type === 'Point') {
            return object.properties.old_zorder_id;
        }
        return '';
    }


    implodeIds(arrOfObjects, id_field = '') {
        let res_str = '';
        if (!id_field) {
            id_field = 'id';
        }
        for (const one of arrOfObjects) {

            if (res_str) {
                res_str += ',';
            }

            res_str += one[id_field];

        }
        return res_str;
    }

    implodeSts(arrOfObjects) {
        let res_str = '';
        for (const one of arrOfObjects) {

            console.log(one);
            if (res_str) {
                res_str += ',';
            }
            res_str += one;

        }
        return res_str;
    }

    makeQuery() {
        this.route.queryParams.subscribe((params) => {
            if (params['delivery_date'] &&  params['courier_ids'] ) {
                this.delivery_date = params['delivery_date'];
                this.courier_ids = params['courier_ids'];

                /* Установка даты */
                if (this.delivery_date) {
                    this.delivery_date = this.delivery_date.split('.');
                    this.formFilter.get('inputDate').setValue(this.delivery_date[2] + '-' + this.delivery_date[1] + '-' + this.delivery_date[0]);
                }

                /* Установка курьера */
                if (this.courier_ids) {
                    this.eventer.userLookupById.emit(this.courier_ids);

                    this.mapZoom=10;
                }

            }
        });
        console.log(this.selectedHub);

        const query = 'hub_id=' + this.selectedHub.id + '&' +
            'per=' + (this.filters.load_perenos ? 1 : 0) + '&' +
            (this.filters.load_orders ? 'ord=1' : '') +
            (this.filters.load_zorders ? '&zord=1' : '') +
            (this.filters.load_too_late ? '&too_late=1' : '') +

            '&date=' + this.helper.formatDateForSQL(this.inputDate.nativeElement.value) +
            '&cr_ids=' + this.implodeIds(this.filters.couriers) +
            '&cl_ids=' + this.implodeIds(this.filters.clients) +
            '&sts=' + this.filters.statuses.join(',') +
            '&shs=' + this.filters.shifts.join(',') +
            '&subs=' + this.implodeIds(this.filters.substatuses, 'substatus_id') +
            '&zone_ids=' + this.implodeIds(this.filters.zones, 'zone_id') +
            '&d_ids=' + this.implodeIds(this.filters.districts) +

            (this.filters.before_14 ? '&bef_14=1' : '') +
            (this.filters.before_18 ? '&bef_18=1' : '') +
            (this.filters.after_18 ? '&af_18=1' : '') +
            (this.filters.is_important ? '&is_imp=1' : '') +
            (this.filters.without_courier ? '&wc=1' : '') +
            (this.filters.more_25kg ? '&more_25kg=1' : '');

        return query;
    }

    reLoadObjects() {
        // console.log(this.filters);

        //  console.log(this.selectedHub);

        if (this.hubChanged) {
            this.mapCenter = [this.selectedHub.map_center_lat, this.selectedHub.map_center_lon];
            this.instance.setCenter(this.mapCenter, this.mapZoom);
            this.hubChanged = false;
        }

        const query = this.makeQuery();

        const $url = environment.lara_api_url + '/maps/getPointsForMap?' + query;
        this.loadingObjectManager.setUrlTemplate($url);
        this.loadingObjectManager.reloadData();
        this.getPointsCount();
        console.log('загрузили заново');

    }


    // Подгрузка зон
    loadZones() {


        this.api.getZones(0, null, 1).subscribe(zones => {
            // Проходимся по массиву
            for (const data of zones) {


                const poly = this.ymaps.geometry.Polygon.fromEncodedCoordinates(data.geometry);
                const polygon = new this.ymaps.Polygon(poly);

                this.setPolygonProps(polygon, data);
                /* конвертация координат в нормальную последовательность */

                const coors = polygon.geometry.getCoordinates();
                const coorsTo = coors.map(cor => {
                    return cor.map(point => [point[1], point[0]]);
                });
                polygon.geometry.setCoordinates(coorsTo);
                this.instance.geoObjects.add(polygon);

            }
        });
    }


    onSelectCourier(users: User[]) {
        this.filters.couriers = users;
        this.selectedUsers = users;
    }

    onSelectClient(clients: ClientInfo[]) {
        this.filters.clients = clients;
    }

    onChangeZone(zones: OrderZone[]) {
        this.filters.zones = zones;
    }

    onChangeDistrict(districts: District[]) {
        this.filters.districts = districts;
    }

    onChangeStatuses(statuses) {
        this.filters.statuses = statuses;
    }

    onChangePerenos(statuses) {

        //this.filters.load_perenos = true;
        this.filters.substatuses = statuses;
    }

    updateCheckBox(event, key) {
        switch (key) {
            case 'before_14': {
                this.filters.before_14 = event.target.checked;
                break;
            }
            case 'before_18': {
                this.filters.before_18 = event.target.checked;
                break;
            }
            case 'after_18': {
                this.filters.after_18 = event.target.checked;
                break;
            }
            case 'more_25kg': {
                this.filters.more_25kg = event.target.checked;
                break;
            }
            case 'is_important': {
                this.filters.is_important = event.target.checked;
                break;
            }
            case 'load_orders': {
                this.filters.load_orders = event.target.checked;
                break;
            }
            case 'load_zorders': {
                this.filters.load_zorders = event.target.checked;
                break;
            }
            case 'load_too_late': {
                this.filters.load_too_late = event.target.checked;
                break;
            }
            case 'load_perenos': {
                this.filters.load_perenos = event.target.checked;
                break;
            }
            case 'without_courier': {
                this.filters.without_courier = event.target.checked;
                break;
            }


        }
    }


    setPolygonProps(polygon, zone) {
        polygon.options.set({
            fillColor: (zone.color ? zone.color : '#554433'),
            strokeColor: '#3076B4',
            opacity: 0.5,
            strokeWidth: 1,
            objectID: zone.id,
            zone_id: zone.zone_id, // Место
            shelf: zone.shelf, // Полка
            district_id: zone.district_id, // Направление ид
            district_name: (zone.district ? zone.district.name : ''), // Направление имя
            tarif: zone.tarif
        });
    }

    public selectedUsers = [];
    onLookup(event) {
        this.filters.couriers = event ? [event] : null;
        this.selectedUsers = event ? [event] : [];
    }

    onChangeHub(hub) {
        this.selectedHub = hub;
        this.hubChanged = true;
        this.appUserSelectEventsSubjectEmit();
    }

    appUserSelectEventsSubjectEmit() {
        this.appHubChangeEventSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: [this.selectedHub]});
    }

    getPointsCount() {
        const query = this.makeQuery();
        this.pointsCount = -1;
        this.api.getPointCount(query + "&count=1").subscribe(data => {

            this.pointsCount = data;
            this.bodyClick();
            console.log(this.pointsCount);
        });
    }
    onChangeShift(shifts) {
        this.filters.shifts = shifts;
    }
}


