import {Component, ComponentFactoryResolver, OnInit} from '@angular/core';
import {SkladService} from '../../../service/sklad.service';
import {ActivatedRoute} from '@angular/router';
import {AppComponent} from '../../../app.component';
import {Order} from "../../../service/models";

@Component({
    selector: 'app-print-courier-mact',
    templateUrl: './print-courier-mact.component.html',
    styleUrls: ['./print-courier-mact.component.scss']
})
export class PrintCourierMactComponent implements OnInit {

    mactId: string;
    mactData: any;
    loading: boolean;
    loadMact = false;
    totalCount = 0;
    totalCnt = 0;
    totalPrc = 0;
    totalPrice = 0;

    constructor(
        private route: ActivatedRoute,
        private api: SkladService,
        private resolver: ComponentFactoryResolver,
        private app: AppComponent
    ) {
    }


    ngOnInit() {

        this.loading = true;

        this.route.params.subscribe(params => {
            this.mactId = params['mactId'];
            this.loading = false;
        });

        this.loadMact = false
        this.api.getMact(this.mactId).subscribe((data) => {
            this.mactData = data;
            this.loadMact = true;
            this.calculate();

        })
    }

    printPage() {
        window.print();
    }

    /**
     * Суммирование оценочной стоимости и кол-ва мест
     * @private
     */
    private calculate() {
       for (const val of this.mactData.courier_act.orders) {
           this.totalCnt += Number(val.hash_bar_codes_count);
           this.totalPrc +=  Number(this.getPrice(val));
       }
    }

    /**
     *
     * LSD-15613
     * Возвращает ОС или сумму товаров
     * в зависимости, что больше
     * @param order
     */
    getPrice(order: Order) {
        const price = (order.bill.payment_card >= 1) ? order.bill.payment_card : order.bill.payment_cash;
        return (order.bill.os > price) ? order.bill.os : price;
    }
}
