import {CounterComponent} from '../storage/board-online/counter.component';
import {SkladService} from 'app/service/sklad.service';
import {Component, OnInit, ViewChild} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {Hub} from "../service/models";
import {HubsService} from "../service/hubs.service";
import {CurrentUserService} from "../service/current-user.service";
import {ActivatedRoute, Router} from "@angular/router";

@Component({
    selector: 'app-sberbank',
    templateUrl: './sberbank.component.html',
    styleUrls: ['./sberbank.component.scss']
})

export class SberbankComponent implements OnInit {

    public loading: boolean|null = false;
    public todayCouriers = [];
    public notReadyCouriers = [];
    public statuses = ['Пришёл', 'В режиме сборки', 'Собран'];

    public tmpUseImprovedProcedure: boolean = false;
    public tmpToggleImprovedModeCounter: number = 0;

    @ViewChild('counter', {read: CounterComponent})
    private counter: CounterComponent;

    counterState = 'counter is ticking';

    public selectedHubId: number;
    public selectedHubs: Hub[];
    public hubsList: Hub[];

    constructor(
        private api: SkladService,
        private hubsService: HubsService,
        private currentUserService: CurrentUserService,
        private route: ActivatedRoute,
        private router: Router,
        protected title: Title
    ) {
        setInterval(() => {
            window.location = window.location;
        }, 14400000);
    }

    ngOnInit() {
        this.route.queryParams.subscribe((params) => {
            this.tmpUseImprovedProcedure = !!(params['useImprovedProcedure'] || false);
        });

        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
            let userHub = this.currentUserService.getCurrentHub();
            let hub = hubs.find((hub: Hub) => hub.id === userHub.id);

            this.selectedHubs = [hub, ];
            this.selectedHubId = hub.id;

            this.loadingSberbank(this.selectedHubId);
            this.counter.startAt = 45;
            this.counter.counterState.subscribe(msg => {
                if (msg === 'COMPLETE') {
                    this.counterState = 'counter has stopped';
                    this.counter.start();
                    this.loadingSberbank(this.selectedHubId);
                }
            });
            this.counter.start();

            this.title.setTitle('Сбербанк');
        });
    }

    loadingSberbank(hub_id: number) {
        if (false === this.loading) {
            this.loading = true;
        }

        this.api.getSberbank(hub_id, this.tmpUseImprovedProcedure).subscribe(courier => {
            this.notReadyCouriers = courier;
            this.loading = null;
        });
    }

    timeAssemble (start, end, status) {
        const today = new Date();
        const month = +today.getMonth() + 1;
        if (status === 2) {
            const str = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + start;
            return Math.round(((new Date().getTime() - new Date(str).getTime()) / 1000) / 60);
        } else if (status === 3) {
            const start_time = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + start;
            const end_time = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + end;
            return Math.round(((new Date(end_time).getTime() - new Date(start_time).getTime()) / 1000) / 60);
        }
    }

    onChangeHub(hub) {
        if (this.selectedHubId !== hub.id) {
            this.loading = false;
        }

        this.selectedHubs = [hub, ];
        this.selectedHubId = hub.id;
        this.loadingSberbank(this.selectedHubId);
    }

    public onTitleClick() {
        this.tmpToggleImprovedModeCounter++;
        if (this.tmpToggleImprovedModeCounter > 2) {
            this.tmpToggleImprovedModeCounter = 0;
            this.tmpUseImprovedProcedure = !this.tmpUseImprovedProcedure;
            const routePrefix = this.router.url.match(/(.*)\/sberbank/)[1] || '';
            console.warn(this.router.url, routePrefix);
            const queryParams = {
                useImprovedProcedure: 0,
            };
            if (this.tmpUseImprovedProcedure) {
                queryParams.useImprovedProcedure = 1;
            } else {
                delete queryParams.useImprovedProcedure;
            }

            this.router.navigate([routePrefix + '/sberbank'], {queryParams});
        }
    }
}
