import {Component, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {AppComponent} from '../../app.component';

@Component({
    selector: 'app-courier-interface',
    templateUrl: './courier-interface.component.html',
    styleUrls: ['./courier-interface.component.scss']
})
export class CourierInterfaceComponent implements OnInit {
    public formDeliveryDate: FormGroup;
    public deliveryDate = new Date();

    constructor(
        protected app: AppComponent
    ) {
        this.formDeliveryDate = new FormGroup({
            deliveryDate: new FormControl(this.deliveryDate)
        });
    }

    onDateChange(date) {
        if (!date) {
            return;
        }

    }

    ngOnInit() {
    }

}
