import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {HttpNoInterceptorService} from '../../service/http-no-interceptor.service';

@Component({
    selector: 'app-second-shift-assembling',
    templateUrl: './second-shift-assembling.component.html',
    styleUrls: ['second-shift-assembling.component.css']
})

export class SecondShiftAssemblingComponent implements OnInit {
    public formScan: FormGroup;
    public data;
    public notifyMessage: string = null;
    public isError = false;
    public loading = false;
    public printBarCode = null;
    @ViewChild('barcode') barcode: ElementRef;


    ngOnInit(): void {
        setTimeout(() => this.clearAndFocus(), 500);
    }
    constructor(
        private api: HttpNoInterceptorService,
    ) {
        this.formScan = new FormGroup({
            barcode: new FormControl('', Validators.required),
        });
    }

    onBarCodeKeyDown(event) {
        if (event.keyCode !== 13) {
            return;
        }
        this.onScan();
    }

    onScan() {
        if (!this.formScan.valid) {
            return this.clearAndFocus();
        }
        this.loading = true;
        this.isError = false;
        this.notifyMessage = null;
        this.data = [];
        this.api.get('/storage/assemblingSecondShift/' + this.formScan.controls.barcode.value).subscribe((data: any) => {
            console.warn(data.body.response.data);
            this.loading = false;
            this.isError = false
            this.data = data.body.response.data;
            this.notifyMessage = data.body.response.message;
            this.clearAndFocus();
        }, error => {
            console.warn(error);
            this.notifyMessage = error.error.response.message;
            this.isError = true;
            this.loading = false;
            this.clearAndFocus();
        });
    }

    clearAndFocus() {
        this.formScan.reset();
        this.barcode.nativeElement.focus();
    }
}
