import {Injectable} from '@angular/core';
import {environment} from "../../environments/environment";
import {Observable} from "rxjs";
import {Hub, Order, OrderGood, OrderZone, User} from './models';
import {OrdersService} from "./orders.service";
import {CurrentUserService} from "./current-user.service";

@Injectable({
    providedIn: 'root'
})
export class HelpersService {
    static zones: OrderZone[] = [];
    private expenseTypes = [
        'Неизвестный тип',
        'Парковка',
        'Другое',
        'Парковка в БЦ'
    ];

    private expenseStatuses = [
        'Запрос',
        'Одобрен',
        'Отменен'
    ]

    public QCCompleteNames = ['0 - отлично',
        '1 - Нет региона',
        '2 - Нет города',
        '3 - Нет улицы',
        '4 - Нет дома',
        '5 - Нет квартиры',
        '6 - Адрес неполный',
        '7 - иностранный адрес',
        '8 - OK',
        '9 - Нужна проверка разбора',
        '10 - Под вопросом, дома нет в фиас'];


    public QCNames = ['0 - Адрес распознан уверенно',
        '1 - остались «лишние» части',
        '2 - Адрес «мусорный»',
        '3 - есть альтернативы'];

    constructor(
        private api: OrdersService,
        protected currentUser: CurrentUserService
    ) {
    }


    public isYandex(clientId) {
        return (clientId === environment.yandex_client_id);
    }

    public isDetmir(clientId) {
        return (clientId === environment.detmir_client_id);
    }


    public expenseTypeName(type) {
        return this.expenseTypes[type];
    }

    public expenseStatusName(status) {
        return this.expenseStatuses[status];
    }

    /**
     * Хешированный список зон
     */
    public getZones(zone_type = 1) {
        return new Observable((observer) => {
            if (!HelpersService.zones.length) {
                this.api.getZones(null, null, zone_type).subscribe(zones => {
                    HelpersService.zones[zone_type] = zones;
                    observer.next(HelpersService.zones[zone_type]);
                    observer.complete();
                });
            } else {
                observer.next(HelpersService.zones[zone_type]);
                observer.complete();
            }
        });
    }

    /**
     * Вспомогательный метод возращающий
     * цвет статуса забора по id статусу
     * @param idStatus
     */
    public getColorStatus(idStatus) {
        const colors = [
            'gray',//новый
            'primary',//подтверждённый
            'success',//выполнен
            'danger',//отмена
            'success',//Выполняется
            'info',//перенос
            'teal',//На складе
        ];
        if (!colors[idStatus - 1]) {
            return 'primary';
        }
        return colors[idStatus - 1];
    }

    /**
     * Название статуса заказа
     * @param status
     */
    public getNameOrderStatus(status: any) {
        let statusInt = status;
        if (status instanceof Order) {
            statusInt = status.status;
        }

        const statuses = [
            'Новая заявка',
            'Заявка принята',
            'На складе',
            'На доставке',
            'Доставлен',
            'Частичный отказ',
            'Полный отказ',
            'Отмена',
            'В перемещении',
        ];
        if (!statuses[statusInt - 1]) {
            return 'неизвестный статус';
        }
        return statuses[statusInt - 1];
    }

    public getQCGeoName(status) {
        const statuses = ['0 — точные координаты',
            '1 — ближайший дом',
            '2 — улица',
            '3 — населенный пункт',
            '4 — город',
            '5 — координаты не определены'];
        return statuses[status];
    }

    public getQCCompleteName(status) {

        return this.QCCompleteNames[status];
    }

    public getQCName(status) {
        return this.QCNames[status];
    }

    public getNameGoodStatus(good: OrderGood) {
        const statuses = [
            'Ожидается на склад',
            'Принят на склад',
            'Выдан на доставку',
            'Доставлен',
            'Отказной товар',
            'Выдан с возвратом',
            'Отмена',
            'Ожидается от Агента'
        ];
        if (!statuses[good.status - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';
        }
        return statuses[good.status - 1];
    }

    public getNamePlaceStatus(status) {
        const statuses = [
            'Ожидается на склад',
            'Принят на склад',
            'Выдан на доставку',
            'В перемещении'
        ];

        if (!statuses[status - 1]) {
            return 'неизвестный статус';
        }
        return statuses[status - 1];

    }

    public getNameTEStatus(status) {
        const statuses = [
            'Новая',
            'Собрана',
            'На доставке',
            'Доставлена'
        ];
        if (!statuses[status - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';
        }
        return statuses[status - 1];
    }


    public getNameTransitStatus(status: number) {
        const statuses = [
            'Новое',
            'На доставке',
            'Доставлено'
        ];

        if (!statuses[status - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';
        }
        return statuses[status - 1];
    }

    /**
     * Название статуса забора
     * @param num
     */
    public getNameZOrderStatus(num) {
        const statuses = [
            'Новый',
            'Подтвержден',
            'Выполнен',
            'Отмена',
            'Выполняется',
            'Перенос',
            'На складе'

        ];

        //if (num === 7) {
        //    return 'На складе';
        //}
        if (!statuses[num - 1]) {

            return 'неизвестный статус ' + num;


        }
        return statuses[num - 1];
    }

    /**
     * Название статуса курьера
     * @param courierStaus
     */
    public getNameCourierStatus(courierStaus: any) {
        const statuses = [
            'Пришел',
            'В сборке',
            'Собран'
        ];
        if (courierStaus.queue_status === null) {
            return 'Не пришел';
        }
        if (!statuses[courierStaus.queue_status - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';

        }
        return statuses[courierStaus.queue_status - 1];
    }

    public getColorCourierStatus(courierStaus: any) {
        const statuses = [
            'status_alert bg-primary text-white',
            'status_alert bg-warning',
            'status_alert bg-success text-white'
        ];
        if (courierStaus.queue_status === null) {
            return 'status_alert bg-danger text-white';
        }
        if (!statuses[courierStaus.queue_status - 1]) {
            // throw new Error('Invalid status');
            return;
        }
        return statuses[courierStaus.queue_status - 1];
    }

    public getCourierType(type: number) {
        const statuses = [
            'Авто',
            'Пеший',
            'Грузовой',
            'Стажер',
            'Наёмник',
            'Fastzilla',
            'Stadol'
        ];
        if (!statuses[type - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный тип';
        }
        return statuses[type - 1];
    }

    public getReturnActStatusName(returnAct: any) {
        const statuses = [
            'Новый',
            'Выдан на возврат',
            'Подписан клиентом'
        ];
        if (!statuses[returnAct.status]) {
            // throw new Error('Invalid status');
        }
        return statuses[returnAct.status];
    }

    /**
     * Вывести месяц
     * @param month
     */
    public getMonthName(date) {
        const month = new Date(date).getMonth();
        const monthes = [
            'января', 'февраля', 'марта',
            'апреля', 'мая', 'июня', 'июля',
            'августа', 'сентября', 'октября',
            'ноября', 'декабря'
        ];
        if (!monthes[month]) {
            // throw new Error('Invalid month');
            return 'неизвестный месяц';
        }
        return monthes[month];
    }

    /**
     * Период доставки
     * @param interval
     */
    public getDeliveryTimePeriod(interval: number, deliveryTimeStart: number = 0, deliveryTimeEnd: number = 0) {
        switch (interval) {
            case 1: {
                return '10:00 - 14:00';
            }
            case 2: {
                return '10:00 - 18:00';
            }
            case 3: {
                return '14:00 - 18:00';
            }
            case 4: {
                return '18:00 - 22:00';
            }
            case 5: {
                return '10:00 - 22:00';
            }
            case 11: {
                return '12:00 - 18:00';
            }
            case 12: {
                return '18:00 - 22:00';
            }
            case 13: {
                return '12:00 - 22:00';
            }
            default: {
                if (!deliveryTimeStart || !deliveryTimeEnd) {
                    return 'Не передали интервал';
                } else {
                    return ((deliveryTimeStart < 10) ? '0' : '') + deliveryTimeStart + ':00 - ' + deliveryTimeEnd + ':00';
                }
            }
        }
    }

    /**
     * Период доставки с объекта заказа
     * @param order
     * @param minimized
     */
    getDeliveryTimePeriodOrder(order, minimized = false) {
        let start;
        let end;
        if (order.delivery_time) {
            const time = order.delivery_time;
            if (time === 1) {
                start = 10;
                end = 14;
            } else if (time === 2) {
                start = 10;
                end = 18;
            } else if (time === 3) {
                start = 14;
                end = 18;

            } else if (time === 4) {
                start = 18;
                end = 22;

            } else if (time === 5) {
                start = 10;
                end = 22;

            } else if (time === 11) {
                start = 12;
                end = 18;
            } else if (time === 12) {
                start = 18;
                end = 22;
            } else if (time === 13) {
                start = 12;
                end = 22;
            }
        } else {
            start = order.delivery_time_start;
            end = order.delivery_time_end;
        }
        if (start && end) {
            if (minimized) {
                return start + '-' + end
            } else {
                return start + ':00 - ' + end + ':00'
            }
        } else {
            return '-';
        }
    }

    /**
     * Расшифровка опции вскрытия
     * @param option
     */
    public getOptionOpeningName(option: number) {
        switch (option) {
            case 1: {
                return 'Разрешено';
            }
            case 2: {
                return 'Только внешней упаковки заказа';
            }
            case 3: {
                return 'Запрещено';
            }
            default: {
                return 'Не указано';
            }
        }
    }

    /**
     * Список предстатусов для проблем
     */
    public getPredstatusList() {
        const predstatusList = [
            'Недозвон',
            'Сдвиг',
            'Перенос',
            'Отмена',
            'Подъём КГТ'
        ];
        return predstatusList;
    }

    /**
     * Получение предстатуса для проблем
     * @param predstatus
     */
    public getPredstatus(predstatus) {
        if (predstatus === '2-27') {
            return 'Сдвиг';
        }
        const predstatuses = [
            'Недозвон',
            'Перенос',
            'Отмена',
            'КГТ'
        ];
        if (predstatus == 99) {
            return 'КГТ';
        }
        if (!predstatuses[predstatus - 1]) {
            // throw new Error('Invalid status');
            return 'неизвестный статус';
        }
        return predstatuses[predstatus - 1];
    }

    public fileTypeName(type: number) {
        switch (type) {
            case 1: {
                return 'Претензионный лист';
            }
        }
    }

    /**
     * Расшифровка типа ндс
     * @param nds
     */
    public getNdsName(nds: number) {
        switch (nds) {
            case 1: {
                return 'HДС 18%';
            }
            case 2: {
                return 'Без HДС';
            }
            case 3: {
                return 'HДС 10%';
            }
            case 4: {
                return 'HДС 18/118';
            }
            case 5: {
                return 'HДС 10/110';
            }
            case 6: {
                return 'НДС 0%';
            }
            case 7: {
                return 'НДС 20%';
            }
            case 8: {
                return 'НДС 20/120';
            }
            default: {
                return '?';
            }
        }
    }

    /**
     * Права доступа для карточки заказа
     * @param name
     * @param user
     */
    public checkPermissions(name_permission) {
        let user: any = [];
        this.currentUser.get().subscribe(data => {
            user = data;
        })
        for (const user_permission of user.permissions) {
            if (name_permission === user_permission.name) {
                return true;
            }
        }
        return false;
    }

    public viewerRules(name, user, order) {
        if (user.role === 1) {
            return true;
        }
        let operator = false;
        if (user.role === 9) {
            operator = true;
        }
        let ChiefOperator = false;
        /*for (const group of user.groups) {
            if (group.title === 'ChiefOperator') {
                ChiefOperator = true;
            }
        }*/
        switch (name) {
            case 'edit_order': {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'is_important': {
                if (operator && ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'courier': {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator || ChiefOperator) {
                        return true;
                    }
                }
                break;
            }
            case 'set_status' : {
                // TODO добавить установку конечного статуса в интерфейс
                break;
            }
            case 'edit_goods' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    if (ChiefOperator) {
                        return true;
                    } else {
                        return false;
                    }
                } else {
                    if (operator || ChiefOperator) {
                        return true;
                    }
                }
                break;
            }
            case 'edit_options' : {
                if (ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'address' : {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'weight' : {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'dimension' : {
                if (operator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'operator_comment' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator) {
                        return true;
                    } else {
                        return false
                    }
                }
                break;
            }
            case 'delivery_date' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator) {
                        return true;
                    } else {
                        return false
                    }
                }
                break;
            }
            case 'interval' : {
                if (order.status === 5 || order.status === 6 || order.status === 7 || order.status === 8) {
                    return false;
                } else {
                    if (operator) {
                        return true;
                    } else {
                        return false
                    }
                }
                break;
            }
            case 'drop_status' : {
                if (ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            case 'inner_n' : {
                if (ChiefOperator) {
                    return true;
                } else {
                    return false;
                }
                break;
            }
            default : {
                break;
            }
        }
    }


    public formatDateForSQL(str) {
        return str.replace(/(\d{2})\.(\d{2})\.(\d{4})/, '$3-$2-$1');
    }

    public formatDate(str) {
        return str.replace(/(\d{4})-(\d{2})-(\d{2})/, '$3.$2.$1');
    }

    public formatDateTime(str) {
        return str.replace(/(\d{4})-(\d{2})-(\d{2}) (\d{2}:\d{2}):(\d{2})/, '$3.$2.$1 $4');
    }

    /**
     routeLink на печать возвратного акта
     **/
    public getReturnActPrintRouteLink(oldVactId) {
        return [
            '/print-return-acts',
            {
                'old_vact_id': oldVactId
            }
        ]
    }

    /**
     * Фильтрует список пользователей непустым списком хабов по дефолтному хабу
     * @param users
     * @param hubs
     */
    public filterUsersListByHubsList(users: (User | null)[], hubs: ((Hub | number)[]) | null) {
        if (null === hubs || !hubs.length) {
            return users;
        }

        return users.filter((user: User | null) => {
            if (null === user || !user.hasOwnProperty('default_hub_id')) {
                return true;
            }

            return !!hubs.find((item: Hub | number) => {
                if (typeof item === "number") {
                    return item === user.default_hub_id;
                } else {
                    return item.id === user.default_hub_id;
                }
            });
        });
    }

    /**
     * Отфильтровать пользователей по смене
     * @param users
     * @param shiftNumber
     */
    public filterUsersListByShiftNumber(users: User[], shiftNumber: number) {
        return users.filter(user => {
            if (!user) {
                return true;
            }

            switch (shiftNumber) {
                case 1:
                    return user.work_is_first_shift;
                    break;
                case 2:
                    return user.work_is_second_shift;
                    break;
            }

            return true;
        })
    }

    /**
     * Возвращает нужную форму существительного рядом с числительным
     * @param number
     * @param wordForms
     */
    public getPluralEnding(number: number, wordForms: string[]) {
        const cases = [2, 0, 1, 1, 1, 2];
        return wordForms[(number % 100 > 4 && number % 100 < 20) ? 2 : cases[(number % 10 < 5) ? number % 10 : 5]];
    }

    /**
     * Возращает настройку клиента
     * @param settings
     * @param key
     */
    public getClientSetting(settings, key) {
        const setting = settings.find(value => value.setting_key.key === key);
        if (setting) {
            return setting.setting_value;
        }
        return null;
    }

}
