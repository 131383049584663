import {Component, OnInit} from '@angular/core';
import {User, UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../../statistics/statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {FileDownloadService} from '../../http/file-download.service';
import {LaraService} from '../../service/lara.service';

@Component({
    selector: 'app-couriers-map',
    templateUrl: './couriers-map.component.html',
    styleUrls: ['couriers-map.component.scss']
})
export class CouriersMapComponent implements OnInit {
    public isLoading = false;
    public user: User = null;
    public selectedHub: UserHub = null;
    public points;

    protected ymaps: any;
    protected instance: any;
    public mapZoom = 8;
    public mapCenter: [number, number] = [55.751952, 37.600739];
    ngOnInit(): void {

    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        public currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        public helpers: HelpersService,
        private fileDownloadService: FileDownloadService,
        private api: LaraService
    ) {
        this.selectedHub = this.currentUserService.getCurrentHub();
         this.currentUserService.get().subscribe(data => {
            if (data instanceof User) {
                this.user = data;
                this.loadReport();
            }
        })
    }

    /**
     * Загрузка отчета
     */
    loadReport() {
        this.isLoading = true;
        if (!this.user) {
            return this.notifier.openNotifier('Ошибка определения пользователя', null, {class: 'danger', duration: 5000});
        }
        this.api.getCouriersPoints(this.user.id).subscribe((data: any) => {
            this.points = data;
            this.isLoading = false;
        }, error => {
            console.warn(error);
            this.isLoading = false;
        });
    }

    loadMap(event) {
        this.ymaps = event.ymaps;
        this.instance = event.instance;
        const elem = this.instance.container.getElement().parentElement.parentElement;
        elem.classList.remove('container');
        elem.classList.add('map');
        this.instance.container.fitToViewport();

        this.mapZoom = 9;
        this.mapCenter = [this.selectedHub.map_center_lat, this.selectedHub.map_center_lon];
        this.instance.setCenter(this.mapCenter, this.mapZoom);
    }

    getProperties(point) {
        if (point.uid) {
            return {
                iconContent: point.uid + ' ' + point.interval,
                balloonContentHeader: point.uid + ' ' + point.interval,
                balloonContentBody: point.target.address_text,
            }
        }

        return {
            iconContent: 'Забор №' + point.old_zorder_id + ' ' + point.warehouse.working_hours,
            /* todo Ganzal: а тут не должно ли быть интервала самого забора? */
            balloonContentHeader: 'Забор №' + point.old_zorder_id + ' ' + point.warehouse.working_hours,
            balloonContentBody:  point.address_text ? point.address_text : point.warehouse.address_text,
        }
    }
    getOption(point) {
        if (point.uid) {
            return {
                preset: 'islands#darkGreenStretchyIcon',
            };
        }
        return {
            preset: 'islands#darkOrangeStretchyIcon',
        };
    }

    getGeometry(point) {
        if (point.target) {
            return [point.target.lat, point.target.lon];
        }

        if (point.lat && point.lon) {
            return [point.lat, point.lon];
        }

        return [point.warehouse.lat, point.warehouse.lon];
    }
}
