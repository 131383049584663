import {Component} from '@angular/core';
import {OrderDialogComponent} from "../order-dialog/order-dialog.component";

@Component({
    selector: 'app-order-dialog-mini',
    templateUrl: './order-dialog-mini.component.html',
    styleUrls: ['./order-dialog-mini.component.scss']
})
export class OrderDialogMiniComponent extends OrderDialogComponent {

}
