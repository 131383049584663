import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent implements OnInit {
    public baseUrl = '/assets/files/';
    public baseFormat = '.docx';
    public documents = [
        {
            name: 'Служебная записка - Изменения штатного расписания',
            url:  this.baseUrl + 'shatrasp' + this.baseFormat,
        },
        {
            name: 'ЛГ Заявление на отпуск без сохранения зп',
            url:  this.baseUrl + 'Логсис_Групп_Заявление_на_бс' + this.baseFormat,
        },
        {
            name: 'ЛГ Заявление на отпуск',
            url:  this.baseUrl + 'Логсис_Групп_Заявление_на_отпуск' + this.baseFormat,
        },
        {
            name: 'ЛГ Заявление на увольнение',
            url:  this.baseUrl + 'Логсис_Групп_Заявление_на_увольнение' + this.baseFormat,
        },
        {
            name: 'ЛГ Заявление на выдачу документов по работе',
            url:  this.baseUrl + 'Логсис_Групп_заявление_о_выдачи_справки' + this.baseFormat,
        },
        {
            name: 'ЛГ Служебная записка',
            url:  this.baseUrl + 'Логсис_Групп_Служебная_записка' + this.baseFormat,
        },
        {
            name: 'ЛС Сервис Заявление на отпуск без сохранения зп',
            url:  this.baseUrl + 'ЛС_Сервис_Заявление_на_бс' + this.baseFormat,
        },
        {
            name: 'ЛС Сервис Заявление на отпуск',
            url:  this.baseUrl + 'ЛС_Сервис_Заявление_на_отпуск' + this.baseFormat,
        },
        {
            name: 'ЛС Сервис Заявление на увольнение',
            url:  this.baseUrl + 'ЛС_Сервис_Заявление_на_увольнение' + this.baseFormat,
        },
        {
            name: 'ЛС Сервис Заявление на выдачу документов по работе',
            url:  this.baseUrl + 'ЛС_Сервис_Заявление_о_выдачи_справки' + this.baseFormat,
        },
        {
            name: 'ЛС Сервис Служебная записка',
            url:  this.baseUrl + 'ЛС_Сервис_Служебная_записка' + this.baseFormat,
        },
        {
            name: 'Бланк Служебной записки',
            url:  this.baseUrl + 'Организация_Служебная_записка' + this.baseFormat,
        },
    ];

    ngOnInit(): void {
    }
}
