import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {ClientInfo, Hub, OrderZone, ParentCompany, User, UserHub} from '../../service/models';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {CurrentUserService} from '../../service/current-user.service';
import {StatisticsService} from '../statistics.service';
import {HelpersService} from '../../service/helpers.service';
import {Subject} from 'rxjs';
import {HubsService} from '../../service/hubs.service';
import {LaraService} from '../../service/lara.service';
import {DailyMeetingClientsComponent} from "../daily-meeting/daily-meeting-clients/daily-meeting-clients.component";
import {DialogService} from "../../components/dialog/dialog.service";
import {AppComponent} from "../../app.component";
import {DailyMeetingOrdersComponent} from "../daily-meeting/daily-meeting-orders/daily-meeting-orders.component";
import {NewStatOrdersComponent} from "./new-stat-orders/new-stat-orders.component";

@Component({
    selector: 'app-new-stat',
    templateUrl: './new-stat.component.html',
    styleUrls: ['./new-stat.component.scss']
})
export class NewStatComponent implements OnInit {
    public gridData: GridDataResult;
    public date = new Date();
    public formList: FormGroup;
    public isLoading = false;
    public rowIndex = 1;
    public selectedHubs: UserHub[];

    public days: Day[] = [
        {value: '0', name: 'Все дни'},
        {value: '2,3,4,5,6', name: 'Будни'},
        {value: '1,7', name: 'Выходные'}
    ];

    public points: Point[] = [
        {value: 0, name: 'Все точки'},
        {value: 1, name: 'Заказы'},
        {value: 2, name: 'Заборы'}
    ];


    public toggleText = 'Показать';
    public showHidden = false;

    public parentCompanies: ParentCompany[];
    public selectedClients: ClientInfo[] = [];
    public selectedZones: OrderZone[] = [];
    public selectedUsers: User[] = [];

    public selectedStatuses = [];
    public selectedCourierType = [];

    public cancelReasons = [];
    public changeDateReasons = [];

    public hubsList: Hub[] = null;
    public selectedHubId: number;
    public appUserSelectEventsSubject: Subject<any> = new Subject<any>();
    public appStatusChangedEventsSubject: Subject<any> = new Subject<any>();

    public statData = [];

    public statusNames = [
        'Выполнено',
        '- Доставлена',
        '- Частичный отказ',
        '- Полный отказ',
        'Не выполнено'];

    public statusValues = [
        '5,6,7',
        '5',
        '6',
        '7',
        '8,100'
    ];

    public toggleHidden() {
        this.showHidden = !this.showHidden;
        if (this.showHidden) {
            this.toggleText = 'Скрыть';
        } else {
            this.toggleText = 'Показать';
        }
    }

    ngOnInit(): void {
        this.hubsService.get().subscribe((hubs: Hub[]) => {
            this.hubsList = hubs;
        });
        const hub = this.currentUserService.getCurrentHub();
        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;
        this.formList.controls.hub_id.setValue(hub.id);
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        private currentUserService: CurrentUserService,
        private statisticsService: StatisticsService,
        private api: LaraService,
        public helpers: HelpersService,
        protected dataPipe: DatePipe,
        protected hubsService: HubsService,
        protected app: AppComponent,
    ) {
        this.setStartInfo();
        this.formList = new FormGroup({

            company_id: new FormControl(0),
            points: new FormControl(0),
            days_of_week: new FormControl('0'),
            hub_id: new FormControl(),
            date_start: new FormControl(this.date, Validators.required),
            date_end: new FormControl(this.date, Validators.required),
        });
        this.selectedHubs = [this.currentUserService.getCurrentHub(),];
    }


    public onChangeStatuses(statuses) {

        this.selectedStatuses = statuses;
        console.log(statuses);
    }


    onChangeHub(hub) {
        this.selectedHubs = [hub,];
        this.selectedHubId = hub.id;
        this.formList.controls.hub_id.setValue(hub.id);
        this.appUserSelectEventsSubjectEmit();
    }

    appUserSelectEventsSubjectEmit() {
        this.appUserSelectEventsSubject.next({type: 'filterIncludeHubsUpdated', filterIncludeHubs: this.selectedHubs});
    }

    onSelectClient(clients: ClientInfo[]) {
        this.selectedClients = clients;
    }

    onSelectCourier(users: User[]) {
        this.selectedUsers = users;
    }

    onChangeZone(zones: OrderZone[]) {
        this.selectedZones = zones;
    }

    prepareQuery() {
        const query = this.formList.value;
        query.date_start = this.datePipe.transform(this.formList.value.date_start, 'yyyy-MM-dd');
        query.date_end = this.datePipe.transform(this.formList.value.date_end, 'yyyy-MM-dd');
        query.client_ids = this.selectedClients.map((client: ClientInfo) => client.id);
        query.courier_ids = this.selectedUsers.map((user: User) => user.id);
        query.zones_ids = this.selectedZones.map((zone: OrderZone) => zone.zone_id);
        query.hub_id = this.selectedHubId;
        query.status = this.selectedStatuses;
        query.courier_types = this.selectedCourierType;
        query.details = '';

        return query;
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (this.formList.value.date_start > this.formList.value.date_end) {
            return this.notifier.openNotifier('Дата начала не может быть больше даты конца', null, {
                class: 'danger',
                duration: 5000
            });
        }
        this.isLoading = true;


        this.statisticsService.getNewStatistic(this.prepareQuery()).subscribe(data => {
            this.isLoading = false;

            this.statData = data.stat;
        }, error => {
            this.isLoading = false;
        });

    }


    /**
     * Установка стартовых данных
     * @private
     */
    private setStartInfo() {
        this.statisticsService.getNewStatStartInfo().subscribe(data => {
            this.parentCompanies = data.parent_companies;
        });
        this.api.getSubstatusesByType('0,1,3,5').subscribe((data: any) => {
            for (let i = 0; i < data.length; i++) {
                if (data[i].substatus_id == 0 || data[i].substatus_id == 28 || (data[i].substatus_id == 15)) {
                    continue;
                }
                if ((data[i].type == 1) || (data[i].type == 5)) { // причины отмены
                    this.cancelReasons.push(data[i]);

                } else { // причины переносов
                    this.changeDateReasons.push(data[i]);
                }
            }
            this.statusNames.push(' - Отмена');
            this.statusValues.push('8');
            for (let i = 0; i < this.cancelReasons.length; i++) {
                this.statusNames.push(' --- ' + this.cancelReasons[i].name);
                this.statusValues.push('8_' + this.cancelReasons[i].substatus_id)
            }

            this.statusNames.push(' - Перенос');
            this.statusValues.push('100');
            for (let i = 0; i < this.changeDateReasons.length; i++) {
                this.statusNames.push(' --- ' + this.changeDateReasons[i].name);
                this.statusValues.push('100_' + this.changeDateReasons[i].substatus_id)
            }
            this.appStatusChangedEventsSubject.next({
                type: 'statusListChanged',
                customStatusesNames: this.statusNames,
                statuses: this.statusValues
            });
        });
    }


    /**
     * Событие при смене типа курьера
     * @param value
     */
    onSelectCourierType(value) {
        this.selectedCourierType = value;
    }

    /**
     * на вход - зона в которой надо показать косячные заказы
     * @param area
     */

    public onDetailsClick(details: string, km_zone: string) {
        let requestData = this.prepareQuery();
        requestData.details = details;
        requestData.km_zone = km_zone;

        //console.log(requestData);
        this.statisticsService.getNewStatisticDetails(requestData).subscribe(responseData => {
            //console.log(responseData);
            this.app.createDialog(NewStatOrdersComponent, {data: responseData})
                .onDestroy(data => {

                })


        }, error => {

        });

    }

    /*const sub = this.dialog.openEditDialog('Изменить стоимость', price, '').subscribe(result => {
        if (result) {
            this.api.changeExpensePrice(id, result).subscribe(data => {
                const row = this.findRow({id: id});
                if (row) {
                    row.price = data.price;
                }
            });
        }

        sub.unsubscribe();*/

}

interface Day {
    value: string;
    name: string;
}

interface Point {
    value: number;
    name: string;
}





