import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ClientInfo} from '../../service/models';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Title} from '@angular/platform-browser';
import {SkladService} from '../../service/sklad.service';
import {HelpersService} from '../../service/helpers.service';
import {DetmirService} from '../../service/detmir.service';


import {MatDialog, MatDialogConfig} from '@angular/material';
import {ChooseDateDialogComponent} from '../../components/choose-date-dialog/choose-date-dialog.component';
import {DialogService} from '../../components/dialog/dialog.service';

@Component({
    selector: 'app-returnacts',
    templateUrl: './returnacts.component.html',
    styleUrls: ['./returnacts.component.scss']
})
export class ReturnactsComponent implements OnInit {
    public returnActList: any[] = [];
    public message: string = '';

    private pageTitle = 'Возвратные акты';
    public selectedClient: ClientInfo;
    public formActs: FormGroup;
    private actId;
    public sendingReturn: boolean = false;
    public gettingLabel: boolean = false;
    public sendingTE: boolean = false;
    @ViewChild('input_datestart') dateStart: ElementRef;
    @ViewChild('input_dateend') dateEnd: ElementRef;

    public chooseDate: boolean = false;

    public print: boolean = false;
    public barcode: string;

    constructor(
        private dialog: MatDialog,
        private title: Title,
        private api: SkladService,
        public helpers: HelpersService,
        private detmirApi: DetmirService,
        private commonDialog: DialogService,
    ) {
        this.title.setTitle(this.pageTitle);
        this.formActs = new FormGroup({
            dateStart: new FormControl('', Validators.required),
            dateEnd: new FormControl('', Validators.required)
        });
    }

    ngOnInit() {
    }


    public onChangeClient(client: ClientInfo) {
        this.selectedClient = client;
        this.clearActs();

    }

    public clearActs() {
        this.message = '';
        this.returnActList = [];
    }


    public onSend(actId) {
        this.actId = actId;
        this.openDialog();

    }

    openDialog() {
        const dialogConfig = new MatDialogConfig();


        dialogConfig.autoFocus = true;
        const tomorrow = new Date();
        tomorrow.setDate(tomorrow.getDate() + 1);
        dialogConfig.data = {
            defaultDate: tomorrow,
            description: 'Выберите дату для возврата'
        };

        const dialogRef = this.dialog.open(ChooseDateDialogComponent, dialogConfig);

        dialogRef.afterClosed().subscribe(
            data => {
                this.sendToDetmir(this.actId, data)
            });
    }


    public printBarcode(barcode) {
        this.print = true;
        this.barcode = barcode;

    }

    public sendToDetmir(actId, date) {
        this.actId = actId;
        if (!date) {
            this.commonDialog.alert('не выбрана дата для возврата!');
            return;
        }
        this.sendingReturn = true;
        this.detmirApi.sendReturnAct(actId, date).subscribe(data => {
            this.sendingReturn = false;
            this.commonDialog.alert('Отправили. Через 10 минут можно получать штрихкод');

            this.loadActs();
        });
    }

    public getLabel(actId) {
        this.actId = actId;
        this.gettingLabel = true;
        this.detmirApi.getLabel(actId).subscribe(data => {
            this.gettingLabel = false;
            this.commonDialog.alert(data.result);
            console.log(data);
            this.loadActs();

        });
    }

    public sendTE(actId) {
        this.actId = actId;
        this.sendingTE = true;
        this.detmirApi.sendTEInfo(actId).subscribe(data => {
            this.sendingTE = false;
            console.log(data);
            this.commonDialog.alert(data.result);
            this.loadActs();

        });
    }


    public showLogs(logs) {
        let str = '<table border="1" class="bordered_table">';
        for (let i = 0; i < logs.length; i++) {

            str += '<tr valign="top"><td>' + logs[i].created_at + '</td><tr><td><xmp>' + logs[i].request + '</xmp></td></tr>';
            str += '<tr><td><xmp>' + logs[i].response + '</xmp></td></tr>';

        }

        str += '</table>';
        this.commonDialog.alert(str, 800, 600, true);
    }

    public loadActs() {


        if (!this.dateStart.nativeElement.value || !this.dateEnd.nativeElement.value) {
            this.message = 'Выберите даты начала и конца периода';
            return;
        }
        this.message = 'подгружаем акты...';
        this.api.getReturnActs(
            (this.selectedClient ? this.selectedClient.id : 0),
            this.helpers.formatDateForSQL(this.dateStart.nativeElement.value),
            this.helpers.formatDateForSQL(this.dateEnd.nativeElement.value)
        ).subscribe(data => {
            console.log(data);
            if ((data != null) && (data.length)) {
                this.returnActList = data;
                this.message = '';
            } else {
                this.message = 'Нет актов за выбранный период';
            }

        });

    }
}
