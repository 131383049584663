import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from "@angular/router";
import {DocReturnService} from "../../../service/doc-return.service";
import {DocReturnAct} from "../../../service/models";
import {Title} from "@angular/platform-browser";

@Component({
    selector: 'app-print-doc-return-act',
    templateUrl: './print-doc-return-act.component.html',
    styleUrls: ['./print-doc-return-act.component.scss']
})
export class PrintDocReturnActComponent implements OnInit {
    public loading: boolean = false;

    public act: DocReturnAct | null;
    public act_id: number;

    constructor(
        protected api: DocReturnService,
        protected route: ActivatedRoute,
        protected title: Title
    ) {
    }

    ngOnInit() {
        this.act = null;
        this.route.params.subscribe(params => {
            this.act_id = params['act_id'];
            this.title.setTitle('Logsis CRM');

            this.loading = true;
            this.api.getAct(this.act_id).subscribe(responsePayload => {
                this.act = new DocReturnAct(responsePayload);
                this.loading = false;

                setTimeout(() => this.printPage(), 500);
            }, () => {
                this.loading = false;
            });
        });
    }

    /**
     * Клик по кнопке печати акта
     */
    onPrint() {
        this.printPage();
    }

    /**
     * Печать акта
     */
    printPage() {
        window.print();
    }
}
