import {Component, OnInit} from '@angular/core';
import {SkladService} from '../../service/sklad.service';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {User, UserHub} from "../../service/models";
import {CurrentUserService} from "../../service/current-user.service";
import {HubsService} from "../../service/hubs.service";
import {EventerService} from "../../service/eventer.service";

@Component({
    selector: 'app-sberbank-control',
    templateUrl: './sberbank-control.component.html',
    styleUrls: ['./sberbank-control.component.scss']
})
export class SberbankControlComponent implements OnInit {
    editQueue = false;
    loading = false;

    public todayCouriers = [];
    public notReadyCouriers = [];
    public readyCouriers = [];
    public statuses = ['Пришёл', 'В режиме сборки', 'Собран']

    counterState = 'counter is ticking';

    public selectedHubId: number;
    public selectedHubs: UserHub[];
    public userHubs: UserHub[];

    constructor(
        private currentUserService: CurrentUserService,
        private eventerService: EventerService,
        private hubsService: HubsService,
        private api: SkladService
    ) {
        this.eventerService.primaryHubChange.subscribe((hub) => {
            this.onChangeHub(hub);
        });
    }

    ngOnInit() {
        this.currentUserService.get().subscribe(async (user: User) => {
            this.userHubs = user.hubs;
            let hub = this.currentUserService.getCurrentHub();

            this.selectedHubs = [hub, ];
            this.selectedHubId = hub.id;
        })
        this.loadingSberbank(this.selectedHubId);
    }

    loadingSberbank(hub_id: number = null) {
        if (null === hub_id) {
            hub_id = this.selectedHubId;
        }
        this.loading = true;
        this.editQueue = false;
        this.api.getSberbankOptimized(hub_id).subscribe(courier => {
            this.notReadyCouriers = courier.filter(item => {
                return item.priority <= 0;
            });
            this.readyCouriers = courier.filter(item => {
                return item.priority > 0;
            });
            this.loading = false;
        });
    }
    dropped(event: CdkDragDrop<string[]>) {
        moveItemInArray(
            this.readyCouriers,
            event.previousIndex,
            event.currentIndex
        );
    }
    saveQueue() {
        this.editQueue = !this.editQueue;
        if (this.editQueue) { return; }
        const couriers: any = this.readyCouriers.map(data => {
            return data.id;
        });
        this.saveAssemblePriorities(couriers);
    }
    startAssemble(id, index) {
        this.api.startAssemble(id).subscribe(data => {
            console.log(data);
            for (const courier of this.notReadyCouriers) {
                if (courier.id === id) {
                    this.readyCouriers.push(courier);
                }
            }
            this.notReadyCouriers.splice(index, 1);
        })
    }
    saveAssemblePriorities(couriers) {
        this.api.saveAssemblePriorities({id: couriers}).subscribe( data => {
            this.loadingSberbank(this.selectedHubId)
        })
    }
    timeAssemble (start, end, status) {
        const today = new Date();
        const month = +today.getMonth() + 1;
        if (status === 2) {
            const str = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + start;
            return Math.round(((new Date().getTime() - new Date(str).getTime()) / 1000) / 60);
        } else if (status === 3) {
            const start_time = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + start;
            const end_time = today.getFullYear() + '-' + month + '-' + today.getDate() + ' ' + end;
            return Math.round(((new Date(end_time).getTime() - new Date(start_time).getTime()) / 1000) / 60);
        }
    }

    onChangeHub(hub) {
        if (!this.currentUserService.hasHub(hub.id))
        {
            hub = this.currentUserService.getCurrentHub();
        }

        this.selectedHubs = [hub, ];
        this.selectedHubId = hub.id;
        this.loadingSberbank(this.selectedHubId);
    }
}
