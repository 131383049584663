import {Component, OnInit} from '@angular/core';
import {GridDataResult} from '@progress/kendo-angular-grid';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NotifierService} from '../../service/notifier.service';
import {DatePipe} from '@angular/common';
import {HelpersService} from '../../service/helpers.service';
import {FinanceService} from '../../service/finance.service';

@Component({
    selector: 'app-transfer-orders',
    templateUrl: './transfer-orders.components.html',
    styleUrls: ['./transfer-orders.component.scss']
})
export class TransferOrdersComponent implements OnInit {
    public gridData: GridDataResult;
    public date = new Date();
    public formList: FormGroup;
    public isLoading = false;
    public data;
    svg: string;
    CODE128: string;
    bottom: string;
    center: string;
    black: string;
    monospace: string;
    public loading = false;

    ngOnInit(): void {
    }

    constructor(
        private notifier: NotifierService,
        private datePipe: DatePipe,
        public helpers: HelpersService,
        protected api: FinanceService,
    ) {
        this.formList = new FormGroup({
            date: new FormControl(this.date, Validators.required),
            company_transfer_id: new FormControl(Validators.required),
            company_receives_id: new FormControl(Validators.required),
        });
    }

    /**
     * Загрузка отчета
     */
    loadData() {
        if (!this.checkError()) {
            return;
        }
        const query = this.prepareQuery();
        this.startLoad(query);
    }

    startLoad(query) {
        this.isLoading = true;
        this.api.getTransferOrders(query).subscribe(data => {
            this.data = data;
            this.isLoading = false;
            setTimeout(() => {
                window.print()
            }, 1000);
        }, error => {
            this.isLoading = false;
        });

    }

    public checkError() {
        if (!this.formList.value.date) {
            this.notifier.openNotifier('Дата выборки не может быть пустой', null, {class: 'danger', duration: 5000});
            return false;
        }
        return true;
    }

    public prepareQuery() {
        const values = this.formList.value;
        values.date = this.datePipe.transform(this.formList.value.date, 'yyyy-MM-dd');
        return values;
    }

    OnChangeCompany(value, type) {
        if (type < 2) {
            this.formList.controls.company_transfer_id.setValue(value ? value.id : null);
        } else {
            this.formList.controls.company_receives_id.setValue(value ? value.id : null);
        }
    }
}
