import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-pickup-delivery',
    templateUrl: './pickup-delivery.component.html',
    styleUrls: ['./pickup-delivery.component.scss']
})
export class PickupDeliveryComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
    }

}
